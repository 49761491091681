import React, { useEffect, useState } from "react";
import Api from "../Api";

export default () => {
  const [packageState, setPackageState] = useState({
    loading: true,
    packages: null
  });

  useEffect(() => {
    const fetchData = async () => {
      Api.get("/api/v1/packages", {}).then(res => {
        setPackageState({
          ...packageState,
          loading: false,
          packages: res.data
        });
      });
    };
    fetchData();
  }, []);

  return [packageState];
};
