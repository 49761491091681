import React, { useState, useEffect } from "react";
import axios from "axios";
import { css, cx } from "emotion";

import Spinner from "../Spinner";
import Title from "../Title";
import Question from "./Question";
import Footer from '../v2/shared/Footer'

export default ({ reverse = false }) => {
  const [state, setState] = useState({ faqs: null, loading: true });

  useEffect(() => {
    let cancel;
    const fetchData = async () => {
      await axios
        .get("/api/v1/faqs", {
          cancelToken: new axios.CancelToken(c => (cancel = c))
        })
        .then(res => {
          setState({
            ...state,
            loading: false,
            faqs: res.data
          });
        });
      return () => cancel();
    };
    fetchData();
  }, []);

  return (
    <>
      <div className={reverse ? "bg-white" : "bg-light"}>
        <Title
          title="FAQ"
          description="Infinit Care frequently asked questions"
        />
      </div>
      <div className={reverse ? "bg-light" : "bg-white"}>
        <div
          className={cx(
            "container",
            css`
              padding: 2rem 0;
            `
          )}
        >
          {state.faqs ? (
            <div className="d-flex flex-column align-items-center">
              {state.faqs.map((question, index) => (
                <Question
                  key={index}
                  question={question.question}
                  answer={question.answer}
                />
              ))}
            </div>
          ) : (
            <Spinner />
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};
