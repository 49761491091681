import axios from 'axios';
import useSWR from 'swr';
import React from 'react';
import styled from '@emotion/styled';
import { Col, Row, Button } from 'react-bootstrap';

import Track from './Track';
import {
  useAppData
} from '../contexts/AppDataProvider';
import { openChat } from '../../../packs/freshchat';
import BookASessionButton from './BookASessionButton';

const StyledBookASessionCard = styled.div`
  margin-top: 6rem;
  margin-bottom: 6rem;
  background-color: #e28986;
  padding: 2rem;
  margin-left: 3rem;
  margin-right: 3rem;
  color: white;
  h3 {
    font-size: 26px;
    font-weight: 600;
  }
  @media (max-width: 575.98px) {
    margin-left: 0;
    margin-right: 0;
    margin-top: 2rem;
  }
  p {
    font-size: 14px;
  }
`;

const BookButton = styled(Button)`
  color: #1db4b3;
  width: 150px;
  font-weight: 700;
  font-size: 0.8rem;
  padding: 0.5rem;
  &:hover {
    border: 1px solid #fff;
    background-color: #fff;
    color: #1db4b3;
  }
`;

const fetcher = (url) => axios.get(url).then((res) => res.data);
const BookASessionCard = () => {
  const { data } = useSWR('/api/v2/meetings', fetcher);
  const { bookASessionLandingPage, withChat, companyID } = useAppData();
  return (
    <StyledBookASessionCard className="rounded">
      <Row>
        <Col md={8}>
          <h3>Are you ready to book a counseling session?</h3>
          <p>
            Get counseling support for your mental well-being.
            <br />
            Infinit Care connects you to counselors from the comfort of your own
            home.
          </p>
        </Col>
        <Col
          md={4}
          className="text-center flex-column align-items-center d-flex justify-content-center">
          <div style={{ width: '150px' }}>
            {!companyID && data?.sessions_left <= 0 && (
              <BookButton
                href="/individual/dashboard/packages"
                onClick={() => Track.bookASession(bookASessionLandingPage())}
                className="bg-white border border-white rounded my-2">
                Book a session
              </BookButton>
            )}
            {(data?.sessions_left > 0 || (companyID && data?.sessions_left <= 0)) && (
              <BookASessionButton page={bookASessionLandingPage()}/>
            )}
          </div>
          {withChat && (
            <BookButton
              onClick={openChat}
              className="bg-white border border-white rounded my-2">
              Chat with us
            </BookButton>
          )}
        </Col>
      </Row>
    </StyledBookASessionCard>
  );
};

export default BookASessionCard;
