import React from "react";
import { injectGlobal } from "emotion";

injectGlobal`
  #patient-app-spinner-container {
    position: relative;
  }
  #patient-app-spinner {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    pointer-events: none;
  }

  #patient-app-spinner .center {
    display: block;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  #patient-app-spinner .spinner-wrapper {
    display: block;
    position: relative;
    width: 100%;
    min-height: 100px;
    height: 100px;
  }

  #patient-app-spinner .spinner-wrapper .spinner {
    position: absolute;
    overflow: hidden;
    left: 50%;
    margin-left: -50px;
    animation: outer-rotate 2.91667s linear infinite;
  }

  #patient-app-spinner .spinner-wrapper .spinner .inner {
    width: 100px;
    height: 100px;
    position: relative;
    animation: sporadic-rotate 5.25s cubic-bezier(0.35, 0, 0.25, 1) infinite;
  }

  #patient-app-spinner .spinner-wrapper .spinner .inner .gap {
    position: absolute;
    left: 49px;
    right: 49px;
    top: 0;
    bottom: 0;
    border-top: 10px solid;
    box-sizing: border-box;
  }

  #patient-app-spinner .spinner-wrapper .spinner .inner .left,
  #patient-app-spinner .spinner-wrapper .spinner .inner .right {
    position: absolute;
    top: 0;
    height: 100px;
    width: 50px;
    overflow: hidden;
  }

  #patient-app-spinner .spinner-wrapper .spinner .inner .left .half-circle,
  #patient-app-spinner .spinner-wrapper .spinner .inner .right .half-circle {
    position: absolute;
    top: 0;
    width: 100px;
    height: 100px;
    box-sizing: border-box;
    border: 10px solid #00a2a1;
    border-bottom-color: transparent;
    border-radius: 50%;
  }

  #patient-app-spinner .spinner-wrapper .spinner .inner .left {
    left: 0;
  }

  #patient-app-spinner .spinner-wrapper .spinner .inner .left .half-circle {
    left: 0;
    border-right-color: transparent;
    animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
    -webkit-animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1)
      infinite;
  }

  #patient-app-spinner .spinner-wrapper .spinner .inner .right {
    right: 0;
  }

  #patient-app-spinner .spinner-wrapper .spinner .inner .right .half-circle {
    right: 0;
    border-left-color: transparent;
    animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
    -webkit-animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1)
      infinite;
  }

  @keyframes outer-rotate {
    0% {
      transform: rotate(0deg) scale(0.5);
    }
    100% {
      transform: rotate(360deg) scale(0.5);
    }
  }

  @keyframes left-wobble {
    0%,
    100% {
      transform: rotate(130deg);
    }
    50% {
      transform: rotate(-5deg);
    }
  }

  @keyframes right-wobble {
    0%,
    100% {
      transform: rotate(-130deg);
    }
    50% {
      transform: rotate(5deg);
    }
  }

  @keyframes sporadic-rotate {
    12.5% {
      transform: rotate(135deg);
    }
    25% {
      transform: rotate(270deg);
    }
    37.5% {
      transform: rotate(405deg);
    }
    50% {
      transform: rotate(540deg);
    }
    62.5% {
      transform: rotate(675deg);
    }
    75% {
      transform: rotate(810deg);
    }
    87.5% {
      transform: rotate(945deg);
    }
    100% {
      transform: rotate(1080deg);
    }
  }
`;

export default () => {
  return (
    <div id="patient-app-spinner-container">
      <div id="patient-app-spinner">
        <div className="center">
          <div className="spinner-wrapper">
            <div className="spinner">
              <div className="inner">
                <div className="gap"></div>
                <div className="left">
                  <div className="half-circle"></div>
                </div>
                <div className="right">
                  <div className="half-circle"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
