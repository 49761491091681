import React, { useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import 'react-day-picker/dist/style.css';
import UIBack from '../../shared/ui/Back';
import { StyledHeader } from './styles';
import styled from '@emotion/styled';
import * as moment from 'moment';
import CoachModal from './CoachModal';
import UIProfileImage from '../../shared/ui/ProfileImage';
import { useBookSessionData } from '../../contexts/BookSessionDataProvider';
import { useAppData } from '../../contexts/AppDataProvider';

const StyledSubHeader = styled.p`
  font-size: 12px;
  color: #333333;
  font-weight: bold;
  opacity: 0.7;
  letter-spacing: 1.2px;
`;
const CoachContainer = styled.div`
  background-color: #f3fbfb;
  padding: 46px 39px;
  .coach-info {
    .name {
      color: #1db4b3;
      font-weight: 600;
    }
    .title {
      font-size: 12px;
    }
  }
`;

const ReminderContainer = styled.div`
  padding: 46px 39px;
  background-color: #f8f8f8;
`;
export const StyledName = styled.span`
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
`;
const BookingDone = () => {
  const [showCoachModal, setShowCoachModal] = useState(false);
  const {
    selectedSession,
    reviewDetails: { booking_details, professional },
  } = useBookSessionData();
  const { cancellation_period_time_in_words } = useAppData()
  return (
    <>
      <CoachModal
        showCoachModal={showCoachModal}
        setShowCoachModal={setShowCoachModal}
        professional={professional}
      />
      <UIBack text={'Go Back Home'} to="/" />
      <Container>
        <StyledHeader>
          <h3>Get ready for your online {selectedSession} session!</h3>
        </StyledHeader>
        <Row>
          <Col lg={4} md={5}>
            <CoachContainer className='mb-4'>
              <StyledSubHeader>
                YOUR {selectedSession == 'coaching' ? 'COACH' : 'COUNSELOR'}
              </StyledSubHeader>
              <div className="d-flex mb-3 align-items-center">
                <UIProfileImage
                  src={professional?.avatar_url || '/coaching/profile.png'}
                  className="mr-2"
                />
                <div className="coach-info">
                  <StyledName
                    className="name d-block"
                    onClick={() => setShowCoachModal(true)}>
                    {professional?.full_name}
                  </StyledName>
                  <span className="title d-block">{professional?.title}</span>
                </div>
              </div>
              <hr className="mb-3" />
              <StyledSubHeader>DATE & TIME</StyledSubHeader>
              <span className="d-block">
                {moment(booking_details?.meeting_date).format('dddd, LL')}
              </span>
              <span className="d-block">
                {moment(booking_details?.start_time)?.format('LT')} to{' '}
                {moment(booking_details?.end_time)?.format('LT')}
              </span>
            </CoachContainer>
          </Col>
          <Col lg={8} md={7}>
            <ReminderContainer>
              <StyledSubHeader>
                REMINDERS ABOUT YOUR {selectedSession.toUpperCase()} SESSION
              </StyledSubHeader>
              <ul>
                <li>
                  Please join the Zoom link for your {selectedSession} session
                  at the appointed time
                </li>
                <li>
                  Your {selectedSession == 'coaching' ? 'coach' : 'counselor'}{' '}
                  will wait up to 15 minutes, after which you will be marked as
                  a no-show
                </li>
                <li>
                  Cancelled sessions in less than {cancellation_period_time_in_words} and no-shows are wakoko
                  marked as <b>`completed`</b>.
                </li>
                <li>
                  To rebook a session:
                  <ul>
                    <li>
                      Cancel your existing booking more than {cancellation_period_time_in_words} ahead of
                      the original appointment
                    </li>
                    <li>Book a new appointment</li>
                  </ul>
                </li>
              </ul>
            </ReminderContainer>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <StyledSubHeader className="mt-3">What’s next?</StyledSubHeader>
            <p>You will receive an email from us about your appointment.</p>
            <p>The email shall include:</p>
            <ul>
              <li>Confirmed date and time of your appointment</li>
              <li>The link to the videoconferencing session (via Zoom)</li>
              <li>Tips on how to prepare for your {selectedSession} session</li>
            </ul>
            <p className="mt-4">
              If you have any questions or you need assistance, you can contact
              us at support@infinitcare.co.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default BookingDone;
