import React, { useState, useEffect } from 'react';
import { useBookSessionData } from '../contexts/BookSessionDataProvider';
import { UIBackButton } from '../shared/ui/Back';
import { StyledHeader, StyledUIButton } from './Coaching/styles';
import { Row } from 'react-bootstrap';
import { useAppData } from '../contexts/AppDataProvider';
import moment from 'moment';

const SalaryDeductionForm = () => {
  const { currentStep, setCurrentStep, setPreviousStep, selectedSession } =
    useBookSessionData();
  const { firstName, lastName, consentSigned, hasPreference } = useAppData();
  const userFullName = firstName + ' ' + lastName;
  const currentDate = moment().format('MMMM D, YYYY');
  const [authorityToDeductHTML, setHTML] = useState('');

  useEffect(() => {
    const fetchAuthorityToDeductHTML = async () => {
      const res = await fetch('/api/v2/companies/authority_to_deduct', {
        headers: { 'Content-Type': 'application/json' },
      });
      const { authority_to_deduct_copy } = await res.json();
      setHTML(authority_to_deduct_copy);
    };
    fetchAuthorityToDeductHTML();
  }, []);

  const handleOnSubmit = async () => {
    const params = { company_user: { agreed_to_salary_deduction: true } };
    const res = await fetch('/api/v2/company_users/update', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    if (res.status === 200) {
       let nextStep = 'preference_form';
      if (hasPreference && selectedSession == 'counseling') {
        nextStep = consentSigned ? 'select_date_time' : 'consent_form';
      }
      if (selectedSession == 'coaching') {
        nextStep = 'select_reasons';
      }
      setPreviousStep(currentStep);
      setCurrentStep(nextStep);
    }
  };

  return (
    <>
      <UIBackButton onClick={() => setCurrentStep('out_of_session')} />
      <StyledHeader>
        <p className="book-session">BOOK A SESSION</p>
        <h3>Authority to Deduct</h3>
      </StyledHeader>
      <Row>
        <section
          className="p-4"
          dangerouslySetInnerHTML={{ __html: authorityToDeductHTML }}></section>
      </Row>
      <Row className="justify-content-center m-0 mt-4">
        <h6 className="font-weight-bold w-100 text-center d-block">
          Employee Acknowledgement:
        </h6>
        <p>
          Name: {userFullName} <br /> Date: {currentDate}{' '}
        </p>
      </Row>
      <Row>
        <StyledUIButton
          text="Submit"
          className="mt-2"
          action={() => handleOnSubmit()}></StyledUIButton>
      </Row>
    </>
  );
};
export default SalaryDeductionForm;
