import React from 'react';
import styled from '@emotion/styled';
import { Col, Row } from 'react-bootstrap';
import ContentCard from '../Content/Card';

const MoreContentCol = styled(Col)`
  margin: 0 auto;
  @media (max-width: 575.98px) {
    padding: 0 15px;
  }
  h3 {
    font-size: 1.2rem;
    font-weight: normal;
  }
`;

const RelatedContents = ({ relatedContents }) => {
  return (
    <MoreContentCol className="mt-5">
      <Row>
        <Col>
          <h3>Related Content</h3>
        </Col>
      </Row>
      <Row>
        {relatedContents.map((content, index) => {
          return <ContentCard withContent={false} key={index} data={content} />;
        })}
      </Row>
    </MoreContentCol>
  );
};

export default RelatedContents;
