import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Preferences from '../Preferences';
import CategoryPreferences from '../Preferences/Categories';
import Categories from '../Categories';
import Pricing from '../Pricing';
import Content from '../Content';
import Pag404 from '../Pag404';
import CompanyUsers from '../Company/CompanyUsers';
import Webinars from '../Webinars';
import Webinar from '../Webinars/Webinar';
import LearningModules from '../LearningModules';
import LearningModule from '../LearningModules/show';
import Assessment from '../Assessment';
import AssessmentResult from '../Assessment/Result';
import AssessmentUserForm from '../Assessment/AssessmentUserForm';
import Dashboard from '../Dashboard';
import MoodTracker from '../MoodTracker';
import ExploreComponent from '../Explore';
import AccountDeleted from '../AccountDeleted';
import CompanyMeetingSessionsRequests from '../Company/MeetingSessionsRequests';
import CompanyDashboard from '../CompanyDashboard';
import BookSession from '../BookSession';
import PodcastView from '../Podcast/PodcastView';
import VerifyEmail from '../VerifyEmail';
import SuccessScreen from '../SuccessScreen';

const ActiveRoutes = ({ sessionsLeft, companyID }) => (
  <Router basename="/">
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route exact path="/categories" component={CategoryPreferences} />
      <Route exact path="/categories/:id" component={Categories} />
      <Route exact path="/preferences" component={Preferences} />
      <Route exact path="/pricing" component={Pricing} />
      <Route path="/content/:slug" component={Content} />
      <Route exact path="/company" component={CompanyDashboard} />
      <Route exact path="/company/users" component={CompanyUsers} />
      <Route
        exact
        path="/company/meeting_sessions_requests"
        component={CompanyMeetingSessionsRequests}
      />
      <Route exact path="/webinars" component={Webinars} />
      <Route exact path="/webinars/:slug" component={Webinar} />
      <Route exact path="/learning_modules/" component={LearningModules} />
      <Route exact path="/learning_modules/:id" component={LearningModule} />
      <Route exact path="/my_learning_modules" component={LearningModules} />
      <Route exact path="/assessment" component={Assessment} />
      <Route exact path="/assessment/result" component={AssessmentResult} />
      <Route
        exact
        path="/assessment/user_form"
        component={AssessmentUserForm}
      />
      <Route path="/insights" component={MoodTracker} />
      <Route path="/explore" component={ExploreComponent} />
      <Route path="/account_deleted" component={AccountDeleted} />
      {(sessionsLeft > 0 || (companyID && sessionsLeft <= 0)) && (
        <Route path="/book_session" component={BookSession} />
      )}
      <Route path="/podcasts/:id" component={PodcastView} />
      <Route exact path="/verify_email" component={VerifyEmail} />
      <Route exact path="/success" component={SuccessScreen} />
      <Route component={Pag404} />
    </Switch>
  </Router>
);

export default ActiveRoutes;
