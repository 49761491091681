import React from 'react';
import styled from '@emotion/styled';
import moment from 'moment';

const AssessmentHistoryContainer = styled.div`
  height: 100%;
  @media (max-width: 991.98px) {
    margin-top: 50px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 767.98px) {
    margin-top: 50px;
    width: 100%;
  }
`;
const Header = styled.h6`
  font-size: 1.125rem;
  font-weight: bold;
  text-align: center;
  @media (max-width: 767.98px) {
    font-size: 1rem;
  }
`;

const AssessmentEventsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
`;

const AssessmentEvent = styled.div`
  position: relative;
  display: block;
  width: 100%;
  .date,
  .score {
    font-size: 0.875rem;
  }
  .date {
    margin-bottom: 0px;
  }
  @media (max-width: 992px) {
    .date {
      padding: 0px;
    }
  }
  .score {
    font-weight: 600;
  }
  .right {
    text-align: right;
  }
  @media (max-width: 767.98px) {
    .left {
      padding-left: 20%;
    }
    .right {
      padding-right: 20%;
    }
  }
  @media (max-width: 575.98px) {
    .left {
      padding-left: 10%;
    }
    .right {
      padding-right: 10%;
    }
  }
`;

const TimeLineButton = styled.span`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: white;
  box-shadow: 0px 8px 24px #00000029;
  position: absolute;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 0;
`;

const TimeLine = styled.div`
  width: 8px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 20px;
  text-align: center;
  background: #eaf3fa 0% 0% no-repeat padding-box;
  border-radius: 4px;
  position: absolute;
  top: 40px;
  z-index: 0;
  @media (max-width: 991.98px) {
    margin-top: 50px;
  }
`;

const AssessmentHistory = ({ assessments }) => {
  return (
    <AssessmentHistoryContainer>
      <Header>Assessment History</Header>
      <AssessmentEventsContainer>
        {assessments.map((assessment, index) => (
          <AssessmentEvent key={assessment.id}>
            <div className={`${index % 2 == 1 ? 'right' : 'left'}`}>
              <p className="date">
                {moment(assessment.created_at).format('MMMM YYYY')}
              </p>
              <p className="score">{assessment.score} pts</p>
            </div>

            <TimeLineButton></TimeLineButton>
          </AssessmentEvent>
        ))}

        <TimeLine></TimeLine>
      </AssessmentEventsContainer>
    </AssessmentHistoryContainer>
  );
};

export default AssessmentHistory;
