import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Container, Row, Col } from 'react-bootstrap';

import ContentCard from '../Content/Card';
import ModuleCard from '../LearningModules/Card';
import Spinner from '../../Spinner';

const SearchResults = () => {
  const [modules, setModules] = useState([]);
  const [contents, setContents] = useState([]);
  const [busy, setBusy] = useState(true);

  const search = useLocation().search;
  const searchedKeyword = new URLSearchParams(search).get('keyword');
  const modulesUrl = '/api/v2/learning_modules?keywords=' + searchedKeyword;
  const contentsUrl =
    '/api/v2/contents/search?' +
    `terms[title]=${searchedKeyword}&` +
    `terms[category]=${searchedKeyword}`;

  const NoResults = () => (
    <h5 className="text-center min-vh-100">No search results...</h5>
  );

  useEffect(() => {
    fetchFeed();
  }, []);

  async function fetchFeed() {
    setBusy(true);
    await Promise.all([fetchContents(), fetchModules()]);
    setBusy(false);
  }

  async function fetchContents() {
    const res = await fetch(contentsUrl);
    if (res.status === 200) {
      setContents(await res.json());
    }
  }

  async function fetchModules() {
    const res = await fetch(modulesUrl);
    if (res.status === 200) {
      setModules(await res.json());
    }
  }

  function hasResults() {
    return !busy && (contents.length > 0 || modules.length > 0);
  }

  function hasNoResults() {
    return !busy && contents.length < 1 && modules.length < 1;
  }

  return (
    <Container className="min-vh-100">
      {busy && <Spinner />}
      {hasResults() && (
        <>
          <Row>
            <Col>
              <p className="text-left">
                Showing results for "<b>{searchedKeyword}</b>"
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center m-0 p-0">
            {modules.map((module, index) => (
              <ModuleCard key={`module-${index}`} module={module} />
            ))}
            {contents.map((content, index) => (
              <ContentCard
                key={`content-${index}`}
                withContent={true}
                data={content}
              />
            ))}
          </Row>
        </>
      )}
      {hasNoResults() && <NoResults />}
    </Container>
  );
};

export default SearchResults;
