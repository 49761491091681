import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
  color: #00a2a1;
  &:hover {
    color: #00a2a1;
  }
`;

const UILink = ({ children, to }) => {
  return <StyledLink to={to}>{children}</StyledLink>;
};

export default UILink;
