import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import styled from '@emotion/styled';
import useSWR from 'swr';
import axios from 'axios';
import { useAppData } from '../contexts/AppDataProvider';
import { CheckIn as CheckInTracker } from '../shared/Track';

const ModalHeader = styled(Modal.Header)`
  border-bottom: none;
`;
const ModalBody = styled.div`
  padding: 0 2rem 2rem 2rem;
  font-size: 1rem;
  color: #333333;
  h4 {
    font-size: 1.25rem;
  }
`;
const MoodChooser = styled.div`
  margin: 2rem 0;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  font-weight: medium;
  align-items: center;
  > span {
    margin: 0 10px;
  }
`;

const ResponseActions = styled.div`
  button {
    font-weight: bold;
  }
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
`;

const ReasonChooser = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  button {
    margin: 0.5rem;
    border: 1px solid #f9f9f9;
    border-radius: 10px;
    line-height: 2.5;
    outline: none !important;
    box-shadow: none !important;
    &:hover,
    &.selected {
      color: #62cdcc;
      background: #effafa;
      border: 1px solid #effafa;
      cursor: pointer;
    }
    &:active {
      background: none !important;
      color: #6c757d !important;
    }
  }
`;

const StyledFigure = styled.figure`
  width: 90px;
  padding: 10px;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
  &.selected {
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    cursor: pointer;
  }
  img {
    width: 60px;
  }
  figcaption {
    text-transform: capitalize;
    padding-top: 10px;
    font-size: 14px;
  }
`;

const InsightLink = styled.a`
  color: #1db4b3;
  &:hover {
    color: #1db4b3;
  }
`;

const CheckInModal = ({
  setShowCheckInModal = () => {},
  hideCheckInDashboard = () => {},
}) => {
  const [modalStep, setModalStep] = useState(1);
  const [formData, setFormData] = useState({
    check_in_mood_id: null,
    reasons: [],
    notes: null,
  });
  const [didAnswer, setDidAnswer] = useState(false);

  const handleClickMood = (value) => {
    const checkInMood = formData['check_in_mood_id'] === value;
    if (!checkInMood) {
      setFormData({ ...formData, ['check_in_mood_id']: value });
    }
  };
  const handleClickReasons = (value) => {
    if (formData['reasons'].includes(value)) {
      setFormData({
        ...formData,
        reasons: formData['reasons'].filter((item) => item !== value),
      });
    } else {
      setFormData({
        ...formData,
        reasons: [...formData.reasons, value],
      });
    }
  };

  const handleNotes = (e) =>{
    if (e.target.value.trim().length > 0) {
      setFormData({
        ...formData,
        notes: e.target.value,
      });
    }else{
      setFormData({
        ...formData,
        notes: null,
      });
    }

  }
  const handleMoodSkip = async () => {
    setShouldCheckIn(false);
    hideCheckInDashboard();
    try {
      await axios.post('/api/v2/check_ins', {
        check_in: { skipped: true },
      });
    } catch {}
  };

  const handleModalHidden = () => {
    CheckInTracker.closedModal();
    setShouldCheckIn(false);
    setShowCheckInModal(false);
    if (didAnswer) {
      hideCheckInDashboard();
      window.location = '/insights';
    }
  };

  const saveCheckIn = async () => {
    try {
      await axios.post('/api/v2/check_ins', { check_in: formData });
      setModalStep(4);
      setDidAnswer(true);
    } catch {}
  };

  const { data } = useSWR('/api/v2/check_in/moods');
  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data: reasons } = useSWR('/api/v2/check_in/reasons', fetcher, {
    revalidateOnFocus: false,
  });
  const { firstName } = useAppData();
  const [shouldCheckIn, setShouldCheckIn] = useState(true);

  return (
    <Modal
      dialogClassName="modal-50w"
      show={shouldCheckIn}
      onHide={() => handleModalHidden()}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <ModalHeader closeButton></ModalHeader>
      <ModalBody>
        {modalStep === 1 && (
          <>
            <Modal.Body>
              <h4 className="text-center">
                <strong>Hi, {firstName}!</strong>
              </h4>
              <p className="text-center">How are you feeling today?</p>
              <MoodChooser>
                {data
                  ?.sort((a, b) => a.position - b.position)
                  .map(({ id, title, image_url }) => (
                    <span key={id} onClick={() => handleClickMood(id)}>
                      {formData && (
                        <StyledFigure
                          className={`${
                            formData['check_in_mood_id'] == id ? 'selected' : ''
                          }`}>
                          <img src={image_url ?? '/default_mood.png'} />
                          <figcaption>
                            <strong>{title}</strong>
                          </figcaption>
                        </StyledFigure>
                      )}
                    </span>
                  ))}
              </MoodChooser>
              <ResponseActions>
                {formData && formData['check_in_mood_id'] && (
                  <Button
                    size="sm"
                    variant="primary"
                    className="btn-green mb-1 py-2 px-4"
                    onClick={() => setModalStep(2)}>
                    Next
                  </Button>
                )}
                {formData && !formData['check_in_mood_id'] && (
                  <Button
                    size="sm"
                    variant="primary"
                    className="btn-secondary mb-1 py-2 px-4 disabled">
                    Next
                  </Button>
                )}
                <Button
                  size="sm"
                  variant="link"
                  className="text-muted mt-2"
                  onClick={handleMoodSkip}>
                  skip
                </Button>
              </ResponseActions>
            </Modal.Body>
          </>
        )}
        {modalStep === 2 && (
          <>
            <Modal.Body>
              <h4 className="text-center">
                <strong>What makes you feel that way?</strong>
              </h4>
              <p className="text-center">You may choose more than one.</p>
              <ReasonChooser>
                {reasons?.map(({ id, title }) => (
                  <span key={id}>
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      onClick={() => handleClickReasons(title)}
                      className={`${
                        formData['reasons'].includes(title) ? 'selected' : ''
                      }`}>
                      {title}
                    </Button>
                  </span>
                ))}
              </ReasonChooser>
              <ResponseActions>
                <Button
                  size="sm"
                  variant="primary"
                  disabled={formData['reasons'].length == 0}
                  className={`mb-1 py-2 px-4 ${
                    formData['reasons'].length > 0
                      ? 'btn-green'
                      : 'btn-secondary disabled'
                  }`}
                  onClick={() => setModalStep(3)}>
                  Next
                </Button>
                <Button
                  size="sm"
                  variant="link"
                  className="text-muted mt-2"
                  onClick={handleModalHidden}>
                  Not Now
                </Button>
              </ResponseActions>
            </Modal.Body>
          </>
        )}
        {modalStep === 3 && (
          <>
            <Modal.Body>
              <h4 className="text-center">
                <strong>Write down anything that comes to mind.</strong>
              </h4>
              <p className="text-center">
                Use this space to reflect on your thoughts and feelings. What's
                on your mind right now?
              </p>
              <textarea
                className="form-control mt-5 mb-4 p-2"
                rows="4"
                placeholder="You were feeling that way because…"
                onChange={handleNotes}></textarea>
              <p className="text-center">
                <small>
                  When you save notes, they’re stored in our secure database.
                </small>
              </p>
              <ResponseActions>
                <Button
                  size="sm"
                  variant="primary"
                  className={`mb-1 py-2 px-4 ${
                    formData['notes']?.length > 0
                      ? 'btn-green'
                      : 'btn-secondary disabled'
                  }`}
                  disabled={
                    !formData['notes']?.length || formData['notes']?.length == 0
                  }
                  onClick={() => saveCheckIn()}>
                  Save
                </Button>
                <Button
                  size="sm"
                  variant="link"
                  className="text-green mt-2"
                  onClick={() => saveCheckIn()}>
                  Save without notes
                </Button>
              </ResponseActions>
            </Modal.Body>
          </>
        )}
        {modalStep === 4 && (
          <>
            <Modal.Body>
              <h4 className="mb-4 text-center">
                <strong>Thank you for logging your mood.</strong>
              </h4>
              <p className="text-center">
                Being aware of your moods and feelings is an important step in
                self-care.
              </p>
              <p className="text-center">
                To understand your patterns and mood shifts, visit the{' '}
                <Button
                  onClick={() => handleModalHidden()}
                  variant="link"
                  className="text-green p-0">
                  Insights page
                </Button>
                .
              </p>
              <ResponseActions>
                <Button
                  onClick={() => handleModalHidden()}
                  className="btn btn-green mr-2 border">
                  <h6 className="m-1">Visit the Insights page</h6>
                </Button>
              </ResponseActions>
            </Modal.Body>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default CheckInModal;
