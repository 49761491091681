import axios from 'axios';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import useSWR from 'swr';
import { useHistory } from 'react-router-dom';
import { Col, Row, Button } from 'react-bootstrap';

import Layout from '../Layout';
import { useAppData } from '../contexts/AppDataProvider';

const StyledCol = styled(Col)`
  text-align: center;
  margin-bottom: 2rem;
  > div {
    background-color: #f8f8f8;
    border-radius: 10px;
    padding: 1.5rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
`;

const StyleQuestionHeader = styled(Row)`
  text-align: center;
  margin-bottom: 4rem;
  margin-top: 3rem;
  h2 {
    font-weight: bold;
    font-size: 1.5rem;
  }
`;

const StyledBackButton = styled(Button)`
  font-weight: bold;
  width: 120px;
  padding: 8px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px #1db4b3 solid;
  background: white;
  color: #1db4b3;
  margin-right: 10px;
  &:hover,
  &:focus {
    border: 1px #1db4b3 solid !important;
    background: white !important;
    color: #1db4b3 !important;
  }
`;

const StyledSubmitButton = styled(Button)`
  background: #1db4b3;
  font-weight: bold;
  width: 120px;
  padding: 8px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px #1db4b3 solid;
  &:hover,
  &:focus {
    border: 1px #1db4b3 solid !important;
    background: #1db4b3 !important;
    color: white !important;
  }
`;

const fetcher = (url) => axios.get(url).then((res) => res.data);

const Preferences = () => {
  let history = useHistory();
  const { userId } = useAppData();
  const [preferences, setPreferences] = useState([]);

  const handlePreferenceClick = (preference) => {
    const foundPreference = preferences.find((pref) => pref === preference);

    if (!foundPreference) {
      setPreferences((prevState) => [...prevState, preference]);
    } else {
      setPreferences((prevState) =>
        prevState.filter((pref) => preference !== pref),
      );
    }
  };

  const { data } = useSWR('/api/v2/categories?onboarding=true', fetcher);

  const saveCategories = async () => {
    const queryString = preferences.map(
      (preference) => `category_ids[]=${preference}`,
    );
    try {
      await axios.post(
        `/api/v2/users/${userId}/categories?`,
        queryString.join('&'),
      );
      history.push('/');
    } catch {}
  };

  return (
    <Layout>
      <StyleQuestionHeader>
        <Col>
          <h2>What would you like to work on?</h2>
          <p>Select all that apply</p>
        </Col>
      </StyleQuestionHeader>
      <Row md={3} xs={1} className="justify-content-md-center">
        {data?.map((category) => {
          const selected = preferences.includes(category.id);
          return (
            <StyledCol key={category.id}>
              <div
                style={{
                  backgroundColor: selected ? '#1db4b3' : '#F8F8F8',
                  color: selected ? '#FFF' : '#333',
                  cursor: 'pointer',
                }}
                onClick={() => handlePreferenceClick(category.id)}>
                {category.title}
              </div>
            </StyledCol>
          );
        })}
      </Row>
      <Row>
        <Col style={{ textAlign: 'center' }}>
          <StyledBackButton
            style={{ width: '180px' }}
            variant="success"
            onClick={() => history.push('/')}>
            Just browsing
          </StyledBackButton>
          <StyledSubmitButton onClick={saveCategories} variant="success">
            Submit
          </StyledSubmitButton>
        </Col>
      </Row>
    </Layout>
  );
};

export default Preferences;
