import React from 'react';
import ContentLoader from 'react-content-loader';

const Loader = () => {
  return (
    <ContentLoader height="650" width="100%">
      <rect x="0" y="35" rx="5" ry="5" width="100%" height="10" />
      <rect x="0" y="75" rx="5" ry="5" width="100%" height="50" />
      <rect x="0" y="145" rx="5" ry="5" width="100%" height="100" />
    </ContentLoader>
  );
};

export default Loader;
