import React from 'react';
import { Modal, Image } from 'react-bootstrap';
import styled from '@emotion/styled';
import UIButton from '../shared/ui/Button';

const ModalHeader = styled(Modal.Header)`
  border-bottom: none;
`;
const ModalBody = styled.div`
  padding: 0 2rem 2rem 2rem;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #333333;
  text-align: center;

  h6 {
    font-size: 1.125rem;
    margin-bottom: 32px;
    font-weight: bold;
    margin-top: 85px;
    color: #1db4b3;
  }
  p {
    font-size: 0.875rem;
    margin-bottom: 32px;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
  width: 100%;
  button {
    width: 30%;
    max-width: 238px;
    height: 40px;
  }
`;

const StyledImg = styled(Image)`
  position: absolute;
  top: -40px;
`;

const HowItWorksModal = ({ toggleShowHowItWorks }) => {
  return (
    <Modal
      show={true}
      dialogClassName="modal-50w"
      onHide={() => toggleShowHowItWorks()}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <ModalHeader closeButton></ModalHeader>
      <ModalBody>
        <StyledImg src="./insight-how-it-works/hello_img.svg" />
        <h6>How Insights Work</h6>
        <p>
          Insights will help you track your moods so you can understand the
          feelings underneath.
        </p>
        <p>
          Simply log your mood changes and the factors that influence them. Make
          it a habit to log your mood daily, ideally at the same time each day.
          Over time, you can see your patterns which can help you become more
          self-aware.
        </p>
        <ButtonWrapper>
          <UIButton text={'Got it!'} action={toggleShowHowItWorks} />
        </ButtonWrapper>
      </ModalBody>
    </Modal>
  );
};

export default HowItWorksModal;
