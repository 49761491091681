import React from 'react';
import { truncate } from '../../../packs/helpers';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

const StyledContentCard = styled(Card)`
  line-height: 1.1rem;
  position: relative;
  padding-bottom: 4rem;
  cursor: pointer;
  &.related {
    height: 270px;
  }
  border: 0;
`;

const StyledContentCardTitle = styled(Card.Title)`
  font-size: 14px;
  font-weight: bold;
`;

const StyledContentCardText = styled(Card.Text)`
  font-size: 13px;
  line-height: 1.5;
  overflow: hidden;
`;

const StyledRightLink = styled.span`
  position: absolute;
  bottom: 15px;
  right: 15px;
  a {
    font-weight: bold;
    font-size: 0.8rem;
    color: #1db4b3;
    &:hover {
      text-decoration: none;
    }
  }
`;

const TimeText = styled.p`
  position: absolute;
  bottom: 0px;
  left: 15px;
  font-size: 13px;
  min-width: 100px;
  color: #191919;
  display: flex;
  span {
    justify-content: center;
    align-items: center;
    padding-top: 4px;
  }
`;

const PodcastCard = ({ podcast, withContent = true }) => {
  const history = useHistory();

  return (
    <StyledContentCard
      className="h-100 shadow-sm"
      onClick={() => {
        history.push(`/podcasts/${podcast.slug}`);
      }}>
      <Card.Img
        variant="top"
        src={podcast?.thumbnail_url}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = '/default_article.png';
        }}
      />

      <Card.Body>
        <StyledContentCardTitle>{podcast?.title}</StyledContentCardTitle>
        {withContent && (
          <StyledContentCardText>
            {truncate(podcast?.description, 90)}
          </StyledContentCardText>
        )}
      </Card.Body>
      <TimeText className="time-text">
        <img className="mr-1" src="/podcast/podcast-icon.svg" alt="" />
        <span>{podcast?.estimated_time}</span>
      </TimeText>
      <StyledRightLink className="right-link">
        <Link to={`/podcasts/${podcast.slug}`}>Start Watching</Link>
      </StyledRightLink>
    </StyledContentCard>
  );
};

export default PodcastCard;
