import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { css, cx } from "emotion";

import Title from "../Title";

import PricingPackage from "../shared/PricingPackage"

const DiscountCodeForm = ({ packageId, discountCode, setDiscountCode, setDiscountedPrice }) => {
  const [isCodeValid, setIsCodeValid] = useState(null)

  async function handleDiscountCodeSubmit(event) {
    event.preventDefault()
    const payload = {package_id: packageId, discount_code: discountCode}

    const res = await fetch("/api/v2/package_discounts", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    })

    if (res.status === 200) {
      const { discounted_price } = await res.json()
      setDiscountedPrice(discounted_price)
      setIsCodeValid(true)
    } else {
      setIsCodeValid(false)
    }
  }

  return (
    <form id="discount_code_form" className="mb-2" onSubmit={handleDiscountCodeSubmit}>
      <fieldset className="input-group mb-2" disabled={isCodeValid === true}>
        <div className="input-group-prepend">
          <label htmlFor="discount_code" className="input-group-text">Discount Code:</label>
        </div>
        <input type="text"
          id="discount_code"
          name="discount_code"
          className={isCodeValid === false ? "form-control border border-danger" : "form-control" }
          value={discountCode}
          onChange={(e) => setDiscountCode(e.target.value)}
        />
        <div className="input-group-append">
          <button type="submit" className={cx(
            "btn",
            css`
              color: white;
              background: #ff8582;
            `
          )}>Apply</button>
        </div>
      </fieldset>
      { isCodeValid === true ? <span className="text-success font-weight-bold">Discount Code Applied!</span> : null }
      { isCodeValid === false ? <span className="text-danger font-weight-bold">Invalid discount code</span> : null }
    </form>
  )
};

const Payment = () => {
  const { packageName } = useParams();
  const [packageDetail, setPackageDetail] = useState(null);

  // For the DiscountCodeForm
  const [discountCode, setDiscountCode] = useState("")

  // For slashing the package
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [paying, setPaying] = useState(null)
  const [paymentLink, setPaymentLink] = useState(null)

  useEffect(() => {
    fetchPackageDetails()
  }, [])

  async function fetchPackageDetails() {
    const res = await fetch("/api/v1/packages/" + packageName)

    if (res.status === 200) {
      setPackageDetail(await res.json())
    } else {
      setPackageDetail(null)
    }
  }

  if (packageDetail === null) {
    return (
      <div>
        <Spinner />
      </div>
    )
  }

  if (packageDetail === null) {
    return (
      <div>
        <h1>Package not found</h1>
      </div>
    )
  }

  async function handleOrderSubmit(event) {
    event.preventDefault()
    const payload = {package_id: packageDetail.id}

    if (discountedPrice) {
      payload["discount_code"] = discountCode
    }

    const res = await fetch("/api/v2/orders", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    })

    if (res.status === 200) {
      const { payment_link } = await res.json()
      setPaying(true)
      setPaymentLink(payment_link)
      window.open(payment_link, "_blank")
    } else {
      setPaying(false)
    }
  }

  return (
    <>
      <div className="bg-light">
        <Title
          title="Payment Summary"
          description="Please check the package details prior to payment."
        />
      </div>
      <div
        className={cx(
          "container bg-white",
          css`
            padding-top: 2rem;
            padding-bottom: 2rem;
          `
        )}
      >
        <div className="row mt-2 d-flex justify-content-center">
          <div className="col-md-4 col-xs-8 text-center">
              { paying == true &&
                <>
                  <p>
                    Wait for a few minutes. After we've verified your payment, the session
                    should reflect on your <a href ="/">dashboard</a>.
                  </p>
                  <p>
                    If the payment window didn't open in a new tab, visit it here:
                    <br />
                    <a href={paymentLink} target="_blank">{paymentLink}</a>.
                  </p>
                </>
              }

              { !paying &&
                <>
                  <form id="order_form" onSubmit={handleOrderSubmit}>
                    <button type="submit" disabled={paying} className={cx(
                      "btn mb-2",
                      css`
                        color: white;
                        background: #ff8582;
                      `
                    )}>Pay online</button>
                  </form>
                  {paying === false && <p className="text-danger">Something went wrong. Try again.</p>}
                  <DiscountCodeForm
                    packageId={packageDetail.id}
                    discountCode={discountCode}
                    setDiscountCode={setDiscountCode}
                    setDiscountedPrice={setDiscountedPrice}
                  />
                </>
              }
          </div>

          <div className="col-md-4 col-xs-8">
            <PricingPackage
              discountedPrice={discountedPrice}
              packageDetail={packageDetail}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
