import axios from 'axios';
import { Row } from 'react-bootstrap';
import { Redirect, useHistory } from 'react-router-dom';
import React, { useState, useEffect, useRef, useCallback } from 'react';

import Layout from '../Layout';
import AssessmentQuestions from './Questions';
import AssessmentPauseModal from './PauseModal';
import AssessmentWelcomeScreen from './WelcomeScreen';

import { useAppData } from '../contexts/AppDataProvider';

export default () => {
  const history = useHistory();
  const clickedElement = useRef();
  const { currentAssessment, setCurrentAssessment, access_to_assessment } =
    useAppData();
  const [startAssessment, setStartAssessment] = useState(false);
  const [showPauseAssessment, setShowPauseAssessment] = useState(false);
  const [exitAssessment, setExitAssessment] = useState(false);

  const disableClickCallback = useCallback((e) => {
    clickedElement.current = e;

    if (e.target.id == 'profile-nav-dropdown') return;
    let showPauseDialog = true;
    if (e.currentTarget.tagName == 'FOOTER' && e.target.tagName == 'A') {
      let href = e.target.href;
      if (!href.includes('/privacy-policy') && !href.includes('/terms')) {
        showPauseDialog = false;
      }
    }
    if (showPauseDialog) {
      e.stopPropagation();
      e.preventDefault();
      let isLoadingResult = $('#patient-app-spinner-container').length > 0;
      if (!isLoadingResult) setShowPauseAssessment(true);
    }
  }, []);

  const callAssessment = async () => {
    await axios
      .get(`/api/v2/assessments/${currentAssessment.id}`)
      .then((res) => {
        setCurrentAssessment(res.data);
      });
  };

  const createAssesment = async (skipped = false) => {
    try {
      await axios.post('/api/v2/assessments', { skip: skipped }).then((res) => {
        setCurrentAssessment(res.data);
      });
    } catch {
      history.push('/');
    }
  };

  useEffect(() => {
    if (!access_to_assessment) return;
    if (!currentAssessment) {
      createAssesment();
    } else {
      callAssessment();
    }
  }, []);

  const runAssessment = async () => {
    setStartAssessment(true);
    callAssessment();
  };

  const disableOutsideClick = () => {
    $('nav').on('click', disableClickCallback);
    $('footer').on('click', disableClickCallback);
  };

  useEffect(() => {
    if (exitAssessment && !!clickedElement.current) {
      $('nav').off('click', disableClickCallback);
      $('footer').off('click', disableClickCallback);
      clickedElement.current.target.click();
    }
  }, [exitAssessment]);

  useEffect(() => {
    if (startAssessment) {
      try {
        disableOutsideClick();
      } catch (error) {
        window.addEventListener(
          'load',
          () => {
            disableOutsideClick();
          },
          { once: true },
        );
      }
    }
  }, [startAssessment]);

  if (!access_to_assessment) {
    return <Redirect to="/" />;
  }
  if (!currentAssessment) {
    return <></>;
  } else {
    if (currentAssessment.done) {
      return <Redirect to="/assessment/result" />;
    } else if (
      currentAssessment.status == 'completed' &&
      currentAssessment.missing_user_info
    ) {
      return <Redirect to="/assessment/user_form" />;
    } else {
      return (
        <Layout>
          <Row className="justify-content-center h-100 m-0">
            {showPauseAssessment && (
              <AssessmentPauseModal
                showPauseAssessment={showPauseAssessment}
                setShowPauseAssessment={setShowPauseAssessment}
                setExitAssessment={setExitAssessment}
              />
            )}
            {!startAssessment && (
              <AssessmentWelcomeScreen
                assessment={currentAssessment}
                runAssessment={runAssessment}
                is_retake={currentAssessment?.is_retake || false}
              />
            )}
            {startAssessment && !!currentAssessment.questions && (
              <AssessmentQuestions
                assessment={currentAssessment}
                callAssessment={callAssessment}
              />
            )}
          </Row>
        </Layout>
      );
    }
  }
};
