import React from 'react';
import DashboardCard from './DashboardCard';
import styled from '@emotion/styled';
import Doughnut from './Doughnut';
import { CardContent } from './styles';

const Bullet = styled.div`
  background: #1db4b3;
  border-radius: 2px;
  opacity: 1;
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-right: 15px;
`;

const BulletContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const Count = styled.div`
  color: blank;
  font-size: 25px;
  font-weight: 500;
  margin-top: 1rem;
`;

export const PercentEmployees = ({ count }) => {
  const title = '% of Employees Registered on the App';
  const tooltipText = 'Registered users who are still employed by the company';
  if (count?.registered_users == 0 && count?.unregistered_users == 0) {
    return (
      <DashboardCard
        title={title}
        withQuestionMark={true}
        tooltipText={tooltipText}>
        <Count>0%</Count>
        <p>No Registered Users</p>
      </DashboardCard>
    );
  }
  const data = [
    { color: '#97D7D2', percent: count?.registered_users },
    { color: '#1DB4A8', percent: count?.unregistered_users },
  ];
  const doughnutConstants = {
    colors: data
      .filter((value) => value.percent != 0)
      .map((value) => value.color),
    percents: data
      .filter((value) => value.percent != 0)
      .map((value) => value.percent),
  };

  return (
    <DashboardCard
      title={'% of Employees Registered on the App'}
      tooltipText={
        'Employees who have activated their app access versus total employee population'
      }
      withQuestionMark={true}>
      <CardContent>
        <div className="mt-4">
          <Doughnut params={doughnutConstants} />
        </div>
        <BulletContainer className="mt-2">
          <div>
            <Bullet style={{ backgroundColor: '#97D7D2' }} />
            <span>{count?.registered_users}% registered on the app</span>
          </div>
          <div>
            <Bullet tyle={{ backgroundColor: '#1DB4A8' }} />
            <span>{count?.unregistered_users}% not registered</span>
          </div>
        </BulletContainer>
      </CardContent>
    </DashboardCard>
  );
};
