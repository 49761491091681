import React, { useState, useEffect } from 'react';
import { Col, Image } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { UIButton } from '../v2/shared/ui';

const VerifyEmail = () => {
  const location = useLocation();

  if (!location.state.email) {
    window.location = '/';
  }
  const [userDetails] = useState({
    email: location.state.email || '',
    password: location.state.password || '',
  });
  const [emailSent, setEmailSent] = useState(false);
  const [seconds, setSeconds] = useState(10);

  const resendEmail = async() => {
    setEmailSent(true);
    const payload = {
      new_email: userDetails.email,
      password: userDetails.password,
    };
    const res = await fetch('/api/v2/user_email', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        let newSecond = prevSeconds - 1;
        if (newSecond == 0) {
          clearInterval(timer);
        }
        return newSecond;
      });
    }, 1000);
  };

  useEffect(() => {
    if (seconds == 0) {
      setEmailSent(false);
      setSeconds(10);
    }
  }, [seconds]);

  return (
    <>
      <Col
        lg={{ span: 6, offset: 3 }}
        md={{ span: 8, offset: 2 }}
        className="text-center mt-5">
        <h6 className="font-weight-bold mb-5">Update Email</h6>
        <Image src="/verify-email.svg" style={{ width: '70%' }} />
        <section className="m-5">
          <p>
            We sent a confirmation email to your new email address. Please
            verify your new email by clicking on the verification link.
          </p>
        </section>
        <div className="text-center mt-5">
          <span className="d-block">Didn’t receive an email?</span>
          <UIButton
            onClick={resendEmail}
            className={`white ${emailSent ? 'disabled' : ''}`}
            disabled={emailSent}
            style={{ width: '250px' }}
            text={
              emailSent
                ? `Email Sent (${seconds})`
                : 'Resend Verification Email'
            }
          />
        </div>
      </Col>
    </>
  );
};

export default VerifyEmail;
