import React from "react";
import { Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  StyledContentCard,
  StyledContentCardTitle,
  StyledRightLink,
  StyledContentCardText
} from "../Content/Card";
import { truncate } from "../../../packs/helpers";

const LearningModule = ({ module }) => (
  <Col lg={{span:4, offset:0}} md={{span:10, offset:1}} style={{ marginTop: '2rem' }}>
    <StyledContentCard className="h-100 shadow-sm">
      <Card.Img
        variant="top"
        src={module.image_url ? module.image_url : "/default_article.png"}>
      </Card.Img>
      <Card.Body>
        <StyledContentCardTitle>{module.title}</StyledContentCardTitle>
        <StyledContentCardText>
          {truncate(module.summary, 90)}
        </StyledContentCardText>
      </Card.Body>
      <StyledRightLink>
        <Link to={`learning_modules/${module.id}`}>
          View
        </Link>
      </StyledRightLink>
    </StyledContentCard>
  </Col>
);

export default LearningModule;