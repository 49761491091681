import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BookASessionCard from '../../shared/BookASessionCard';
import AudioPlayer from './AudioPlayer';
import AudioItem from './AudioItem';
import RelatedContents from '../../shared/RelatedContents';

const ContentBody = styled.div`
  padding: 1rem;
  p {
    font-size: 0.9rem;
    line-height: 1.9rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  strong {
    font-size: 1.1rem;
    font-weight: bold;
  }
`;

const AudioHeader = styled.div`
  background-color: #1db4b3;
  color: #fff;
  padding-top: 20px;
  a {
    color: #fff;
    &:hover {
      text-decoration: none;
    }
  }
  h2,
  p {
    margin-left: 2rem;
  }
  h2 {
    -webkit-font-smoothing: antialiased;
    font-weight: 700;
  }
  @media (max-width: 575.98px) {
    margin-bottom: 1rem;
    a {
      color: #fff;
    }
    h2,
    p {
      margin-left: 1rem;
      font-size: 1rem;
    }
  }
`;

const Audio = ({ data, createMarkup, relatedContents }) => {
  const [currentAudio] = useState(data);
  const [isPlaying, setIsPlaying] = useState(false);
  return (
    <>
      <Row>
        <Col>
          <AudioHeader className="rounded">
            <Row>
              <Col md={12}>
                <div>
                  <p style={{ fontSize: '14px' }} className="pt-2">
                    {data?.published_date}{' '}
                    {`${data?.author ? ` | by ${data?.author}` : ''}`}
                  </p>
                  <Link to={`/content/${data?.slug}`}>
                    <h2>{data?.title}</h2>
                  </Link>
                  <p style={{ fontSize: '14px' }} className="pt-2">
                    {`${
                      data?.estimated_time
                        ? `${
                            data?.estimated_time == 1
                              ? `1 minute`
                              : `${data?.estimated_time} minutes`
                          }`
                        : ''
                    }`}
                    {`${data?.source ? ` | ${data?.source}` : ''}`}
                  </p>
                </div>
              </Col>
            </Row>
          </AudioHeader>
        </Col>
      </Row>
      <Row>
        <Col>
          <div>
            <div className="col-lg-8 mx-auto mt-5">
              <AudioItem currentAudio={currentAudio} />
              <AudioPlayer
                currentAudio={currentAudio}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
              />
            </div>
            <ContentBody>
              <div dangerouslySetInnerHTML={createMarkup(data)} />
            </ContentBody>
          </div>
        </Col>
      </Row>
      {relatedContents && (
        <Row>
          <Col>
            {relatedContents.length > 0 && (
              <RelatedContents relatedContents={relatedContents} />
            )}
          </Col>
        </Row>
      )}
      <Row>
        <Col>{data && <BookASessionCard />}</Col>
      </Row>
    </>
  );
};

export default Audio;
