import React, { useState } from 'react';
import { Modal, Carousel, Image } from 'react-bootstrap';
import styled from '@emotion/styled';
import axios from 'axios';
import UIButton from '../ui/Button';
import { mutate } from 'swr';

const ModalHeader = styled(Modal.Header)`
  border-bottom: none;
`;
const ModalBody = styled.div`
  padding: 0 2rem 2rem 2rem;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #333333;
  text-align: center;
  img {
    margin-bottom: 20px;
    max-width: 400px;
    @media (max-width: 950px) {
      max-width: 100%;
    }
  }
  h6 {
    font-size: 1.375rem;
    margin-bottom: 20px;
    font-weight: bold;
  }
  p {
    font-size: 0.875rem;
    margin-bottom: 20px;
    max-width: 430px;
    margin-left: auto;
    margin-right: auto;
  }
  .carousel-item {
    min-width: 300px;
  }
`;

const StyledNavigation = styled.div`
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    width: 8px;
    height: 8px;
    background: #e6e6e6;
    border-radius: 50%;
    display: inline-block;
    margin-left: 4px;
    margin-right: 4px;
    cursor: pointer;
  }
  span.active {
    width: 10px;
    height: 10px;
    background: #333333;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
  width: 100%;
  button {
    width: 30%;
    max-width: 238px;
  }
`;

const OnBoardingModal = ({}) => {
  const [index, setIndex] = useState(0);
  const [show, setShow] = useState(true);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const buttonCallback = async () => {
    try {
      await axios.post('/api/v2/items', {
        item: { name: 'onboarding', info: 'read' },
      });
      mutate('/api/v2/dashboard');
    } catch {}
    setShow(false);
  };

  return (
    <Modal
      id="onboarding-modal"
      show={show}
      dialogClassName="modal-50w"
      onHide={() => buttonCallback()}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <ModalHeader closeButton></ModalHeader>
      <ModalBody>
        <Carousel
          variant="dark"
          controls={false}
          indicators={false}
          activeIndex={index}
          onSelect={handleSelect}
          data-ride="false"
          autoPlay={false}
          interval={3000}>
          <Carousel.Item>
            <div className="image-wrapper">
              <Image src="/on-boarding-modals/mental_health_continuum_scale.png" />
            </div>

            <h6>Mental health is a continuum</h6>
            <p>
              All of us move along the continuum from “excelling” to “in crisis”
            </p>
          </Carousel.Item>
          <Carousel.Item>
            <div className="image-wrapper">
              <Image src="/on-boarding-modals/care.png" />
            </div>

            <h6>Care that’s right for you</h6>
            <p>
              Infinit Care is designed to provide personalized support wherever
              you may be in the continuum
            </p>
          </Carousel.Item>
          <Carousel.Item>
            <div className="image-wrapper">
              <Image src="/on-boarding-modals/get_started.png" />
            </div>

            <h6>Get started</h6>
            <p>
              Chat with us, explore resources, or follow a well-being program to
              improve your state of mental health
            </p>
          </Carousel.Item>
        </Carousel>
        <StyledNavigation>
          <span
            className={index == 0 ? 'active' : ''}
            onClick={() => handleSelect(0)}></span>
          <span
            className={index == 1 ? 'active' : ''}
            onClick={() => handleSelect(1)}></span>
          <span
            className={index == 2 ? 'active' : ''}
            onClick={() => handleSelect(2)}></span>
        </StyledNavigation>
        <ButtonWrapper>
          <UIButton text={'Got it!'} action={buttonCallback} />
        </ButtonWrapper>
      </ModalBody>
    </Modal>
  );
};

export default OnBoardingModal;
