import axios from 'axios';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Button, Image } from 'react-bootstrap';

const ModalHeader = styled(Modal.Header)`
  border-bottom: none;
`;
const StyledContent = styled.div`
  padding-left: 50px;
  padding-top: 50px;
  padding-bottom: 120px;
  h4 {
    font-size: 26px;
    padding-bottom: 26px;
  }
  p {
    padding-bottom: 26px;
    width: 65%;
    font-size: 16px;
  }
  @media (max-width: 1199.98px) {
    padding-top: 0px;
  }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    padding-left: 25px;
    h4 {
      font-size: 24px;
    }
    p {
      font-size: 14px;
      width: 70%;
    }
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    padding-right: 25px;
    padding-left: 25px;
    h4 {
      font-size: 20px;
      padding-bottom: 10px;
    }
    p {
      font-size: 13px;
      width: 90%;
    }
  }
  @media (max-width: 767.98px) {
    padding-right: 20px;
    padding-left: 20px;
    h4 {
      font-size: 20px;
      padding-bottom: 10px;
    }
    p {
      font-size: 13px;
      width: 90%;
    }
  }
  @media (max-width: 790px) {
    padding-bottom: 40px;
    p {
      width: 100%;
    }
  }
`;
const ResponseActions = styled.div`
  button.accept {
    font-weight: 600;
    margin-right: 20px;
  }
  button.cancel {
    font-weight: 400;
    color: #4db5b4;
    background-color: white;
    border: none;
  }
  @media (max-width: 790px) {
    button.accept {
      display: block;
      margin-bottom: 15px !important;
    }
  }
`;
const StyledImage = styled(Image)`
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 35%;
  @media (max-width: 1199.98px) {
    width: 32%;
  }
`;

const AssessmentWelcomeModal = ({ setCurrentAssessment }) => {
  const [showAssessment, setShowAssessment] = useState(true);
  const history = useHistory();
  const startAssessment = async (skipped = false) => {
    try {
      await axios.post('/api/v2/assessments', { skip: skipped }).then((res) => {
        setCurrentAssessment(res.data);
      });
    } catch {}
  };
  const handleCancel = async () => {
    await startAssessment(true);
    setShowAssessment(false);
  };
  const handleAccept = async () => {
    await startAssessment();
    history.push('/assessment');
  };

  return (
    <Modal
      dialogClassName="modal-50w"
      show={showAssessment}
      onHide={handleCancel}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <ModalHeader closeButton></ModalHeader>
      <Modal.Body>
        <StyledContent>
          <h4>
            <strong>Know your well-being score.</strong>
          </h4>
          <p>
            Get an assessment of your current mental well-being. This is based
            on the World Health Organization Well-Being Index (WHO-5).
          </p>
          <ResponseActions>
            <Button
              id="assessment-take-the-test"
              size="sm"
              variant="primary"
              className="accept btn-green mb-1 py-2 px-4"
              onClick={handleAccept}>
              Take the test
            </Button>
            <Button
              size="sm"
              variant="primary"
              className="cancel btn-secondary mb-1 py-2 px-4"
              onClick={handleCancel}>
              Maybe later
            </Button>
          </ResponseActions>
        </StyledContent>
        <StyledImage src="/assessment/people-beautiful-flower.png" />
      </Modal.Body>
    </Modal>
  );
};
export default AssessmentWelcomeModal;
