import React, { useEffect, useState } from "react";
import Api from "../Api";

export default ({ limit }) => {
  const [professionalState, setProfessionalState] = useState({
    loading: true,
    professionals: null
  });

  useEffect(() => {
    const fetchData = async () => {
      await Api.get(
        `/api/v1/professionals?featured=true&limit=${limit}`,
        {}
      ).then(res => {
        setProfessionalState({
          ...professionalState,
          loading: false,
          professionals: res.data
        });
      });
    };
    fetchData();
  }, []);

  return [professionalState];
};
