import React, { useRef, useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import { Image, Modal } from 'react-bootstrap';
import UIButton from '../shared/ui/Button';
import Dropzone from 'react-dropzone';
import Spinner from '../../Spinner';
import { useAppData } from '../contexts/AppDataProvider';

const DragAndDrop = (props) => {
  const ModalHeader = styled.div`
    background-color: #f8f9fa !important;
    height: 66px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    .modal-title {
      color: #7e84a3;
      font-size: 16px !important;
    }
    .close-button {
      cursor: pointer;
    }
  `;

  const StyledContent = styled.div`
    text-align: center;
    .choose-file {
      color: #1db4b3;
      cursor: pointer;
      background: transparent;
      border: none;
    }
    .delete-file {
      font-size: 21px;
      background: transparent;
      border: none;
    }
    .file-type {
      font-size: 12px;
    }
    .file-name {
      background-color: #eefcce;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 400px;
      margin: auto;
      padding-left: 40px;
      padding-right: 40px;
    }
    .upload-button {
      width: fit-content;
      display: block;
      margin: auto;
      margin-top: 20px;
    }
    .drag-section {
      border: 2px dashed #dddddd;
      margin: 40px;
      padding: 60px 50px;
    }
    .dragging {
      border: 2px dashed #1db4b3;
      background-color: #edf9f9;
    }
    .importing_result {
      font-size: 13px;
    }
  `;

  const ErrorContainer = styled.div`
    text-align: center;
    .error-message {
      font-size: 12px;
    }
    .file-name {
      background-color: #ffeaea;
    }
  `;

  const [file, setFile] = useState();
  const [count, setCount] = useState(0);
  const [status, setStatus] = useState('initial');
  const [response, setResponse] = useState(null);
  const [action, setAction] = useState(props.title);
  const { email, phoneRequirementEnabled } = useAppData();
  const inputRef = useRef(null);

  useEffect(() => {
    const id = setInterval(() => {
      if (count < 100) {
        setCount((oldCount) => oldCount + 1);
      }
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    setAction(props.title);
  }, [props]);

  const upload = async () => {
    if (typeof file?.file === 'undefined') return;
    setStatus('uploading');
    const formData = new FormData();

    formData.append('file', file?.file);

    if(!!props.companyId){
      formData.append('company_id', props.companyId);
    }
    if(!!props.siteId){
      formData.append('site_id', props.siteId);
    }
    let url = '/api/v2/company_admin/company_users/bulk_upload';
    if (props.title == 'Batch Resigned') {
      url = '/api/v2/company_admin/company_users/bulk_resigned';
    }
    const res = await fetch(url, {
      method: 'POST',
      body: formData,
    });
    if (res.status === 200) {
      let response = await res.json();
      setResponse(response)
      if (action == 'Batch Upload') {
        setStatus('done_uploading_with_email');
      } else {
        setStatus('done_offboarding');
        props.refetchData();
      }
    } else {
      setStatus('with_error');
    }
  };

  const uploadAgain = () => {
    setFile(null);
    setStatus('initial');
  };

  const handleClick = () => {
    inputRef.current.open();
  };

  const removeButton = () => {
    setFile(null);
  };

  const processBatchUpload = (text) => {
    const rowContents = csvToArr(text, ',');

    let errors = [];
    if (rowContents.length == 0) {
      errors.push('No content found or missing headers');
    }else if (rowContents.length > 2000) {
      errors.push('Limit number of rows to 2000');
    }else {
      const headers = ['Phone', 'Email', 'First Name', 'Last Name'];

      let validHeaders = [];
      let invalidHeaders = []
      Object.keys(rowContents[0]).forEach((column) => {
        if (headers.includes(column)) {
          validHeaders.push(column);
        }else{
          if(column != 'Site'){
            invalidHeaders.push(column)
          }
        }
      });

      const missingHeaders = headers.filter((n) => !validHeaders.includes(n));
      if (missingHeaders.length > 0) {
        errors.push(`Missing Headers: ${missingHeaders.join(', ')}`);
      }
      if (invalidHeaders.length > 0){
        errors.push(`Invalid Headers: ${invalidHeaders.join(', ')}`);
      }

      if (errors.length == 0) {
        const validEmail =
          /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.([a-zA-Z0-9-.]+)+$/;
        const validPhoneNumber = /^(09|\+639|9|639)\d{9}$/;
        rowContents.forEach((row, index) => {
          if((row['Email'] || '' ).trim() == ''){
            // do nothing
          }else{
            if (!row['Email'].match(validEmail)) {
              errors.push(` Invalid email: ${row['Email']} row: ${index + 2}`);
            }
            if (phoneRequirementEnabled && !row['Phone'].match(validPhoneNumber)) {
              errors.push(` Invalid phone: ${row['Phone']} at row: ${index + 2}`);
            }
          }

        });
      }
    }
    if (errors.length > 0) {
      errors = errors.slice(0, 10);
      setFile({ file: file, error: errors.join('<br/>') });
    }
  };

  const processBatchResigned = (text) => {
    const rowContents = csvToArr(text, ',');
    let errors = [];
    if (rowContents.length == 0) {
      errors.push('No content found or missing headers');
    }else if (rowContents.length > 2000) {
      errors.push('Limit number of rows to 2000');
    } else {
      const headers = ['Confirmed Email'];

      let validHeaders = [];
      let invalidHeaders = []
      Object.keys(rowContents[0]).forEach((column) => {
        if (headers.includes(column)) {
          validHeaders.push(column);
        }else{
          invalidHeaders.push(column)
        }
      });

      const missingHeaders = headers.filter((n) => !validHeaders.includes(n));
      if (missingHeaders.length > 0) {
        errors.push(`Missing Headers: ${missingHeaders.join(', ')}`);
      }
      if (invalidHeaders.length > 0){
        errors.push(`Invalid Headers: ${invalidHeaders.join(', ')}`);
      }

      if (errors.length == 0) {
        const validEmail =
          /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.([a-zA-Z0-9-.]+)+$/;
        rowContents.forEach((row, index) => {
          let email = row['Confirmed Email']
          if((email || '' ).trim() == ''){
            // do nothing
          }else{
            if (!(email || '').match(validEmail)) {
              errors.push(` Invalid email: ${email} row: ${index + 2}`);
            }
          }
        });
      }
    }
    if (errors.length > 0) {
      errors = errors.slice(0, 10);
      setFile({ file: file, error: errors.join('<br/>') });
    }
  };

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0] || '';
        if (file == '') {
          setFile(null);
        } else {
          setFile({ file: file });
        }
      }
      if (fileRejections.length > 0) {
        const rejectObj = fileRejections[0] || {};
        let error = rejectObj.errors
        if (error != null) {
         setFile({ file: file?.file, error: error[0].message });
        }
      }
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        if (action == 'Batch Upload') {
          reader.onload = () => {
            const text = reader.result;
            processBatchUpload(text);
          };
        } else if (action == 'Batch Resigned') {
          reader.onload = () => {
            const text = reader.result;
            processBatchResigned(text);
          };
        }

        reader.readAsText(file);
      });
    },
    [action],
  );

  const csvToArr = (stringVal, splitter) => {
    const [keys, ...rest] = stringVal
      .trim()
      .split('\n')
      .map((item) => item.split(splitter));

    const formedArr = rest.map((item) => {
      const object = {};
      keys.forEach(
        (key, index) => (object[key.trim()] = item.at(index).trim()),
      );
      return object;
    });
    return formedArr;
  };

  const closeModal = () => {
    setFile(null);
    setStatus('initial');
    props.setShowModal(false);
  };

  return (
    <Modal
      dialogClassName="modal-50w"
      show={props?.show}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <ModalHeader>
        <span className="modal-title">{props.title}</span>
        <Image
          className="close-button"
          variant="close"
          src="/modal_close_button.svg"
          onClick={closeModal}
        />
      </ModalHeader>
      <StyledContent>
        <Dropzone
          noClick={true}
          multiple={false}
          accept={{
            'text/csv': ['.csv'],
          }}
          noKeyboard={true}
          noDragEventsBubbling={true}
          onDrop={onDrop}
          ref={inputRef}>
          {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
            <section
              className={`drag-section ${isDragActive ? 'dragging' : ''}`}>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {['done_uploading_with_email', 'done_offboarding'].includes(status) && (
                  <>
                    {status == 'done_uploading_with_email' && (
                      <p className="importing_result text-success">{`Importing in progress. You can leave this screen. Importing results will be emailed to ${email}.`}</p>
                    )}
                    {status == 'done_offboarding' && response?.success && (
                      <p className="importing_result text-success">
                        Successfully offboarded {response?.offboarded_users_count} users.
                      </p>
                    )}
                    {status == 'done_offboarding' && !response?.success && (
                      <p className="importing_result text-success">
                        Successfully offboarded {response?.offboarded_users_count} users. Please check your email for the list of users who have not been offboarded.
                      </p>
                    )}
                    <UIButton
                      text={'Upload Again'}
                      className="white upload-button bordered"
                      onClick={uploadAgain}
                    />
                  </>
                )}
                {status == 'with_error' && (
                  <>
                    <p className="importing_result text-danger">
                      Something went wrong. Please try again.
                    </p>
                    <UIButton
                      text={'Upload Again'}
                      className="white upload-button bordered"
                      onClick={uploadAgain}
                    />
                  </>
                )}
                {status == 'uploading' && (
                  <>
                    <div
                      style={{ height: 100 }}
                      className="d-flex justify-content-center">
                      <Spinner />
                    </div>
                    <p className="mt-2">Uploading files...</p>
                  </>
                )}
                {status == 'initial' && (
                  <div className="text-center">
                    {!file && <Image src="/file-upload.svg" className="mb-2" />}

                    {file && file?.error && (
                      <ErrorContainer>
                        <Image
                          className="close-button mb-3"
                          variant="close"
                          src="/circle-x-filled.svg"
                        />
                        <p className="text-danger mb-2">File upload failed</p>
                        <p className="error-message">
                          {file?.error ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: file?.error,
                              }}></span>
                          ) : (
                            'Please check your column header. The first row must contain Email.'
                          )}{' '}
                        </p>
                        <UIButton
                          text={'Upload Again'}
                          className="white upload-button bordered"
                          onClick={uploadAgain}
                        />
                      </ErrorContainer>
                    )}

                    {file && !file?.error && (
                      <>
                        <div className="file-name  mb-2">
                          <span>{file?.file?.name}</span>
                          <button
                            className="delete-file"
                            onClick={removeButton}>
                            x
                          </button>
                        </div>
                        <UIButton
                          text={'Upload'}
                          className="white upload-button bordered"
                          onClick={upload}
                        />
                      </>
                    )}

                    {!file && (
                      <>
                        <span className="d-block">
                          Drag and Drop or{' '}
                          <button className="choose-file" onClick={handleClick}>
                            Choose file
                          </button>{' '}
                          to upload
                        </span>
                        <span className="file-type">(.csv)</span>
                      </>
                    )}
                  </div>
                )}
              </div>
            </section>
          )}
        </Dropzone>
      </StyledContent>
    </Modal>
  );
};

export default DragAndDrop;