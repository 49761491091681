import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Col, Image } from 'react-bootstrap';
import { UIButton } from '../shared/ui';
import { UISubmitButton } from '../shared/ui/Button';
import { useAppData } from '../contexts/AppDataProvider';
import UIModal from '../shared/ui/Modal';
import VerifiedScreen from './AcceptedScreen';

const StyledUserInfoContainer = styled.div`
  @media (max-width: 400px) {
    .text-left span{
      font-size: 12px;
    }
  }
`;
const StyledNameInitial = styled.span`
  background: #1db4b3;
  border-radius: 100%;
  font-size: 24px;
  color: white;
  font-weight: 500;
  width: 63px;
  height: 63px;
  display: inline-block;
  line-height: 63px;
  margin-right: 1rem;
  @media (max-width: 400px) {
    font-size: 18px;
    width: 45px;
    height: 45px;
    line-height: 45px;
    margin-right: 0.5rem;
  }
`;

const StyledInput = styled.input`
  height: 40px;
  font-size: 13px;
`;

const UpdateScreen = () => {
  const [userDetails, setUserDetails] = useState({ email: '', password: '' });
  const [updating, setUpdating] = useState(false);
  const [errors, setErrors] = useState({});
  const [emailSent, setEmailSent] = useState(false);
  const [seconds, setSeconds] = useState(10);
  const [showVerifyScreen, setShowVerifyScreen] = useState(false);
  const [showSameEmailModal, setShowSameEmailModal] = useState(false);
  const [verified, setVerified] = useState(false);

  const { email, firstName, lastName } = useAppData();
  const userFullName = firstName + ' ' + lastName;
  function handleOnChange(event) {
    const updatedAttribute = { [event.target.name]: event.target.value };
    setUserDetails({ ...userDetails, ...updatedAttribute });
  }

  const resendEmail = ()=>{
    setEmailSent(true)
    const payload = {
      new_email: userDetails.email,
      password: userDetails.password,
    };
    updateEmail(payload);
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        let newSecond = prevSeconds - 1;
        if(newSecond == 0){
          clearInterval(timer);
        }
        return newSecond;
      });
    }, 1000);
    
  }

  useEffect(() => {
   if(seconds==0){
    setEmailSent(false);
    setSeconds(10)
   }
  }, [seconds]);

  async function updateEmail(payload) {
    setUpdating(true);

    const res = await fetch('/api/v2/user_email', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (res.status === 200) {
      if (payload.new_email == email) {
        setVerified(true)
      } else {
        setShowVerifyScreen(true);
      }
    } else {
      const errors = await res.json();
      setErrors(errors);
    }
    setUpdating(false);
  }

  const useSameEmail = () => {
    const payload = {
      new_email: email
    };
    updateEmail(payload);
  };
  
  const handleOnSubmit = (event) => {
    event.preventDefault();
    if(userDetails.email == email){
      setErrors({email: ['Already using this email']})
      return
    }
    const payload = {
      new_email: userDetails.email,
      password: userDetails.password,
    };
    updateEmail(payload);
  }

  if(verified){
    return <VerifiedScreen/>
  }else if(showVerifyScreen){
    return (
      <>
        <Col sm={{ span: 8, offset: 2 }} className="text-center">
          <h6 className="font-weight-bold mb-5">Update Email</h6>
          <Image src="verify-email.svg" style={{ width: '70%' }} />
          <section className="m-5">
            <p>
              Please verify your new email address by clicking on the
              verification link we sent to your inbox.
            </p>
          </section>
          <div className="text-center mt-5">
            <span className="d-block">Didn’t receive an email?</span>
            <UIButton
              onClick={resendEmail}
              className={`white ${emailSent ? 'disabled' : ''}`}
              disabled={emailSent}
              style={{ width: '250px' }}
              text={
                emailSent
                  ? `Email Sent (${seconds})`
                  : 'Resend Verification Email'
              }
            />
          </div>
        </Col>
      </>
    );
  }else{
    return (
      <>
        <Col sm={{ span: 6, offset: 3 }} className="text-center">
          <h6 className="font-weight-bold mb-5">Update Email</h6>
          <StyledUserInfoContainer className="d-flex">
            <StyledNameInitial>{firstName[0] + lastName[0]}</StyledNameInitial>
            <div className="text-left">
              <span className="d-block font-weight-bold">{userFullName}</span>
              <span className="d-block">{email}</span>
            </div>
          </StyledUserInfoContainer>

          <form className="d-flex flex-column mt-5" onSubmit={handleOnSubmit}>
            <div className="mb-3 text-left">
              <label htmlFor="email" className="form-label">
                Enter your new email address
              </label>
              <StyledInput
                id="email"
                type="email"
                name="email"
                value={userDetails.email}
                onChange={handleOnChange}
                className="form-control"
                required
              />
              {errors.email && (
                <span className="small text-danger mt-2">
                  {' '}
                  {errors.email.join(', ')}{' '}
                </span>
              )}
            </div>
            <div className="mb-3 text-left">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <StyledInput
                id="password"
                type="password"
                name="password"
                onChange={handleOnChange}
                className="form-control"
                required
              />
              {errors.password && (
                <span className="small text-danger mt-2">
                  {' '}
                  {errors.password.join(', ')}{' '}
                </span>
              )}
            </div>
            <div className="text-center mt-5">
              <UIButton
                className="white"
                disabled={updating}
                onClick={()=>{setShowSameEmailModal(true)}}
                style={{ width: '250px' }}
                text={'Use the same email'}
              />
            </div>
            <div className="text-center mt-2">
              <UISubmitButton
                disabled={updating}
                style={{ width: '250px' }}
                text={'Submit'}
              />
            </div>
          </form>
          <UIModal
            header={'Update Email'}
            description={`Are you sure you want to use ${email}?`}
            show={showSameEmailModal}
            setShowModal={setShowSameEmailModal}
            successAction={useSameEmail}
          />
        </Col>
      </>
    );
  }

};

export default UpdateScreen;
