import React from 'react';
import { StyledHeader, StyledUIButton } from './Coaching/styles';
import { Col, Image, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useBookSessionData } from '../contexts/BookSessionDataProvider';

const OutOfSessionSubmitted = () => {
  const history = useHistory();
  const { selectedSession } = useBookSessionData();
  return (
    <>
      <StyledHeader>
        <p className="book-session">BOOK A SESSION</p>
      </StyledHeader>
      <Row className="justify-content-center m-0 mb-4">
        <div className="w-100 text-center">
          <Image height="250px" src="/no-session-success.gif" />
        </div>
        <Col lg={8} className="text-center">
          <h3>Your request has been submitted</h3>
          <p>
            Your request for a {selectedSession} session has been sent to your
            wellness point-of-contact. Please wait for an email confirmation
            about the status of your request.
          </p>
        </Col>
      </Row>
      <Row>
        <StyledUIButton
          text="Go back to Dashboard"
          action={() => history.push('/')}></StyledUIButton>
      </Row>
    </>
  );
};
export default OutOfSessionSubmitted;
