import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Modal, Button, Image } from 'react-bootstrap';
import { UserContext } from '../contexts/UserContext';
const ModalHeader = styled(Modal.Header)`
  border-bottom: none;
`;
const ModalBody = styled(Modal.Body)`
  overflow: hidden;
`;
const StyledContent = styled.div`
  text-align: center;
  h4 {
    font-size: 18px;
    padding-bottom: 24px;
    color: #e88480;
    font-weight: bold;
  }
  p {
    font-size: 14px;
    line-height: 25px;
  }
`;
const ResponseActions = styled.div`
  button.accept {
    font-weight: 600;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    background: #e88480;
    color: white;
    border: none;
    margin-top: 24px;
    margin-bottom: 24px;
    &:hover,
    &:focus {
      background: #e88480 !important;
      color: white !important;
      border: none !important;
      box-shadow: none !important;
    }
  }
  button.cancel {
    font-weight: 400;
    color: #10123d;
    background-color: white;
    border: none;
    font-size: 12px;
    margin-bottom: 24px;
    &:hover,
    &:focus {
      color: #10123d !important;
      background-color: white !important;
      border: none !important;
      box-shadow: none !important;
    }
  }
`;

const StyledImage = styled(Image)`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 24px;
`;

const DeleteAccountModal = ({ showDeleteModal, setShowDeleteModal }) => {
  const { currentUser } = useContext(UserContext);
  const handleAccept = () => {
    setShowDeleteModal(false);
  };

  const deleteAccount = () => {
    window.location.href = '/account_deleted';
  };

  return (
    <Modal
      dialogClassName="modal-50w"
      show={showDeleteModal}
      onHide={() => setShowDeleteModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <ModalHeader closeButton></ModalHeader>
      <ModalBody>
        <StyledContent>
          <StyledImage src="/delete_account.svg" />
          <h4>Delete Account</h4>
          <p>We’re sad to see you go, {currentUser.first_name}.</p>
          <p>
            Once your account is deleted, it cannot be recovered. <br />
            This action is irreversible.Are you sure you wish to proceed?
          </p>
          <ResponseActions>
            <Button
              size="sm"
              variant="primary"
              className="accept py-2 px-4"
              onClick={() => deleteAccount()}>
              Delete My Account
            </Button>
            <Button
              size="sm"
              variant="primary"
              className="cancel btn-secondary mb-1 py-2 px-4"
              onClick={handleAccept}>
              Cancel
            </Button>
          </ResponseActions>
        </StyledContent>
      </ModalBody>
    </Modal>
  );
};

export default DeleteAccountModal;
