import React from 'react';
import { Col } from 'react-bootstrap';

import Loader from '../../Content/Array';
import WebinarCardFullWidth from '../Card/WebinarCardFullWidth';

const DashboardWebinarArray = ({ data }) => {
  return (
    <>
      {!data && <Loader />}
      {data
        ? data.map((webinar, index) => {
            return (
              <Col sm={12} key={index}>
                <WebinarCardFullWidth webinar={webinar} />
              </Col>
            );
          })
        : []}
    </>
  );
};

export default DashboardWebinarArray;
