import React, { useState } from 'react';
import styled from '@emotion/styled';

import { useHistory } from 'react-router-dom';
import UIButton, { UISubmitButton } from '../v2/shared/ui/Button';
import UIModal from '../v2/shared/ui/Modal';

const StyledUserInfoContainer = styled.div`
  @media (max-width: 400px) {
    .text-left span {
      font-size: 12px;
    }
  }
`;
const StyledNameInitial = styled.span`
  background: #1db4b3;
  border-radius: 100%;
  font-size: 24px;
  color: white;
  font-weight: 500;
  width: 63px;
  height: 63px;
  display: inline-block;
  line-height: 63px;
  margin-right: 1rem;
  padding-left: 12px;
  @media (max-width: 400px) {
    font-size: 18px;
    width: 45px;
    height: 45px;
    line-height: 45px;
    margin-right: 0.5rem;
  }
`;

const StyledInput = styled.input`
  height: 40px;
  font-size: 13px;
`;

const UpdateProfileEmail = ({
  setEditing,
  user: { first_name, last_name, email },
}) => {
  const history = useHistory();
  const [userDetails, setUserDetails] = useState({ email: '', password: '' });
  const [updating, setUpdating] = useState(false);
  const [errors, setErrors] = useState({});
  const userFullName = first_name + ' ' + last_name;
  const [showModal, setShowModal] = useState(false);
  function handleOnChange(event) {
    const updatedAttribute = { [event.target.name]: event.target.value };
    setUserDetails({ ...userDetails, ...updatedAttribute });
  }

  async function update(payload) {
    setUpdating(true);

    const res = await fetch('/api/v2/user_email', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    setUpdating(false);
    if (res.status === 200) {
      history.push({
        pathname: '/verify_email',
        state: {
          email: payload.new_email,
          password: payload.password,
        },
      });
    } else {
      const errors = await res.json();
      setErrors(errors);
    }
  }

  const submitNewEmail = () => {
    if (userDetails.email == email) {
      setErrors({ email: ['Already using this email'] });
      return;
    }
    const payload = {
      new_email: userDetails.email,
      password: userDetails.password,
    };
    update(payload);
  };

  return (
    <section className="card mt-2 col-lg-8 col-md-8 col-sm-12 p-3">
      <div className="card-body">
        <h6 className="mb-5 text-center">Update Email address</h6>
        <StyledUserInfoContainer className="d-flex">
          <StyledNameInitial>{first_name[0] + last_name[0]}</StyledNameInitial>
          <div className="text-left">
            <span className="d-block font-weight-bold">{userFullName}</span>
            <span className="d-block">{email}</span>
          </div>
        </StyledUserInfoContainer>

        <form
          className="d-flex flex-column mt-5"
          onSubmit={(event) => {
            event.preventDefault();
            setShowModal(true);
          }}>
          <div className="mb-3 text-left">
            <label htmlFor="email" className="form-label">
              New email
            </label>
            <StyledInput
              id="email"
              type="email"
              name="email"
              value={userDetails.email}
              onChange={handleOnChange}
              className="form-control"
              required
            />
            {errors.email && (
              <span className="small text-danger mt-2">
                {' '}
                {errors.email.join(', ')}{' '}
              </span>
            )}
          </div>
          <div className="mb-3 text-left">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <StyledInput
              id="password"
              type="password"
              name="password"
              onChange={handleOnChange}
              className="form-control"
              required
            />
            {errors.password && (
              <span className="small text-danger mt-2">
                {' '}
                {errors.password.join(', ')}{' '}
              </span>
            )}
          </div>
          <div className="d-flex justify-content-around mt-2">
            <UIButton
              text={'Cancel'}
              style={{ width: '200px' }}
              className="white"
              onClick={() => {
                setEditing('none');
              }}
            />
            <UISubmitButton
              disabled={updating}
              style={{ width: '200px' }}
              text={'Submit'}
            />
          </div>
        </form>
        <UIModal
          header={'Update Email'}
          description={`Are you sure you want to update your email to ${userDetails?.email}?`}
          show={showModal}
          setShowModal={setShowModal}
          successAction={submitNewEmail}
        />
      </div>
    </section>
  );
};

export default UpdateProfileEmail;
