import React, { useState } from "react";
import { css, cx } from "emotion";

function Question({ question, answer }) {
  const [shown, setShown] = useState(false);
  return (
    <div
      className={cx(
        "card shadow-sm mb-2 question",
        shown && "show",
        css`
          width: 70%;
          &.show {
            height: auto;
            opacity: 1;
            visibility: visible;
          }
        `
      )}
    >
      <div
        className={cx(
          "card-body",
          css`
            position: relative;
          `
        )}
      >
        <i
          onClick={() => setShown(!shown)}
          className={cx(
            "fa toggler fa-chevron-down",
            css`
              position: absolute;
              top: 1rem;
              right: 1rem;
              cursor: pointer;
              transition-duration: 500ms;
              transition-property: transform;
              transition-timing-function: ease;
              &:hover {
                color: #4db5b4;
              }
            `,
            shown &&
              css`
                transform: rotate(180deg);
              `
          )}
        ></i>
        <h4
          className={cx(
            "font-weight-bold h5",
            css`
              cursor: pointer;
            `
          )}
          onClick={() => setShown(!shown)}
        >
          {question}
        </h4>
        <p
          className={cx(
            "answer",
            css`
              opacity: 0;
              height: 0;
              visibility: hidden;
              transition: opacity 1s ease-out;
            `,
            shown &&
              css`
                height: auto;
                opacity: 1;
                visibility: visible;
              `
          )} dangerouslySetInnerHTML={{__html: answer}} />
      </div>
    </div>
  );
}

export default Question;
