import React from "react";
import { css, cx, injectGlobal, keyframes } from "emotion";

const testimonialClass = css`
   {
    animation-duration: 2000ms;
  }
`;

const testimonialsFadeIn = keyframes({
  "0%": {
    opacity: 0,
    backgroundColor: "lightgray"
  },
  "100%": {
    opacity: 1,
    backgroundColor: "white"
  }
});

const Testimonial = ({ testimonial: { name, company_name, content } }) => {
  return (
    <div className="col">
      <div
        className={cx(
          "testimonial border m-2 rounded bg-white shadow-sm p-3",
          testimonialClass,
          css`
            animation: ${testimonialsFadeIn};
          `
        )}
      >
        <div className="d-flex flex-column justify-content-between align-items-start p-2">
          <div className="flex-grow-1 px-2">
            <blockquote
              className={cx(
                "blockquote text-left flex-nowrap",
                css`
                  font-size: 0.9rem;
                `
              )}
            >
              <p className="mb-0">{content}</p>
            </blockquote>
          </div>
          <div
            className={cx(
              "text-left d-flex flex-row justify-content-center align-items-center",
              css`
                height: 40px;
              `
            )}
          >
            <div
              className={cx(
                "text-muted flex-grow-1",
                css`
                  font-size: 0.8rem;
                `
              )}
            >
              {name} <cite title="Company">{company_name}</cite>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
