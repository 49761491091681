import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import styled from '@emotion/styled';
import useEmergencyContact from './useEmergencyContact';

const StyledProfile = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 31px;
`;

const StyledList = styled.li`
  border: none;
  padding-bottom: 10px;
  a.active {
    color: #00a2a1 !important;
  }
  a {
    font-weight: regular;
    color: #333333 !important;
  }
`;

const ProfileNavigation = ({}) => {
  const { currentUser } = useContext(UserContext);
  const [emergencyContactState] = useEmergencyContact();
  return (
    <div className="col-lg-3 col-md-4 col-sm-12 mt-2">
      <StyledProfile>Profile</StyledProfile>
      <ul className="list-group">
        <StyledList className="list-group-item">
          <NavLink to="/dashboard/profile">Account Information</NavLink>
        </StyledList>
        <StyledList className="list-group-item">
          <NavLink to="/dashboard/change_password">Change Password</NavLink>
        </StyledList>
        {emergencyContactState?.emergency_contact && (
          <StyledList className="list-group-item">
            <NavLink to="/dashboard/emergency_contact">
              Emergency Contact
            </NavLink>
          </StyledList>
        )}
        <StyledList className="list-group-item">
          <NavLink to="/dashboard/preferences">Preferences</NavLink>
        </StyledList>
        <StyledList className="list-group-item">
          <NavLink to="/dashboard/session_history">Session History</NavLink>
        </StyledList>
        { !(currentUser.individualSessionsEnabled == false) &&
          <StyledList className="list-group-item">
            <NavLink to="/dashboard/payment_summary">Payment Summary</NavLink>
          </StyledList>
        }
        {currentUser.with_company &&
          currentUser.dependent_enabled &&
          !currentUser.dependent && (
            <StyledList className="list-group-item">
              <NavLink to="/dashboard/dependents">Dependents</NavLink>
            </StyledList>
          )}
      </ul>
    </div>
  );
};

export default ProfileNavigation;
