import React from 'react';
import styled from '@emotion/styled';
import { CardContent, DoughnutContainer } from '../styles';
import { Utilization } from './Utilization';
import DashboardCard from '../DashboardCard';
import { useAppData } from '../../../contexts/AppDataProvider';

const StyledText = styled.p`
  color: #7e84a3;
  padding-top: 20px;
`;

export const SessionUtilization = ({ data }) => {
  const { cancellation_period_time_in_words } = useAppData()
  const title = 'Session Utilization';
  const tooltipText = `Pending - upcoming scheduled sessions
                        Done - completed sessions
                        Cancelled Paid - sessions that were cancelled in less than ${cancellation_period_time_in_words}
                        No Show - the employee did not attend the session
                        Cancelled - sessions that were cancelled for ${cancellation_period_time_in_words} or more; can be rebooked
                        `;
  return (
    <DashboardCard
      title={title}
      withQuestionMark={true}
      tooltipText={tooltipText}>
      <div className="d-flex justify-content-between">
        <StyledText>
          Remaining prepaid sessions: {data?.remaing_sessions}
        </StyledText>
        <StyledText>Excess sessions: {data?.excess_sessions}</StyledText>
      </div>
      <CardContent>
        <Utilization data={data?.meetings_utilization} />
      </CardContent>
    </DashboardCard>
  );
};
