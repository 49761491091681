import useSWR from 'swr';
import axios from 'axios';
import {
  StyledHeader,
  StyledHeading,
  StyledHeadingBody,
  StyledCol,
  SeeAllLink,
} from './styles';
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';

import Layout from '../Layout';
import Sessions from './Sessions';
import CheckInModal from './CheckInModal';

import DashboardWebinarArray from '../Webinars/Array/DashboardWebinarArray';

import { useAppData } from '../contexts/AppDataProvider';
import AssessmentWelcomeModal from '../Assessment/WelcomeModal';
import AssessmentDashboard from '../Assessment/Dashboard';
import Quote from '../shared/Quote';
import CheckInDashboard from './CheckInDashboard';
import ContentCardFullWidth from '../Content/Card/ContentCardFullWidth';
import OnBoardingModal from '../shared/modals/OnBoardingModal';
import Loader from './Loader';
import SessionDashboard from './SessionDashboard';
import PodcastDashboard from './PodcastDashboard';

const fetcher = (url) => axios.get(url).then((res) => res.data);

const Dashboard = () => {
  const { currentAssessment, setCurrentAssessment, access_to_assessment } =
    useAppData();
  const [showAssessmentModal, setShowAssessmentModal] = useState(false);
  const [showCheckInModal, setShowCheckInModal] = useState(false);

  const { data: dashboard } = useSWR('/api/v2/dashboard', fetcher, {
    revalidateOnFocus: false,
  });

  const [
    {
      done_onboarding,
      should_check_in,
      events,
      nudges,
      assessment,
      meeting,
      events_type,
    },
    setDashboard,
  ] = useState({
    done_onboarding: true,
    should_check_in: false,
    events: null,
    nudges: null,
    assessment: null,
    meeting: null,
    events_type: null,
  });

  useEffect(() => {
    if (!!dashboard) {
      setDashboard(dashboard);
    }
  }, [dashboard]);

  const hideCheckInDashboard = () => {
    setDashboard((currentState) => ({
      ...currentState,
      should_check_in: false,
    }));
  };
  return (
    <Layout>
      <Row>
        <Sessions />
        <StyledCol lg={10} sm={12}>
          {(assessment?.should_take ||
            should_check_in ||
            access_to_assessment) && (
            <StyledHeading className="mt-5">For Today</StyledHeading>
          )}
          {access_to_assessment && (
            <AssessmentDashboard
              setShowAssessmentModal={setShowAssessmentModal}
              is_retake={assessment?.is_retake}
            />
          )}
          {nudges?.session && <SessionDashboard session={meeting} />}
          {should_check_in && (
            <CheckInDashboard setShowCheckInModal={setShowCheckInModal} />
          )}
          {nudges?.show_podcast_banner && <PodcastDashboard />}

          {!nudges && <Loader />}
          {nudges && (
            <div className="mb-3 mt-5">
              <Row>
                <StyledHeader>
                  <StyledHeading>For You</StyledHeading>
                </StyledHeader>
                {nudges?.contents?.map((content, index) => (
                  <ContentCardFullWidth
                    key={index}
                    withContent={true}
                    data={content}
                  />
                ))}
                {nudges?.webinars && (
                  <div className="mb-3 w-100">
                    <DashboardWebinarArray data={nudges?.webinars} />
                  </div>
                )}
              </Row>
            </div>
          )}
          {events && (
            <div className="mb-3">
              <Row>
                <StyledHeader>
                  <StyledHeading>Latest Events</StyledHeading>
                  <SeeAllLink
                    to={{
                      pathname: `/webinars`,
                      search: `?events_type=${events_type}`,
                    }}>
                    See All
                  </SeeAllLink>
                </StyledHeader>
              </Row>
              <Row>
                <DashboardWebinarArray data={events} />
              </Row>
            </div>
          )}

          {nudges?.quote && (
            <Row>
              <Col>
                <Quote quote={nudges?.quote} />
              </Col>
            </Row>
          )}
        </StyledCol>
      </Row>
      {showAssessmentModal && (
        <AssessmentWelcomeModal setCurrentAssessment={setCurrentAssessment} />
      )}
      {showCheckInModal && (
        <CheckInModal
          setShowCheckInModal={setShowCheckInModal}
          hideCheckInDashboard={hideCheckInDashboard}
        />
      )}
      {!done_onboarding && <OnBoardingModal />}
    </Layout>
  );
};

export default Dashboard;
export { StyledHeader, StyledHeading, StyledHeadingBody };
