import useSWR from 'swr';
import React from 'react';
import styled from '@emotion/styled';
import ContentArray from '../Content/Array';
import axios from 'axios';
import Loader from '../Content/Array/Loader';
import ExploreFilter from './ExploreFilter';

export const StyledHeading = styled.h2`
  font-size: 1rem;
  font-weight: bold;
  color: #333333;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  opacity: 0.7;
`;
const fetcher = (url) => axios.get(url).then((res) => res.data);
const ExploreCategories = () => {
  const { data } = useSWR('/api/v2/categories?home=true', fetcher, {
    revalidateOnFocus: false,
  });
  return (
    <>
      {!data && <Loader />}

      <ExploreFilter categories={data} />
      {/* {data?.map((categoryData, index) => (
        <div key={index} className="mb-5">
          <ContentArray categoryData={categoryData} />
        </div>
      ))} */}
    </>
  );
};

export default ExploreCategories;
