import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Spinner from '../../Spinner';
import styled from '@emotion/styled';
import ContentCard from '../Content/Card';
import PodcastCard from '../Podcast/PodcastCard';

const Styledh5 = styled.h5`
  font-size: 1rem;
  font-weight: bold;
  color: #333333;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  opacity: 0.7;
`;

const SpinnerWrapper = styled.div`
  margin-top: 100px;
`;
const ExploreResults = ({ contents, keyWord, busy }) => {
  return (
    <Container className="min-vh-100">
      {busy && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}

      {!busy && contents.length > 0 && (
        <>
          <Row>
            <Col>
              <p className="text-left">
                Showing results for "<b>{keyWord}</b>"
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center m-0 p-0">
            {contents.map((content, index) =>
              content.hasOwnProperty('podcast_url') ? (
                <Col
                  lg={{ span: 4, offset: 0 }}
                  md={{ span: 10, offset: 1 }}
                  style={{ marginTop: '2rem' }}>
                  <PodcastCard key={`content-${index}`} podcast={content} />
                </Col>
              ) : (
                <ContentCard
                  key={`content-${index}`}
                  withContent={true}
                  data={content}
                />
              ),
            )}
          </Row>
        </>
      )}
      {!busy && contents.length <= 0 && (
        <Styledh5 className="text-center min-vh-100">
          No search results...
        </Styledh5>
      )}
    </Container>
  );
};

export default ExploreResults;
