import React, { useContext } from "react";
import { css, cx } from "emotion";

import Title from "../Title";
import FeaturedCounselors from "../shared/FeaturedCounselors";
import Spinner from "../Spinner";
import PricingPackage from "../shared/PricingPackage";
import PricingPackageButton from "../shared/PricingPackageButton";
import usePackages from "../shared/usePackages";

const SignUpPricingPackages = () => {
  const [packageState] = usePackages();
  return (
    <>
      <div className="bg-light">
        <Title
          title="Get counseling support for your mental well-being"
          description=""
        >
          <>
            <p className="mb-1">
              Choose a plan based on the number of sessions you need.
            </p>
            <p>Each session consists of 60 minutes of online counseling.</p>
            <a href="#featured-counselors" className="text-muted mx-2">
              <u>
                See featured counselors <i className="fa fa-chevron-down"></i>
              </u>
            </a>
            <br />
            <br />
          </>
        </Title>
      </div>
      <div className="bg-white">
        <div
          className={cx(
            "container",
            css`
              padding: 2rem 0;
            `
          )}
        >
          {packageState.packages ? (
            <>
              <div className="card-deck mt-2 justify-content-center">
                {packageState.packages.map((packageDetail, index) => (
                  <PricingPackage key={index} packageDetail={packageDetail} />
                ))}
              </div>
              <div className="text-center">
                <a
                  href="/users/sign_up"
                  className={cx(
                        "btn btn-lg",
                          css`
                            background-color: #4db5b4;
                            color: #fff;
                            border: 0;
                            &:hover,
                            &:active,
                            &:focus {
                              background: #4db5b4;
                              color: #fff;
                            }`)}
                >
                  Sign Up
                </a>
              </div>
            </>
          ) : (
            <Spinner />
          )}
        </div>
      </div>
      <FeaturedCounselors />
    </>
  );
};

export default SignUpPricingPackages;
