import React, { useEffect, useState } from "react";
import Api from "../Api";

const usePayment = () => {
  const [paymentState, setPackageState] = useState({
    loading: true,
    payments: null,
    status: "completed"
  });

  useEffect(() => {
    const fetchData = async () => {
      await Api.get(`/api/v2/orders?status=${paymentState?.status}`, {}).then(res => {
        setPackageState({
          ...paymentState,
          loading: false,
          payments: res.data
        });
      });
    };
    fetchData();
  }, []);

  return [paymentState];
};

export default usePayment;
