import React from 'react';
import { Col, Image } from 'react-bootstrap';
import { UIButton } from '../shared/ui';

const InitialScreen = ({ setCurrentStep }) => {
  return (
    <>
      <Col sm={{ span: 8, offset: 2 }} className="text-center">
        <Image src="update-email.svg" style={{ width: '70%' }} />
        <section className="m-5">
          <h6 className="mb-4" style={{ fontWeight: '600', fontSize: '18px' }}>
            Update your email
          </h6>
          <p>
            To ensure that you continue to receive important notifications and
            updates from us, we kindly request that you update your email
            address as soon as possible.
          </p>
          <p>
            This will also help us in ensuring that your account information is
            up-to-date.
          </p>
        </section>
        <UIButton
          onClick={() => setCurrentStep('update_screen')}
          style={{ width: '250px' }}
          text={'Continue'}></UIButton>
      </Col>
    </>
  );
};

export default InitialScreen;
