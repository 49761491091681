import React, { useContext, useState, useRef } from 'react';
import { UserContext } from '../../contexts/UserContext';

const EditForm = ({ dependent, setEditing, setDependent }) => {
  const { setUserProfile } = useContext(UserContext);
  const [errors, setErrors] = useState({});
  const originalDependent = useRef(dependent);

  function handleOnChange(event) {
    const updatedAttribute = { [event.target.name]: event.target.value };
    setDependent({ ...dependent, ...updatedAttribute });
  }

  async function handleOnSubmit(event) {
    event.preventDefault();
    const payload = {
      user_dependent: {
        relationship: dependent.relationship,
      },
      dependent: {
        first_name: dependent.first_name,
        last_name: dependent.last_name,
        email: dependent.email,
      },
    };

    const res = await fetch(`/api/v2/user_dependents/${dependent.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (res.status === 200) {
      setEditing(false);
      setDependent(dependent);
    } else {
      const { errors } = await res.json();
      setErrors(errors[0]);
    }
  }

  function cancelButton(){
    setEditing(false);
    setDependent(originalDependent.current);
  }

  return (
    <section>
      <div className="card-body">
        <form className="d-flex flex-column" onSubmit={handleOnSubmit}>
          <div className="mb-3">
            <label
              htmlFor="dependent[first_name]"
              className="form-label font-weight-bolder">
              {' '}
              First Name{' '}
            </label>
            <input
              id="dependent[first_name]"
              name="first_name"
              value={dependent?.first_name}
              onChange={handleOnChange}
              className="form-control"
              style={{ height: 'auto' }}
              required
            />
            {errors.name && (
              <span className="small text-danger mt-2">
                {' '}
                {errors.name.join(', ')}{' '}
              </span>
            )}
          </div>
          <div className="mb-3">
            <label
              htmlFor="dependent[last_name]"
              className="form-label font-weight-bolder">
              {' '}
              Last Name{' '}
            </label>
            <input
              id="dependent[last_name]"
              name="last_name"
              value={dependent?.last_name}
              onChange={handleOnChange}
              className="form-control"
              style={{ height: 'auto' }}
              required
            />
            {errors.name && (
              <span className="small text-danger mt-2">
                {' '}
                {errors.name.join(', ')}{' '}
              </span>
            )}
          </div>
          <div className="mb-3">
            <label
              htmlFor="dependent[email]"
              className="form-label font-weight-bolder">
              {' '}
              Email{' '}
            </label>
            <input
              id="dependent[email]"
              type="email"
              name="email"
              value={dependent?.email}
              onChange={handleOnChange}
              className="form-control"
              required
              disabled
            />
            {errors.phone && (
              <span className="small text-danger mt-2">
                {' '}
                {errors.phone.join(', ')}{' '}
              </span>
            )}
          </div>
          <div className="mb-3">
            <label
              htmlFor="dependent[relationship]"
              className="form-label font-weight-bolder">
              {' '}
              Relationship{' '}
            </label>
            <select
              name="relationship"
              value={dependent?.relationship}
              onChange={handleOnChange}
              className="form-control"
              required>
              <option value="Spouse">Spouse</option>
              <option value="Sibling">Sibling</option>
              <option value="Parent">Parent</option>
              <option value="Guardian">Guardian</option>
              <option value="Relative">Relative</option>
              <option value="Other">Other</option>
            </select>
            {errors.relationship && (
              <span className="small text-danger mt-2">
                {' '}
                {errors.relationship.join(', ')}{' '}
              </span>
            )}
          </div>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              onClick={() => cancelButton()}
              style={{ height: 'fit-content' }}
              className="mt-auto btn btn-general bg-white text-dark border rounded mr-2">
              Cancel
            </button>
            <button
              type="submit"
              onClick={handleOnSubmit}
              className="btn-general rounded">
              Update
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default EditForm;