import React, { useState, useEffect, useRef } from 'react';
import { Container, Form } from 'react-bootstrap';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import {
  StyledDiv,
  StyledHeader,
  FormContainer,
} from '../../shared/styles/RegistrationStyle';
import Terms from '../../shared/Terms';

const CompanySetup = ({ formData }) => {
  const background = '/otp/background.svg';
  const [siteOptions, setSiteOptions] = useState([]);
  const [showTerms, setShowTerms] = useState(false);
  const [agree, setAgree] = useState(false);
  const [code, setCode] = useState('');
  const [site, setSite] = useState('');
  const [validToSignup, setValidToSignup] = useState(false);
  const [error, setError] = useState({});
  const debounceTimeout = useRef(null);
  const axiosToken = useRef(null);

  const history = useHistory();

  useEffect(() => {
    setSite(siteOptions[0]);
  }, [siteOptions]);

  useEffect(() => {
    if (code === '') {
      setSiteOptions([]);
      setSite(null);
      return;
    }
    if (code) {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        getSites();
      }, 500);
    }
  }, [code]);

  const handleIndividualSignup = () => {
    Cookies.set(
      'userType',
      'individual',
      { expires: 5 / 1440 }, // 5 minutes expiration
    );
    setShowTerms(true);
  };

  const handleSignup = () => {
    Cookies.set(
      'userType',
      'employee',
      { expires: 5 / 1440 }, // 5 minutes expiration
    );
    setShowTerms(true);
  };

  const handleClose = () => {
    setShowTerms(false);
  };

  const handleChangeSite = (event) => {
    setSite(event.target.value);
    Cookies.set('sign_up_code', code, { expires: 5 / 1440 });
    Cookies.set('site', site, { expires: 5 / 1440 });
  };

  const handleFinish = async () => {
    if (Cookies.get('userType') === 'employee') {
      try {
        const res = await axios.post('/api/v2/users', {
          user: {
            ...formData,
            sign_up_code: code,
            site,
          },
        });
        if (res.status === 200) {
          history.push({
            pathname: 'mobile_registration',
            state: {
              registration_phone: formData.phone,
              formData,
            },
          });
        }
      } catch (err) {
        console.log('errors', _err);
      }
    } else {
      try {
        const res = await axios.post('/api/v2/users', {
          user: {
            ...formData,
          },
        });
        if (res.status === 200) {
          history.push({
            pathname: 'mobile_registration',
            state: {
              registration_phone: formData.phone,
              formData,
            },
          });
        }
      } catch (_err) {
        console.log('errors', _err);
      }
    }
  };

  const handleCodeChange = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (validToSignup) {
        handleSignup();
      }
    } else {
      setValidToSignup(false);
    }
    if (axiosToken.current) {
      axiosToken.current.cancel('Operation canceled');
    }
    setCode(event.target.value);
  };

  const getSites = async () => {
    axiosToken.current = axios.CancelToken.source();
    try {
      const res = await axios.get(`/api/v2/company_sites?invite_code=${code}`, {
        cancelToken: axiosToken.current.token,
      });
      if (res.status === 200) {
        setSiteOptions(res['data']);
        if (res['data'].length > 0) {
          setSite(siteOptions[0]);
        }
        setError({});
        setValidToSignup(true);
      }
    } catch (_err) {
      if (axios.isCancel(_err)) {
        console.error(_err.message);
      } else {
        setSiteOptions([]);
        setSite(null);
        setError(_err['response']['data']['error']);
      }
    }
  };

  return (
    <StyledDiv>
      <Terms
        show={showTerms}
        handleClose={handleClose}
        agree={agree}
        setAgree={setAgree}
        handleFinish={handleFinish}
      />
      <Container>
        <StyledHeader>Enter your company code</StyledHeader>
        <img src={background} alt="" className="background-image" />
        <FormContainer>
          <p>
            Enter your company code to access mental health benefits exclusive
            to your company.
          </p>
          <Form>
            <Form.Group>
              <label htmlFor="company_code">Company Code</label>
              <input
                type="text"
                id="company_code"
                name="company_code"
                placeholder="Enter your code here"
                className="form-control"
                value={code}
                onChange={(e) => handleCodeChange(e)}
                onKeyDown={(e) => handleCodeChange(e)}
              />
              {error.length > 0 && (
                <p className="text-left text-error">
                  <small>{error}</small>
                </p>
              )}
            </Form.Group>
            {siteOptions.length > 0 && (
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Label>Site</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  style={{ height: '50px' }}
                  onChange={(e) => handleChangeSite(e)}>
                  {siteOptions.length > 0 &&
                    siteOptions.map((site) => {
                      return (
                        <option key={site} value={site}>
                          {site}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Group>
            )}

            <div className="company-setup-actions">
              <div className="individual" onClick={handleIndividualSignup}>
                Sign up as an individual
              </div>
              <div className="sign-up">
                <button
                  type="button"
                  onClick={handleSignup}
                  disabled={!validToSignup}
                  style={{
                    opacity: validToSignup ? '1' : '0.5',
                  }}>
                  Sign Up
                </button>
              </div>
            </div>
          </Form>
        </FormContainer>
      </Container>
    </StyledDiv>
  );
};

export default CompanySetup;
