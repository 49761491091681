import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import UpdateEmail from '../UpdateEmail';
import Pag404 from '../Pag404';

const DisabledEmailRoutes = () => (
  <Router basename="/">
    <Switch>
      <Route exact path="/" component={UpdateEmail} />
      <Route component={Pag404} />
    </Switch>
  </Router>
);

export default DisabledEmailRoutes;
