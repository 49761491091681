import React, { useState, useEffect, useRef } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { UserEngagement } from './components/UserEngagement';
import { UsersAverageMood } from './components/UsersAverageMood';
import * as moment from 'moment';
import styled from '@emotion/styled';
import useSWR from 'swr';
import DateRangeInput from '../shared/filters/DateRangeInput';
import { useAppData } from '../contexts/AppDataProvider';
import Navigation from '../Layout/Navigation';
import Footer from '../shared/Footer';
import { TopViewedContent } from './components/TopViewedContent';
import { ChatHelpLine } from './components/ChatHelpLine';
import { CounselingSessionRequest } from './components/CounselingSessionRequest';
import { NewUsersPerMonth } from './components/NewUsersPerMonth';
import DashboardCard from './components/DashboardCard';
import Cloaking from '../shared/Cloaking';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { SessionUtilization } from './components/sessionUtilization';
import { Reasons } from './components/Reasons';
import { Recommendations } from './components/Recommendations';
import { MonthlySessionsUtilization } from './components/MonthlySessionsUtilization';
import { Title as StyledTitle } from './components/styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const CalendarContainer = styled.div`
  position: relative;
  background-color: #edf9f9;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
`;
const DateRange = styled.span`
  line-height: 30px;
  letter-spacing: 1.2px;
  color: #333333;
  text-transform: uppercase;
  opacity: 0.7;
  font-weight: 600;
`;
const DateRangeInputContainer = styled.div`
  width: 275px;
  background-color: white;
`;
const ContainerWrapper = styled(Container)`
  margin-top: 2.5rem;
`;
const Count = styled.div`
  color: blank;
  font-size: 25px;
  font-weight: 500;
  margin-top: 1rem;
`;
const ContractDateStyle = styled.p`
  font-size: 12px;
  color: #7e84a3;
`;
const TotalEmployee = styled.span`
  font-size: 11px;
`;

const startDateDefault = () => {
  let date_now = moment();
  let default_start_date = moment('July, 1, 2022');
  if (default_start_date.year() != date_now.year()) {
    default_start_date = moment().startOf('year');
  }
  return default_start_date;
};

const CompanyDashboard = () => {
  const [startDate, setStartDate] = useState(
    startDateDefault().format('DD/MM/YYYY'),
  );
  const [endDate, setEndDate] = useState(
    moment().endOf('month').format('DD/MM/YYYY'),
  );
  const [chatEntries, setChatEntries] = useState(null);
  const { companyID, companyCsManager } = useAppData();
  const [siteId, setSiteId] = useState(null);
  const [filteredCompanyId, setFilteredCompanyId] = useState(companyID);

  const searchParams = new URLSearchParams({
    start_date: startDate,
    end_date: endDate,
    site_id: siteId,
    company_id: filteredCompanyId,
  });

  const { data: site_data } = useSWR(
    `/api/v2/company_admin/client_dashboard/site_data?${searchParams.toString()}`,
    { revalidateOnFocus: false },
  );

  const { data: user_data } = useSWR(
    `/api/v2/company_admin/client_dashboard/user_data?${searchParams.toString()}`,
    { revalidateOnFocus: false },
  );
  const { data: content_data } = useSWR(
    `/api/v2/company_admin/client_dashboard/content_data?${searchParams.toString()}`,
    { revalidateOnFocus: false },
  );
  const { data: meetings_usage } = useSWR(
    `/api/v2/company_admin/client_dashboard/meetings_usage?${searchParams.toString()}`,
    { revalidateOnFocus: false },
  );
  const { data: user_engagement } = useSWR(
    `/api/v2/company_admin/client_dashboard/user_engagement?${searchParams.toString()}`,
    { revalidateOnFocus: false },
  );
  const { data: recommendationsData } = useSWR(
    `/api/v2/recommendations/count?${searchParams.toString()}`,
    { revalidateOnFocus: false },
  );
  const { data: meetingReasons } = useSWR(
    `/api/v2/company_admin/client_dashboard/meeting_reasons?${searchParams.toString()}`,
    { revalidateOnFocus: false },
  );
  const { data: counselingRequests } = useSWR(
    `/api/v2/company_admin/meeting_sessions_requests?limit=2&site_id=${siteId}&company_id=${filteredCompanyId}`,
    { revalidateOnFocus: false },
  );
  const { data: handledCompanies } = useSWR(companyCsManager ?
    "/api/v2/company_admin/cs_managers/companies" : null,
    { revalidateOnFocus: false },
  );
  const { isCloaked, firstName, lastName } = useAppData();
  const user = firstName + ' ' + lastName;


  const ContractDate = () => (
    <ContractDateStyle>
      Since {moment(user_data?.first_contract?.start_date)?.format('MMMM YYYY')}
    </ContractDateStyle>
  );

  useEffect(() => {
    if (!!user_engagement) {
      setChatEntries(user_engagement.find((data) => data['key'] === 'Chat'));
    }
  }, [user_engagement]);

  useEffect(() => {
    if (handledCompanies?.length > 0) {
      setFilteredCompanyId(handledCompanies[0].id)
    }
  }, [handledCompanies])

  const onCompanyChange = (value) => {
    setFilteredCompanyId(value);
  }
  return (
    <div>
      <Navigation />
      {isCloaked && <Cloaking user={user} />}
      <ContainerWrapper>
        {handledCompanies?.length > 0 && (
          <>
            <Row>
              <Col md={4} sm={12}>
                <StyledTitle>Company</StyledTitle>
                <select
                  name="selectedCompany"
                  defaultValue={filteredCompanyId}
                  className="form-control mb-5"
                  onChange={(event) => onCompanyChange(event.target.value)}>
                  {handledCompanies.map((company, index) => (
                    <option
                      key={`companyname-${index}-${company.id}`}
                      value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
          </>
        )}
        {site_data?.available_sites?.length > 0 && (
          <>
            <Row>
              <Col md={4} sm={12}>
                <select
                  name="siteId"
                  defaultValue={site_data?.current_site?.id}
                  className="form-control mb-4"
                  onChange={(event) => setSiteId(event.target.value)}>
                  <option disabled> -- Select a Company Site --</option>
                  {site_data?.available_sites?.map((siteObject) => (
                    <option key={`site-${siteObject.id}`} value={siteObject.id}>
                      {siteObject.name}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col md={4} sm={12}>
            <DashboardCard
              title={'% of Registered Employees '}
              withQuestionMark={true}
              tooltipText={
                'Employees who have activated their app access versus total employee population'
              }>
              <ContractDate />
              {user_data?.total_active_users && (
                <Count>
                  {
                    user_data?.percentage_of_employees_registered
                      ?.activated_users
                  }
                  {'% '}
                  <TotalEmployee>
                    out of{' '}
                    {user_data?.percentage_of_employees_registered?.total_users_count.toLocaleString()}{' '}
                    employees
                  </TotalEmployee>
                </Count>
              )}
            </DashboardCard>
          </Col>
          <Col md={4} sm={12}>
            <DashboardCard
              title={'Total Activated Users'}
              withViewAll={true}
              linkUrl={
                !!(siteId || site_data?.current_site?.id)
                  ? `/company/users?site_id=${
                      siteId || site_data?.current_site?.id
                    }&company_id=${
                      filteredCompanyId
                    }`
                  : '/company/users'
              }
              withQuestionMark={true}
              tooltipText={
                'The number of users who have activated the app by confirming their email'
              }>
              <ContractDate />
              <Count>{user_data?.total_activated_users}</Count>
            </DashboardCard>
          </Col>
          <Col md={4} sm={12}>
            <DashboardCard
              title={'Total Active Users'}
              withQuestionMark={true}
              tooltipText={
                'Registered users who are still employed by the company'
              }>
              <ContractDate />
              <Count>{user_data?.total_active_users}</Count>
            </DashboardCard>
          </Col>
        </Row>
        <CalendarContainer className="mb-4">
          <DateRange>DATE RANGES &nbsp;</DateRange>
          <DateRangeInputContainer>
            <DateRangeInput
              startDate={startDateDefault()}
              onChange={({ startDate, endDate }) => {
                if (startDate && endDate) {
                  setStartDate(moment(startDate).format('DD/MM/YYYY'));
                  setEndDate(moment(endDate).format('DD/MM/YYYY'));
                }
              }}
            />
          </DateRangeInputContainer>
        </CalendarContainer>
        <Row>
          <Col lg={6} md={12}>
            {user_data && <NewUsersPerMonth data={user_data} />}
          </Col>
          <Col lg={6} md={12}>
            {user_engagement && <UserEngagement data={user_engagement} />}
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={12}>
            <DashboardCard
              title={'Unique Content Consumed'}
              withQuestionMark={true}
              tooltipText={'The number of resources opened by users'}>
              <Count>{content_data?.unique_consumed_content_count}</Count>
            </DashboardCard>
            {user_data?.users_average_mood && (
              <UsersAverageMood data={user_data?.users_average_mood} />
            )}
          </Col>
          <Col lg={6} md={12}>
            {content_data?.top_viewed_content && (
              <TopViewedContent contents={content_data?.top_viewed_content} />
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={12}>
            {meetings_usage?.meetings_utilization && (
              <SessionUtilization data={meetings_usage} />
            )}
          </Col>
          <Col lg={6} md={12}>
            {meetings_usage && (
              <MonthlySessionsUtilization
                data={meetings_usage}
                purpose={'counseling'}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {counselingRequests?.data.length > 0 && (
              <CounselingSessionRequest
                data={counselingRequests.data}
                siteId={siteId}
                companyId={filteredCompanyId}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <DashboardCard
              title={'Total users who booked counseling sessions'}
              withQuestionMark={true}
              tooltipText={
                'The number of users who have booked at least 1 counseling session'
              }>
              <Count>
                {meetings_usage?.user_booked_counseling_sessions_count}
              </Count>
            </DashboardCard>
          </Col>
          <Col md={6} sm={12}>
            <DashboardCard
              title={'Booked more than 1 counseling session'}
              withQuestionMark={true}
              tooltipText={
                'The number of users who have booked more than 1 counseling session'
              }>
              <Count>
                {meetings_usage?.users_booked_many_counseling_sessions_count}
              </Count>
            </DashboardCard>
          </Col>
        </Row>
        <Row>
          <Col>{chatEntries && <ChatHelpLine data={chatEntries} />}</Col>
        </Row>
        <Row>
          <Col>
            {meetingReasons?.counseling && (
              <Reasons
                reasons={meetingReasons?.counseling}
                title={'Reasons For Seeking Counseling'}
                tooltipText={
                  'Counselor assessment of reason for counseling. Counselor can indicate more than one reason per session.'
                }
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {recommendationsData && (
              <Recommendations
                recommendations={recommendationsData?.counseling}
                title={'Counselor Recommendations'}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <DashboardCard
              title={'Total users who booked coaching sessions'}
              withQuestionMark={true}
              tooltipText={
                'The number of users who have booked at least 1 coaching session'
              }>
              <Count>
                {meetings_usage?.user_booked_coaching_sessions_count}
              </Count>
            </DashboardCard>
          </Col>
          <Col md={6} sm={12}>
            <DashboardCard
              title={'Booked more than 1 coaching session'}
              withQuestionMark={true}
              tooltipText={
                'The number of users who have booked more than 1 coaching session'
              }>
              <Count>
                {meetings_usage?.users_booked_many_coaching_sessions_count}
              </Count>
            </DashboardCard>
          </Col>
        </Row>
        <Row>
          <Col>
            {meetingReasons && (
              <Reasons
                reasons={meetingReasons?.coaching}
                title={'Reasons For Seeking Coaching'}
                tooltipText={
                  'Coach assessment of reason for coaching. Coach can indicate more than one reason per session.'
                }
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {recommendationsData && (
              <Recommendations
                recommendations={recommendationsData?.coaching}
                title={'Coach Recommendations'}
              />
            )}
          </Col>
        </Row>
      </ContainerWrapper>
      <Footer />
    </div>
  );
};

export default CompanyDashboard;
