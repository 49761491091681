import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PricingPackages from './PricingPackages';
import Payment from './Payment';
import Profile from './Profile';
import EmergencyContact from './EmergencyContact';
import Preferences from './Preferences';
import Dashboard from './Dashboard';
import QuestionsForm from './QuestionsForm';
import PaymentSummary from './PaymentSummary';
import Page404 from '../Page404';
import { UserContext } from '../contexts/UserContext';
import MoodTacker from './MoodTracker';
import Dependents from './Dependents';
import SessionHistory from './SessionHistory';
import VerifyEmail from './VerifyEmail';
import ChangePassword from './ChangePassword';

export default ({ currentUser }) => {
  const [userProfile, setUserProfile] = useState(currentUser);
  return (
    <UserContext.Provider value={{ currentUser: userProfile, setUserProfile }}>
      <Router basename="/individual">
        <Switch>
          <Route exact path="/dashboard/" component={Dashboard} />
          <Route exact path="/dashboard/packages" component={PricingPackages} />
          <Route path="/dashboard/profile" component={Profile} />
          <Route path="/dashboard/change_password" component={ChangePassword} />
          <Route
            path="/dashboard/emergency_contact"
            component={EmergencyContact}
          />
          <Route path="/dashboard/preferences" component={Preferences} />
          <Route path="/dashboard/payments/:packageName" component={Payment} />
          {currentUser.individualSessionsEnabled && <Route path="/dashboard/payment_summary" component={PaymentSummary} />}
          <Route path="/dashboard/session_history" component={SessionHistory} />
          <Route path="/responses/new" component={QuestionsForm} />
          {currentUser.withMoodTracker && (
            <Route path="/dashboard/mood_tracker" component={MoodTacker} />
          )}
          {currentUser.with_company &&
            currentUser.dependent_enabled &&
            !currentUser.dependent && (
              <Route path="/dashboard/dependents" component={Dependents} />
            )}
          <Route path="/verify_email" component={VerifyEmail} />
          <Route component={Page404} />
        </Switch>
      </Router>
    </UserContext.Provider>
  );
};
