import React, { useState, useEffect } from 'react';
import useEmergencyContact from '../shared/useEmergencyContact';
import ProfileNavigation from '../shared/ProfileNavigation';
import styled from '@emotion/styled';

const StyledCardBody = styled.div`
  padding-top: 35px;
  padding-bottom: 30px;
  padding-left: 40px;
  padding-right: 40px;
`;
const StyledLabel = styled.span`
  font-size: 14px;
  margin-bottom: 5px;
`;
const StyledInfo = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
`;

const EmergencyContactInfo = ({
  setEditing,
  contact: { name, relationship, phone },
}) => {
  return (
    <div className="col col-lg-9 col-md-8 col-sm-12 mt-2">
      <div className="card">
        <StyledCardBody className="card-body">
          <div className="d-flex justify-content-between">
            <h5 className="card-title">Emergency Contact</h5>
            <span onClick={() => setEditing(true)} className="btn btn-sm pt-0">
              <i className="far fa-edit"></i>
            </span>
          </div>
          <div>
            <StyledLabel className="d-block"> Name</StyledLabel>
            <StyledInfo className="d-block">{name}</StyledInfo>
          </div>
          <div>
            <StyledLabel className="d-block">Relationship</StyledLabel>
            <StyledInfo className="d-block">{relationship}</StyledInfo>
          </div>
          <div>
            <StyledLabel className="d-block">Phone</StyledLabel>
            <StyledInfo className="d-block">{phone}</StyledInfo>
          </div>
        </StyledCardBody>
      </div>
    </div>
  );
};

const EmergencyContactForm = ({ setContact, contact, setEditing }) => {
  const [emergencyContact, setEmergencyContact] = useState(contact);
  const [errors, setErrors] = useState({});

  function handleOnChange(event) {
    const updatedAttribute = { [event.target.name]: event.target.value };
    setEmergencyContact({ ...emergencyContact, ...updatedAttribute });
  }

  async function handleOnSubmit(event) {
    event.preventDefault();
    const payload = {
      emergency_contact: {
        name: emergencyContact.name,
        relationship: emergencyContact.relationship,
        phone: emergencyContact.phone,
      },
    };

    const res = await fetch('/api/v2/emergency_contacts', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (res.status === 200) {
      setEditing(false);
      setContact(emergencyContact);
    } else {
      const { errors } = await res.json();
      setErrors(errors[0]);
    }
  }

  return (
    <div className="col-lg-8 col-md-8 col-sm-12 mt-2">
      <section className="card">
        <div className="card-body">
          <h5 className="card-title">Emergency Contact</h5>
          <form className="d-flex flex-column" onSubmit={handleOnSubmit}>
            <div className="mb-3">
              <label
                htmlFor="emergency_contact[name]"
                className="form-label font-weight-bolder">
                {' '}
                Name{' '}
              </label>
              <input
                id="emergency_contact[name]"
                type="text"
                name="name"
                value={emergencyContact.name}
                onChange={handleOnChange}
                className="form-control"
                required
              />
              {errors.name && (
                <span className="small text-danger mt-2">
                  {' '}
                  {errors.name.join(', ')}{' '}
                </span>
              )}
            </div>
            <div className="mb-3">
              <label
                htmlFor="emergency_contact[relationship]"
                className="form-label font-weight-bolder">
                {' '}
                Relationship{' '}
              </label>
              <select
                name="relationship"
                value={emergencyContact.relationship}
                onChange={handleOnChange}
                className="form-control"
                required>
                <option value="Spouse">Spouse</option>
                <option value="Sibling">Sibling</option>
                <option value="Parent">Parent</option>
                <option value="Guardian">Guardian</option>
                <option value="Relative">Relative</option>
                <option value="Other">Other</option>
              </select>
              {errors.relationship && (
                <span className="small text-danger mt-2">
                  {' '}
                  {errors.relationship.join(', ')}{' '}
                </span>
              )}
            </div>
            <div className="mb-3">
              <label
                htmlFor="emergency_contact[phone]"
                className="form-label font-weight-bolder">
                {' '}
                Phone{' '}
              </label>
              <input
                id="emergency_contact[phone]"
                type="text"
                name="phone"
                value={emergencyContact.phone}
                onChange={handleOnChange}
                className="form-control"
                required
              />
              {errors.phone && (
                <span className="small text-danger mt-2">
                  {' '}
                  {errors.phone.join(', ')}{' '}
                </span>
              )}
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                onClick={() => setEditing(false)}
                style={{ height: 'fit-content' }}
                className="mt-auto btn btn-general bg-white text-dark border rounded mr-2">
                Cancel
              </button>
              <button
                type="submit"
                onClick={handleOnSubmit}
                className="btn-general rounded">
                Update
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

const EmergencyContact = ({}) => {
  const [editingEmergencyContact, setEditingEmergencyContact] = useState(false);
  const [emergencyContactState] = useEmergencyContact();
  const [contact, setContact] = useState(
    emergencyContactState.emergency_contact,
  );

  useEffect(() => {
    setContact(emergencyContactState.emergency_contact);
  }, [emergencyContactState]);

  return (
    <div className="container personal-information">
      <div className="row mt-5">
        <ProfileNavigation />
        {contact &&
          (editingEmergencyContact ? (
            <EmergencyContactForm
              contact={contact}
              setEditing={setEditingEmergencyContact}
              setContact={setContact}
            />
          ) : (
            <EmergencyContactInfo
              contact={contact}
              setEditing={setEditingEmergencyContact}
            />
          ))}
      </div>
    </div>
  );
};

export default EmergencyContact;
