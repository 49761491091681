import React from "react";
import { css, cx } from "emotion";
import * as moment from "moment";

import Title from "../Title";
import Spinner from "../Spinner";
import useIndividualSessions from "../shared/useIndividualSessions";
import { useAppData } from "../v2/contexts/AppDataProvider";

const Dashboard = () => {
  const { cancellation_period_time_in_words } = useAppData()
  const [upcomingSessionsState] = useIndividualSessions([
    "upcoming_sessions",
    "individual"
  ]);
  const [pastSessionsState] = useIndividualSessions(["past", "individual"]);
  
  return (
    <>
      <div className="bg-light">
        <Title
          title="Your counseling sessions"
          description="Take a look at your upcoming and past appointments"
        />
      </div>
      <div className="bg-light">
        <div
          className={cx(
            "container",
            css`
              padding-top: 2rem;
              padding-bottom: 2rem;
            `
          )}
        >
          <div
            className={cx(
              "card-deck",
              css`
                min-height: 50vh;
              `
            )}
          >
            <div className="col-md-6 col-xs-12 card shadow-sm">
              <div className="card-body">
                <h6 className="font-weight-bold">Upcoming Sessions</h6>
                {upcomingSessionsState.meetings ? (
                  upcomingSessionsState.meetings.length > 0 ? (
                    upcomingSessionsState.meetings.map(
                      ({
                        meeting_date,
                        start_time,
                        end_time,
                        meeting_url,
                        id,
                        status
                      }) => (
                        <div key={id}>
                          <p>
                            You have an appointment with one of our counselors
                            on
                          </p>
                          <p className="mb-0 font-weight-bold">
                            {moment(meeting_date).format("ll")}
                          </p>
                          <p className="mb-0">
                            <span className="font-weight-bold mr-3">
                              {`${moment(start_time).format(
                                "hh:mm A"
                              )}-${moment(end_time).format("hh:mm A")}`}
                            </span>{" "}
                            {status == "canceled" &&
                              "Canceled** (for rebooking)"}
                          </p>
                          <p>
                            <a
                              className="btn btn-sm btn-general"
                              href={meeting_url}
                            >
                              join session
                            </a>
                          </p>
                        </div>
                      )
                    )
                  ) : (
                    <p>
                      Confirmed appointments will appear here (within 24 hours
                      of booking)
                    </p>
                  )
                ) : (
                  <Spinner />
                )}
              </div>
            </div>
            <div className="col-md-6 col-xs-12 card shadow-sm">
              <div className="card-body">
                <h6 className="font-weight-bold">Previous Sessions</h6>
                {pastSessionsState.meetings ? (
                  pastSessionsState.meetings.length > 0 ? (
                    pastSessionsState.meetings.map(
                      ({ meeting_date, status, id }) => (
                        <div key={id} className="mb-4">
                          <p className="mb-1">
                            {status == "canceled" &&
                              "You canceled an appointment scheduled at"}
                            {status != "canceled" &&
                              "You completed a counseling session last"}
                          </p>
                          <p className="mb-0">
                            <span className="font-weight-bold mr-3">
                              {moment(meeting_date).format("ll")}
                            </span>{" "}
                            {status == "done" && "(Completed)"}
                            {status == "no_show" && "(No Show*)"}
                            {(status == "canceled_24_hrs" || status == 'canceled_paid') && "Cancelled Paid"}
                            {status == "canceled" &&
                              "Canceled** (for rebooking)"}
                          </p>
                        </div>
                      )
                    )
                  ) : (
                    <p>
                      A summary of your past appointments will appear here
                      (within 24 hours of completion)
                    </p>
                  )
                ) : (
                  <Spinner />
                )}
              </div>
            </div>
          </div>
          <div className={cx("row align-items-end mt-4")}>
            <div className="col">
              <p className="font-weight-bold">Cancelation Policy</p>
              <p className="mb-1">
                <small>
                  * Cancelations that are done with less than {cancellation_period_time_in_words} to go
                  before a scheduled appointment are also considered a
                  'no-show'.
                </small>
              </p>
              <p className="mb-1">
                <small>
                  ** Cancelations that are done more than {cancellation_period_time_in_words} before a
                  scheduled appointment may be rebooked.
                </small>
              </p>
              <p>
                <small>
                  If you want to make changes to your upcoming session, please send us an email at&nbsp;
                  <a href="mailto:support@infinitcare.co">support@infinitcare.co</a>.
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
