import axios from 'axios';
import * as moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import React, { useState, useEffect } from 'react';

import Layout from '../Layout';
import styles from './webinar.module.scss';

const WebinarLoader = () => {
  return (
    <ContentLoader height="650" width="100%">
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="200" />
      <rect x="0" y="220" rx="5" ry="5" width="100%" height="20" />
      <rect x="0" y="250" rx="5" ry="5" width="100%" height="20" />
    </ContentLoader>
  );
};

const Speakers = ({ speakers }) => {
  return (
    <div className={styles.speakers_container}>
      <h5 className={styles.header}>
        About The Speaker{speakers.length > 1 ? 's' : ''}
      </h5>
      {speakers.map((speaker) => {
        return (
          <div key={speaker.id} className={styles.speaker}>
            <div className={styles.profile}>
              <img src={speaker.avatar_url}></img>
            </div>
            <div className={styles.additional_info}>
              <span className={styles.name}>{speaker.full_name}</span>
              <div className={styles.position}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: speaker.description,
                  }}></div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const Webinar = () => {
  let { slug } = useParams();
  const [webinar, setWebinar] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`/api/v2/webinars/${slug}`);
      setWebinar(res.data);
    };
    fetchData();
  }, []);

  const meeting_date = (date) => {
    return moment(date).format('dddd, LL');
  };
  return (
    <Layout>
      {!webinar && <WebinarLoader />}
      {webinar && (
        <div className={styles.webinar_container}>
          <Row>
            <Col md={9} lg={6} className={styles.webinar_info}>
              <h5 className={styles.header}>
                {webinar.done ? 'Webinar' : 'Upcoming Webinar'}
              </h5>
              <h4 className={styles.title}>{webinar.title}</h4>
              <p className={styles.description}>{webinar.description}</p>
              <time className={styles.date}>
                {meeting_date(webinar.event_date)}
              </time>
              <time className={styles.time}>
                Via {webinar.tool_type}, {webinar.start_time} -{' '}
                {webinar.end_time}
              </time>
              <a
                className={`button--rectangle ${styles.watch_now}`}
                href={
                  webinar.done ? webinar.event_link : webinar.registration_url
                }
                target="_blank"
                rel="noopener noreferrer">
                {webinar.done ? 'Watch Now' : 'Register Now'}
              </a>
            </Col>
            <Col>
              {webinar.speakers.length > 0 && (
                <Speakers speakers={webinar.speakers.slice(0, 3)} />
              )}
            </Col>
          </Row>
        </div>
      )}
    </Layout>
  );
};

export default Webinar;
