import React from 'react';
import DashboardCard from './DashboardCard';
import { Bar } from 'react-chartjs-2';
import { CardContent } from './styles';
import { NoData } from './NoData';

export const UserEngagement = ({ data }) => {
  const title = 'User Engagement';
  const tooltipText = 'Number of users who utilize Infinit Care services';

  if (data.length == 0) {
    return (
      <NoData title={title} withQuestionMark={true} tooltipText={tooltipText} />
    );
  }

  const options = {
    responsive: true,
    legend: {
      display: false,
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  const values = [];
  const labels = [];

  data.filter((object) => {
    if (object.key == 'Chat' && object.data.used_chat_helpline_count > 0) {
      labels.push(object.key);
      values.push(object.data.used_chat_helpline_count);
    }

    if (object.count > 0) {
      labels.push(object.key);
      values.push(object.count);
    }
  });

  const chartData = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: '#FED54D',
        borderRadius: 50,
        barThickness: 16,
      },
    ],
  };

  return (
    <DashboardCard
      title={title}
      withQuestionMark={true}
      tooltipText={tooltipText}>
      <CardContent>
        <Bar options={options} data={chartData} className="mt-4" />
      </CardContent>
    </DashboardCard>
  );
};
