import React from 'react';
import styled from '@emotion/styled';
import { Modal, Button } from 'react-bootstrap';

const ModalHeader = styled(Modal.Header)`
  border-bottom: none;
`;
const ModalBody = styled(Modal.Body)`
  overflow: hidden;
`;
const StyledContent = styled.div`
  text-align: center;
  padding: 50px;
  padding-top: 30px;
  h4 {
    font-size: 26px;
    padding-bottom: 26px;
  }
  p {
    padding-bottom: 26px;
    font-size: 16px;
  }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    h4 {
      font-size: 24px;
    }
    p {
      font-size: 14px;
    }
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    h4 {
      font-size: 20px;
    }
    p {
      font-size: 13px;
    }
  }
  @media (max-width: 767.98px) {
    h4 {
      font-size: 20px;
    }
    p {
      font-size: 13px;
    }
  }
`;
const ResponseActions = styled.div`
  text-align: center;
  button.cancel {
    font-weight: 400;
    color: #4db5b4;
    background-color: white;
    border: none;
  }
`;

const NotesModal = (props) => {
  return (
    <Modal
      dialogClassName="modal-50w"
      show={props?.show}
      onHide={() => props.setShowModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <ModalHeader closeButton></ModalHeader>
      <ModalBody>
        <StyledContent>
          <h6 className="mb-4">Your Note</h6>
          <h6 className="mb-4">{props?.date}</h6>
          <p style={{whiteSpace: 'pre-line'}}>{props?.notes}</p>
          <ResponseActions>
            <Button
              size="sm"
              variant="primary"
              className="accept btn-green mb-1 py-2 px-4"
              onClick={() => props.setShowModal(false)}>
              Close
            </Button>
          </ResponseActions>
        </StyledContent>
      </ModalBody>
    </Modal>
  );
};

export default NotesModal;
