import React from 'react';
import ContentLoader from 'react-content-loader';

const ArticleLoader = () => {
  return (
    <ContentLoader height="650" width="100%">
      <rect x="0" y="42" rx="5" ry="5" width="100%" height="200" />
      <rect x="0" y="265" rx="5" ry="5" width="100%" height="10" />
      <rect x="0" y="285" rx="5" ry="5" width="100%" height="10" />
      <rect x="0" y="305" rx="5" ry="5" width="100%" height="10" />
      <rect x="0" y="335" rx="5" ry="5" width="100%" height="10" />
      <rect x="0" y="355" rx="5" ry="5" width="100%" height="10" />
      <rect x="0" y="375" rx="5" ry="5" width="100%" height="10" />
      <rect x="0" y="395" rx="5" ry="5" width="100%" height="8" />
      <rect x="0" y="415" rx="5" ry="5" width="100%" height="8" />
      <rect x="0" y="445" rx="5" ry="5" width="100%" height="8" />
      <rect x="0" y="465" rx="5" ry="5" width="100%" height="8" />
      <rect x="0" y="485" rx="5" ry="5" width="100%" height="8" />
      <rect x="0" y="505" rx="5" ry="5" width="100%" height="8" />
      <rect x="0" y="525" rx="5" ry="5" width="100%" height="8" />
    </ContentLoader>
  );
};

export default ArticleLoader;
