import React from 'react';
import styled from '@emotion/styled';

const StyledCard = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #0000001a;
  border-radius: 8px;
  padding: 21px 24px;
`;

const UICard = ({ children, className, onClick }) => {
  return (
    <StyledCard className={className} onClick={onClick}>
      {children}
    </StyledCard>
  );
};

export default UICard;
