import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from '@emotion/styled';
import 'react-day-picker/dist/style.css';
import { StyledHeader } from './styles';
import { UISubmitButton } from '../../shared/ui/Button';
import { UIBackButton } from '../../shared/ui/Back';
import * as moment from 'moment';
import UIProfileImage from '../../shared/ui/ProfileImage';
import { StyledName } from './BookingDone';
import CoachModal from './CoachModal';
import { useBookSessionData } from '../../contexts/BookSessionDataProvider';

const CoachContainer = styled.div`
  background-color: #f3fbfb;
  padding: 38px;
  display: flex;
  max-width: 662px;
  margin: auto;
  justify-content: space-around;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 30px;
  }

  h6 {
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 1.2px;
    color: #333333;
    opacity: 0.7;
    margin-bottom: 16px;
  }
  span {
    font-size: 14px;
  }
  .coach-info {
    display: flex;
    align-items: center;
    .name {
      color: #1db4b3;
      font-weight: 600;
    }
    .title {
      font-size: 12px;
    }
  }
`;

const StyledForm = styled.form`
  max-width: 662px;
  margin: auto;
  margin-top: 32px;
  label[for='contact_number'] {
    margin-top: 20px;
  }
  textarea, input{
    font-size: 14px;
  }
`;
export const StyledUISubmitButton = styled(UISubmitButton)`
  width: fit-content;
  display: block;
  text-align: center;
  margin: auto;
  padding: 12px 51px;
`;

const StyledTelInput = styled.input`
  &:invalid {
    color: box-shadow: 0 0 4px red;
  }

  &::placeholder {
    color: lightgray;
  }
`

const ReviewSession = () => {
  const { 
    setCurrentStep, 
    selectedReasons, 
    selectedSession,
    selectedDateAndSlot: { slot, date}, 
    reviewDetails: userForm, 
    setReviewDetails: setUserForm
  } = useBookSessionData();

  const [phoneError, setPhoneError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [professional, setProfessional] = useState({});
  const slotTime = moment(new Date(slot.time_slot));
  const [showCoachModal, setShowCoachModal] = useState(false);

  const handlePhoneChange = (event) => {
    setUserForm({
      ...userForm,
      ...{ phone: event.target.value },
    });
  }

  const selectedDate = () => {
    const slot_date = slot.time_slot.split("T")[0]
    const date_parts = slot_date.split("-")
    return `${date_parts[2]}/${date_parts[1]}/${date_parts[0]}`
  }

  const postMeeting = async () => {
    const selectedReasonIDs = selectedReasons.map((object) => object.id);
    const payload = {
      professional_id: slot.professional,
      time_slot: slot.time_slot,
      date: selectedDate(),
      additional_notes: userForm.notes,
      phone: `${userForm.phone}`,
      
      coaching_reason_ids: selectedReasonIDs,
      purpose: selectedSession,
      consent_signed: true
    };

    const res = await fetch('/api/v2/meetings', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    let responseBody = await res.json();

    if (res.status == 200) {
      setUserForm({
        ...userForm,
        ...{ booking_details: responseBody, professional: professional },
      });
      setCurrentStep('booking_done');
    } else {

      let phone_error =
        responseBody?.errors[0]?.phone?.join('') ||
        responseBody?.errors[0]?.start_time?.join('') ||
        'Your booking could not be completed at this time. Please check the details you provided. If the issue persists, contact our support team at support@infinitcare.co or reach out to us via Chat.';

      if(!!responseBody?.errors && (responseBody?.errors[0]?.base || []).includes('Invalid schedule')){
        phone_error = 'There was an issue with the time slot that you are trying to book. Please try a different time slot. If the issue persists, contact our support team at support@infinitcare.co or reach out to us via Chat.'
      }
      setPhoneError(phone_error);
      setSubmitted(false)
    }
  };

  const fetchProfessional = async () => {
    const res = await fetch(`/api/v2/professionals/${slot.professional}`);
    if (res.status == 200) {
      setProfessional(await res.json());
    }
  };

  useEffect(() => {
    fetch("/api/v2/track/track_client_page_view", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        page: "Session Details",
        purpose: selectedSession
      }),
    })

   fetchProfessional();
  }, []);

  const submitBooking = (event) => {
    event.preventDefault()
    setSubmitted(true);
    postMeeting();
  };

  return (
    <>
      <CoachModal
        showCoachModal={showCoachModal}
        setShowCoachModal={setShowCoachModal}
        professional={professional}
      />
      <UIBackButton onClick={() => setCurrentStep('select_date_time')} />
      <StyledHeader>
        <p className="book-session">BOOK A SESSION</p>
        <h3>Review session details</h3>
      </StyledHeader>
      <Row className="justify-content-center h-100 m-0 d-block">
        <Col xl={12}>
          <CoachContainer>
            <div>
              <h6>
                YOUR {selectedSession == 'coaching' ? 'COACH' : 'COUNSELOR'}
              </h6>
              <div className="d-flex">
                <UIProfileImage
                  src={professional?.avatar_url || '/coaching/profile.png'}
                  className="mr-2"
                />
                <div className="coach-info">
                  <StyledName
                    className="name d-block"
                    onClick={() => setShowCoachModal(true)}>
                    {professional?.full_name}
                  </StyledName>
                  <span className="title d-block">{professional?.title}</span>
                </div>
              </div>
            </div>
            <div>
              <h6>DATE & TIME</h6>
              <span className="d-block">{moment(date).format('dddd, LL')}</span>
              <span className="d-block">
                {slotTime.format('LT')} to{' '}
                {slotTime.add(1, 'hours').format('LT')}
              </span>
            </div>
          </CoachContainer>
        </Col>
        <Col xl={12}>
          <StyledForm id="confirm-booking-form" onSubmit={submitBooking}>
            <label htmlFor="notes">
              Please share anything that will help your{' '}
              {selectedSession == 'coaching' ? 'coach' : 'counselor'} prepare
              for your session. (Optional)
            </label>
            <textarea
              name="notes"
              className="form-control"
              placeholder="Your message here…"
              rows="4"
              value={userForm?.notes || ''}
              onChange={(event) =>
                setUserForm({ ...userForm, ...{ notes: event.target.value } })
              }
            />
            <div className="text-center">
              <div className="d-flex align-items-center border rounded w-fit-content mx-auto my-2">
                <span className="d-inline font-weight-bold px-2">+63</span>
                <StyledTelInput
                  type="tel"
                  onChange={handlePhoneChange}
                  value={userForm?.phone || ""}
                  pattern="^(\d{10})$"
                  placeholder='9170123456'
                  className="form-control border-0"
                  required
                />
              </div>
              {!!phoneError && (
                <small className="text-danger d-block">{phoneError}</small>
              )}
              <small className="d-block">
                *An SMS reminder will be sent to you an hour before your
                appointment.
              </small>
            </div>
          </StyledForm>
        </Col>
        <Col xl={12}>
          <StyledUISubmitButton
            form="confirm-booking-form"
            className={`mt-4`}
            type="submit"
            text={'Confirm Booking'}
            disabled={submitted}
            >
          </StyledUISubmitButton>
        </Col>
      </Row>
    </>
  );
};
export default ReviewSession;
