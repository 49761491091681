import { useState, useEffect } from "react";
import Api from "../Api";

export default statuses => {
  const [meetingsState, setMeetingsState] = useState({
    loading: true,
    meetings: null
  });

  let urlApi = "/api/v1/meetings";
  let queryString = "";

  if (!(!statuses || 0 === statuses.length)) {
    queryString = statuses.map(status => `status[]=${status}`).join("&");
  }

  if (queryString !== "") {
    urlApi = `${urlApi}?${queryString}`;
  }

  useEffect(() => {
    const fetchData = async () => {
      Api.get(urlApi, {}).then(res => {
        setMeetingsState({
          ...meetingsState,
          loading: false,
          meetings: res.data
        });
      });
    };
    fetchData();
  }, []);

  return [meetingsState];
};
