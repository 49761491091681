import React from 'react';
import styled from '@emotion/styled';
import DashboardCard from './DashboardCard';
import { CardContent } from './styles';

export const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .no-data {
    border: 1px dashed #7e84a3;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    margin-bottom: 20px;
  }
`;
export const NoData = ({
  title,
  withQuestionMark = false,
  tooltipText = '',
}) => {
  return (
    <DashboardCard
      title={title}
      withQuestionMark={withQuestionMark}
      tooltipText={tooltipText}>
      <CardContent className="d-flex justify-content-center align-items-center">
        <NoDataContainer>
          <div className="no-data" />
          <p>No data to display</p>
        </NoDataContainer>
      </CardContent>
    </DashboardCard>
  );
};
