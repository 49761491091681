import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { SWRConfig } from 'swr';
import './shared/ErrorHandler';
import AppDataProvider from './contexts/AppDataProvider';
import ActiveRoutes from './Routes/ActiveRoutes';
import DisabledEmailRoutes from './Routes/DisabledEmailRoutes';

const Main = (appData) => {
  const { sessionsLeft, companyID, emailValidity } = appData;
  return (
    <SWRConfig
      value={{
        revalidateOnFocus: true,
      }}>
      <AppDataProvider {...appData}>
        <Router basename="/">
          {emailValidity == 'update_required' ? (
            <DisabledEmailRoutes />
          ) : (
            <ActiveRoutes sessionsLeft={sessionsLeft} companyID={companyID} />
          )}
        </Router>
      </AppDataProvider>
    </SWRConfig>
  );
};

export default Main;
