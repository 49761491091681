import React from 'react';
import styled from '@emotion/styled';
import Track from '../shared/Track';
import MeetingCancelButton from '../Meetings/Cancel';
import { useAppData } from '../contexts/AppDataProvider';
import BookASessionButton from '../shared/BookASessionButton';

const StyledButtonLink = styled.a`
  background-color: white;
  color: #1db4b3;
  height: 42px;
  line-height: 42px;
  font-size: 14px;
  border: none;
  font-weight: bold;
  border-radius: 5px;
  padding-left: 40px;
  padding-right: 40px;
  display: block;
  cursor: pointer;
  @media (max-width: 767.98px) {
    font-size: 12px;
    width: 100%;
  }
  &:hover,
  &:active,
  &:focus {
    color: #1db4b3 !important;
    background-color: white !important;
    border: white !important;
    box-shadow: none !important;
    text-decoration: none !important;
  }
`;

const StyledContainer = styled.div`
  position: relative;
  background-color: #eefcce;
  padding: 1.5rem 1.875rem;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  h6 {
    font-size: 1rem;
    font-weight: 500;
  }
  p {
    font-size: 0.875rem;
    margin-bottom: 0px;
    -webkit-font-smoothing: antialiased;
  }
  @media (max-width: 767.98px) {
    display: block;
    text-align: center;
    p {
      margin-bottom: 10px;
    }
  }

  button {
    width: fit-content;
    padding: 12px 40px;
    color: #1db4b3;
    font-size: 14px;
    background-color: white;
    border: white;
    font-weight: bold;
    margin: 0px;
    &:hover,
    &:active,
    &:focus {
      color: #1db4b3 !important;
      background-color: white !important;
      border: white !important;
      box-shadow: none !important;
    }
    @media (max-width: 767.98px) {
      width: 100%;
    }
    @media (max-width: 575.98px) {
      button {
        margin-top: 10px;
      }
    }
  }

  button.disabled {
    background: #ffffff !important;
    font-weight: 600;
    font-size: 0.8rem;
    width: 100%;
    padding: 10px;
    padding-left: 10px;
    padding-right: 10px;
    color: #c1c1c1 !important;
  }
`;

const MeetingDate = styled.span`
  font-weight: bold;
  display: block;
  @media (max-width: 991.98px) {
    display: inline;
  }
  @media (max-width: 767.98px) {
    display: block;
  }
`;

const SessionDashboard = ({
  session: {
    sessions_left,
    sessions_count_visible,
    meeting_today,
    nearest_meeting,
    days_until_next_session,
    meeting_url,
  },
}) => {
  const DATE_OPTIONS = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  const { companyID, bookASessionLandingPage } = useAppData();

  return (
    <div>
      {!nearest_meeting?.id && (
        <StyledContainer>
          <div>
            {sessions_count_visible === true && (
              <h6>
                You have{' '}
                <strong>
                  {sessions_left === 1
                    ? `${sessions_left || 0} free session`
                    : `${sessions_left || 0} free sessions`}
                </strong>{' '}
                left.
              </h6>
            )}
            <p>Reach out to our counselors or coaches.</p>
          </div>
          {!companyID && sessions_left <= 0 && (
            <StyledButtonLink
              href="/individual/dashboard/packages"
              onClick={() => Track.bookASession(bookASessionLandingPage())}>
              Book a session
            </StyledButtonLink>
          )}
          {((companyID && sessions_left <= 0) || sessions_left > 0) && (
            <BookASessionButton page={bookASessionLandingPage()}/>
          )}
        </StyledContainer>
      )}
      {nearest_meeting?.id && (
        <StyledContainer>
          {meeting_today == true && nearest_meeting?.date && (
            <>
              <div className="pr-4">
                <h6>Upcoming sessions</h6>
                <p>
                  You have a {nearest_meeting?.purpose} session today at{' '}
                  <strong>{nearest_meeting?.start_time}</strong>.
                </p>
              </div>
              <div>
                {meeting_url && meeting_url != '' && (
                  <StyledButtonLink
                    className="mb-2"
                    href={meeting_url}
                    target="_blank">
                    Join session
                  </StyledButtonLink>
                )}
                <MeetingCancelButton meeting={nearest_meeting} />
              </div>
            </>
          )}
          {nearest_meeting?.date && meeting_today == false && (
            <>
              <div className="pr-4">
                <p>
                  You have an upcoming {nearest_meeting?.purpose} session this{' '}
                  <MeetingDate>
                    {new Date(nearest_meeting?.date).toLocaleDateString(
                      'en-US',
                      DATE_OPTIONS,
                    )}
                  </MeetingDate>{' '}
                  at <strong>{nearest_meeting?.start_time}</strong>.
                </p>
              </div>
              <div>
                <button className="disabled mb-2">
                  {days_until_next_session}&nbsp;
                  {days_until_next_session === 1 ? 'day' : 'days'}
                  &nbsp;remaining
                </button>
                <MeetingCancelButton meeting={nearest_meeting} />
              </div>
            </>
          )}
        </StyledContainer>
      )}
    </div>
  );
};

export default SessionDashboard;
