import React from 'react';
import styled from '@emotion/styled';
import { Button } from 'react-bootstrap';

const StyledButton = styled(Button)`
  background-color: white;
  border-color: white;
  &:hover,
  &:focus,
  &:active {
    background-color: white !important;
    border-color: white !important;
    box-shadow: none !important;
  }
  &.green svg {
    color: #1db4b3;

    &:hover {
      color: #1db4b3 !important;
    }
  }
`;

const EditButton = ({action, color}) => {
  return (
    <StyledButton onClick={action} className={`btn btn-sm pt-0 ${color}`}>
      <i className="far fa-edit"></i>
    </StyledButton>
  );
};

export default EditButton;
