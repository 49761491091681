import React from 'react';
import styled from '@emotion/styled';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const StyledPhoneInput = styled(PhoneInput)`
  margin-bottom: 30px;
  width: fit-content;
  margin: auto;
  .invalid-number-message {
    top: 35px;
    background: transparent;
  }

  &:has(> .invalid-number) {
    margin-bottom: 20px !important;
  }
`;

const UIPhoneNumber = ({ onChange, setPhoneError, value }) => {
  const testRegex = (regex, value, country) => {
    let valid_number = regex.test(value);
    if (!valid_number) {
      setPhoneError('Invalid number: ' + value + ', ' + country);
    } else {
      setPhoneError(null);
    }
    return true;
  };

  const phoneValidation = (value, country) => {
    let validation = onlyCountries.filter((val) => val.country == country.name);

    if (validation.length > 0) {
      return testRegex(validation[0].regex, `+${value}`, country.name);
    } else {
      setPhoneError('Invalid country validation');
      return false;
    }
  };

  const onlyCountries = [
    { code: 'ph', country: 'Philippines', regex: /^(\+639)\d{9}$/ }
  ];

  return (
    <>
      <label htmlFor="contact_number">Contact Number</label>
      <StyledPhoneInput
        country={'ph'}
        preferredCountries={['ph']}
        isValid={(value, country) => phoneValidation(value, country)}
        onlyCountries={onlyCountries.map((country) => country.code)}
        value={value}
        inputProps={{
          name: 'contact_number',
        }}
        onChange={onChange}
      />
    </>
  );
};

export default UIPhoneNumber;
