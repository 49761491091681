import React, { useState, useEffect } from "react";
import axios from "axios";
import { css, cx } from "emotion";

import Testimonial from "./Testimonial";
import Title from "../Title";
import Spinner from "../Spinner";

const useTestimonials = () => {
  const [state, setState] = useState({ testimonials: null, loading: true });

  useEffect(() => {
    const fetchData = async () => {
      await axios.get("/api/v1/testimonials").then(res => {
        setState({
          ...state,
          loading: false,
          testimonials: res.data
        });
      });
    };
    fetchData();
  });
  return [state];
};

const Testimonials = () => {
  const [state] = useTestimonials();

  return (
    <>
      <div className="bg-light">
        <Title
          title="Feedback to our Infinit Care counselors"
          description="Here's what others are saying!"
        />
      </div>
      <div className="bg-white">
        <div
          className={cx(
            "container",
            css`
              padding: 2rem 0;
            `
          )}
        >
          {state.testimonials ? (
            <div className="row no-gutters row-cols-1 row-cols-sm-3">
              {state.testimonials.map(testimonial => (
                <Testimonial key={testimonial.id} testimonial={testimonial} />
              ))}
            </div>
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </>
  );
};

export default Testimonials;
