import React, { useState, useEffect } from 'react';
import DashboardCard from './DashboardCard';
import { NoData } from './NoData';
import { HorizontalChartContainer } from './styles';
import { Bar } from 'react-chartjs-2';

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};
export const horizontalBarOptions = {
  responsive: true,
  indexAxis: 'y',
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      ticks: {
        stepSize: 1,
      },
    },
  },
};

export const Recommendations = ({ recommendations = [], title }) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  const labels = recommendations?.map((value) => value.title);
  const [data, setData] = useState(null);

  useEffect(() => {
    let barThickness = windowDimensions.width * 0.015;
    barThickness = barThickness > 16 ? 16 : barThickness;
    setData({
      labels,
      datasets: [
        {
          data: recommendations?.map((value) => value.count),
          backgroundColor: '#FED54D',
          borderRadius: 50,
          barThickness: barThickness,
        },
      ],
    });
  }, [windowDimensions]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (recommendations.length == 0) {
    return <NoData title={title} />;
  }

  return (
    <DashboardCard title={title}>
      {data && (
        <HorizontalChartContainer>
          <Bar
            options={horizontalBarOptions}
            data={data}
            className="mt-4"
            height={100}
          />
        </HorizontalChartContainer>
      )}
    </DashboardCard>
  );
};
