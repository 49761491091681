import React from 'react';
import styled from '@emotion/styled';

const StyledBanner = styled.div`
  font-size: 12px;
  color: #1db4b3;
  background-color: #f3fbfb;
  text-align: center;
`;

const Banner = () => {
  return (
    <StyledBanner>
      The Infinit Care service is only available in the Philippines.
    </StyledBanner>
  );
};

export default Banner;