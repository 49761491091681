import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { DayPicker } from 'react-day-picker';
import * as moment from 'moment';
import 'react-day-picker/dist/style.css';
import { UIButton } from '../../shared/ui';
import { UIBackButton } from '../../shared/ui/Back';
import { useEffect } from 'react';
import { StyledHeader } from './styles';
import { useBookSessionData } from '../../contexts/BookSessionDataProvider';
import Track from '../../shared/Track';
import Spinner from '../../../Spinner';

const TimeSlotButton = styled(UIButton)`
  padding: 11px 56px;
  max-width: 174px;
  @media (max-width: 767px) {
    margin:auto;
    display: block;
  }
  &.outlined {
    background-color: white;
    background-color: white !important;
    color: #1db4b3;
    &:hover {
      background-color: #1db4b3 !important;
    }
  }
`;

const CalendarContainer = styled.div`
  background-color: #fcfcfc;
  display: flex;
  max-width: 915px;
  margin: auto;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  h6 {
    font-size: 16px;
    font-weight: 600;
    color: black;
  }
`;
const CoachingInfo = styled.div`
  width: 25%;
  color: grey;
  font-weight: 600;
  font-size: 14px;
  svg {
    font-size: 15px;
    margin-right: 8px;
  }
  border-right: 5px solid #f7f7f7;
  @media (max-width: 991px) {
    width: 100%;
    border: none;
    background-color: #fcfcfc;
    h6 {
      color: black;
    }
  }

  h6 {
    padding: 30px;
  }

  .counselor-name {
    padding: 15px 0px 15px 15px;
    width: 100%;
    cursor: pointer;
  }

  .selected-counselor {
    background: #1DB4B3;
    color: #FFFFFF;
  }
`;
const DateAndTime = styled.div`
  width: 45%;
  @media (max-width: 991px) {
    width: 60%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
  background-color: #fcfcfc;
  padding: 30px 30px 0px 30px;
  button[name='day'] {
    background-color: #1db4b30d;
    color: #1db4b3;
    font-weight: 600;
  }
  button[name='day'].rdp-day_outside {
    background-color: #fcfcfc;
    color: #c1c1c1 !important;
    font-weight: normal;
  }
  button.rdp-day_selected {
    background-color: #1db4b3 !important;
    color: white !important;
  }
  @media (max-width: 767px) {
    .rdp {
      width: 80%;
      margin: auto;
    }
  }
  @media (max-width: 500px) {
    .rdp {
      width: 100%;
    }
  }
  .rdp-table {
    th,
    td {
      border: 8px solid transparent;
    }
  }
  .rdp-nav_button {
    color: #1db4b3;
  }
  .rdp-caption_label {
    font-weight: normal;
    font-size: 14px;
  }
`;
const Schedules = styled.div`
  padding: 30px 30px;
  width: 30%;
  @media (max-width: 991px) {
    width: 40%;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 0px;
    margin-top: 40px;
  }
  background-color: #fcfcfc;
  text-align: center;
`;
const TimeSlotContainer = styled.div`
  max-height: 340px;
  overflow: auto;
`;
const StyledUIButton = styled(UIButton)`
  width: fit-content;
  display: block;
  text-align: center;
  margin: auto;
  padding: 12px 51px;
`;
const CalendarInfo = styled.p`
  font-size: 12px;
`;

const CalendarSessions = () => {
  const {
    selectedSession,
    setCurrentStep,
    selectedDateAndSlot,
    setSelectedDateAndSlot,
  } = useBookSessionData();

  const [selectedCounselor, setSelectedCounselor] = useState(null);
  const [timeSlots, setTimeSlots] = useState({});
  const [loadingTimeSlots, setLoadingTimeSlots] = useState(true);
  const prevStep =
    (selectedSession == 'coaching' ? 'select_reasons' : 'select_session');
  const professional =
     selectedSession == 'coaching' ? 'coach' : 'counselor';
  const setDateAndRemoveSlot = (date) => {
    if(!date){
      return
    }
    setSelectedDateAndSlot({ date: date, slot: null });
  };

  const toggleTimeSlot = (timeSlot) => {
    timeSlot['professional'] = timeSlot['professional'] || timeSlot['professional_id'];
    setSelectedDateAndSlot({ ...selectedDateAndSlot, slot: timeSlot });
  };

  const toggleTimeSlotV2 = (timeSlot) => {
    setSelectedDateAndSlot({ ...selectedDateAndSlot, slot: {
      professional: selectedCounselor.professional_id,
      professional_id: selectedCounselor.professional_id,
      professional_name: selectedCounselor.professional_name,
      time_slot: timeSlot
    }});
  }

  const fetchTimeSlotsOnSelectedDate = async () => {
    setLoadingTimeSlots(true);
    const dateString = moment(selectedDateAndSlot.date).format('DD-MM-YYYY');
    let url = `/api/v2/availabilities/slots/?date=${dateString}&purpose=`;
    const res = await fetch(url + selectedSession);
    if (res.status == 200) {
      const body = await res.json();
      let previous_slots = []
      let other_slots = []
      let timeSlotValue = {}

      timeSlotValue = {
        prev_professional_available_this_month: body.prev_professional_available_this_month,
        prev_professional_earliest_available_slot: body.prev_professional_earliest_available_slot,
        prev_professional_name: body.prev_professional_name,

        new_other_slots: body.new_others,
        new_previous_pro_slots: body.new_prev_professionals
      };
      
      if (body.new_prev_professionals) {
        setSelectedCounselor(body.new_prev_professionals[0])
      }
      setTimeSlots(timeSlotValue);
    }
    setLoadingTimeSlots(false);
  };

  useEffect(() => {
    Track.pageView({
      page: 'Calendar',
      purpose: selectedSession,
    });
  }, [])

  useEffect(() => {
    if (selectedDateAndSlot.date) {
      fetchTimeSlotsOnSelectedDate();
    }
  }, [selectedDateAndSlot.date]);

  /**
   * 
   * timeSlots = body.new_previous_pro_slots
   */
  const SelectedCounselorSlots = ({ timeSlots }) => 
    timeSlots.map((object, index) => (
      <TimeSlotButton 
        key={index}
        text={`${moment(object.start_time).format('h:mma')}`}
        className={`mb-2 ${
          selectedDateAndSlot?.slot?.time_slot == object.start_time
          ? ''
          : 'outlined'
        }`}
        action={() => {
          toggleTimeSlotV2(object.start_time);
        }}
      />
    ))

  const TimeSlots = ({ timeSlots }) =>
    timeSlots.map((object, index) => (
      <TimeSlotButton
        key={index}
        text={moment(object.time_slot).format('h:mma')}
        className={`mb-2 ${
          selectedDateAndSlot?.slot?.time_slot == object.time_slot &&
          selectedDateAndSlot?.slot?.professional == object.professional
            ? ''
            : 'outlined'
        }`}
        action={() => {
          toggleTimeSlot(object);
        }}
      />
    ));

  /**
   * 
   * timeSlots = body.new_others
   */
  const OtherTimeSlotsV2 = ({timeSlots}) => {
    if (timeSlots.length == 0) {
      return <></>;
    } else {
      return (
        <>
          <CalendarInfo className="mt-2">
            <strong>
              Other{' '}
              {selectedSession == 'counseling' ? "Counselor's" : 'Coaches’'}{' '}
              Schedules
            </strong>
          </CalendarInfo>
          <TimeSlots timeSlots={timeSlots} />
        </>
      )
    }
  }

  const CounselorSidebar = () => {
    if (loadingTimeSlots) {
      return (
        <div className="d-flex justify-content-center h-100">
          <Spinner />
        </div>
      );
    }
    if  (!loadingTimeSlots) {
      return <>
        {
          timeSlots.new_previous_pro_slots.length > 0 &&
          <h6>
            {selectedSession == 'coaching' ? 'Coaches' : 'Counselors'}
          </h6>
        }
        
        {
          timeSlots.new_previous_pro_slots && timeSlots.new_previous_pro_slots.map((professional) => {
            return <div 
              key={professional.professional_id}
              onClick={() => {
                setSelectedCounselor(professional);
                setDateAndRemoveSlot(selectedDateAndSlot.date);
              }} 
              className={selectedCounselor.professional_id == professional.professional_id ? "counselor-name selected-counselor" : "counselor-name"}>
                {professional.professional_name}
              </div>
          })
        }
      </>
      
    }
    
  }

  const TimeSlotViewV2 = () => {
    if (loadingTimeSlots) {
      return (
        <div className="d-flex justify-content-center h-100">
          <Spinner />
        </div>
      )
    }
    if (selectedCounselor) {
      return (
        <>
          <CalendarInfo>
            <strong>Your {selectedSession == 'counseling' ? "Counselor's" : 'Coaches’'}{' '}Schedules</strong>
          </CalendarInfo>
          <SelectedCounselorSlots timeSlots={selectedCounselor.time_slots}/>
          <OtherTimeSlotsV2 timeSlots={timeSlots?.new_other_slots} />
        </>
      )
    } else if (timeSlots?.new_other_slots?.length > 0) {
      return <OtherTimeSlotsV2 timeSlots={timeSlots?.new_other_slots} />
    } else {
      return (
        <>
          <CalendarInfo>No Time Slots available</CalendarInfo>
        </>
      );
    }

    
  }

  return (
    <>
      <UIBackButton onClick={() => setCurrentStep(prevStep)} />
      <StyledHeader>
        <p className="book-session">BOOK A SESSION</p>
        <h3>Choose a date and time</h3>
        <p>
          Choose your preferred date and time and we’ll match you with the right{' '}
          {professional}.
        </p>
      </StyledHeader>
      <Row>
        <Col>
          <CoachingInfo className="d-lg-none">
            <h6>
              Online {selectedSession == 'coaching' ? 'Coaching' : 'Counseling'}
            </h6>
            <span>
              <FontAwesomeIcon icon={faClock} />
              60 min
            </span>
          </CoachingInfo>

        </Col>
      </Row>
      <Row>
        <Col>
          <CalendarContainer>
            <CoachingInfo className="d-none d-lg-block">
              <CounselorSidebar />
              
            </CoachingInfo>
            <DateAndTime>
              <h6>Select a Date & Time</h6>
              <DayPicker
                mode="single"
                selected={selectedDateAndSlot.date}
                onSelect={setDateAndRemoveSlot}
                fromDate={moment().toDate()}
                showOutsideDays={true}
                className={'mt-3 calendar'}
                defaultMonth={selectedDateAndSlot.date}
                disabled={loadingTimeSlots}
              />
            </DateAndTime>
            <Schedules>
              <h6>{moment(selectedDateAndSlot.date).format('dddd, MMM DD')}</h6>
              <TimeSlotContainer className="mb-3 h-100">
                <TimeSlotViewV2 />
              </TimeSlotContainer>
            </Schedules>
          </CalendarContainer>
          <StyledUIButton
            className={`mt-3 ${!selectedDateAndSlot.slot ? 'disabled' : ''}`}
            text={'Next: Review'}
            disabled={!selectedDateAndSlot.slot}
            action={() => setCurrentStep('review_session')}></StyledUIButton>
        </Col>
      </Row>
    </>
  );
};
export default CalendarSessions;
