import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { SWRConfig } from 'swr';
import Pag404 from './Pag404';
import Counselors from './Public/Counselors';
import Registration from './Public/Registration';
import Otp from './Public/Otp';
import SetupAccount from './Public/SetupAccount';
import MobileRegistration from './Public/MobileRegistration';

const PublicMain = () => {
  return (
    <SWRConfig
      value={{
        revalidateOnFocus: true,
      }}>
      <Router basename="/">
        <Switch>
          <Route path="/counselors" component={Counselors} />
          <Route path="/otp" component={Otp} />
          <Route path="/registration" component={Registration} />
          <Route path="/setup_account" component={SetupAccount} />
          <Route path="/mobile_registration" component={MobileRegistration} />
          <Route component={Pag404} />
        </Switch>
      </Router>
    </SWRConfig>
  );
};
export default PublicMain;
