import React, { useEffect, useState, useRef } from 'react';
import YouTube from 'react-youtube';
import axios from 'axios';

const VideoPlayer = ({ podcast }) => {
  const [shouldPlay, setShouldPlay] = useState(false);
  const [isScrolledDown, setIsScrolledDown] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const playerRef = useRef(null);

  const onPlayerStateChange = (event) => {
    const player = event.target;
    if (event.data === YouTube.PlayerState.PLAYING) {
      setInterval(() => {
        setCurrentTime(player.getCurrentTime());
      }, 1000); // Update current time every second (1000 milliseconds)
    } else {
      clearInterval(); // Stop updating current time when the video is paused or ended
    }
  };

  useEffect(() => {
    hitUserPodcast({ played_duration: currentTime });
  }, [currentTime]);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsScrolledDown(scrollTop > 600);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const opts = {
    width: '100%',
    height: '400',
    playerVars: {
      autoplay: shouldPlay ? 1 : 0,
    },
  };

  const onReady = (event) => {
    // event.target.playVideo();
    const player = event.target;
    player.seekTo(podcast?.played_duration);
    player.playVideo();
  };

  const hitUserPodcast = async (payload) => {
    await axios.patch(`/api/v2/podcasts/${podcast.id}/user_podcast`, {
      podcast: payload,
    });
  };

  const handleVideoStatus = (event) => {
    switch (event?.data) {
      case 0:
        hitUserPodcast({ play_status: 'not_started' });
      case 1:
        hitUserPodcast({ play_status: 'playing' });
      case 2:
        hitUserPodcast({ play_status: 'played' });
    }
  };

  const playerContainerStyle = {
    position: isScrolledDown ? 'fixed' : 'relative',
    bottom: isScrolledDown ? '0' : 'auto',
    right: isScrolledDown ? '0' : 'auto',
    height: '400',
    zIndex: '99',
  };

  return (
    <div>
      {podcast && podcast.podcast_url && (
        <div style={playerContainerStyle}>
          <YouTube
            videoId={`${podcast.podcast_url.substring(
              podcast.podcast_url.indexOf('v=') + 2,
              podcast.podcast_url.length,
            )}`}
            opts={opts}
            onReady={onReady}
            onPlay={handleVideoStatus}
            onPause={handleVideoStatus}
            onEnd={handleVideoStatus}
            onStateChange={onPlayerStateChange}
            ref={playerRef}
          />
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
