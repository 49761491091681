import React from 'react';
import styled from '@emotion/styled';
import { Modal, Button } from 'react-bootstrap';
import UIProfileImage from '../../shared/ui/ProfileImage';
import { useBookSessionData } from '../../contexts/BookSessionDataProvider';

const ModalHeader = styled(Modal.Header)`
  border-bottom: none;
  p {
    text-align: center;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    padding-top: 10px;
  }
`;
const ModalBody = styled(Modal.Body)`
  overflow: hidden;
`;
const StyledContent = styled.div`
  padding: 0px 34px;
  text-align: center;
  .coach-info {
    .name {
      font-size: 16px;
      font-weight: 500;
    }
    .title {
      font-size: 14px;
    }
  }
  .expertise{
    margin-top: 40px;
    p {
      font-weight: 500;
      margin-bottom: 15px;
      font-size: 14px;
      padding: 0px;
    }
    .name {
      background: #eaf3fa;
      padding: 10px 12px 7px 12px;
      font-size: 14px;
      font-weight: 300;
      border-radius: 20px;
      margin: 10px;
      margin-left: 0px;
      display: inline-block;
    }
  }
`;
const ResponseActions = styled.div`
  text-align: center;
  button.cancel {
    font-weight: 400;
    color: #4db5b4;
    background-color: white;
    border: none;
    margin-top: 50px;
  }
`;

const CoachModal = ({
  showCoachModal,
  setShowCoachModal,
  professional,
}) => {
  const { selectedSession } = useBookSessionData();
  const expertises =
     selectedSession == 'coaching'
       ? professional?.coach_expertise
       : professional?.counselor_expertise;
  return (
    <Modal
      dialogClassName="modal-40w"
      show={showCoachModal}
      onHide={() => setShowCoachModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <ModalHeader closeButton>
        <p>Your {selectedSession == 'coaching' ? 'Coach' : 'Counselor'}</p>
      </ModalHeader>
      <ModalBody>
        <StyledContent>
          <div className="d-flex align-items-center justify-content-center">
            <UIProfileImage
              src={professional?.avatar_url || '/coaching/profile.png'}
              className="mr-3"
            />
            <div className="coach-info">
              <span className="name d-block">{professional?.full_name}</span>
              <span className="title d-block">{professional?.title}</span>
            </div>
          </div>
          {expertises?.length > 0 && (
            <div className="expertise">
              <p>EXPERTISE</p>
              {expertises.map((expertise, index) => {
                return (
                  <span key={index} className="name">
                    {expertise}
                  </span>
                );
              })}
            </div>
          )}
          <ResponseActions>
            <Button
              size="sm"
              variant="primary"
              className="cancel btn-secondary mb-1 py-2 px-4"
              onClick={() => setShowCoachModal(false)}>
              <strong>Close</strong>
            </Button>
          </ResponseActions>
        </StyledContent>
      </ModalBody>
    </Modal>
  );
};

export default CoachModal;
