import React, { useState, useEffect } from "react";
import axios from "axios";
import { css, cx } from "emotion";

import Api from "../Api";
import Spinner from "../Spinner";
import Title from "../Title";
import Question from "../FaqPage/Question";

export default ({ reverse }) => {
  const [state, setState] = useState({ faqs: null, loading: true });

  useEffect(() => {
    const fetchData = async () => {
      await Api.get("/api/v1/faqs?featured=true").then(res => {
        setState({
          ...state,
          loading: false,
          faqs: res.data
        });
      });
    };
    fetchData();
  }, []);

  return (
    <>
      <div className={cx(reverse ? "bg-white" : "bg-light")}>
        <Title
          title="FAQ"
          description="Infinit Care frequently asked questions"
        />
      </div>
      <div className={cx(reverse ? "bg-light" : "bg-white")}>
        <div
          className={cx(
            "container",
            css`
              padding: 2rem 0;
            `
          )}
        >
          {state.faqs ? (
            <div className="d-flex flex-column align-items-center">
              {state.faqs.map((question, index) => (
                <Question
                  key={index}
                  question={question.question}
                  answer={question.answer}
                />
              ))}
              <div className="my-5 text-center">
                <span className="h5">Ready to get started?</span>
                <a
                  href="/individual/dashboard/packages#pricing-packages"
                  className={cx(
                    "btn btn-lg ml-2",
                    css`
                      background-color: #4db5b4;
                      color: #fff;
                      border: 0;
                      &:hover,
                      &:active,
                      &:focus {
                        background: #4db5b4;
                        color: #fff;
                      }
                    `
                  )}
                >
                  Sign Up Now
                </a>
              </div>
            </div>
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </>
  );
};
