import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPauseCircle, faPlayCircle } from '@fortawesome/free-solid-svg-icons';

const AudioPlayer = ({ currentAudio, isPlaying, setIsPlaying }) => {
  const audioRef = useRef(null);

  const playAudioHandler = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying((prevState) => !prevState);
  };

  const [audioInfo, setAudioInfo] = useState({
    currentTime: 0,
    duration: 0,
  });

  const getTime = (time) => {
    return (
      Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2)
    );
  };

  const timeUpdateHandler = (event) => {
    const current = event.target.currentTime;
    const duration = event.target.duration;
    setAudioInfo({ ...audioInfo, currentTime: current, duration });
    if (current == duration) {
      setIsPlaying(!isPlaying);
    }
  };

  const dragHandler = (event) => {
    audioRef.current.currentTime = event.target.value;
    setAudioInfo({ ...audioInfo, currentTime: event.target.value });
  };

  return (
    <div className="player">
      <div className="time-control">
        <p>{getTime(audioInfo.currentTime)}</p>
        <input
          min={0}
          max={audioInfo.duration || 0}
          value={audioInfo.currentTime}
          type="range"
          onChange={dragHandler}
        />
        <p>{audioInfo.duration ? getTime(audioInfo.duration) : '0:00'}</p>
      </div>
      <div className="play-control">
        <FontAwesomeIcon
          onClick={playAudioHandler}
          className="play mr-3"
          size="3x"
          icon={isPlaying ? faPauseCircle : faPlayCircle}
        />
        <marquee className="font-italic">{currentAudio.title}</marquee>
      </div>
      <audio
        onTimeUpdate={timeUpdateHandler}
        ref={audioRef}
        onLoadedMetadata={timeUpdateHandler}
        src={currentAudio.audio_url}
      />
    </div>
  );
};

export default AudioPlayer;
