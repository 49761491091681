import React from 'react';
import styled from '@emotion/styled';
import Doughnut from '../Doughnut';
import { NoDataContainer } from '../NoData';
import { CardContent, DoughnutContainer } from '../styles';

const StyledDoughnutContainer = styled(DoughnutContainer)`
  @media (max-width: 1199.98px) {
    flex-direction: column;
  }
`;

export const Utilization = ({ data }) => {
  if (data.length == 0) {
    return (
      <CardContent className="d-flex justify-content-center align-items-center">
        <NoDataContainer>
          <div className="no-data" />
          <p>No data to display</p>
        </NoDataContainer>
      </CardContent>
    );
  }
  const status = {
    done: { color: '#97D7D2', text: 'Done' },
    canceled_paid: { color: '#1DB4A8', text: 'Cancelled Paid' },
    no_show: { color: '#FED54D', text: 'No Show' },
    canceled: { color: '#FEAF4D', text: 'Canceled' },
    pending: { color: '#F06680', text: 'Pending' },
  };

  const doughnutConstants = {
    colors: data.map((value) => status[value.status]['color']),
    percents: data.map((value) => value.percent),
  };
  return (
    <StyledDoughnutContainer>
      <div className="mood-chart">
        <Doughnut params={doughnutConstants} />
      </div>
      <div className="mood-results">
        {data.map((value, index) => {
          return (
            <div className="mood-item" key={index}>
              <span
                className="dot"
                style={{
                  backgroundColor: status[value.status]['color'],
                }}></span>
              <span className="percent" style={{ textTransform: 'capitalize' }}>
                {status[value.status]['text']} ({value.value})
              </span>
            </div>
          );
        })}
      </div>
    </StyledDoughnutContainer>
  );
};
