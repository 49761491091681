import React, { useState, useContext } from 'react';
import { mutate } from 'swr';
import { Button } from 'react-bootstrap';
import styled from '@emotion/styled';
import axios from 'axios';
import { useAppData } from '../contexts/AppDataProvider';

const DismissButton = styled(Button)`
  font-size: 14px;
  font-weight: bold;
  color: whitesmoke;
  background: #404345;
`;

const CancelButton = styled(Button)`
  background: #1db4b3;
  border: 1px #c1c1c1 solid !important;
  font-size: 14px;
  font-weight: bold;
  &:hover,
  &:focus {
    border: 1px #1db4b3 solid !important;
    background: #1db4b3 !important;
    color: white !important;
  }
`;

const MeetingCancel = ({ meeting }) => {
  return (
    <>
      <button
        className="btn btn-sm btn-white border-0 text-muted w-100"
        data-toggle="modal"
        data-target="#cancel-session-modal"
        style={{ fontSize: '12px' }}>
        Cancel session
      </button>
      <div
        className="modal fade"
        id="cancel-session-modal"
        tabIndex={-1}
        aria-labelledby="cancel-session-modal"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-4">
            <CancelForm meeting={meeting} />
          </div>
        </div>
      </div>
    </>
  );
};

const CancelForm = ({ meeting }) => {
  const [reasonForCancelling, setReasonForCancelling] = useState('');
  const { cancellation_period_time_in_words } = useAppData()

  const submitCancel = async (event) => {
    event.preventDefault();
    const url = `/api/v2/meetings/${meeting.id}/cancel`;
    const res = await axios.patch(url, {
      reason_for_cancelling: reasonForCancelling,
    });

    if (res.status === 200) {
      mutate('/api/v2/meetings');
      mutate('/api/v2/dashboard');
      document.getElementById('dismiss-modal').click();
    } else {
      alert('Failed to cancel the meeting... Please refresh your browser.');
    }
  };

  return (
    <section>
      <h4 className="mb-4">Cancel Appointment</h4>
      <p className="p-4 border border-dark">
        <span className="font-weight-bolder">Cancellation Policy:&nbsp;</span>
        Cancelling in less than { cancellation_period_time_in_words } before your scheduled appointment will
        be considered as "completed"
      </p>

      <form onSubmit={submitCancel}>
        <div className="form-group">
          <label className="form-label font-weight-bolder">
            Reason for cancelling:
          </label>
          <textarea
            value={reasonForCancelling}
            onChange={(event) => setReasonForCancelling(event.target.value)}
            id="reason_for_cancelling"
            className="form-control"
            required></textarea>
        </div>
        <p className="font-weight-bolder text-center pb-0 mb-0">
          Are you sure you want to cancel your appointment on <br />
          {meeting.date} at {meeting.start_time}?
        </p>
        <div className="row justify-content-center">
          <CancelButton type="submit" className="mt-1">
            Yes, cancel this appointment
          </CancelButton>
          <DismissButton
            className="btn btn-dark mt-1 ml-1"
            data-dismiss="modal"
            id="dismiss-modal">
            No, go back
          </DismissButton>
        </div>
      </form>
    </section>
  );
};

export default MeetingCancel;
