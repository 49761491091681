import React from 'react';
import { Link } from 'react-router-dom';
import { css, cx } from 'emotion';
import * as moment from 'moment';

import Title from './Title';
import Spinner from './Spinner';

export default () => {
  return (
    <>
      <div className="bg-light">
        <Title
          title="Move along..."
          description="There is nothing to see here..."
        />
      </div>
      <div className="bg-white">
        <div
          className={cx(
            'container',
            css`
              padding-top: 2rem;
              padding-bottom: 2rem;
            `,
          )}>
          <div
            className={cx(
              'row',
              css`
                min-height: 50vh;
              `,
            )}>
            <div className="col">
              <p>We can't seem to find the page you're looking for.</p>
              <p>Here are some helpful links instead.</p>
              <ul>
                <li>
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li>
                  <Link to="/dashboard/profile">Profile page</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
