import React from 'react';
import styled from '@emotion/styled';
import { Button } from 'react-bootstrap';

export const StyledButton = styled(Button)`
  width: 100%;
  background: #1db4b3 !important;
  border: 1px #1db4b3 solid !important;
  font-size: 14px;
  font-weight: bold;
  &:hover,
  &:focus,
  &:active,
  &:focus-visible {
    background-color: #1db4b3 !important;
    border-color: #1db4b3 !important;
    color: white !important;
    box-shadow: none;
  }
  &.disabled {
    color: white;
    background-color: #c1c1c1;
    border-color: #c1c1c1 !important;
  }
  &.white,
  &.white:hover,
  &.white:focus,
  &.white:active,
  &.white:focus-visible {
    color: #1db4b3 !important;
    background-color: white !important;
    border-color: white !important;
    border: none !important;
    box-shadow: none;
  }

  &.white.bordered {
    border: 1px solid #1db4b3 !important;
  }

  &.orange,
  &.orange:hover,
  &.orange:focus,
  &.orange:active,
  &.orange:focus-visible {
    background-color: #FEAF4D !important;
    border-color: #FEAF4D !important;
  }
`;

const UIButton = (props) => {
  return (
    <StyledButton
      className={props.className}
      onClick={() => props.action()}
      disabled={props.disabled || false}
      {...props}>
      {props.text}
    </StyledButton>
  );
};

export const UISubmitButton = (props) => {
  return (
    <StyledButton type={'submit'} {...props}>
      {props.text}
    </StyledButton>
  );

}

export default UIButton;
