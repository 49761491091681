import React from 'react';
import styled from '@emotion/styled';
import { Modal, Button, Image } from 'react-bootstrap';

const ModalHeader = styled(Modal.Header)`
  border-bottom: none;
`;
const ModalBody = styled(Modal.Body)`
  overflow: hidden;
`;
const StyledContent = styled.div`
  padding-left: 50px;
  padding-top: 50px;
  padding-bottom: 120px;
  h4 {
    font-size: 26px;
    padding-bottom: 26px;
    max-width: 450px;
  }
  p {
    padding-bottom: 26px;
    width: 65%;
    font-size: 16px;
  }
  @media (max-width: 1199.98px) {
    padding-top: 0px;
  }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    padding-left: 25px;
    h4 {
      font-size: 24px;
    }
    p {
      font-size: 14px;
      width: 70%;
    }
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    padding-right: 25px;
    padding-left: 25px;
    h4 {
      font-size: 20px;
      padding-bottom: 10px;
    }
    p {
      font-size: 13px;
      width: 90%;
    }
  }
  @media (max-width: 767.98px) {
    padding-right: 20px;
    padding-left: 20px;
    h4 {
      font-size: 20px;
      padding-bottom: 10px;
    }
    p {
      font-size: 13px;
      width: 90%;
    }
  }
  @media (max-width: 790px) {
    padding-bottom: 40px;
    p {
      width: 100%;
    }
  }
`;
const ResponseActions = styled.div`
  button.accept {
    font-weight: 600;
    margin-right: 20px;
  }
  button.cancel {
    font-weight: 400;
    color: #4db5b4;
    background-color: white;
    border: none;
  }
  @media (max-width: 1199.98px) {
    button.accept {
      display: block;
      margin-bottom: 15px !important;
    }
  }
  @media (max-width: 790px) {
    button.cancel {
      margin-bottom: 55px !important;
    }
  }
`;

const StyledImage = styled(Image)`
  position: absolute;
  bottom: -10px;
  right: -60px;
  @media (max-width: 1650px) {
    width: 40%;
  }
  @media (max-width: 1199.98px) {
    width: 55%;
  }
`;

const AssessmentPauseModal = ({
  showPauseAssessment,
  setShowPauseAssessment,
  setExitAssessment,
}) => {

  const handleAccept = () => {
    setShowPauseAssessment(false);
    setExitAssessment(true);
  };

  return (
    <Modal
      dialogClassName="modal-50w"
      show={showPauseAssessment}
      onHide={() => setShowPauseAssessment(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <ModalHeader closeButton></ModalHeader>
      <ModalBody>
        <StyledContent>
          <h4>
            <strong>Are you sure you don’t want to continue?</strong>
          </h4>
          <p>Your progress will be saved.</p>
          <ResponseActions>
            <Button
              size="sm"
              variant="primary"
              className="accept btn-green mb-1 py-2 px-4"
              onClick={() => setShowPauseAssessment(false)}>
              No, I made a mistake.
            </Button>
            <Button
              size="sm"
              variant="primary"
              className="cancel btn-secondary mb-1 py-2 px-4"
              onClick={handleAccept}>
              Yes, I’m sure.
            </Button>
          </ResponseActions>
        </StyledContent>
        <StyledImage src="/assessment/pause-assessment.svg" />
      </ModalBody>
    </Modal>
  );
};

export default AssessmentPauseModal;
