import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Button } from 'react-bootstrap';
import { useAppData } from '../contexts/AppDataProvider';
import { Container, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import axios from 'axios';
import AssessmentHistory from './History';

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  text-align: center;
  > h1 {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 1.875rem;
  }
  > p {
    font-size: 1.0625rem;
    line-height: 30px;
    margin-bottom: 0px;
  }
  > img.continue {
    width: 336px;
    margin-top: 30px;
    margin-bottom: 50px;
  }
  > img.welcome {
    width: 300px;
  }
  @media (max-width: 575.98px) {
    > img.continue {
      width: 200px;
    }
    > img.welcome {
      width: 200px;
    }
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    > h1 {
      font-size: 28px;
    }
    > p {
      font-size: 16px;
    }
  }
  @media (max-width: 767.98px) {
    > h1 {
      font-size: 26px;
    }
    > p {
      font-size: 14px;
    }
  }
`;
const StyledButton = styled(Button)`
  background: #1db4b3;
  font-size: 14px;
  font-weight: 500;
  padding: 15px 70px;
  border: none;
  &:hover,
  &:focus {
    border: none;
    background: #1db4b3 !important;
    color: white !important;
  }
`;

const LeftContainer = styled(Container)`
  padding-top: 40px;
  .header {
    font-size: 2rem;
    margin-bottom: 71px;
    font-weight: bold;
  }
  @media (max-width: 1200px) {
    .header {
      font-size: 1.75rem;
      margin-bottom: 40px;
    }
  }
  @media (max-width: 767.98px) {
    .header {
      font-size: 1.5rem;
    }
  }
  p {
    font-size: 1rem;
    line-height: 30px;
    padding: 0px 20px;
  }
  @media (max-width: 767.98px) {
    p {
      font-size: 1rem;
    }
  }
  button {
    padding: 15px 18px;
    margin-left: 20px;
    margin-right: 20px;
  }
  @media (max-width: 1200px) {
    .welcome-info {
      text-align: center;
      @media (max-width: 767.98px) {
        margin-bottom: 50px;
      }
    }
    .header {
      font-size: 1.75rem;
      margin-bottom: 50px;
    }
    .mood-image {
      max-width: 200px !important;
      margin-bottom: 20px;
    }
  }
`;

const AssessmentWelcomeScreen = ({ assessment, runAssessment, is_retake }) => {
  const unanswered_questions = assessment.questions.filter(
    (element) => element.answer_id == null,
  );
  const { firstName } = useAppData();
  const handleLetsGetStarted = (event) => {
    runAssessment();
  };
  const [assessments, setAssessments] = useState([]);

  const callAssessments = async () => {
    await axios.get('/api/v2/assessments?status=completed').then((res) => {
      setAssessments(res.data.reverse());
    });
  };

  useEffect(() => {
    callAssessments();
  }, [is_retake]);

  const ContinueAssessmentScreen = () => (
    <StyledContent>
      <h1>Let’s continue the assessment.</h1>
      <p>
        Just a few questions left to know your well-being score. It will take
        less than 2 minutes.
      </p>
      <img className="continue" src="/assessment/continue-assessment.svg" />
      <StyledButton onClick={runAssessment}>Continue</StyledButton>
    </StyledContent>
  );

  if (is_retake) {
    return (
      <>
        {unanswered_questions.length < assessment.questions.length ? (
          <ContinueAssessmentScreen />
        ) : (
          <LeftContainer>
            <Row>
              <Col lg={8} className="welcome-info">
                <h1 className="header">
                  It’s nice seeing you here again, {firstName}.
                </h1>
                <Row>
                  <Col md={12} xl={4}>
                    <img
                      className="mood-image"
                      src={
                        assessments[0]?.health_status == 'high'
                          ? '/assessment/high-mood.svg'
                          : '/assessment/low-mood.svg'
                      }
                    />
                  </Col>
                  <Col md={12} xl={8} className="pt-4">
                    {assessments.length > 0 && (
                      <p>
                        You took our mental health assessment last{' '}
                        <strong>
                          {moment(assessments[0]?.created_at).format(
                            'MMMM YYYY',
                          )}
                        </strong>{' '}
                        and scored a total of
                        <strong> {assessments[0]?.score} points</strong>.
                      </p>
                    )}
                    {assessments[0]?.health_status == 'high' ? (
                      <p>You felt good overall.</p>
                    ) : (
                      <p>
                        We recommended that you seek mental health support via
                        our Chat helpline or to book a counseling session.
                      </p>
                    )}
                    <p>
                      It’s time to take the assessment again to know your
                      current mental health score
                    </p>

                    <StyledButton onClick={runAssessment}>
                      Let’s Get Started
                    </StyledButton>
                  </Col>
                </Row>
              </Col>
              <Col lg={4}>
                <AssessmentHistory assessments={assessments} />
              </Col>
            </Row>
          </LeftContainer>
        )}
      </>
    );
  } else {
    return (
      <>
        {unanswered_questions.length < assessment.questions.length ? (
          <ContinueAssessmentScreen />
        ) : (
          <StyledContent>
            <h1>How’s your well-being?</h1>
            <p>
              The WHO Well-Being Index (WHO-5) is a standard measure of
              well-being. Please indicate for each of the five statements which
              is the closest to how you have been feeling over the last two
              weeks. Response options range from 0 (At no time) to 5 (All of the
              time).
              <br />
              The questionnaire will take less than 2 minutes to complete.
              Please answer as honestly as you can.
            </p>
            <img className="welcome" src="/assessment/start-assessment.png" />
            <StyledButton
              id="assessment-lets-get-started"
              onClick={handleLetsGetStarted}>
              Let’s Get Started
            </StyledButton>
          </StyledContent>
        )}
      </>
    );
  }
};

export default AssessmentWelcomeScreen;
