import React, { useState, useEffect } from 'react';
import * as moment from 'moment';
import styled from '@emotion/styled';
import ProfileNavigation from '../shared/ProfileNavigation';
import Spinner from '../Spinner';
import BookASessionButton from '../v2/shared/BookASessionButton';
import { Col, Row } from 'react-bootstrap';

const StyledCardBody = styled.div`
  max-height: 400px;
  min-height: 100px;
  margin: 10px !important;
  overflow-y: auto;
`;
const Styledth = styled.th`
  border: none !important;
  font-size: 12px;
  font-weight: normal;
`;
const Styledtd = styled.td`
  border: none !important;
  font-size: 12px;
  max-width: 200px;
  &:last-child {
    max-width: 300px;
  }
`;

const Styledtr = styled.tr`
  &:nth-child(odd) {
    background: #f8f9fa 0% 0% no-repeat padding-box;
  }
`;

const SessionContent = ({ meetings, session_left }) => {
  if (meetings.length == 0) {
    return (
      <Row>
        <Col xs={12}>
          <h5>You have no counseling sessions yet</h5>
        </Col>
        <Col xl={4} xs={12} className="mt-2">
          {session_left > 0 && <BookASessionButton page={'Session History'} />}
        </Col>
      </Row>
    );
  }
  return (
    <>
      <h5 className="card-title">Session History</h5>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <Styledth scope="col">DATE</Styledth>
              <Styledth scope="col">TIME</Styledth>
              <Styledth scope="col">NAME</Styledth>
              <Styledth scope="col">TYPE</Styledth>
              <Styledth scope="col">STATUS</Styledth>
              <Styledth scope="col">FEEDBACK</Styledth>
            </tr>
          </thead>
          <tbody>
            {meetings.map((meeting, index) => (
              <Styledtr key={index}>
                <Styledtd>{moment(meeting.meeting_date).format('ll')}</Styledtd>
                <Styledtd>
                  {moment(meeting.start_time).format('hh:mm a')}
                </Styledtd>
                <Styledtd>{meeting.professional}</Styledtd>
                <Styledtd>{meeting.purpose}</Styledtd>
                <Styledtd>{meeting.status}</Styledtd>
                <Styledtd>{meeting.feedback || '-'}</Styledtd>
              </Styledtr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

const SessionHistory = ({}) => {
  const [sessionState, setSessionState] = useState({
    loading: true,
    meetings: [],
    session_left: 0
  });

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch('/api/v2/meetings?all=true');
      let data = await res.json();

      // to get sessions left
      const res2 = await fetch('/api/v2/meetings');
      const additionalData = await res2.json();

      if (res.status === 200) {
        setSessionState({
          ...sessionState,
          loading: false,
          meetings: data.reverse(),
          session_left: additionalData?.sessions_left,
        });
      } else {
        console.log('errors', data.errors);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="container">
      <div className="row mt-5">
        <ProfileNavigation />
        <div className="col-lg-9 col-md-8 col-sm-12 mt-2">
          <div className="card">
            <StyledCardBody className="card-body">
              {sessionState.loading ? (
                <Spinner />
              ) : (
                sessionState.meetings && (
                  <SessionContent
                    meetings={sessionState.meetings}
                    session_left={sessionState.session_left}
                  />
                )
              )}
            </StyledCardBody>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionHistory;
