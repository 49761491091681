import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Col, Container, Row, Image } from 'react-bootstrap';
import Navigation from '../Layout/Navigation';
import Footer from '../shared/Footer';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';

const SuccessContainer = styled.div`
  text-align: center;
  padding: 40px;
  max-width: 466px;
  border-radius: 8px;
`;
const ContainerWrapper = styled(Container)`
  margin-top: 2.5rem;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #e3f2f6, #faf6f7);
    z-index: -1;
  }
  .background-image {
    width: 80%;
    height: auto;
    position: absolute;
    z-index: -1;
  }
`;

const SuccessScreen = () => {
  const location = useLocation();
  const query = qs.parse(location.search);
  const type = query['type'];
  const background = '/otp/background.svg';

  useEffect(() => {
    let redirect = query['redirect_link'] || query['redirect']
    if (type === 'success_registration' || type === 'verified_email') {
      const timer = setTimeout(() => {
        if (!!redirect) {
          window.location = `/${redirect}`;
        } else {
          window.location = `/`;
        }
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <>
      <Navigation />
      <ContainerWrapper>
        <img src={background} alt="" className="background-image" />
        <Row>
          <Col className="text-center d-flex flex-column align-items-center">
            <SuccessContainer className="text-left mt-5">
              <Image height="250px" src="/no-session-success.gif" />
              {type === 'verified_email' && (
                <h6 className="font-weight-bold">
                  Successfully verified email address
                </h6>
              )}
              {type === 'success_registration' && (
                <h6 className="font-weight-bold">
                  Successfully created account
                </h6>
              )}
            </SuccessContainer>
          </Col>
        </Row>
      </ContainerWrapper>
      <Footer />
    </>
  );
};

export default SuccessScreen;