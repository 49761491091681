import React from 'react';
import styled from '@emotion/styled';
import { ProgressBar } from 'react-bootstrap';

const StyledProgressBar = styled(ProgressBar)`
  .progress-bar {
    background-color: #1db4b3;
  }
  background-color: #1db4b314;
  height: 10px;
  width: 100%;
`;

const UIProgressBar = ({ now }) => {
  return (
    <div>
      <StyledProgressBar now={now} />
    </div>
  );
};

export default UIProgressBar;
