import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import get from 'lodash.get';
import { Formik } from 'formik';
import { css, cx } from '@emotion/css';

import Title from '../Title';
import Api from '../Api';

const questions = [
  {
    name: 'gender',
    question: 'Do you have any gender preference for your counselor?',
    options: ['No preference', 'Female', 'Male'],
  },
  {
    name: 'language',
    question: 'Do you have any language preferences?',
    options: [],
  },
  {
    name: 'reason',
    question: 'What brings you here? (Check all that apply)',
    options: [],
  },
  {
    name: 'counseled',
    question: 'Have you tried counseling with a professional before?',
    options: ['true', 'false'],
  },
];

const QuestionTitle = ({ question }) => (
  <div className="row">
    <div className="col my-2">
      <h5 className="font-weight-bold">{question}</h5>
    </div>
  </div>
);

const ReasonQuestionOptions = ({ options, name, form }) => {
  const [other, setOther] = useState(false);
  let [reason] = useState(new Set());

  return (
    <>
      {options.map((option, key) => {
        return (
          <div key={key}>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                name={name}
                value={option}
                id={option}
                onChange={(event) => {
                  if (reason.has(option)) {
                    reason.delete(option);
                  } else {
                    reason.add(option);
                  }

                  form.setFieldValue(name, Array.from(reason));
                }}
              />
              <label className="form-check-label" htmlFor={option}>
                {option}
              </label>
            </div>
          </div>
        );
      })}
      <div className="form-group">
        <label htmlFor="other_reason">Others:</label>
        <input
          name="other_reason"
          id="other_reason"
          className="form-control"
          onBlur={(event) => {
            form.setFieldValue('other_reason', event.target.value);
          }}
        />
        <QuestionErrorMessage form={form} name="other_reason" />
      </div>
    </>
  );
};

const LanguageQuestionOptions = ({ options, name, form }) => {

  const [filipino, setFilipino] = useState(false);
  const [languages] = useState(new Set());
  const [filipinoLanguageOptions, setFilipinoLanguageOptions] = useState([])
  const [dialectOptions, setDialectOptions] = useState([])

  function handleLanguages(event, option, type) {
    // toggle filipino dialect checkbox and select
    const checked = event.target.checked;
    if(type === 'main'){
      if (option === 'Filipino (Dialect)') {
        if (checked) {
          // make first value the default
          languages.add(dialectOptions[0]);
          setFilipino(true);
        } else if (checked === false) {
          // remove all dialects when unchecked
          dialectOptions.forEach((dialect) => languages.delete(dialect));
          setFilipino(false);
        }
      }else{
        if (checked) {
          languages.add(option);
        } else if (checked === false) {
        languages.delete(option);
        }
      }
    }

    if(type === 'dialect'){
      dialectOptions.forEach((dialect) => languages.delete(dialect));
      languages.add(option);
    }
    form.setFieldValue('language', Array.from(languages).join(', '));
  }
  useEffect(() => {
    Api.get(`/api/v2/preferences/supported_values?type=languages`).then((res) => {
      let languages = res.data.languages;
      let defaultOption = languages.filter((language) => ['English', 'Filipino (Tagalog)'].includes(language));
      let dialects = languages.filter((language) => !['English', 'Filipino (Tagalog)'].includes(language));
      setDialectOptions(dialects)
      setFilipinoLanguageOptions(defaultOption)
    })
  }, [])

  return (
    <>
      {filipinoLanguageOptions.map((option, key) => {
        return (
          <div key={key}>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                name={name}
                value={option}
                id={option}
                onChange={(event) => {
                  handleLanguages(event, option, 'main');
                }}
              />
              <label className="form-check-label" htmlFor={option}>
                {option}
              </label>
            </div>
          </div>
        );
      })}
      {dialectOptions.length > 0 &&
        <>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              name={name}
              value={'Filipino (Dialect)'}
              id='Filipino (Dialect)'
              onChange={(event) => {
                handleLanguages(event, 'Filipino (Dialect)', 'main');
              }}
            />
            <label className="form-check-label" htmlFor='Filipino (Dialect)'>
              Filipino (Dialect)
            </label>
          </div>
          {filipino && (
            <div className="form-group">
              <select
                className="form-control"
                name={name}
                onChange={(event) =>
                  handleLanguages(event, event.target.value, 'dialect')
                }>
                {dialectOptions.map(
                  (dielectOption, index) => (
                    <option key={index} value={dielectOption}>
                      {dielectOption}
                    </option>
                  ),
                )}
              </select>
            </div>
          )}
        </>
      }
    </>
  );
};

const DefaultQuestionOptions = ({ options, name, form }) => (
  <div className="form-check">
    {options.map((option, key) => {
      return (
        <div key={key}>
          <>
            <input
              className="form-check-input"
              type="radio"
              name={name}
              value={option}
              id={option}
              onChange={() => form.setFieldValue(name, option)}
            />
            <label className="form-check-label" htmlFor={option}>
              {option == 'true' ? 'Yes' : option == 'false' ? 'No' : option}
            </label>
          </>
        </div>
      );
    })}
  </div>
);

const QuestionOptions = ({ question: { name, options }, form }) => {
  switch (name) {
    case 'language':
      return (
        <LanguageQuestionOptions options={options} name={name} form={form} />
      );
    case 'reason':
      return (
        <ReasonQuestionOptions options={options} name={name} form={form} />
      );
    default:
      return (
        <DefaultQuestionOptions options={options} name={name} form={form} />
      );
  }
};
const QuestionErrorMessage = ({ form, name }) => {
  const formError = get(form.errors, name);

  if (formError)
    return (
      <div
        className={cx(
          'invalid-feedback',
          css`
            display: block;
          `,
        )}>
        {formError}
      </div>
    );

  return null;
};

const Question = ({ children }) => {
  return (
    <div className="card bg-light border-0 my-2">
      <div className="card-body">
        <div className="form-group">{children}</div>
      </div>
    </div>
  );
};

const schema = Yup.object().shape({
  gender: Yup.string().required('This field is required'),
  language: Yup.string().required('This field is required'),
  counseled: Yup.string().required('This field is required'),
  reason: Yup.array().of(Yup.string()),
  other_reason: Yup.string().when('reason', {
    is: (reason) => reason.length < 1,
    then: Yup.string().required('This field is required'),
  }),
});

export const CoachingQuestionsForm = (props) => {
  useEffect(() => {
    questions[0]['question'] =
      'Do you have any gender preference for your coach?';
  }, []);
  return <PreferenceForm onSubmit={props.onSubmit} />;
};

const PreferenceForm = ({ onSubmit = null }) => {
  const [newQuestions, setNewQuestions] = useState([]);
  const handleOnSubmit = (values, actions) => {
    Api.post('/api/v1/preferences', values).then((res) => {
      if (!!onSubmit) {
        onSubmit();
      } else {
        window.location = res.data.return_url;
      }
    });
  };
  useEffect(() => {
    Api.get(`/api/v2/counseling_reasons`).then((res) => {
      let reason_indexes = questions
        .map((question, index) => {
          if (question['name'] == 'reason') {
            return index;
          }
        })
        .filter((e) => e != null);
      if (!!reason_indexes[0]) {
        questions[reason_indexes[0]]['options'] = res.data;
        setNewQuestions(questions);
      }
    });
  }, []);
  return (
    <Formik
      initialValues={{
        gender: '',
        language: '',
        reason: [],
        counseled: '',
        other_reason: '',
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={schema}
      onSubmit={handleOnSubmit}>
      {(props) => {
        return (
          <form autoComplete="off" onSubmit={props.handleSubmit}>
            {newQuestions.map((question, index) => {
              return (
                <Question form={props} name={question.name} key={index}>
                  <QuestionTitle question={question.question} />
                  <QuestionOptions question={question} form={props} />
                  <QuestionErrorMessage form={props} name={question.name} />
                </Question>
              );
            })}
            <div className="text-center">
              <button type="submit" className="btn btn-green my-4 mb-1 py-2">
                Save Preference
              </button>
            </div>
          </form>
        );
      }}
    </Formik>
  );

};

function QuestionsForm() {
  return (
    <>
      <div className="bg-light">
        <Title
          title="Help us match you to the right counselor"
          description="Please answer a few questions on your preferences"
        />
      </div>
      <div className="bg-white">
        <div
          className={cx(
            'container',
            css`
              padding: 2rem 0;
            `,
          )}>
          <div className="row mt-2">
            <div className="col-10 mx-auto">
              <PreferenceForm/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default QuestionsForm;
