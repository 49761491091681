import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Form, Row, Col, Container, Spinner, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import CompanySetup from './CompanySetup';
import Banner from '../MobileRegistration/banner';
import { useHistory } from 'react-router-dom';
import NotRegisteredModal from './NotRegisteredModal';

const StyledContainer = styled.div`
  .input-group {
    flex-wrap: nowrap !important;
  }
  .phone-prefix {
    background: #ffffff;
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .phone-form {
    display: block;
    width: 100%;
    border-left: none !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dddddd;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    opacity: 1;
  }
  input[type='text'] {
    height: 50px !important;
  }
  .error {
    border: solid 1px red !important;
  }
  .text-error {
    color: red;
    margin: 0px;
    padding: 0px;
  }
  .login-link {
    margin-top: 40px;
  }
  a {
    color: #1db4b3;
  }
  .login-header {
    font-size: 24px;
    font-weight: bold;
    margin: 65px 0px 50px 0px;
    z-index: 99;
    text-align: center;
  }
  .btn-submit {
    background: #1db4b3 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    border: none;
    padding: 10px 40px 10px 40px;
  }
  .forgot-link {
    float: right;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0px;
    opacity: 1;
    color: #1db4b3 !important;
    cursor: pointer;
  }
  .email-label {
    float: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
  }
  .form-control {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dddddd !important;
    border-radius: 5px !important;
    opacity: 1;
    height: 50px;
  }
  .form-control::placeholder {
    text-align: left;
    font-size: 14px !important;
    letter-spacing: 0px;
    color: #969696 !important;
    opacity: 1;
  }
  .register {
    margin-top: 60px;
    text-align: center;
    font-size: 12px;
    font-weight: medium;
    letter-spacing: 0px;
    color: #333333;
    a {
      text-align: center;
      font-size: 12px;
      font-weight: medium;
      letter-spacing: 0px;
      color: #1db4b3;
    }
  }
  .login {
    min-height: 90vh;
    .login-sidebar {
      border-radius: 8px;
      padding: 80px 65px 65px 65px;
      text-align: center;
    }
    .logo {
      width: 100px;
    }
    .nav-link {
      text-align: center;
      font-size: 14px;
      letter-spacing: 0px;
      color: #969696;
      opacity: 1;
    }
    .nav-link-active {
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      color: #191919;
      opacity: 1;
      border-bottom: 2px solid #1db4b3;
      padding-bottom: 16px;
    }
    .tab-content {
      margin-top: 70px;
    }
    .form {
      margin: 0px 60px 0px 60px;
      .mobile-input {
        background-color: #1db4b3 !important;
        height: 50px !important;
      }
    }
  }
  .main-content {
    height: 97vh;
    position: relative;
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, #e3f2f6, #faf6f7);
      z-index: -1;
    }
    .secondary-background {
      width: 135%;
      height: auto;
      top: -510px;
      left: -100px;
      position: absolute;
      z-index: -1;
    }
    .applink {
      margin: 12px;
    }
    .mobile {
      position: absolute;
      left: 40%;
    }
    .mobile-tilted {
      position: absolute;
      right: 40%;
      z-index: -1;
    }
    .qr-code {
      margin-right: 20px;
      margin-left: 20px;
    }
  }
`;

const Registration = () => {
  const logo = '/logo.png';
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [disabledSignup, setDisabledSignup] = useState(true);
  const [errors, setErrors] = useState({});
  const [resErr, setResErr] = useState([]);
  const [step, setStep] = useState('registration');
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    password: '',
  });
  const [showQuestionModal, setShowQuestionModal] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href);
    const queryParams = new URLSearchParams(url.search);
    const isRegistered = queryParams.get('is_registered');
    if (isRegistered === 'false') {
      setShowQuestionModal(true)
    }

  }, [window.location.href])

  const handleQuestionModalClose = () => setShowQuestionModal(false);

  const handleNextStep = async (step) => {
    setLoading(true);
    setResErr([])
    setErrors({})
    await axios
      .post('/api/v2/users/verify_fields', { user: formData })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setStep(step);
        }
      })
      .catch((_err) => {
        const redirect_url = _err['response']['data']['redirect_url']
        if (redirect_url) {
          history.push({
            pathname: redirect_url,
            state: {
              registration_phone: formData.phone,
              formData: formData
            },
          })
        } else {
          if (_err['response']['data']['error']) {
            setResErr([_err['response']['data']['error']])
          } else {
            setErrors(_err['response']['data']['errors'][0]);
          }
          setLoading(false);
        }

      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    const allFieldsHaveValues = Object.values(formData).every(
      (val) => val !== '',
    );
    setDisabledSignup(!allFieldsHaveValues);
  };

  return (
    <StyledContainer>
      <Banner />
      <NotRegisteredModal show={showQuestionModal} handleClose={handleQuestionModalClose}/>
      {step === 'registration' && (
        <Row className="login">
          <Col md={5} className="login-sidebar">
            <img src={logo} alt="" className="logo" />
            <div className="login-header">Welcome to Infinit Care</div>
            <Container>
              {errors.notice &&
                <p className="text-center text-error mb-2">
                  <small className="text-error">
                    {errors.notice}
                  </small>
                </p>
              }
              <Form>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label className="email-label">
                        First Name
                      </Form.Label>
                      <Form.Control
                        id="first_name"
                        name="first_name"
                        type="text"
                        placeholder="Enter first name"
                        onChange={handleInputChange}
                        value={formData.first_name}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label className="email-label">Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        id="last_name"
                        name="last_name"
                        placeholder="Enter last name"
                        onChange={handleInputChange}
                        value={formData.last_name}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label className="email-label">
                        Email Address
                      </Form.Label>
                      <Form.Control
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter email address"
                        onChange={handleInputChange}
                        value={formData.email}
                        className={
                          errors.email ? 'error form-control' : 'form-control'
                        }
                        required
                      />
                      {errors.email &&
                        Object.entries(errors.email).map(
                          ([field, fieldErrors]) => (
                            <p className="text-left text-error">
                              <small className="text-error" key={field}>
                                {fieldErrors}
                              </small>
                            </p>

                          ),
                        )}

                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label className="email-label">
                        Mobile Number
                      </Form.Label>
                      <InputGroup>
                        <InputGroup.Text className="phone-prefix">+63</InputGroup.Text>
                        <input
                          className="phone-form"
                          type="text"
                          id="phone"
                          name="phone"
                          placeholder="xxx xxx xxxx"
                          onChange={handleInputChange}
                          value={formData.phone}
                          required
                        />
                      </InputGroup>
                      {errors.phone &&
                        Object.entries(errors.phone).map(
                          ([field, fieldErrors]) => (
                            <p className="text-left text-error">
                              <small className="text-error" key={field}>
                                {fieldErrors}
                              </small>
                            </p>
                          ),
                        )
                      }
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label className="email-label">Password</Form.Label>
                      <Form.Control
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Password (must be at least 8 characters long with 1 number and 1 letter)"
                        onChange={handleInputChange}
                        value={formData.password}
                        required
                        className={
                          errors.password
                            ? 'error form-control'
                            : 'form-control'
                        }
                      />
                      {errors.password &&
                        Object.entries(errors.password).map(
                          ([field, fieldErrors]) => (
                            <p className="text-left text-error" key={field}>
                              <small>
                                {fieldErrors}
                              </small>
                            </p>

                          ),
                        )}
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <button
                      style={{
                        opacity: disabledSignup ? '0.5' : '1',
                      }}
                      type="button"
                      className="btn-submit"
                      disabled={disabledSignup}
                      onClick={() => handleNextStep('company_setup')}>
                      {loading ? (
                        <Spinner animation="border" role="status" size="sm" />
                      ) : (
                        'Next'
                      )}
                    </button>
                  </Col>
                  <Col md={12} className="login-link">
                    Already have an account?&nbsp;
                    <a href="/users/sign_in">Login</a>
                  </Col>
                </Row>
              </Form>
            </Container>
          </Col>
          <Col md={7} className="main-content">
            <div className="login-header">
              Mental healthcare at your fingertips
            </div>
            <img className="secondary-background" src="/loginv2/login-bg.svg" />
            <Row className="mx-auto">
              <Col className="text-right">
                <div>
                  <img className="applink" src="/loginv2/appstore.png" />
                </div>
                <div className="qr-code">
                  <img
                    className="applink"
                    src="/loginv2/qr-code-appstore.svg"
                  />
                </div>
              </Col>
              <Col className="text-left">
                <div>
                  <img className="applink" src="/loginv2/googleplay.png" />
                </div>
                <div className="qr-code">
                  <img
                    className="applink"
                    src="/loginv2/qr-code-playstore.svg"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <img className="mobile" src="/loginv2/mobile.png" />
              </Col>
              <Col>
                <img
                  className="mobile-tilted"
                  src="/loginv2/mobile-tilted.png"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      {step === 'company_setup' && <CompanySetup formData={formData} />}
    </StyledContainer>
  );
};

export default Registration;