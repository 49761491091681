import axios from 'axios';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import UIProgressBar from '../shared/ui/ProgressBar';

const StyledContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  h1 {
    margin-top: 90px;
    font-size: 32px;
    margin-bottom: 40px;
  }
  @media (max-width: 767.98px) {
    h1 {
      font-size: 26px;
    }
  }
  @media (max-width: 575.98px) {
    h1 {
      width: 80%;
    }
  }
`;
const StyledButtonContainer = styled.div`
  text-align: center;
`;
const StyledButton = styled(Button)`
  margin: 50px 10px;
  background: #9b9fa84a;
  font-size: 0.875rem;
  font-weight: 400;
  padding: 15px 19px;
  width: 165px;
  height: 100px;
  border-radius: 5px;
  border: none;
  color: #333333;
  &.btn.btn-primary:active:hover,
  &:hover,
  &:focus {
    border: none;
    color: #1db4b3 !important;
    background-color: #1db4b34d;
    box-shadow: none;
  }
  &.selected {
    border: none;
    color: #1db4b3 !important;
    background-color: #1db4b34d;
    font-weight: bold;
  }

  @media (max-width: 1200px) {
    font-size: 0.875rem;
    padding: 25px 20px;
  }
  @media (max-width: 992px) {
    margin: 15px 10px;
    width: 30%;
  }
  @media (max-width: 767.98px) {
    width: 40%;
  }
  @media (max-width: 575.98px) {
    font-size: 13px;
    padding: 25px 20px;
  }
`;
const StyledNextButton = styled(Button)`
  background: #1db4b3 !important;
  color: white;
  padding: 15px 42px;
  font-weight: bold;
  border: 1px solid white;
  margin-top: 40px;
  &:hover,
  &:focus {
    color: white !important;
    border: 1px solid white !important;
  }
  &:disabled {
    opacity: 0.6;
    background: #1db4b3 !important;
    border-color: #1db4b3 !important;
  }
`;
const StyledBackButton = styled(Button)`
  color: #1db4b3 !important;
  background-color: white;
  padding: 15px 42px;
  border: 1px solid #1db4b3;
  margin-right: 24px;
  font-weight: bold;
  margin-top: 40px;
  &:hover,
  &:focus {
    opacity: 0.8;
    border: 1px solid #1db4b3 !important;
    background-color: white !important;
    color: #1db4b3 !important;
  }
`;
const SubmittedContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  > h1 {
    font-size: 25px;
    margin-bottom: 25px;
    font-weight: bold;
  }
  > p {
    font-size: 20px;
    margin-bottom: 70px;
  }
`;

const AssessmentQuestions = ({ assessment, callAssessment }) => {
  const history = useHistory();
  const [questions, setQuestions] = useState(assessment.questions);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState(null);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [progressSize, setProgressSize] = useState(
    (1 / assessment.questions.length) * 100,
  );
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    let currentIndex = questions.findIndex((q) => q.answer_choice == null);
    if (currentIndex != -1) {
      setCurrentQuestion(currentIndex);
      setCurrentAnswer(questions[currentIndex].answer_choice);
    }
  }, []);

  useEffect(() => {
    let newProgress = 0;
    let answeredQuestions = questions.filter((q) => q.answer_choice != null);
    if (answeredQuestions.length > 0) {
      newProgress = ((answeredQuestions.length + 1) / questions.length) * 100;
      setCurrentProgress(newProgress);
    } else {
      setCurrentProgress(progressSize);
    }
  }, [questions]);

  const submitAnswer = async () => {
    let action = null;
    let question = questions[currentQuestion];
    if (question.answer_id) {
      action = axios.patch(`/api/v2/assessment_answers/${question.answer_id}`, {
        assessment_answer: {
          assessment_id: assessment.id,
          choice: currentAnswer,
        },
      });
    } else {
      action = axios.post('/api/v2/assessment_answers', {
        assessment_answer: {
          assessment_id: assessment.id,
          assessment_question_id: question.question_id,
          choice: currentAnswer,
        },
      });
    }
    try {
      await action.then((res) => {
        if (res.status == 200) {
          questions[currentQuestion].answer_choice = res.data.choice;
          questions[currentQuestion].answer_id = res.data.id;
          setQuestions(questions);
          if (currentQuestion == questions.length - 1) {
            callAssessment();
          }
        }
      });
    } catch (err) {
      console.log('errors', err);
    }
  };

  const handleNext = async () => {
    await submitAnswer();
    setCurrentQuestion(currentQuestion + 1);
    setCurrentAnswer(questions[currentQuestion + 1].answer_choice);
    setCurrentProgress(currentProgress + progressSize);
  };

  const handleBack = () => {
    setCurrentQuestion(currentQuestion - 1);
    setCurrentAnswer(questions[currentQuestion - 1].answer_choice);
    setCurrentProgress(currentProgress - progressSize);
  };

  const selectAnswer = (choice) => {
    setCurrentAnswer(choice);
  };

  const handleSubmit = async (event) => {
    await submitAnswer();
    history.push('assessment/user_form');
  };

  if (!submitted) {
    if (questions.length == 0) {
      return <></>;
    } else {
      return (
        <>
          <UIProgressBar now={Math.round(currentProgress)} />
          <StyledContent>
            <h1>{questions[currentQuestion].question_text}</h1>
            <StyledButtonContainer id="assessment-choices">
              {assessment.choices.map((choice, index) => {
                const choicePoint = assessment.choices.length - 1 - index;
                return (
                  <StyledButton
                    id={'assessment-choice-' + choice}
                    key={index}
                    className={currentAnswer == choice ? 'selected' : ''}
                    onClick={() => selectAnswer(choice)}>
                    {choice}
                    <br />({choicePoint})
                  </StyledButton>
                );
              })}
            </StyledButtonContainer>
            <div>
              {currentQuestion > 0 && (
                <StyledBackButton onClick={handleBack}>Back</StyledBackButton>
              )}
              {currentQuestion < questions.length - 1 && (
                <StyledNextButton
                  id="assessment-next-question-button"
                  onClick={handleNext}
                  disabled={!currentAnswer}>
                  Next
                </StyledNextButton>
              )}
              {currentQuestion == questions.length - 1 && (
                <StyledNextButton
                  id="assessment-submit-button"
                  onClick={handleSubmit}
                  disabled={!currentAnswer}>
                  Next
                </StyledNextButton>
              )}
            </div>
          </StyledContent>
        </>
      );
    }
  } else {
    return (
      <SubmittedContent>
        <h1>You have completed the assessment.</h1>
        <p>We’re now preparing your results.</p>
        <Spinner />
      </SubmittedContent>
    );
  }
};

export default AssessmentQuestions;
