import React, { useState, useEffect, useRef } from 'react';
import styles from './index.scss';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import axios from 'axios';
import useSWR from 'swr';
import moment from 'moment';
import NotesModal from './NotesModal';

const fetcher = (url) => axios.get(url).then((res) => res.data);
const mood_colors = {
  good: '#97D7D2',
  awesome: '#1DB4A8',
  okay: '#FED54D',
  bad: '#FEAF4D',
  terrible: '#F06680',
};
const MoodTacker = () => {
  const [mode, setMode] = useState('weekly');
  const [date, setDate] = useState(moment().format(dateFormat));
  const [durationNav, setDurationNav] = useState({
    prev_enabled: true,
    next_enable: false,
  });

  const { data } = useSWR(
    `/api/v2/check_ins/reports?range=${mode}&date=${date}`,
    fetcher,
  );
  const dateFormat = 'YYYY-MM-DD';
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!!data && data.summary.length > 0) {
      const gap = 75 / 360;
      let startAngle = gap;
      let endAngle = 0;

      let runningPercentage = 0;
      clearCanvas();
      data.summary.forEach((element) => {
        runningPercentage += element.percentage;
        let addedAngle = (runningPercentage / 100) * 2;

        endAngle = addedAngle * Math.PI;
        drawDoughnutChart(startAngle, endAngle, mood_colors[element.mood]);
        startAngle = endAngle + gap;
      });
    }
  }, [data]);

  const drawDoughnutChart = (startAngle, endAngle, color) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.beginPath();
    ctx.arc(96, 96, 80, startAngle, endAngle);
    ctx.lineWidth = 13;
    ctx.strokeStyle = color;
    ctx.lineCap = 'round';
    ctx.stroke();
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  useEffect(() => {
    if (!!data) {
      if (nextDuration() > moment()) {
        setDurationNav({ ...durationNav, next_enable: false });
      } else {
        setDurationNav({ ...durationNav, next_enable: true });
      }
    }
  }, [data]);

  const NavBar = () => {
    return (
      <>
        <div className="menu card col-sm-12">
          <button
            className={mode == 'weekly' ? 'active' : ''}
            onClick={() => setMode('weekly')}>
            Weekly
          </button>
          <button
            className={mode == 'monthly' ? 'active' : ''}
            onClick={() => setMode('monthly')}>
            Monthly
          </button>
          <hr />
        </div>
      </>
    );
  };

  const nextDuration = () => {
    const kind = mode == 'weekly' ? 'week' : 'month';
    return moment(data.start_date, dateFormat).add(1, kind);
  };

  const prevDuration = () => {
    const kind = mode == 'weekly' ? 'week' : 'month';
    return moment(data.start_date, dateFormat).subtract(1, kind);
  };

  const currentWeekMonth = () => {
    const currentMoment = moment();
    return (
      currentMoment >= moment(data.start_date) &&
      currentMoment <= moment(data.end_date)
    );
  };

  const TitleInfo = () => {
    const adjustDate = (momentDate) => {
      if (momentDate > moment()) {
        return;
      }
      setDate(momentDate.format(dateFormat));
    };
    return (
      <div className="header-info-container">
        <div className="title-container">
          <p className="font-weight-bold">
            {mode == 'weekly' ? 'Weekly Tracker' : 'Monthly Tracker'}
          </p>
          {mode == 'weekly'
            ? data && (
                <p>
                  {currentWeekMonth ? 'This Week' : 'Prev Week'},{' '}
                  {moment(data.start_date).format('MMM D')} -
                  {moment(data.end_date).format('MMM D')}
                </p>
              )
            : data && (
                <p>
                  {currentWeekMonth ? 'This Month' : 'Prev Month'},{' '}
                  {moment(data.start_date).format('MMM YYYY')}
                </p>
              )}
        </div>
        <div className="selector-container">
          <button
            className={`mr-2 ${durationNav.prev_enabled ? '' : 'disabled'}`}
            onClick={() => adjustDate(prevDuration())}>
            <i className="fas fa-chevron-left"></i>
          </button>
          <button
            className={durationNav.next_enable ? '' : 'disabled'}
            onClick={() => adjustDate(nextDuration())}>
            <i className="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>
    );
  };

  const MoodDates = () => {
    const renderTooltip = (props) => (
      <Tooltip>{moment(props.value).format('D')}</Tooltip>
    );

    const entering = (e, color) => {
      e.children[0].style.setProperty('--tooltip-arrow-background', color);
      e.children[1].style.backgroundColor = color;
    };
    return (
      <div className="date-items-container">
        {data &&
          data.daily_moods.map((daily, index) => {
            return (
              <OverlayTrigger
                key={index}
                placement="bottom"
                overlay={renderTooltip({ value: daily.date })}
                onEntering={(event) =>
                  entering(event, mood_colors[daily.mood] || '#C1C1C1')
                }>
                <div
                  className={`date-item ${mode == 'monthly' ? 'monthly' : ''} ${
                    daily.mood == '' ? 'greyed' : ''
                  }`}
                  style={{ backgroundColor: mood_colors[daily.mood] }}>
                  {mode == 'weekly'
                    ? moment(daily.date).format('dd')
                    : moment(daily.date).format('D')}
                </div>
              </OverlayTrigger>
            );
          })}
      </div>
    );
  };

  const MoodInsights = () => {
    return (
      <div className="average-mood-insight-container">
        <div className="insight-results">
          {data &&
            data.summary.map((summary, index) => {
              return (
                <div
                  key={index}
                  className={`insight-item ${index == 0 ? 'mt-1' : 'mt-4'}`}>
                  <span
                    className="value font-weight-bold"
                    style={{ textTransform: 'capitalize' }}>
                    {summary.mood}
                  </span>
                  <div className="progress mb-2 mt-1">
                    <div
                      className="progressbar"
                      style={{
                        backgroundColor: mood_colors[summary.mood],
                        width: summary.percentage + '%',
                      }}></div>
                  </div>
                  <span className="reasons">{summary.reasons.join(', ')}</span>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const MissedCheckIns = () => {
    return (
      <p>
        We noticed that you missed filling out your Mood Tracker. How are you?
        <br />
        <br />
        Infinit Care’s Mood Tracker helps you assess your mood on a day-to-day
        basis.
        <br />
        <br />
        The information that you provide in your Mood Tracker is valuable to
        guide us in giving you the best care that you deserve.
        <br />
        <br />
        Infinit Care truly cares for you.
      </p>
    );
  };

  const [selectedNote, setSelectedNote] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const MoodNotes = () => {
    return (
      <div className="mood-notes-container">
        <div className="notes-results">
          {data &&
            data.daily_notes.map((note, index) => {
              return (
                <button
                  key={index}
                  className="btn btn-link text-green notes-button"
                  onClick={() => {
                    setSelectedNote(note);
                    setShowModal(true);
                  }}>
                  {note.date}
                </button>
              );
            })}
        </div>
      </div>
    );
  };

  return (
    <div className="container mood-tracker-container">
      <div className="row">
        <NavBar />
        <div className="card col-sm-12 mood-dates-container p-0">
          <div className="card-body p-0">
            <TitleInfo />
            <MoodDates />
            {data && data.summary.length == 0 && <MissedCheckIns />}
            {data && data.summary.length > 0 && (
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 mt-4">
                  <div className="card">
                    <p className="title">
                      Your average mood this{' '}
                      {mode == 'weekly' ? 'week' : 'month'}
                    </p>
                    <div className="mood-summary average-mood-chart-container">
                      <div width="152" height="152" className="mood-chart">
                        <canvas
                          ref={canvasRef}
                          width="192"
                          height="192"></canvas>
                      </div>
                      <div className="mood-results">
                        {data &&
                          data.summary.map((summary, index) => {
                            return (
                              <div className="mood-item" key={index}>
                                <span
                                  className="dot"
                                  style={{
                                    backgroundColor: mood_colors[summary.mood],
                                  }}></span>
                                <span className="percent">
                                  {summary.percentage}%
                                </span>
                                <span
                                  className="value"
                                  style={{ textTransform: 'capitalize' }}>
                                  {summary.mood}
                                </span>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 mt-4">
                  <div className="card">
                    <p className="title m-0 mb-1">Your mood insights</p>
                    <p className="sub-title ">
                      Focus of your mood this{' '}
                      {mode == 'weekly' ? 'week' : 'month'}
                    </p>
                    <MoodInsights />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                  <div className="card">
                    <p className="title m-0 mb-1">Journal History</p>
                    <p className="sub-title ">View your past entries</p>
                    <MoodNotes />
                    {selectedNote && (
                      <NotesModal
                        header={'Your Note'}
                        date={selectedNote.date}
                        notes={selectedNote.notes}
                        show={showModal}
                        setShowModal={setShowModal}
                        successAction={() => {}}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoodTacker;
