import React from 'react';
import DashboardCard from './DashboardCard';
import { Col, Row } from 'react-bootstrap';

export const CounselingFeedback = () => {
  return (
    <DashboardCard title={'Counseling Feedback'}>
      <Row className="mt-5">
        <Col className="text-center">
          <img src={'/incoming-chat-icon.svg'} />
          <p>No. of Responses</p>
          <h4>123</h4>
        </Col>
        <Col className="text-center">
          <img src={'/rating-icon.svg'} />
          <p>Satisfaction Rating</p>
          <h4>4.6</h4>
        </Col>
        <Col className="text-center">
          <img src={'/recommendation-icon.svg'} />
          <p>Recommendation Rating</p>
          <h4>4.9</h4>
        </Col>
      </Row>
    </DashboardCard>
  );
};
