import React from 'react';
import DashboardCard from './DashboardCard';
import { CardContent } from './styles';
import { NoData } from './NoData';
import { Bar } from 'react-chartjs-2';

export const NewUsersPerMonth = ({ data }) => {
  let title, tooltipText, labels;

  if (data.hasOwnProperty('new_users_per_month')) {
    data = data.new_users_per_month;
    title = 'New Users Per Month';
    tooltipText = 'New registrations per month';
    labels = data?.map((value) => `${value.month}, ${value.year}`);
  } else {
    data = data.new_users_per_day;
    title = 'New Users Per Day';
    tooltipText = 'New registrations per day';
    labels = data?.map((value) => value.day);
  }

  if (data?.length == 0) {
    return (
      <NoData title={title} withQuestionMark={true} tooltipText={tooltipText} />
    );
  }
  const options = {
    responsive: true,
    legend: {
      display: false,
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  const chartData = {
    labels,
    datasets: [
      {
        data: data?.map((value) => value.count),
        backgroundColor: '#9381FF',
        borderRadius: 50,
        barThickness: 16,
      },
    ],
  };

  return (
    <DashboardCard
      title={title}
      withQuestionMark={true}
      tooltipText={tooltipText}>
      <CardContent>
        <Bar options={options} data={chartData} className="mt-4" />
      </CardContent>
    </DashboardCard>
  );
};
