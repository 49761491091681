import axios from 'axios';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Modal, Button } from 'react-bootstrap';
import * as moment from 'moment';
import { DayPickerRangeController } from 'react-dates';
import { UIButton } from '../shared/ui';

const ModalHeader = styled(Modal.Header)`
  border-bottom: none;
`;
const ModalBody = styled(Modal.Body)`
  overflow: hidden;
`;
const StyledContent = styled.div`
  text-align: center;
  h4 {
    font-size: 18px;
    padding-bottom: 24px;
    color: #e88480;
    font-weight: bold;
  }
  p {
    font-size: 14px;
    line-height: 25px;
  }
`;
const ResponseActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 3rem;
  margin-bottom: 0;
  width: 100%;
  button.cancel {
    font-weight: 400;
    color: #1db4b3;
    background-color: white;
    border: none;
    font-size: 12px;
    margin-bottom: 24px;
    &:hover,
    &:focus {
      color: #10123d !important;
      background-color: white !important;
      border: none !important;
      box-shadow: none !important;
    }
  }
`;

const ExportModal = ({ showExportModal, setShowExportModal, filteredCompanyId, siteId }) => {
  const handleCancel = () => {
    setShowExportModal(false);
  };

  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment().endOf('month'));
  const [focusedInput, setFocusedInput] = useState('startDate');
  const [exporting, setExporting] = useState(false);

  const exportUsers = async () => {
  const searchParams = new URLSearchParams({
    date_start: startDate.format('YYYY-MM-DD'),
    date_end: endDate.format('YYYY-MM-DD'),
    site_id: siteId,
    company_id: filteredCompanyId,
    status: 'confirmed',
  });

    setExporting(true);
    try {
      const response = await axios.get(
        `/api/v2/company_admin/company_users/export_users?${searchParams.toString()}`,
        {
          responseType: 'blob',
        },
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      let filename = `${startDate?.format('D_MMM_Y')}-${endDate?.format('D_MMM_Y')}-users.csv`
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error exporting CSV:', error);
    }
    setExporting(false);
  };

  return (
    <Modal
      dialogClassName="export-modal"
      show={showExportModal}
      onHide={() => setShowExportModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <ModalHeader closeButton><h6>Export Users</h6></ModalHeader>
      <ModalBody>
        <div className='d-flex justify-content-center'>
        <StyledContent className='d-flex justify-content-center align-items-center flex-column' style={{width:'fit-content'}}>
          <p>Select the date range base on Date Confirmed</p>
          <p style={{fontWeight: '300'}}>Start Date: {startDate?.format('MMM D Y') || 'Select Date'} - End Date: {endDate?.format('MMM D Y') || 'Select Date'}</p>
          <DayPickerRangeController
              className="margin-auto"
              startDate={startDate}
              endDate={endDate}
              onDatesChange={({ startDate, endDate }) => {
                setStartDate(startDate);
                setEndDate(endDate);
              }}
              numberOfMonths={2}
              focusedInput={focusedInput}
              onFocusChange={focusedInput => setFocusedInput(focusedInput || 'startDate')}
            />
            <ResponseActions>
              <Button
                size="sm"
                variant="primary"
                className="cancel btn-secondary mb-1 py-2 px-4"
                onClick={handleCancel}>
                Cancel
              </Button>
              <UIButton text={exporting ? 'Exporting..' : 'Export Users'} style={{width: 'fit-content'}} onClick={() => exportUsers()} disabled={!startDate || !endDate || exporting}/>
            </ResponseActions>
        </StyledContent>
      </div>
        
      </ModalBody>
    </Modal>
  );
};

export default ExportModal;
