import React from 'react';

const AudioItem = ({ currentAudio }) => {
  return (
    <div className="image-cropper">
      <img
        className="audio-image"
        src={currentAudio.image_url}
        alt={`currentAudio.title `}
      />
    </div>
  );
};

export default AudioItem;
