import styled from '@emotion/styled';
import React from 'react';
import { Dropdown } from 'react-bootstrap';

const StyledDropdown = styled(Dropdown)`
  #dropdown-basic {
    font-size: 14px;
    padding: 10px 40px;
    &,
    &:hover,
    &:active,
    &:focus &.dropdown-toggle {
      background: #4db5b4 !important;
      border-color: #4db5b4 !important;
      color: #fff !important;
    }
    &.white,
    &.white:hover,
    &.white:active,
    &.white:focus &.white.dropdown-toggle {
      background: #fff !important;
      background-color: #fff !important;
      color: #4db5b4 !important;
    }
    &:after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: '';
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }
    &:hover,
    &:active,
    &:focus &.dropdown-toggle {
      background: #4db5b4 !important;
      border-color: #4db5b4 !important;
      color: #fff !important;
    }
    &:focus{
      outline:none;
      box-shadow: none;
    }
  }
  &.calendar{
    #dropdown-basic {
      background: #fff !important;
      border: 1px solid #dee2e6 !important;
      width: 200px;
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 10px;
      height: 42px;
      &:after {
        display: none !important;
      }
      &.grey-text{
        color: #333333 !important;
      }
      .selected-text {
        padding-top: 4px;
      }
    }
  }
`;
const StyledDropdownItem = styled(Dropdown.Item)`
  font-size: 14px;
  padding-top: 7px;
  &.active,
  &:hover,
  &:active,
  &:focus {
    background: #4db5b4 !important;
    border-color: #4db5b4 !important;
    color: #fff !important;
  }
  &.calendar-selector{
    &.active,
    &:hover,
    &:active,
    &:focus {
      background: #fff !important;
      border-color: #fff !important;
      color: #4db5b4 !important;
    }
  }
`;

const UISelect = (props) => {
  return (
    <>
      <StyledDropdown
        onSelect={props.handleOnSelect}
        className={props.className}>
        <Dropdown.Toggle
          id="dropdown-basic"
          className={`${props.white ? 'white' : ''}`}>
          {props.text}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {props.options.map((option, i) => (
            <StyledDropdownItem
              className={`${props.selectedDropDown === option.key && props?.with_active ? 'active' : ''}`}
              eventKey={option.key}
              key={option.key}>
              {option.text}
            </StyledDropdownItem>
          ))}
        </Dropdown.Menu>
      </StyledDropdown>
    </>
  );
};

export const CalendarUISelect = (props) => {
  return (
    <>
      <StyledDropdown
        onSelect={props.handleOnSelect}
        className={`calendar ${props.className}`}>
        <Dropdown.Toggle
          id="dropdown-basic"
          className={`white grey-text input-group border rounded-lg ${props.selectedDropDown === 'custom' ? 'w-auto' : ''}`}>
           <div className="input-group-prepend">
            <img src="/calendar.svg" style={{width: '80%'}} />
          </div>
          <span className="selected-text">
            {props.text}
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {props.options.map((option, i) => (
            <StyledDropdownItem
              className={`calendar-selector ${props.selectedDropDown === option.key && props?.with_active ? 'active' : ''}`}
              eventKey={option.key}
              key={option.key}>
              {option.text}
            </StyledDropdownItem>
          ))}
        </Dropdown.Menu>
      </StyledDropdown>
    </>
  );
};

export default UISelect;