import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Modal, Button } from 'react-bootstrap';
import { DayPickerRangeController } from 'react-dates';
import { UIButton } from '../shared/ui';

const ModalHeader = styled(Modal.Header)`
  border-bottom: none;
`;
const ModalBody = styled(Modal.Body)`
  overflow: hidden;
`;
const StyledContent = styled.div`
  text-align: center;
  h4 {
    font-size: 18px;
    padding-bottom: 24px;
    color: #e88480;
    font-weight: bold;
  }
  p {
    font-size: 14px;
    line-height: 25px;
  }
`;
const ResponseActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 3rem;
  margin-bottom: 0;
  width: 100%;
  button.cancel {
    font-weight: 400;
    color: #1db4b3;
    background-color: white;
    border: none;
    font-size: 12px;
    margin-bottom: 24px;
    &:hover,
    &:focus {
      color: #10123d !important;
      background-color: white !important;
      border: none !important;
      box-shadow: none !important;
    }
  }
`;

const CustomDateModal = ({ showCustomDateModal, setShowCustomDateModal, setCustomDate, setCurrentDateSelectAction, customDate }) => {
  const handleCancel = () => {
    setShowCustomDateModal(false);
  };

  const [focusedInput, setFocusedInput] = useState('startDate');
  const handleCustomDate = () => {
    let text = `Custom: ${customDate.start.format('MMM D Y')} - ${customDate.end.format('MMM D Y')}`
    setCurrentDateSelectAction({key: 'custom', text: text});
    setShowCustomDateModal(false);
  }

  return (
    <Modal
      dialogClassName="export-modal"
      show={showCustomDateModal}
      onHide={() => setShowCustomDateModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <ModalHeader closeButton><h6>Custom Date</h6></ModalHeader>
      <ModalBody>
        <div className='d-flex justify-content-center'>
        <StyledContent className='d-flex justify-content-center align-items-center flex-column' style={{width:'fit-content'}}>
          <p style={{fontWeight: '300'}}>Start Date: {customDate?.start?.format('D MMM Y') || 'Select Date'} - End Date: {customDate?.end?.format('D MMM Y') || 'Select Date'}</p>
          <DayPickerRangeController
              startDate={customDate.start}
              endDate={customDate.end}
              onDatesChange={({ startDate, endDate }) => {
                setCustomDate({start: startDate, end: endDate})
              }}
              numberOfMonths={2}
              focusedInput={focusedInput}
              onFocusChange={focusedInput => setFocusedInput(focusedInput || 'startDate')}
            />
            <ResponseActions>
              <Button
                size="sm"
                variant="primary"
                className="cancel btn-secondary mb-1 py-2 px-4"
                onClick={handleCancel}>
                Cancel
              </Button>
              <UIButton text={'Apply'} style={{width: 'fit-content'}} onClick={handleCustomDate}/>
            </ResponseActions>
        </StyledContent>
      </div>
        
      </ModalBody>
    </Modal>
  );
};

export default CustomDateModal;
