import React, { useContext, useState } from 'react';
import ProfileNavigation from '../shared/ProfileNavigation';

import { UserContext } from '../contexts/UserContext';
import DeleteAccountModal from '../shared/DeleteAccountModal';
import styled from '@emotion/styled';
import EditButton from '../shared/EditButton';
import UpdateProfileEmail from './UpdateProfileEmail';
import { Image } from 'react-bootstrap';

const StyledCardBody = styled.div`
  padding-top: 35px;
  padding-bottom: 30px;
  padding-left: 40px;
  padding-right: 40px;
`;
const StyledLabel = styled.span`
  font-size: 14px;
  margin-bottom: 5px;
`;
const StyledInfo = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
`;
const StyledButton = styled.button`
  font-size: 14px;
  padding: 0px;
  margin-bottom: 100px;
  &:hover {
    text-decoration: none;
  }
`;
const Info = ({ setEditing, user: { first_name, last_name, email, verifiedEmail, verifiedPhone, phone } }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <div className="col-lg-8 col-md-8 col-sm-12 mt-2">
      <div className="card">
        <StyledCardBody className="card-body">
          <div className="d-flex justify-content-between">
            <h5 className="card-title">Account Information</h5>
            <EditButton action={() => setEditing('profile')} color='green' />
          </div>
          <div>
            <StyledLabel className="d-block">First Name</StyledLabel>
            <StyledInfo className="d-block">{first_name}</StyledInfo>
          </div>
          <div>
            <StyledLabel className="d-block">Last Name</StyledLabel>
            <StyledInfo className="d-block">{last_name}</StyledInfo>
          </div>
          <div>
            <StyledLabel className="d-block mr-2">
              Email Address{' '}
              <EditButton action={() => setEditing('email')} color='green' />
            </StyledLabel>
            <StyledInfo className="d-block">{email} { verifiedEmail &&
              <Image className="d-inline-block ml-1 pb-1" src="/check-mark.svg" width={18} height={18}/> }
            </StyledInfo>
            { phone && <div>
                <StyledLabel className="d-block mr-2">
                  Mobile Number{' '}
                </StyledLabel>
                <StyledInfo className="d-block">{phone} { verifiedPhone && <Image className="d-inline-block ml-1 pb-1" src="/check-mark.svg" width={18} height={18} /> }</StyledInfo>
              </div>
            }
          </div>
        </StyledCardBody>
      </div>
      {showDeleteModal && (
        <DeleteAccountModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
        />
      )}
    </div>
  );
};

const Form = ({ user, setEditing }) => {
  const { setUserProfile } = useContext(UserContext);
  const [userData, setUserData] = useState(user);
  const [errors, setErrors] = useState({});

  function handleOnChange(event) {
    const updatedAttribute = { [event.target.name]: event.target.value };
    setUserData({ ...userData, ...updatedAttribute });
  }

  async function handleOnSubmit(event) {
    event.preventDefault();
    const payload = {
      user: {
        first_name: userData.first_name,
        last_name: userData.last_name,
      },
    };

    const res = await fetch('/api/v2/profile', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (res.status === 200) {
      setEditing('none');
      setUserProfile(userData);
    } else {
      const { errors } = await res.json();
      setErrors(errors[0]);
    }
  }

  return (
    <section className="card mt-2 col-lg-8 col-md-8 col-sm-12 p-3">
      <div className="card-body">
        <h5 className="card-title">Account Information</h5>
        <form className="d-flex flex-column" onSubmit={handleOnSubmit}>
          <div className="mb-3">
            <label
              htmlFor="user[first_name]"
              className="form-label font-weight-bolder">
              {' '}
              First Name{' '}
            </label>
            <input
              id="user[first_name]"
              type="text"
              name="first_name"
              value={userData.first_name}
              onChange={handleOnChange}
              className="form-control"
              required
            />
            {errors.first_name && (
              <span className="small text-danger mt-2">
                {' '}
                {errors.first_name.join(', ')}{' '}
              </span>
            )}
          </div>
          <div className="mb-3">
            <label
              htmlFor="user[last_name]"
              className="form-label font-weight-bolder">
              {' '}
              Last Name{' '}
            </label>
            <input
              id="user[last_name]"
              type="text"
              name="last_name"
              value={userData.last_name}
              onChange={handleOnChange}
              className="form-control"
              required
            />
            {errors.last_name && (
              <span className="small text-danger mt-2">
                {' '}
                {errors.last_name.join(', ')}{' '}
              </span>
            )}
          </div>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              onClick={() => setEditing('none')}
              style={{ height: 'fit-content' }}
              className="mt-auto btn btn-general bg-white text-dark border rounded mr-2">
              Cancel
            </button>
            <button
              type="submit"
              onClick={handleOnSubmit}
              className="btn-general rounded">
              Update
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

const Profile = ({}) => {
  const { currentUser } = useContext(UserContext);
  const [editingState, setEditingState] = useState('none');

  return (
    <div className="container personal-information">
      <div className="row mt-5">
        <ProfileNavigation />
        {editingState == 'email' && (
          <UpdateProfileEmail user={currentUser} setEditing={setEditingState} />
        )}
        {editingState == 'profile' && (
          <Form user={currentUser} setEditing={setEditingState} />
        )}
        {editingState == 'none' && (
          <Info user={currentUser} setEditing={setEditingState} />
        )}
      </div>
    </div>
  );
};

export default Profile;
