import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';

const CanvasContainer = styled.div`
  width: 152px;
  height: auto;
  margin: auto;
`;

const Doughnut = ({ params }) => {
  const canvasRef = useRef(null);
  useEffect(() => {
    let per_percent = 0;
    if (params?.percents.length > 1) {
      per_percent = (2 * Math.PI) / (params?.percents.length * 5 + 100);
    } else {
      per_percent = (2 * Math.PI) / 100;
    }

    const gap = per_percent * 5;

    let startAngle = 0;
    let endAngle = 0;

    let runningPercentage = 0;
    clearCanvas();
    params?.percents.forEach((percentage, index) => {
      runningPercentage += percentage;
      endAngle = runningPercentage * per_percent;
      drawDoughnutChart(startAngle, endAngle, params?.colors[index]);
      startAngle = endAngle + gap;
      runningPercentage += 5;
    });
  }, [params]);

  const drawDoughnutChart = (startAngle, endAngle, color) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.beginPath();
    ctx.arc(96, 96, 80, startAngle, endAngle);
    ctx.lineWidth = 13;
    ctx.strokeStyle = color;
    ctx.lineCap = 'round';
    ctx.stroke();
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  return (
    <CanvasContainer>
      <canvas ref={canvasRef} width="192" height="192"></canvas>
    </CanvasContainer>
  );
};

export default Doughnut;
