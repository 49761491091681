import Api from '../../Api';
import React from 'react';
import styled from '@emotion/styled';
import { Navbar, NavDropdown, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useAppData } from '../contexts/AppDataProvider';

const StyledProfileDropdown = styled(NavDropdown)`
  .dropdown-menu {
    right: 0 !important;
  }
  a {
    padding-right: 0;
  }
  a.nav-link {
    font-size: 0.8rem;
    &:hover {
      text-shadow: 0px 0px 1px white;
    }
  }
  a.nav-link.active {
    color: #1db4b3 !important;
  }
  a.nav-link:hover {
    color: #16181b;
    background-color: #f8f9fa;
  }
  #profile-nav-dropdown {
    color: white;
    background: #1db4b3;
    text-align: center;
    padding: 0.6em;
    border-radius: 5px;
    margin-left: 15px;
  }
`;

const StyledNav = styled(Nav)`
  a.nav-link.active {
    color: #1db4b3 !important;
    font-weight: 500;
  }
`;

const StyledNavbarBrand = styled(Navbar.Brand)`
  .company-logo {
    max-height: 50px;
    margin-left: 20px;
    margin-right: 0px;
  }
  .logo-divider {
    border-left: 2px solid #00a2a1;
    margin-left: 20px;
  }
`;

const Navigation = () => {
  const {
    firstName,
    lastName,
    companyLogo,
    companyAdmin,
    companyCsManager,
    withMoodTracker,
    currentAssessment,
    access_to_assessment,
  } = useAppData();

  const logout = async () => {
    await Api.delete('/api/v2/users/logout');
    window.location.href = '/';
  };

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <LinkContainer to="/">
          <StyledNavbarBrand>
            <img src="/logo.svg" />
            {companyLogo && <span className="logo-divider"></span>}
            {companyLogo && (
              <img
                src={companyLogo}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  let parentNode = currentTarget.parentNode;
                  parentNode.removeChild(currentTarget.previousElementSibling);
                  parentNode.removeChild(currentTarget);
                }}
                className="company-logo"
              />
            )}
          </StyledNavbarBrand>
        </LinkContainer>
        <StyledNav className="d-none d-lg-flex align-items-center ml-auto">
          <LinkContainer exact={true} to="/">
            <Nav.Link>Home</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/explore">
            <Nav.Link>Explore</Nav.Link>
          </LinkContainer>
          {withMoodTracker && (
            <LinkContainer to="/insights">
              <Nav.Link>Insights</Nav.Link>
            </LinkContainer>
          )}
          {access_to_assessment && (
            <LinkContainer to="/assessment">
              <Nav.Link>Assessment</Nav.Link>
            </LinkContainer>
          )}
          <LinkContainer exact={true} to="/webinars">
            <Nav.Link>Events</Nav.Link>
          </LinkContainer>
          {(companyAdmin || companyCsManager) && (
            <LinkContainer to="/company">
              <Nav.Link>Dashboard</Nav.Link>
            </LinkContainer>
          )}
        </StyledNav>
        <Navbar.Brand className="mr-0">
          <StyledProfileDropdown
            title={firstName[0] + lastName[0]}
            id="profile-nav-dropdown">
            <Nav.Link
              className="text-dark"
              href="/individual/dashboard/profile">
              Profile
            </Nav.Link>
            <Nav.Link
              className="text-dark"
              href="/individual/dashboard/session_history">
              Session History
            </Nav.Link>
            <LinkContainer exact={true} to="/" className="text-dark d-lg-none">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/explore" className="text-dark d-lg-none">
              <Nav.Link>Explore</Nav.Link>
            </LinkContainer>
            {withMoodTracker && (
              <LinkContainer to="/insights" className="text-dark d-lg-none">
                <Nav.Link>Insights</Nav.Link>
              </LinkContainer>
            )}
            {access_to_assessment && (
              <LinkContainer to="/assessment" className="text-dark d-lg-none">
                <Nav.Link>Assessment</Nav.Link>
              </LinkContainer>
            )}
            <LinkContainer to="/webinars" className="text-dark d-lg-none">
              <Nav.Link>Events</Nav.Link>
            </LinkContainer>
            {(companyAdmin || companyCsManager) && (
              <LinkContainer to="/company" className="text-dark d-lg-none">
                <Nav.Link>Dashboard</Nav.Link>
              </LinkContainer>
            )}
            <Nav.Link className="text-dark" onClick={logout}>
              Logout
            </Nav.Link>
          </StyledProfileDropdown>
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default Navigation;
