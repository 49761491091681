import React from 'react';
import styled from '@emotion/styled';

const StyledFigure = styled.figure`
  padding: 30px 19px;
  background-color: #fff9f2;
  border-radius: 8px;
  margin-bottom: 80px;
  padding-bottom: 20px;
  position: relative;
  p,
  figcaption {
    font-size: 0.875rem;
    font-weight: 500;
  }
  figcaption {
    text-align: right;
    font-weight: bold;
  }
  img {
    position: absolute;
    left: -10px;
    bottom: -30px;
  }
`;

const Quote = ({ quote }) => {
  return (
    <StyledFigure>
      <blockquote>
        <p>"{quote.description}"</p>
      </blockquote>
      <figcaption>— {quote.author}</figcaption>
      <img src="/qoute.svg" />
    </StyledFigure>
  );
};

export default Quote;
