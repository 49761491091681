import React from 'react';
import useSWR from 'swr';
import styled from '@emotion/styled';
import { Row, Col } from 'react-bootstrap';
import ContentCard from '../Card';
import Loader from './Loader';
import { StyledHeader, StyledHeading } from '../../Dashboard';
import { Link } from 'react-router-dom';

export const StyledDescription = styled.p`
  color: #333333;
  margin-bottom: 0px;
`;
const ArticleArray = ({ categoryData }) => {
  const { data } = useSWR(
    categoryData?.id
      ? `/api/v2/contents?category_id=${categoryData?.id}`
      : null,
    { revalidateOnFocus: false },
  );

  return (
    <>
      {!data && <Loader />}
      {data && data.length > 0 && (
        <>
          <Row>
            <StyledHeader>
              <StyledHeading className="mb-4">
                {categoryData?.title}
              </StyledHeading>
              <Link
                to={{
                  pathname: `/categories/${categoryData?.id}`,
                  state: { categoryData },
                }}
                className="text-green font-weight-bold">
                See All
              </Link>
            </StyledHeader>
          </Row>
          <Row>
            <Col style={{ fontSize: '14px' }} md={10}>
              <StyledDescription>{categoryData?.description}</StyledDescription>
            </Col>
          </Row>
          <Row>
            {data?.map((content, index) => (
              <ContentCard key={index} withContent={true} data={content} />
            ))}
          </Row>
        </>
      )}
    </>
  );
};

export default ArticleArray;
