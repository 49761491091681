import axios from 'axios';
import useSWR from 'swr';
import React from 'react';
import styled from '@emotion/styled';
import { Row, Col, Button, Image } from 'react-bootstrap';
import { useAppData } from '../contexts/AppDataProvider';

import { openChat } from '../../../packs/freshchat';
import MeetingCancelButton from '../Meetings/Cancel';
import BookASessionButton from '../shared/BookASessionButton';
import { UIButton } from '../shared/ui';
import { useHistory } from 'react-router-dom';

const StyledWelcome = styled.h3`
  font-size: 1.2rem;
  font-weight: bold;
`;

const CapitalizedHeading = styled.h4`
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const StyledSubmitButton = styled(Button)`
  background: #1db4b3;
  font-weight: 600;
  font-size: 0.8rem;
  width: 100%;
  margin-bottom: 1rem;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px #1db4b3 solid;
  &:hover,
  &:focus {
    border: 1px #1db4b3 solid !important;
    background: #1db4b3 !important;
    color: white !important;
  }
`;

const JoinSessionButton = styled(Button)`
  background: #ffffff;
  font-weight: 600;
  font-size: 0.8rem;
  width: 100%;
  margin-bottom: 1rem;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px #1db4b3 solid;
  color: #1db4b3;
  &:hover,
  &:focus {
    border: 1px #1db4b3 solid !important;
    background: #ffffff !important;
    color: #1db4b3 !important;
  }
`;

const DaysRemainingButton = styled(Button)`
  background: #ffffff !important;
  font-weight: 600;
  font-size: 0.8rem;
  width: 100%;
  margin-bottom: 1rem;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px #c1c1c1 solid !important;
  color: #c1c1c1 !important;
  &:hover {
    border: 1px #c1c1c1 solid !important;
    background: #ffffff !important;
    color: #c1c1c1 !important;
  }
`;

const StyledLink = styled.a`
  font-size: 0.8rem;
  color: #1db4b3;
  text-decoration: underline;
  font-weight: 500;
  :hover {
    color: #1db4b3;
  }
`;

const fetcher = (url) => axios.get(url).then((res) => res.data);

const Sessions = () => {
  const { data } = useSWR('/api/v2/meetings', fetcher);
  const history = useHistory();

  let nearest_meeting = {};
  if (data) {
    nearest_meeting = data.nearest_meeting;
  }

  const { firstName, companyID, bookASessionLandingPage, withChat, verifiedEmail } =
    useAppData();

  const DATE_OPTIONS = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  const moveToVerifyEmail = () => {
    history.push('/verify_email?email_sent=true')
  }

  return (
    <>
      <Col lg={2} className="d-block">
        <Row>
          <Col>
            <StyledWelcome>Hello, {firstName}.</StyledWelcome>
          </Col>
        </Row>
       {!verifiedEmail && <Row>
          <Col>
            <span className='font-weight-bold'><Image src="/alert-triangle.svg" />&nbsp; IMPORTANT</span>
            <p>Your email needs verification.</p>
            <UIButton className="mb-2 orange" text={'Verify Email'} action={moveToVerifyEmail}/>
          </Col>
        </Row>}
        {data?.sessions_count_visible === true && (
          <Row>
            <Col>
              <CapitalizedHeading>Available Sessions</CapitalizedHeading>
              <p>
                You have{' '}
                <strong>
                  {data?.sessions_left === 1
                    ? `${data?.sessions_left || 0} session`
                    : `${data?.sessions_left || 0} sessions`}
                </strong>{' '}
                available for booking.
              </p>
            </Col>
          </Row>
        )}
        <Row>
          <Col className="mt-2">
            {(companyID || (!companyID && data?.sessions_left > 0)) && 
              <BookASessionButton page={bookASessionLandingPage()} />
            }
            {!companyID && data?.sessions_left <= 0 && (
              <BookASessionButton
                page={bookASessionLandingPage()}
                url={'/individual/dashboard/packages'}
              />
            )}
          </Col>
        </Row>
        {withChat && (
          <Row>
            <Col>
              <StyledSubmitButton onClick={openChat}>
                Chat with us
              </StyledSubmitButton>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <CapitalizedHeading>Upcoming sessions</CapitalizedHeading>
            {data?.meeting_today == true && nearest_meeting?.date && (
              <>
                <p>
                  You have {`${nearest_meeting?.setup == 'on_site' ? 'an onsite' : 'a'}`} counseling session today at{' '}
                  <strong>{nearest_meeting?.start_time}</strong>.
                </p>
                {data?.meeting_url && data?.meeting_url != '' && nearest_meeting?.setup != 'on_site' && (
                  <JoinSessionButton href={data?.meeting_url} target="_blank">
                    Join session
                  </JoinSessionButton>
                )}
              </>
            )}
            {nearest_meeting?.date && data?.meeting_today == false && (
              <>
                <p>
                  You have an upcoming {`${nearest_meeting?.setup == 'on_site' ? 'on site' : ''}`} {nearest_meeting.purpose} session this{' '}
                  <strong>
                    {new Date(nearest_meeting?.date).toLocaleDateString(
                      'en-US',
                      DATE_OPTIONS,
                    )}
                  </strong>{' '}
                  at <strong>{nearest_meeting?.start_time}</strong>.
                </p>
                <DaysRemainingButton className="disabled">
                  {data?.days_until_next_session}&nbsp;
                  {data?.days_until_next_session === 1 ? 'day' : 'days'}
                  &nbsp;remaining
                </DaysRemainingButton>
              </>
            )}
            {nearest_meeting?.id && nearest_meeting?.setup != 'on_site' && (
              <MeetingCancelButton meeting={nearest_meeting} />
            )}
            {!nearest_meeting?.date && <p>You have no upcoming sessions</p>}
          </Col>
        </Row>
        <Row>
          <Col>
            <StyledLink href="/individual/dashboard/session_history">
              <img className="pb-1 pr-2" src="/clock.svg" />
              See session history
            </StyledLink>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Sessions;
export { StyledSubmitButton };
