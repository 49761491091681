import React from 'react'
import Navbar from '../Layout/Navigation'
import PricingPackages from '../../Individual/PricingPackages'

export default () => (
  <>
    <Navbar />
    <PricingPackages />
  </>
)
