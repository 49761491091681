import React, { useEffect, useState } from 'react';
import ProfileNavigation from '../shared/ProfileNavigation';
import styled from '@emotion/styled';

const StyledPasswordDiv = styled.div`
  position: relative;
  .input {
    padding-right: 35px;
  }
  .eye-icon {
    position: absolute;
    top: 6px;
    right: 8px;
    border: none;
    background: transparent;
  }
  .eye-icon:focus {
    outline: none;
  }
`;
const StyledStatusSpan = styled.span`
  text-align: center;
  width: fit-content;
  margin: auto;
  display: block;
  padding: 7px;
  border-radius: 5px;
  &.success {
    background-color: #eefcce;
  }
`;
const Form = () => {
  const defaultpasswordErrors = {
    minLength: false,
    lowerCase: false,
    upperCase: false,
    special: false,
    number: false,
    passwordMatched: false,
  };
  const defaultFormValue = {
    old_password: '',
    new_password: '',
    confirm_new_password: '',
  };
  const [formValue, setFormValue] = useState(defaultFormValue);
  const [showPasswordItems, setShowPasswordItems] = useState([]);
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState(defaultpasswordErrors);
  function clearForm() {
    setFormValue({
      old_password: '',
      new_password: '',
      confirm_new_password: '',
    });
  }
  const [showSuccessStatus, setShowSuccessStatus] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  function handleOnChange(event) {
    const updatedAttribute = { [event.target.name]: event.target.value };
    setFormValue({ ...formValue, ...updatedAttribute });
  }

  useEffect(() => {
    checkPasswordValidity(
      formValue.new_password,
      formValue.confirm_new_password,
    );
  }, [formValue]);

  function checkPasswordValidity(newPassword, confirmNewPassword) {
    let regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%&*!])[A-Za-z\d@#$%&*!]{9,}$/;
    let tempErrors = {};

    setEnableSubmit(false);
    if (newPassword.length == 0) {
      setPasswordErrors(defaultpasswordErrors);
      return;
    }

    if (regex.test(newPassword) && newPassword == confirmNewPassword) {
      setPasswordErrors(defaultpasswordErrors);
      if (formValue.old_password.length > 0) {
        setEnableSubmit(true);
      }
      return;
    }

    tempErrors = {
      ...tempErrors,
      ...{ passwordMatched: newPassword != confirmNewPassword },
    };

    if (newPassword.length < 9) {
      tempErrors = { ...tempErrors, ...{ minLength: true } };
    }

    if (!/(.*[a-z])/.test(newPassword)) {
      tempErrors = { ...tempErrors, ...{ lowerCase: true } };
    }

    if (!/(.*[A-Z])/.test(newPassword)) {
      tempErrors = { ...tempErrors, ...{ upperCase: true } };
    }

    if (!/(.*[@#$%&*!])/.test(newPassword)) {
      tempErrors = { ...tempErrors, ...{ special: true } };
    }

    if (!/(.*\d)/.test(newPassword)) {
      tempErrors = { ...tempErrors, ...{ number: true } };
    }
    setPasswordErrors(tempErrors);
  }

  async function handleOnSubmit(event) {
    event.preventDefault();
    setSubmitting(true);
    setErrors(null);
    setShowSuccessStatus(false);
    const payload = {
      old_password: formValue?.old_password,
      new_password: formValue?.new_password,
      sign_out_user: false
    };

    const res = await fetch('/api/v2/users/update_password', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (res.status === 200) {
      setShowSuccessStatus(true);
      setFormValue(defaultFormValue);
    } else {
      const errors = await res.json();
      setErrors(errors);
    }
    setSubmitting(false);
  }

  const togglePasswordVisibility = (passwordName) => {
    let array = [...showPasswordItems];
    if (array.includes(passwordName)) {
      const index = array.indexOf(passwordName);
      if (index > -1) {
        array.splice(index, 1);
      }
      setShowPasswordItems(array);
    } else {
      array.push(passwordName);
      setShowPasswordItems(array);
    }
  };

  return (
    <section className="card mt-2 col-lg-8 col-md-8 col-sm-12 p-3">
      <div className="card-body">
        <h5 className="card-title">Change Password</h5>
        {showSuccessStatus && (
          <StyledStatusSpan className="success">
            Your password has been successfully updated.
          </StyledStatusSpan>
        )}
        {errors?.error && (
          <StyledStatusSpan className="alert-danger alert">
            {errors.error.replace('Validation failed:', '').trim()}
          </StyledStatusSpan>
        )}
        <form className="d-flex flex-column" onSubmit={handleOnSubmit}>
          <div className="mb-3">
            <label
              htmlFor="old_password"
              className="form-label font-weight-bolder">
              {' '}
              Old Password{' '}
            </label>
            <StyledPasswordDiv>
              <input
                id="old_password"
                type={
                  showPasswordItems.includes('old_password')
                    ? 'text'
                    : 'password'
                }
                name="old_password"
                onChange={handleOnChange}
                value={formValue?.old_password}
                className="form-control"
                required
              />
              <button
                className="eye-icon"
                type="button"
                onClick={() => togglePasswordVisibility('old_password')}>
                <img
                  className="icon"
                  src={
                    showPasswordItems.includes('old_password')
                      ? '/eye-thin.svg'
                      : '/eye-off-thin.svg'
                  }
                  alt=""
                />
              </button>
            </StyledPasswordDiv>
          </div>
          <div className="mb-3">
            <label
              htmlFor="new_password"
              className="form-label font-weight-bolder">
              {' '}
              New Password{' '}
            </label>
            <StyledPasswordDiv>
              <input
                id="new_password"
                name="new_password"
                type={
                  showPasswordItems.includes('new_password')
                    ? 'text'
                    : 'password'
                }
                onChange={handleOnChange}
                value={formValue?.new_password}
                className="form-control"
                required
              />
              <button
                className="eye-icon"
                type="button"
                onClick={() => togglePasswordVisibility('new_password')}>
                <img
                  className="icon"
                  src={
                    showPasswordItems.includes('new_password')
                      ? '/eye-thin.svg'
                      : '/eye-off-thin.svg'
                  }
                  alt=""
                />
              </button>
            </StyledPasswordDiv>
          </div>
          <div className="mb-3">
            <label
              htmlFor="confirm_new_password"
              className="form-label font-weight-bolder">
              {' '}
              Confirm New Password{' '}
            </label>

            <StyledPasswordDiv>
              <input
                id="confirm_new_password"
                type={
                  showPasswordItems.includes('confirm_new_password')
                    ? 'text'
                    : 'password'
                }
                name="confirm_new_password"
                onChange={handleOnChange}
                value={formValue?.confirm_new_password}
                className="form-control input"
                required
              />
              <button
                className="eye-icon"
                type="button"
                onClick={() =>
                  togglePasswordVisibility('confirm_new_password')
                }>
                <img
                  className="icon"
                  src={
                    showPasswordItems.includes('confirm_new_password')
                      ? '/eye-thin.svg'
                      : '/eye-off-thin.svg'
                  }
                  alt=""
                />
              </button>
            </StyledPasswordDiv>
          </div>

          <ul>
            <li
              className={`p-1 m-2 ${
                passwordErrors?.minLength ? 'alert-danger alert' : ''
              }`}>
              at least 9 characters
            </li>
            <li
              className={`p-1 m-2 ${
                passwordErrors?.lowerCase ? 'alert-danger alert' : ''
              }`}>
              minimum one lowercase letter
            </li>
            <li
              className={`p-1 m-2 ${
                passwordErrors?.upperCase ? 'alert-danger alert' : ''
              }`}>
              minimum one uppercase letter
            </li>
            <li
              className={`p-1 m-2 ${
                passwordErrors?.number ? 'alert-danger alert' : ''
              }`}>
              minimum one number
            </li>
            <li
              className={`p-1 m-2 ${
                passwordErrors?.special ? 'alert-danger alert' : ''
              }`}>
              minimum one special character (@, #, $, %, &, *, !)
            </li>
            <li
              className={`p-1 m-2 ${
                passwordErrors?.passwordMatched ? 'alert-danger alert' : ''
              }`}>
              confirm password did not match
            </li>
          </ul>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              onClick={() => clearForm()}
              style={{ height: 'fit-content' }}
              className="mt-auto btn btn-general bg-white text-dark border rounded mr-2">
              Cancel
            </button>
            <button
              type="submit"
              style={{ opacity: enableSubmit && !submitting ? '1' : '0.7' }}
              disabled={!enableSubmit || submitting}
              onClick={handleOnSubmit}
              className="btn-general rounded">
              Update
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

const ChangePassword = ({}) => {
  return (
    <div className="container personal-information">
      <div className="row mt-5">
        <ProfileNavigation />
        <Form />
      </div>
    </div>
  );
};

export default ChangePassword;
