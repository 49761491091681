import React, { useState, useEffect } from 'react';
import { Container, Spinner, InputGroup } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import {
  StyledDiv,
  StyledHeader,
  FormContainer,
} from '../../shared/styles/RegistrationStyle';
import PublicLayout from '../PublicLayout';
import axios from 'axios';
import Cookies from 'js-cookie';

const MobileRegistration = () => {
  const background = '/otp/background.svg';
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [phone, setPhone] = useState('');
  const history = useHistory();
  const location = useLocation();
  const [userParams, setUserParams] = useState({});

  useEffect(() => {
    let phone = location.state?.registration_phone || Cookies.get('phone') || ''
    phone = phone.replace("+63",'')
    const email = Cookies.get('email')
    const password = Cookies.get('password')

    if (phone !== '') {
      setPhone(phone);
    }
    if(phone !== '' || !!email || !!password){
      setUserParams({
        email: Cookies.get('email'),
        password: Cookies.get('password'),
        phone: phone
      })
    }
    if (location.state?.formData) {
      setUserParams(location.state?.formData)
    }
  }, [location.state, Cookies.get('phone')]);

  const handleNext = async () => {
    try {
      const res = await axios.post('/api/v2/users/phone_update', { user: userParams })
        if (res.status === 200) {
          Cookies.set(
            'phone',
            phone,
            { expires: 5 / 1440 }
          )
          history.push({
            pathname: '/otp',
            state: {
              registration_phone: phone,
            },
          });
        }
    } catch (_err) {
      if(_err['response']['data']){
        setError(_err['response']['data']['error'] || _err['response']['data']['errors'][0]);
      }else{
        console.log('errors', err);
      }
    }
  };

  const handleInputChange = (value) => {
    if(value.match(/^(9)\d{9}$/) !== null){
      setError(null)
      setUserParams({ ...userParams, phone: value })
    }else{
      setError('Invalid phone number format (9XX XXX XXXX)')
    }
    setPhone(value);
  };

  return (
    <PublicLayout>
      <StyledDiv>
        <Container>
          <StyledHeader>Let&apos;s verify your mobile number</StyledHeader>
          <img src={background} alt="" className="background-image" />

          <FormContainer>
            <label htmlFor="mobileNumber">Mobile Number:</label>
            <InputGroup>
              <InputGroup.Text className="phone-prefix">+63</InputGroup.Text>
              <input
                type="number"
                id="mobileNumber"
                name="mobileNumber"
                placeholder="xxx xxx xxxx"
                className="form-control"
                value={phone}
                onChange={(e) => handleInputChange(e.target.value)}
              />
            </InputGroup>
            {error && <p className="text-left text-error">
                <small>
                  {error}
                </small>
              </p>
            }
            {loading && (
              <button type="button" disabled={loading}>
                <Spinner animation="border" role="status" size="sm" />
              </button>
            )}
            {!loading && (
              <button type="button" className={!!error ? 'disabled' : ''} onClick={handleNext}>
                Verify
              </button>
            )}
          </FormContainer>
        </Container>
      </StyledDiv>
    </PublicLayout>
  );
};

export default MobileRegistration;