import React from 'react';
import DashboardCard from './DashboardCard';
import Doughnut from './Doughnut';
import { CardContent, DoughnutContainer } from './styles';
import { NoData } from './NoData';

export const UsersAverageMood = ({ data }) => {
  const title = "User's Average Mood";
  const tooltipText = 'Moods logged by users and top reasons for these moods';
  if (data.length == 0) {
    return (
      <NoData title={title} withQuestionMark={true} tooltipText={tooltipText} />
    );
  }

  const mood_colors = {
    good: '#97D7D2',
    awesome: '#1DB4A8',
    okay: '#FED54D',
    bad: '#FEAF4D',
    terrible: '#F06680',
  };

  const doughnutConstants = {
    colors: data.map((value) => mood_colors[value.mood]),
    percents: data.map((value) => value.percent),
  };

  return (
    <DashboardCard
      title={title}
      withQuestionMark={true}
      tooltipText={tooltipText}>
      <CardContent>
        <DoughnutContainer className="flex-column">
          <div className="mood-chart">
            <Doughnut params={doughnutConstants} />
          </div>
          <div className="mood-results">
            {data.map((value, index) => {
              return (
                <div className="mood-item" key={index}>
                  <span
                    className="dot"
                    style={{
                      backgroundColor: mood_colors[value.mood],
                    }}></span>
                  <span
                    className="percent"
                    style={{ textTransform: 'capitalize' }}>
                    {value.percent}% {value.mood}
                  </span>
                  <span className="value">{value.reasons}</span>
                </div>
              );
            })}
          </div>
        </DoughnutContainer>
      </CardContent>
    </DashboardCard>
  );
};
