import React from 'react';
import styled from '@emotion/styled';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BookASessionCard from '../../shared/BookASessionCard';
import RelatedContents from '../../shared/RelatedContents';

const ArticleBody = styled.div`
  padding: 1rem;
  p {
    font-size: 0.9rem;
    line-height: 1.9rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  strong {
    font-size: 1.1rem;
    font-weight: bold;
  }
`;

const ArticleHeader = styled.div`
  background-color: #1db4b3;
  color: #fff;
  a {
    color: #fff;
    &:hover {
      text-decoration: none;
    }
  }
  h2,
  p {
    margin-left: 2rem;
  }
  h2 {
    -webkit-font-smoothing: antialiased;
    font-weight: 700;
  }
  @media (max-width: 575.98px) {
    margin-bottom: 1rem;
    a {
      color: #fff;
    }
    h2,
    p {
      margin-left: 1rem;
      font-size: 1rem;
    }
  }
`;

const StyledArticleImageCol = styled.div`
  height: 100%;
  padding: 20px;
  > div {
    height: 100%;
    width: 100%;
    border-radius: 4px;
  }
`;

const StyledReadMore = styled.a`
  background: #1db4b3;
  font-weight: 600;
  font-size: 0.8rem;
  border: 1px #1db4b3 solid;
  border-radius: 4px;
  color: #ffffff;
  padding: 10px 20px;
  &:hover {
    color: #ffffff;
    border: 1px #1db4b3 solid;
    background: #1db4b3;
  }
`;

const Article = ({ data, createMarkup, relatedContents }) => {
  return (
    <>
      <Row>
        <Col>
          <ArticleHeader className="rounded">
            <Row>
              <Col md={6}>
                <div className="d-flex flex-column justify-content-center">
                  <p style={{ fontSize: '14px' }} className="pt-3">
                    {data?.published_date}{' '}
                    {`${data?.author ? ` | by ${data?.author}` : ''}`}
                  </p>
                  <Link to={`/content/${data?.slug}`}>
                    <h2>{data?.title}</h2>
                  </Link>
                  <p style={{ fontSize: '14px' }} className="pt-2">
                    {`${
                      data?.estimated_time
                        ? `${data?.estimated_time} minute read`
                        : ''
                    }`}
                    {`${data?.source ? ` | ${data?.source}` : ''}`}
                  </p>
                </div>
              </Col>
              <Col md={6} className="d-none d-md-block">
                <StyledArticleImageCol className="d-flex justify-content-center align-items-center">
                  <img
                    className="w-100 rounded"
                    src={
                      data?.image_url ? data?.image_url : '/default_article.png'
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = '/default_article.png';
                    }}
                  />
                </StyledArticleImageCol>
              </Col>
            </Row>
          </ArticleHeader>
        </Col>
      </Row>
      <Row>
        <Col>
          <div>
            <ArticleBody>
              <div dangerouslySetInnerHTML={createMarkup(data)} />
            </ArticleBody>
            <div className="text-center">
              {data?.url && (
                <StyledReadMore
                  href={data?.url}
                  target="_blank"
                  className="btn mx-auto">
                  Read more
                </StyledReadMore>
              )}
            </div>
          </div>
        </Col>
      </Row>
      {relatedContents && (
        <Row>
          <Col>
            {relatedContents.length > 0 && (
              <RelatedContents relatedContents={relatedContents} />
            )}
          </Col>
        </Row>
      )}
      <Row>
        <Col>{data && <BookASessionCard />}</Col>
      </Row>
    </>
  );
};

export default Article;
