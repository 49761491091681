import axios from 'axios';

const getCSRFToken = () => {
  const token = document.querySelector('meta[name="csrf-token"]');
  if (token) {
    return token.getAttribute('content');
  }
  return null;
};

const Api = axios.create({
  headers: {
    'X-CSRF-Token': getCSRFToken(),
  },
});

export default Api;
