import Api from '../../Api';
import React from 'react';
import styled from '@emotion/styled';
import { Navbar, NavDropdown, Nav, Container } from 'react-bootstrap';
import { useAppData } from '../contexts/AppDataProvider';

const StyledProfileDropdown = styled(NavDropdown)`
  .dropdown-menu {
    right: 0 !important;
  }
  a {
    padding-right: 0;
  }
  a.nav-link {
    font-size: 0.8rem;
    &:hover {
      text-shadow: 0px 0px 1px white;
    }
  }
  a.nav-link.active {
    color: #1db4b3 !important;
  }
  a.nav-link:hover {
    color: #16181b;
    background-color: #f8f9fa;
  }
  #profile-nav-dropdown {
    color: white;
    background: #1db4b3;
    text-align: center;
    padding: 0.6em;
    border-radius: 5px;
    margin-left: 15px;
  }
`;

const StyledNavbarBrand = styled(Navbar.Brand)`
  .company-logo {
    max-height: 50px;
    margin-left: 20px;
    margin-right: 0px;
  }
  .logo-divider {
    border-left: 2px solid #00a2a1;
    margin-left: 20px;
  }
`;

const Navigation = () => {
  const {
    firstName,
    lastName,
    companyLogo,
  } = useAppData();

  const logout = async () => {
    await Api.delete('/api/v2/users/logout');
    window.location.href = '/';
  };

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <div>
          <StyledNavbarBrand>
            <img src="/logo.svg" />
            {companyLogo && <span className="logo-divider"></span>}
            {companyLogo && (
              <img
                src={companyLogo}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  let parentNode = currentTarget.parentNode;
                  parentNode.removeChild(currentTarget.previousElementSibling);
                  parentNode.removeChild(currentTarget);
                }}
                className="company-logo"
              />
            )}
          </StyledNavbarBrand>
        </div>
        <Navbar.Brand className="mr-0">
          <StyledProfileDropdown
            title={firstName[0] + lastName[0]}
            id="profile-nav-dropdown">
            <Nav.Link className="text-dark" onClick={logout}>
              Logout
            </Nav.Link>
          </StyledProfileDropdown>
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default Navigation;
