import React from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

const StyledContainer = styled.div`
  .podcast-detail {
    cursor: pointer;
    margin-bottom: 37px;
    display: flex;
    flex-flow: row nowrap;

    .podcast-thumbnail {
      margin-right: 16px;
      max-width: 120px;
      height: auto;

      img {
        border-radius: 5px;
      }
    }

    .podcast-detail-description {
      text-align: left;
      font-size: 14px;
      letter-spacing: 0px;

      .title {
        font-weight: 600;
        color: #333333;
        opacity: 1;
      }

      .description {
        color: #191919;
        opacity: 0.7;
      }

      .estimated-time {
        margin-left: 8px;
        font-size: 12px;
        color: #333333;
        opacity: 1;
      }

      .played {
        margin-left: 8px;
        font-size: 12px;
        color: #1db4b3;
        opacity: 1;
      }

      .icon-times {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .icon {
          padding-bottom: 8px;
        }

        .play {
          cursor: pointer;
          width: 40px;
          height: 40px;
          margin-right: 10px;
          border-radius: 30px;
          background: #1db4b3 0% 0% no-repeat padding-box;
          opacity: 1;
          text-align: center;
          position: relative;

          img {
            width: 20px;
            height: 20px;
            position: absolute;
            top: 50%;
            right: 30%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
`;

const PodcastList = ({ podcast }) => {
  const history = useHistory();

  return (
    <StyledContainer>
      <div
        className="podcast-detail"
        onClick={() => {
          history.push(`/podcasts/${podcast.slug}`);
        }}>
        <div className="podcast-thumbnail">
          <img src={podcast.thumbnail_url} alt="" />
        </div>
        <div className="podcast-detail-description">
          <div className="title">{podcast.title}</div>
          <div className="description">
            {podcast.description.length > 180
              ? podcast.description.slice(0, 180) + '...'
              : podcast.description}
          </div>
          <div className="icon-times">
            <label className="play">
              <img src="/podcast/podcast-play.svg" alt="" />
            </label>

            <img className="icon" src="/podcast/podcast-icon.svg" alt="" />
            <label className="estimated-time" htmlFor="">
              {podcast.estimated_time}
            </label>
            {podcast.play_status == 'played' && (
              <label className="played" htmlFor="">
                Played
              </label>
            )}
          </div>
        </div>
      </div>
    </StyledContainer>
  );
};

export default PodcastList;
