import React, { useState } from 'react';;
import { mutate } from 'swr';

const AddForm = ({setEditing }) => {
  const [dependent, setDependent] = useState({});
  const [errors, setErrors] = useState({});

  function handleOnChange(event) {
    const updatedAttribute = { [event.target.name]: event.target.value };
    setDependent({ ...dependent, ...updatedAttribute });
  }

  async function handleOnSubmit(event) {
    event.preventDefault();
    const payload = {
      user_dependent: {
        relationship: dependent?.relationship,
      },
      dependent: {
        first_name: dependent?.first_name,
        last_name: dependent?.last_name,
        email: dependent?.email,
      },
    };
    let formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    const res = await fetch('/api/v2/user_dependents', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    if (res.status === 200) {
      setEditing(false);
      mutate('/api/v2/user_dependents', [], true);
    } else {
      const { errors } = await res.json();
      if (!!errors) {
        setErrors(errors[0]);
      }
    }
  }

  const validateForm = () =>{
    let formErrors = {}
    if(!dependent?.relationship){
      formErrors = {...formErrors, relationship: ["can't be blank"]}
    }
    if (!dependent?.first_name) {
      formErrors = {...formErrors, first_name: ["can't be blank"]};
    }
    if (!dependent?.last_name) {
      formErrors = {...formErrors, last_name: ["can't be blank"]};
    }
    if (!dependent?.email) {
      formErrors = {...formErrors, email: ["can't be blank"] };
    }
    return formErrors;
  }

  return (
    <section className="col-sm-12 p-3">
      <div className="card-body">
        <h5 className="card-title">Add Dependent Information</h5>
        <form className="d-flex flex-column" onSubmit={handleOnSubmit}>
          <div className="mb-3">
            <label
              htmlFor="dependent[first_name]"
              className="form-label font-weight-bolder">
              {' '}
              First Name{' '}
            </label>
            <input
              id="dependent[first_name]"
              name="first_name"
              onChange={handleOnChange}
              className="form-control"
              style={{ height: 'auto' }}
              required
            />
            {errors.first_name && (
              <span className="small text-danger mt-2">
                {' '}
                {errors.first_name.join(', ')}{' '}
              </span>
            )}
          </div>
          <div className="mb-3">
            <label
              htmlFor="dependent[last_name]"
              className="form-label font-weight-bolder">
              {' '}
              Last Name{' '}
            </label>
            <input
              id="dependent[last_name]"
              name="last_name"
              onChange={handleOnChange}
              className="form-control"
              style={{ height: 'auto' }}
              required
            />
            {errors.last_name && (
              <span className="small text-danger mt-2">
                {' '}
                {errors.last_name.join(', ')}{' '}
              </span>
            )}
          </div>
          <div className="mb-3">
            <label
              htmlFor="dependent[email]"
              className="form-label font-weight-bolder">
              {' '}
              Email{' '}
            </label>
            <input
              id="dependent[email]"
              type="email"
              name="email"
              onChange={handleOnChange}
              className="form-control"
              required
            />
            {errors.email && (
              <span className="small text-danger mt-2">
                {' '}
                {errors.email.join(', ')}{' '}
              </span>
            )}
          </div>
          <div className="mb-3">
            <label
              htmlFor="dependent[relationship]"
              className="form-label font-weight-bolder">
              {' '}
              Relationship{' '}
            </label>
            <select
              id="dependent[relationship]"
              name="relationship"
              onChange={handleOnChange}
              className="form-control"
              required>
              <option value=""></option>
              <option value="Spouse">Spouse</option>
              <option value="Sibling">Sibling</option>
              <option value="Parent">Parent</option>
              <option value="Guardian">Guardian</option>
              <option value="Relative">Relative</option>
              <option value="Other">Other</option>
            </select>
            {errors.relationship && (
              <span className="small text-danger mt-2">
                {' '}
                {errors.relationship.join(', ')}{' '}
              </span>
            )}
          </div>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              onClick={() => setEditing(false)}
              style={{ height: 'fit-content' }}
              className="mt-auto btn btn-general bg-white text-dark border rounded mr-2">
              Cancel
            </button>
            <button
              type="submit"
              onClick={handleOnSubmit}
              className="btn-general rounded">
              Add
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default AddForm;