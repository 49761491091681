import React from 'react';
import DashboardCard from './DashboardCard';
import styled from '@emotion/styled';
import { Col, Row } from 'react-bootstrap';

export const ChatHelpLine = ({ data }) => {
  const title = 'Chat Helpline';

  if (data.length == 0 && !data.error) {
    <NoData title={title} />;
  }
  return (
    <DashboardCard title={'Chat Helpline'}>
      {!!data.error && <p className="text-danger">{data.error}</p>}
      {!data.error && (
        <>
          <Row className="mt-5">
            <Col className="text-center">
              <img src={'/incoming-chat-icon.svg'} />
              <p>Used chat helpline</p>
              <h4>{data?.data?.used_chat_helpline_count}</h4>
            </Col>
            <Col className="text-center">
              <img src={'/used-chat-icon.svg'} />
              <p>Incoming chats</p>
              <h4>{data?.data?.incoming_chat_count}</h4>
            </Col>
          </Row>
        </>
      )}
    </DashboardCard>
  );
};
