import styled from '@emotion/styled';

export const StyledDiv = styled.div`
  .container {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, #e3f2f6, #faf6f7);
      z-index: -1;
    }
    button {
      background: #1db4b3 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      margin-top: 50px !important;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0px;
      color: #ffffff;
      padding: 10px 40px 10px 40px;
      border: none;
      display: block;
      margin: 0 auto;
    }
    .error {
      border: solid 1px red;
    }
    .text-error {
      color: red;
    }
    .small-text {
      text-align: left;
      font-size: 12px;
      letter-spacing: 0px;
      color: #969696;
      opacity: 1;
    }
  }
  .background-image {
    width: 80%;
    height: auto;
    position: absolute;
    z-index: -1;
  }
  .company-setup-actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    .individual {
      flex-grow: 2;
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 0px;
      color: #1db4b3;
      opacity: 1;
      cursor: pointer;
      margin-top: 50px;
    }
    .sign-up {
      align-items: center;
    }
  }
  .verification-actions {
    margin-top: 50px;
    display: flex;
    flex-flow: row nowrap;
    .contact {
      flex-grow: 2;
    }
    .sign-up {
    }
    .skip {
      text-align: right;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      color: #1db4b3;
      opacity: 1;
      cursor: pointer;
    }
  }
`;

export const StyledHeader = styled.div`
  margin-top: 80px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
`;

export const FormContainer = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  margin: 30px 25% 30px 25%;
  padding: 40px;
  .form-header {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    margin-bottom: 30px;
  }
  label {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
  }
  input {
    border-radius: 5px;
    opacity: 1;
    height: 50px;
    margin-right: 10px;
    border: 1px solid #dddddd;
  }
  button {
    background: #1db4b3 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    margin-top: 50px !important;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #ffffff;
    padding: 10px 40px 10px 40px;
    border: none;
    display: block;
    margin: 0 auto;
  }
  .disabled {
    opacity: 0.7 !important;
  }
`;