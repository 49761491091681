import React, { useEffect, useState } from "react";
import Api from "../Api";

const usePreference = () => {
  const [preferenceState, setPackageState] = useState({
    loading: true,
    preferences: null
  });

  useEffect(() => {
    const fetchData = async () => {
      await Api.get("/api/v1/preferences", {}).then(res => {
        setPackageState({
          ...preferenceState,
          loading: false,
          preferences: res.data
        });
      });
    };
    fetchData();
  }, []);

  return [preferenceState];
};

export default usePreference;
