import React, { useState } from 'react'
import styled from '@emotion/styled';
import UISelect from '../shared/ui/Select';

const StyledSelect = styled.select`
  font-size: 11px;
  &.button-active{
    background-color: #EEFCCE;
  }
  &.button-resigned{
    background-color: #C1C1C1;
    color: #fff;
  }
`
const User = (props) => {
  const employmentStatuses = ['active', 'resigned']
  const [userData, setUserData] = useState(props.data)
  const userFullName = `${userData.first_name} ${userData.last_name}`

  async function changeEmploymentStatus(event) {
    const newStatus = event.target.value
    if(!confirm(`Are you sure you want to mark ${userFullName} as ${newStatus}?`)) return

    const payload = { company_user: { employment_status: newStatus } }
    const res = await fetch(`/api/v2/company_admin/company_users/${userData.id}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    })

    if (res.status === 200) {
      const data = await res.json()
      setUserData(data)
      alert(`Successfully set ${userFullName} as ${newStatus}`)
    } else {
      alert(`Failed to set ${userFullName} as ${newStatus}`)
    }
  }

	return (
    <tr>
      {props.companyPrefix ? <td>{userData.reference_number}</td> : null}
      <td>{userData.email}
        { userData.confirmed_email && (userData.email != userData.confirmed_email) ? (<><br/><small>(confirmed: {userData.confirmed_email})</small></>) : '' }</td>
      <td>{userData.first_name}</td>
      <td>{userData.last_name}</td>
      <td>{userData.confirmed_date}</td>
      <td>
        <StyledSelect value={userData.employment_status} className={`form-control ${userData.employment_status === 'active' ? 'button-active' : 'button-resigned'}`}
          onChange={changeEmploymentStatus}>
          {employmentStatuses.map((status, index) => (
            <option value={status} key={index}> {status.toUpperCase()} </option>
          ))}
        </StyledSelect>
      </td>
    </tr>
  )
}

export default User
