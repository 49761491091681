import React from 'react';
import DashboardCard from './DashboardCard';
import { CardElement } from '../../Content/Card';
import { Col, Row } from 'react-bootstrap';
import { NoData } from './NoData';
import './css-styles.css';
import PodcastCard from '../../Podcast/PodcastCard';

export const TopViewedContent = ({ contents }) => {
  const title = 'Top Viewed Content';
  const tooltipText = 'Most viewed content and resources';
  if (contents.length == 0) {
    return (
      <NoData title={title} withQuestionMark={true} tooltipText={tooltipText} />
    );
  }
  return (
    <DashboardCard
      title={title}
      withQuestionMark={true}
      tooltipText={tooltipText}
      minHeight={'98%'}>
      <Row>
        {contents.map((content, index) => (
          <Col xs={6} key={`content-${index}`} className="mt-4">
            {content?.play_status && (
              <PodcastCard key={index} withContent={false} podcast={content} />
            )}
            {!content?.podcast_url && (
              <CardElement key={index} withContent={false} data={content} />
            )}
          </Col>
        ))}
      </Row>
    </DashboardCard>
  );
};
