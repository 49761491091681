import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import Layout from '../Layout';
import Article from './Article';
import Video from './Video';
import Audio from './Audio/index';
import ArticleLoader from './Card/Loader';

const fetcher = (url) => axios.get(url).then((res) => res.data);

const Content = () => {
  let { slug } = useParams();

  const { data } = useSWR(`/api/v2/contents/${slug}`, fetcher);
  const { data: relatedContents } = useSWR(
    () => (data ? `/api/v2/contents/${slug}/related` : null),
    fetcher,
    { revalidateOnFocus: false },
  );
  function createMarkup(data) {
    return {
      __html: data?.body,
    };
  }

  useEffect(() => {
    axios.post(`/api/v2/consumed_contents`, { slug: slug });
  }, [slug]);

  return (
    <Layout>
      {!data && <ArticleLoader />}
      {data?.content_type == 'article' && (
        <Article
          data={data}
          relatedContents={relatedContents}
          createMarkup={createMarkup}
        />
      )}
      {data?.content_type == 'video' && (
        <Video
          data={data}
          relatedContents={relatedContents}
          createMarkup={createMarkup}
        />
      )}
      {data?.content_type == 'audio' && (
        <Audio
          data={data}
          relatedContents={relatedContents}
          createMarkup={createMarkup}
        />
      )}
    </Layout>
  );
};

export default Content;
