import React, { useState, useEffect } from 'react';
import DashboardCard from './DashboardCard';
import { NoData } from './NoData';
import { HorizontalChartContainer } from './styles';
import {
  getWindowDimensions,
  horizontalBarOptions,
} from './Recommendations';
import { Bar } from 'react-chartjs-2';

const WithData = ({ reasons, title, tooltipText }) => {
  const labels = reasons.map((value) => value.reason);
  const [data, setData] = useState(null);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    let barThickness = windowDimensions.width * 0.015;
    barThickness = barThickness > 16 ? 16 : barThickness;
    setData({
      labels,
      datasets: [
        {
          data: reasons.map((value) => value.count),
          backgroundColor: '#1DB4B3',
          borderRadius: 50,
          barThickness: barThickness,
        },
      ],
    });
  }, [windowDimensions]);

  return (
    <DashboardCard
      title={title}
      withQuestionMark={true}
      tooltipText={tooltipText}>
      {data && (
        <HorizontalChartContainer>
          <Bar
            options={horizontalBarOptions}
            data={data}
            className="mt-4"
            height={100}
          />
        </HorizontalChartContainer>
      )}
    </DashboardCard>
  );
};

export const Reasons = ({ reasons, title, tooltipText }) => {
  if (reasons.length == 0) {
    return (
      <NoData title={title} withQuestionMark={true} tooltipText={tooltipText} />
    );
  } else {
    return (
      <WithData reasons={reasons} title={title} tooltipText={tooltipText} />
    );
  }
};
