import React, { useState, useRef } from 'react';
import styles from './Otp.module.scss';

const OtpInput = ({ numInputs, onOtpChange }) => {
  const [otp, setOtp] = useState(new Array(numInputs).fill(''));
  const inputRefs = new Array(numInputs).fill(0).map((_, i) => useRef());

  const handleInputChange = (e, index) => {
    const value = e.target.value;

    if (!isNaN(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < numInputs - 1 && value) {
        inputRefs[index + 1].current.focus();
      }

      onOtpChange(newOtp.join(''));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  return (
    <div>
      {otp.map((digit, index) => (
        <input
          className={styles.otp_input}
          key={index}
          type="text"
          maxLength="1"
          value={digit}
          onChange={(e) => handleInputChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={inputRefs[index]}
        />
      ))}
    </div>
  );
};

export default OtpInput;