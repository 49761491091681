import useSWR from 'swr';
import axios from 'axios';
import styled from '@emotion/styled';
import { useHistory, Redirect } from 'react-router-dom';
import { Row, Button } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import Layout from '../Layout';
import Spinner from '../../Spinner';

import { useAppData } from '../contexts/AppDataProvider';

const StyledForm = styled.form`
  min-width: 500px;
  @media (max-width: 575.98px) {
    min-width: 300px;
  }
`;
const StyledTitle = styled.h4`
  font-weight: bold;
  margin-bottom: 1.9rem;
`;
const StyledDescription = styled.p`
  max-width: 760px;
  margin-bottom: 1.875rem;
  text-align: center;
  font-size: 0.875rem;
`;
const SubmittedContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  > h1 {
    font-size: 25px;
    margin-bottom: 25px;
    font-weight: bold;
  }
  > p {
    font-size: 20px;
    margin-bottom: 70px;
  }
`;
const StyledSumitButton = styled(Button)`
  background: #1db4b3 !important;
  color: white;
  padding: 15px 42px;
  font-weight: bold;
  border: 1px solid white;
  margin: 0px 0px 15px 0px;
  &:hover,
  &:focus {
    color: white !important;
    border: 1px solid white !important;
  }
`;

const fetcher = (url) => axios.get(url).then((res) => res.data);
const AssessmentUserForm = () => {
  const history = useHistory();
  const [userForm, setUserForm] = useState({});
  const [editing, setEditing] = useState(false);
  const [errors, setErrors] = useState({});
  const [employmentInfo, setEmploymentInfo] = useState(null);
  const { currentAssessment, setCurrentAssessment } = useAppData();
  const swrFetcher = (url, revalidate = false) =>
    useSWR(url, fetcher, {
      revalidateOnFocus: revalidate,
    });

  const { data: formOptions } = swrFetcher(
    '/api/v2/user_employment_information/form_options',
  );
  const { data: latestProfile } = swrFetcher('/api/v2/user_profile');
  const { data: latestEmploymentInfo } = swrFetcher(
    '/api/v2/user_employment_information',
  );

  function handleOnChange(event) {
    const updatedAttribute = { [event.target.name]: event.target.value };
    setUserForm({ ...userForm, ...updatedAttribute });
  }

  const callAssessment = async () => {
    await axios
      .get(`/api/v2/assessments/${currentAssessment.id}`)
      .then((res) => {
        setCurrentAssessment(res.data);
      });
  };

  async function handleOnSubmit(event) {
    setEditing(true);
    event.preventDefault();
    await axios
      .post('/api/v2/user_employment_information', {
        user_employment_information: {
          department: userForm.department,
          employment_level: userForm.employment_level,
          age_group: userForm.age_group,
          gender: userForm.gender,
          account: userForm.account,
        },
      })
      .then((res) => {
        if (res.status == 201) {
          setEditing(false);
          setEmploymentInfo(res);
        } else {
          const { errors } = res;
          setErrors(errors[0]);
        }
      });
  }

  function shouldRenderAccountsField() {
    const accountKeyExists = formOptions && 'accounts' in formOptions;
    return accountKeyExists && formOptions.accounts.length > 0;
  }

  useEffect(() => {
    if (!!latestProfile) {
      setUserForm({
        ...userForm,
        ...{
          age_group: latestProfile.age_group,
          gender: latestProfile.gender,
        },
      });
    }
  }, [latestProfile]);

  useEffect(() => {
    if (!!latestEmploymentInfo) {
      setUserForm({
        ...userForm,
        ...latestEmploymentInfo,
      });
    }
  }, [latestEmploymentInfo]);

  useEffect(() => {
    if (!!employmentInfo) {
      setTimeout(() => {
        callAssessment();
        history.push('/assessment/result');
      }, 2000);
    }
  }, [employmentInfo]);

  if (currentAssessment.done) {
    return <Redirect to="/assessment/result" />;
  } else if (!!employmentInfo) {
    return (
      <Layout>
        <Row className="justify-content-center h-100 m-0">
          <SubmittedContent>
            <h1>You have completed the assessment.</h1>
            <p>We’re now preparing your results.</p>
            <Spinner />
          </SubmittedContent>
        </Row>
      </Layout>
    );
  } else if (currentAssessment.missing_user_info) {
    return (
      <Layout>
        <Row className="justify-content-center h-100 m-0">
          <div className="d-flex justify-content-center flex-column align-items-center px-2">
            <StyledTitle className="text-center">
              Help us build a great place for you!
            </StyledTitle>
            <StyledDescription>
              Help your organization design better mental health interventions.
              Complete the information sheet below. Your responses, as well as
              the survey data, are secured and held in serious confidence.{' '}
              <br />
              <br />
              <strong>
                Please verify if the information below is correct before hitting
                Submit.
              </strong>
            </StyledDescription>
            <StyledForm
              id="user-employment-information-form"
              className="d-flex flex-column"
              onSubmit={handleOnSubmit}>
              {shouldRenderAccountsField() && (
                <div className="mb-4">
                  <label htmlFor="account" className="form-label">
                    My Account
                  </label>
                  <select
                    id="account"
                    name="account"
                    onChange={handleOnChange}
                    className="form-control"
                    required
                    value={userForm?.account || ''}>
                    <option value="">Select Account</option>
                    {formOptions?.accounts.map((account, index) => (
                      <option value={account} key={index}>
                        {account}
                      </option>
                    ))}
                  </select>
                  {errors.account && (
                    <span className="small text-danger mt-2">
                      {errors.account.join(', ')}
                    </span>
                  )}
                </div>
              )}
              {formOptions && 'departments' in formOptions && (
                <div className="mb-4">
                  <label htmlFor="department" className="form-label">
                    My Department
                  </label>
                  <select
                    id="department"
                    name="department"
                    onChange={handleOnChange}
                    className="form-control"
                    required
                    value={userForm?.department || ''}>
                    <option value="">Select department</option>
                    {formOptions?.departments.map((department, index) => (
                      <option value={department} key={index}>
                        {department}
                      </option>
                    ))}
                  </select>
                  {errors.department && (
                    <span className="small text-danger mt-2">
                      {errors.department.join(', ')}
                    </span>
                  )}
                </div>
              )}

              {formOptions && 'employment_levels' in formOptions && (
                <div className="mb-4">
                  <label htmlFor="employment_level" className="form-label">
                    My Work Level
                  </label>
                  <select
                    id="employment_level"
                    name="employment_level"
                    onChange={handleOnChange}
                    className="form-control"
                    value={userForm?.employment_level || ''}
                    required>
                    <option value="">Select work level</option>
                    {formOptions?.employment_levels.map(
                      (employment_level, index) => (
                        <option value={employment_level} key={index}>
                          {employment_level}
                        </option>
                      ),
                    )}
                  </select>
                  {errors.employment_level && (
                    <span className="small text-danger mt-2">
                      {errors.employment_level.join(', ')}
                    </span>
                  )}
                </div>
              )}

              <div className="mb-4">
                <label htmlFor="age_group" className="form-label">
                  My Age Group
                </label>
                <select
                  id="age_group"
                  name="age_group"
                  onChange={handleOnChange}
                  value={userForm?.age_group || ''}
                  className="form-control"
                  required>
                  <option value="">Select age group</option>
                  {formOptions?.age_groups.map((age_group, index) => (
                    <option value={age_group} key={index}>
                      {age_group}
                    </option>
                  ))}
                </select>
                {errors.age_group && (
                  <span className="small text-danger mt-2">
                    {errors.age_group.join(', ')}
                  </span>
                )}
              </div>

              <div className="mb-4">
                <label htmlFor="userForm[gender]" className="form-label">
                  My Gender
                </label>
                <div className="d-flex justify-content-around">
                  {formOptions?.genders.map((gender, index) => (
                    <div key={index}>
                      <input
                        type="radio"
                        id={`gender-${index}`}
                        name="gender"
                        value={gender}
                        checked={userForm?.gender === gender}
                        onChange={handleOnChange}
                        required
                      />
                      <label htmlFor={`gender-${index}`} className="px-2 m-0">
                        {gender}
                      </label>
                    </div>
                  ))}
                </div>

                {errors.gender && (
                  <span className="small text-danger mt-2">
                    {errors.gender.join(', ')}
                  </span>
                )}
              </div>
              <div className="text-center">
                <StyledSumitButton type="submit" disabled={editing == true}>
                  Submit
                </StyledSumitButton>
                <p>
                  <a
                    href="/privacy-policy"
                    className="mt-4 text-green"
                    target="_blank">
                    Privacy Policy
                  </a>
                </p>
              </div>
            </StyledForm>
          </div>
        </Row>
      </Layout>
    );
  }
};

export default AssessmentUserForm;
