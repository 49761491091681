import React from "react";
import { cx, css, keyframes } from "emotion";
import pluralize from "pluralize";

const cardsFadeIn = keyframes({
  "0%": {
    opacity: 0,
    backgroundColor: "lightgray"
  },
  "100%": {
    opacity: 1,
    backgroundColor: "white"
  }
});

const numberWithCommas = numberToFormat => {
  return numberToFormat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const PricingPackage = ({
  packageDetail,
  discountedPrice = null,
  children
}) => {
  return (
    <div
      className={cx(
        'card packages mb-4 px-3 shadow-sm',
        packageDetail.name == 'Starter' &&
          css`
            border-top: 3px solid #4db5b4;
          `,
        packageDetail.name == 'Basic' &&
          css`
            border-top: 3px solid #ffc56f;
          `,
        packageDetail.name == 'Plus' &&
          css`
            border-top: 3px solid #e98481;
          `,
        !(['Plus', 'Starter', 'Basic']).includes(packageDetail.name) &&
          css`
            border-top: 3px solid #4db5b4;
          `,
        css`
          flex: none !important;
          animation-duration: 250ms;
          animation: ${cardsFadeIn};
          &:hover {
            box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
            transition-duration: 1000ms;
          }
        `,
      )}>
      <div className={cx('card-body d-flex flex-column align-items-center')}>
        {packageDetail.name == 'Basic' ? (
          <div>
            <i
              className={cx(
                'fa fa-star',
                css`
                  color: #ffc56f;
                `,
              )}></i>{' '}
            Recommended
          </div>
        ) : (
          <div>&nbsp;</div>
        )}
        <h3
          className={cx(
            'text-center',
            css`
              padding: 20px 0 10px 0;
            `,
          )}>
          {packageDetail.sessions == 1
            ? 'Care Starter'
            : `Care ${packageDetail.name}`}
        </h3>
        {discountedPrice ? (
          <>
            <h6
              className={cx(
                'text-underline card-title text-center',
                css`
                  margin: 1rem 0 0.5rem 0;
                `,
              )}>
              <strike className="text-danger font-weight-bold">
                {`P ${numberWithCommas(parseInt(packageDetail.price))}`}
              </strike>
            </h6>
            <h3
              className={cx(
                'font-weight-bold card-title text-center',
                css`
                  margin: 1rem 0 0.5rem 0;
                `,
              )}>
              {`P ${numberWithCommas(parseInt(discountedPrice))}`}
            </h3>
          </>
        ) : (
          <h3
            className={cx(
              'font-weight-bold card-title text-center',
              css`
                margin: 1rem 0 0.5rem 0;
              `,
            )}>
            {`P ${numberWithCommas(parseInt(packageDetail.price))}`}
          </h3>
        )}
        <p>{packageDetail.description}</p>
        <h4 className="font-weight-bold card-title text-center">
          {packageDetail.sessions}
          <span
            className={cx(
              'font-weight-normal',
              css`
                font-size: 1.2rem;
                color: #a3a9ab;
              `,
            )}>
            {' '}
            {pluralize('session', packageDetail.sessions)}
          </span>
        </h4>
        <div className={cx('mt-auto')}>{children}</div>
      </div>
    </div>
  );
};

export default PricingPackage;
