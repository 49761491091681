import React, { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import styled from '@emotion/styled';
import axios from 'axios';

const StyledImage = styled(Image)`
  width: 83px;
`;
const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  h5 {
    font-size: 17px;
    font-weight: bold;
    margin-top: 30px;
  }
`;

const AccountDeleted = () => {
  useEffect(() => {
    setTimeout(() => {
      axios
        .delete(`/api/v2/user_accounts`)
        .then((res) => {
          window.location.href = '/';
        })
        .catch((err) => {
          window.location.href = '/';
        });
    }, 1000);
  }, []);

  return (
    <StyledContainer>
      <StyledImage src="/logo.png" />
      <h5 className="card-title">Account Successfully Deleted</h5>
    </StyledContainer>
  );
};

export default AccountDeleted;
