import React from "react";
import { Link } from "react-router-dom";
import { css, cx } from "emotion";

const Button = ({ packageDetail, children }) => (
  <button
    type="button"
    className={cx(
      "btn btn-lg",
      packageDetail.name == "Starter" &&
        css`
          background-color: #4db5b4;
          color: #fff;
          border: 0;
          &:hover,
          &:active,
          &:focus {
            background: #4db5b4;
            color: #fff;
          }
        `,
      packageDetail.name == "Basic" &&
        css`
          background-color: #ffc56f;
          color: #fff;
          border: 0;
          &:hover,
          &:active,
          &:focus {
            background: #ffc56f;
            color: #fff;
          }
        `,
      packageDetail.name == "Plus" &&
        css`
          background-color: #e98481;
          color: #fff;
          border: 0;
          &:hover,
          &:active,
          &:focus {
            background: #e98481;
            color: #fff;
          }
        `,
      !["Starter", "Basic", "Plus"].includes(packageDetail.name) &&
        css`
          background-color: #4db5b4;
          color: #fff;
          border: 0;
          &:hover,
          &:active,
          &:focus {
            background: #4db5b4;
            color: #fff;
          }
        `,
    )}
  >
    {children}
  </button>
);

export default ({ packageDetail, submitLink }) => (
  <Link
    className="text-decoration-none"
    to={`/dashboard/payments/${packageDetail.slug.toLowerCase()}`}
  >
    <Button packageDetail={packageDetail}>
      {submitLink == "pay" ? "Sign Up Now!" : "Sign up"}
    </Button>
  </Link>
);
