import React from 'react';
import useSWR from 'swr';
import styles from './Counselors.module.scss';
import { Container, Row, Col } from 'react-bootstrap';
import ProfessionalCard from './ProfessionalCard';
import PublicLayout from '../PublicLayout';

const Counselors = () => {
  const {
    data,
  } = useSWR(`/api/v2/professionals?featured=true`, {
    revalidateOnFocus: false,
  });

  return (
    <PublicLayout>
      <Container className={styles.counselors_container}>
        <div className={styles.hero}>
          <Row>
            <Col lg="6" sm="12">
              <h2 className={styles.header}>
                Access a network of expertise & cares
              </h2>
              <p className={styles.description}>
                At Infinit Care, we understand that life's journey can be filled
                with challenges, obstacles, and opportunities for personal
                growth. Our team of experienced counselors and coaches is here
                to guide you through life's ups and downs, providing support,
                empowerment, and personalized strategies to help you thrive.
                Whether you're seeking professional counseling to address
                emotional difficulties or looking for a coach to unlock your
                full potential, we have the expertise to assist you on your path
                to success and well-being.
              </p>
            </Col>
            <Col lg="6" sm="12">
              <div className={styles.image_container}>
                <img
                  src="/counselors/expertise_care.png"
                  alt="Expertise Care"
                />
              </div>
            </Col>
          </Row>
        </div>
        <h1 className={styles.professional_heading}>
          Meet some of our psychologists, counselors, and coaches
        </h1>
        <p className={styles.professional_description}>
          Our curated network includes providers who are licensed counselors and
          psychologists and have been practicing for at least 5 years
        </p>
        <h4 className={styles.professional_sub_heading}>Counselors</h4>
        <Row>
          {data?.counselors?.map((counselor, index) => {
            return (
              <ProfessionalCard
                professional={counselor}
                key={index}
                type={'counselor'}
              />
            );
          })}
        </Row>
        <h4 className={styles.professional_sub_heading}>Coaches</h4>
        <Row>
          {data?.coaches?.map((coach, index) => {
            return (
              <ProfessionalCard
                professional={coach}
                key={index}
                type={'coach'}
              />
            );
          })}
        </Row>
      </Container>
    </PublicLayout>
  );
};

export default Counselors;
