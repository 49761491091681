import React, { useState, useEffect } from 'react';
import { Container, FormGroup, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import {
  StyledDiv,
  StyledHeader,
  FormContainer,
} from '../../shared/styles/RegistrationStyle';
import Terms from '../../shared/Terms';
import PublicLayout from '../PublicLayout';
import styled from '@emotion/styled';

const StyledTerms = styled.a`
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #1DB4B3;
  opacity: 1;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: #1DB4B3;
  }
`
const SetupAccount = () => {
  const background = '/otp/background.svg';
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    mobile_number: '',
    email: '',
    password: '',
  });
  const [show, setShow] = useState(false);
  const [disabledSignup, setDisabledSignup] = useState(true);
  const [agree, setAgree] = useState(false);

  useEffect(() => {
    if (location.skip) {
      setShow(true);
    }
  }, [location.skip]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    const allFieldsHaveValues = Object.values(formData).every(
      (val) => val !== '',
    );

    // Set disabledSignup based on the condition
    setDisabledSignup(!allFieldsHaveValues);
  };

  const handleClose = () => {
    setShow(false);
    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (agree) {
      try {
        const res = await axios.post('/api/v2/users', {
          user: formData,
        });
        if (res.status === 200) {
          setErrors({});
          setLoading(false);
        }
      } catch (_err) {
        if (_err.response.data.errors) {
          setErrors(_err['response']['data']['errors'][0]);
        } else if (_err.response.data) {
          setErrors(_err['response']['data']);
        }

        setLoading(false);
      }
    }
  };

  return (
    <PublicLayout>
      <StyledDiv>
        <Terms
          show={show}
          handleClose={handleClose}
          agree={agree}
          setAgree={setAgree}
        />
        <Container>
          <StyledHeader>Set up your Infinit Care account</StyledHeader>
          <img src={background} alt="" className="background-image" />
          <form>
            <FormContainer>
              <FormGroup>
                <label htmlFor="first_name">First Name</label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  placeholder="Enter your first name"
                  className="form-control"
                  onChange={handleInputChange}
                  value={formData.first_name}
                  required
                />
              </FormGroup>

              <FormGroup>
                <label htmlFor="last_name">Last Name</label>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  placeholder="Enter your last name"
                  className="form-control"
                  onChange={handleInputChange}
                  value={formData.last_name}
                  required
                />
              </FormGroup>

              <FormGroup>
                <label htmlFor="mobile_number">Mobile Number</label>
                <input
                  type="text"
                  id="mobile_number"
                  name="mobile_number"
                  placeholder="Enter your mobile number"
                  className="form-control"
                  onChange={handleInputChange}
                  value={formData.mobile_number}
                  required
                />
              </FormGroup>

              <FormGroup>
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  className={
                    errors.email ? 'error form-control' : 'form-control'
                  }
                  onChange={handleInputChange}
                  value={formData.email}
                  required
                />
                {errors.email &&
                  Object.entries(errors.email).map(([field, fieldErrors]) => (
                    <small className="text-error" key={field}>
                      {fieldErrors}
                    </small>
                  ))}
              </FormGroup>

              <FormGroup>
                <label htmlFor="password">
                  Setup a password&nbsp;
                  <small className="small">
                    (8 characters long with 1 number and 1 letter)
                  </small>
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Enter new password"
                  className={
                    errors.password ? 'error form-control' : 'form-control'
                  }
                  onChange={handleInputChange}
                  value={formData.password}
                  required
                />
                {errors.password &&
                  Object.entries(errors.password).map(
                    ([field, fieldErrors]) => (
                      <small className="text-error" key={field}>
                        {fieldErrors}
                      </small>
                    ),
                  )}
              </FormGroup>

              <FormGroup>
                <StyledTerms
                  onClick={() => setShow(true)}>
                  Terms & Conditions / Privacy Policy
                </StyledTerms>
              </FormGroup>
              {loading && (
                <button type="submit">
                  <Spinner animation="border" role="status" size="sm" />
                </button>
              )}
              {!loading && (
                <button
                  style={{
                    opacity: disabledSignup || agree == false ? '0.5' : '1',
                  }}
                  type="button"
                  onClick={handleSubmit}
                  disabled={disabledSignup}>
                  Sign up
                </button>
              )}
            </FormContainer>
          </form>
        </Container>
      </StyledDiv>
    </PublicLayout>
  );
};

export default SetupAccount;
