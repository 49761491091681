import React from 'react';
import styled from '@emotion/styled';

const StyledContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 50px;
  position: relative;
  background-color: #fbb13d;
  background-image: url('/podcast/podcast-vector.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 65% center;
  padding: 1.5rem 1.875rem;
  border-radius: 5px;
  overflow: hidden;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 575.98px) {
    display: block;
    text-align: center;
  }
  h6 {
    font-size: 1rem;
    font-weight: 500;
  }
  p {
    font-size: 0.875rem;
    margin-bottom: 0px;
    -webkit-font-smoothing: antialiased;
  }
  button {
    background-color: white;
    color: #1db4b3;
    height: 45px;
    font-size: 0.875rem;
    border: none;
    font-weight: bold;
    border-radius: 5px;
    padding-left: 15px;
    padding-right: 15px;
    @media (max-width: 767.98px) {
      font-size: 12px;
    }
    @media (max-width: 575.98px) {
      margin-top: 10px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
    }
  }
`;

const PodcastDashboard = () => {
  return (
    <StyledContainer>
      <div>
        <h6>Listen, learn & transform your mental well-being</h6>
        <p>Check out our brand-new podcast section!</p>
      </div>
      <button
        onClick={() => {
          window.location.href = '/explore?view=podcast';
        }}>
        View all Podcasts
      </button>
    </StyledContainer>
  );
};

export default PodcastDashboard;
