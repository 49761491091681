import React from 'react';
import { Col } from 'react-bootstrap';
import { UIButton } from '../shared/ui';

const VerifiedScreen = () => {
  return (
    <>
      <Col sm={{ span: 8, offset: 2 }} className="text-center">
        <section className="m-5">
          <h6 className="mb-4" style={{ fontWeight: '600', fontSize: '18px' }}>
            Update your email
          </h6>
          <p>You have successfully updated your email.</p>
          <p>
            Thank you for your cooperation in keeping your account information
            up-to-date.
          </p>
        </section>
        <UIButton
          onClick={() => { window.location.href = '/' }}
          style={{ width: '250px' }}
          text={'Go Back Home'}></UIButton>
      </Col>
    </>
  );
};

export default VerifiedScreen;
