import styled from '@emotion/styled';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { QuestionMark, StyledCard, Title, ViewAll, Header } from './styles';

const StyledToolTip = styled(Tooltip)`
  .arrow::before {
    display: none;
  }
  .tooltip-inner {
    font-size: 12px;
    background-color: #f0eaea !important;
    color: black;
    max-width: 350px;
    white-space: pre-line;
  }
`;

const DashboardCard = ({
  children,
  title,
  withViewAll = false,
  withQuestionMark = false,
  linkUrl = '',
  minHeight = '125px',
  tooltipText = '',
  titleFontSize='16px'
}) => {
  const renderTooltip = (props) => (
    <StyledToolTip
      id="button-tooltip"
      {...props}
      >
      {tooltipText}
    </StyledToolTip>
  );

  return (
    <StyledCard style={{ minHeight: minHeight }}>
      <Header>
        <Title style={{ fontSize: titleFontSize }}>
          {title}

          {withQuestionMark && (
            <OverlayTrigger overlay={renderTooltip}>
              <QuestionMark src="/question_mark.png" />
            </OverlayTrigger>
          )}
        </Title>
        {withViewAll && <ViewAll to={linkUrl}>View All</ViewAll>}
      </Header>
      {children}
    </StyledCard>
  );
};

export default DashboardCard;
