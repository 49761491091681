import qs from 'query-string';
import styled from '@emotion/styled';
import { Row, Col } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Layout from '../Layout';
import styles from './styles.module.scss';
import Sessions from '../Dashboard/Sessions';
import WebinarCardFullWidth from './Card/WebinarCardFullWidth';
const SeeMoreButton = styled.button`
  background: #1db4b30d 0% 0% no-repeat padding-box;
`;

export const StyledCol = styled(Col)`
  @media (min-width: 992px) {
    padding-left: 3rem;
  }

  @media (max-width: 575.98px) {
    padding-left: 15px;
  }
`;

export const StyledEvents = styled.h4`
  color: #1db4b3;
  font-weight: bold;
  font-size: 24px;
  margin: 0px;
  margin-top: 4px;
`;
const WebinarLoader = () => {
  return (
    <ContentLoader width="100%" height="250">
      <rect x="0" y="0" rx="2" ry="2" width="100%" height="103" />
      <rect x="0" y="123" rx="2" ry="2" width="100%" height="50" />
      <rect x="50%" y="193" rx="2" ry="2" width="50%" height="20" />
    </ContentLoader>
  );
};

export default () => {
  const events_type = new URLSearchParams(useLocation().search).get(
    'events_type',
  );
  const [currentWebinars, setCurrentWebinars] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [canSeeMore, setCanSeeMore] = useState(false);
  const [upcoming, setUpcoming] = useState(
    events_type == null || events_type == 'upcoming',
  );
  const [states, setStates] = useState({
    loading: false,
  });

  const [initialLoad, setInitialLoad] = useState(true);

  async function fetchWebinars(page) {
    const queryString = qs.stringify({
      upcoming: upcoming,
      page: page,
      per_page: 2,
    });
    return await fetch(`/api/v2/webinars?${queryString}`);
  }

  function handleSeeMore() {
    setCurrentPage(currentPage + 1);
  }

  async function concatenateWebinarsFromCurrentPage() {
    setStates({ ...states, loading: true });
    const res = await fetchWebinars(currentPage);

    if (res.status === 200) {
      const data = await res.json();

      const { webinars, pagy } = data;

      if (initialLoad && webinars.length == 0) {
        setInitialLoad(false);
        setUpcoming(false);
        return;
      } else if (initialLoad) {
        setInitialLoad(false);
      }
      setStates({
        loading: false,
      });

      if (currentPage >= pagy.last) {
        setCanSeeMore(false);
      } else {
        setCanSeeMore(true);
      }
      if (currentPage === 1) {
        setCurrentWebinars(webinars);
      } else {
        setCurrentWebinars(currentWebinars.concat(webinars));
      }
    }
  }

  useEffect(() => {
    if (currentPage != 0) concatenateWebinarsFromCurrentPage();
  }, [currentPage]);

  useEffect(() => {
    if (currentPage != 1) {
      setCurrentPage(1);
    } else {
      concatenateWebinarsFromCurrentPage();
    }
  }, [upcoming]);

  return (
    <Layout>
      <Row>
        <Sessions />
        <StyledCol lg={10} sm={12}>
          <StyledEvents> Events </StyledEvents>
          <nav className={styles.webinar_nav}>
            <ul>
              <li
                onClick={() => setUpcoming(true)}
                className={upcoming == true ? styles.li_active : ''}>
                Upcoming
              </li>
              <li
                onClick={() => setUpcoming(false)}
                className={upcoming == false ? styles.li_active : ''}>
                Past
              </li>
            </ul>
            <hr></hr>
          </nav>
          <Row>
            {states.loading && currentWebinars.length == 0 && (
              <>
                <Col lg={6}>
                  <WebinarLoader />
                </Col>
                <Col lg={6}>
                  <WebinarLoader />
                </Col>
              </>
            )}
            {currentWebinars
              ? currentWebinars?.map((webinar, index) => (
                  <Col lg={12} key={index}>
                    <WebinarCardFullWidth webinar={webinar} />
                  </Col>
                ))
              : []}
            {!states.loading && currentWebinars.length == 0 && (
              <Col>
                <p> No Available Webinars </p>
              </Col>
            )}
            {canSeeMore && (
              <SeeMoreButton
                onClick={handleSeeMore}
                className="text-green mx-auto p-2 w-100 border-0">
                See more
              </SeeMoreButton>
            )}
          </Row>
        </StyledCol>
      </Row>
    </Layout>
  );
};
