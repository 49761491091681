import React, { useState, useEffect } from 'react';
import { useBookSessionData } from '../contexts/BookSessionDataProvider';
import { UIBackButton } from '../shared/ui/Back';
import { StyledHeader, Selection, StyledUIButton } from './Coaching/styles';
import { Col, Row } from 'react-bootstrap';

const OutOfSessionReason = () => {
  const [outOfSessionReason, setOutOfSessionReason] = useState(null);
  const [reasons, setReasons] = useState([]);
  const { setCurrentStep, selectedSession } = useBookSessionData();

  useEffect(() => {
    getValidReasons();
  }, []);

  async function getValidReasons() {
    const res = await fetch(
      `/api/v2/meeting_sessions_requests/reasons?purpose=${selectedSession}`,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
    if (res.status == 200) {
      const response = await res.json();
      setReasons(response);
    }
  }

  const sendRequest = async () => {
    const payload = {
      meeting_sessions_request: {
        reason: outOfSessionReason,
        session_type: selectedSession,
      },
    };

    const res = await fetch('/api/v2/meeting_sessions_requests', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(payload),
    });

    if (res.status == 200) {
      setCurrentStep('out_of_session_submitted');
    }
  };

  return (
    <>
      <UIBackButton onClick={() => setCurrentStep('out_of_session')} />
      <StyledHeader>
        <p className="book-session">BOOK A SESSION</p>
        <h3>What is your reason?</h3>
        {selectedSession == 'coaching' && (
          <p className="mb-4">
            State the reason of your request. <br /> I would like to request for
            a coaching session and talk to a coach because:
          </p>
        )}
        {selectedSession == 'counseling' && (
          <p className="mb-4">
            State the reason of your request. <br /> I would like to request to
            be granted a counseling and talk to a mental health professional due
            to
          </p>
        )}
      </StyledHeader>
      <Row className="justify-content-center m-0 mt-4">
        <Row>
          {reasons.map((reason, index) => (
            <Col
              lg={
                reasons.length == index + 1 && reasons.length % 2 == 1 ? 12 : 6
              }
              md={12}
              key={index}>
              <Selection
                key={index}
                className={`${reason == outOfSessionReason ? 'active' : ''}`}
                onClick={() => setOutOfSessionReason(reason)}>
                {reason}
              </Selection>
            </Col>
          ))}
        </Row>
      </Row>
      <Row>
        <StyledUIButton
          text="Submit"
          className={`mt-2 ${!outOfSessionReason ? 'disabled' : ''}`}
          disabled={!outOfSessionReason}
          action={() => sendRequest()}></StyledUIButton>
      </Row>
    </>
  );
};
export default OutOfSessionReason;
