import React from 'react';
import styled from '@emotion/styled';
import { Container } from 'react-bootstrap';
import Footer from '../shared/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../globals.scss';
import PublicNavigation from './PublicNavigation';

const ContainerWrapper = styled(Container)`
  margin-top: 2.5rem;
`;

const PublicLayout = ({ children}) => {
  return (
    <>
      <PublicNavigation />
      <ContainerWrapper>{children}</ContainerWrapper>
      <Footer />
    </>
  );
};

export default PublicLayout;
