import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import Layout from './Layout'
import { useAppData } from './contexts/AppDataProvider'


const SalaryDeductionForm = () => {
  const { firstName, lastName } = useAppData()
  const userFullName = firstName + " " + lastName
  const currentDate = moment().format('MMMM D, YYYY')

  const [authorityToDeductHTML, setHTML] = useState('')

  useEffect(() => {
    const fetchAuthorityToDeductHTML = async () => {
      const res = await fetch('/api/v2/companies/authority_to_deduct', {
        headers: {'Content-Type': 'application/json'}
      })
      const { authority_to_deduct_copy } = await res.json()
      setHTML(authority_to_deduct_copy)
    }
    fetchAuthorityToDeductHTML()
  }, [])


  async function handleOnSubmit(event) {
    event.preventDefault()
    const params = { "company_user": { "agreed_to_salary_deduction": true } }
    const res = await fetch('/api/v2/company_users/update', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params)
    })
    if (res.status === 200) {
      window.location = '/book_session';
    }
  }

  return (
    <Layout className='bg-light'>
      <article className='mx-auto col-lg-12 p-0 shadow'>
        <section>
          <div className='p-4 bg-green'></div>
          <h4 className='text-center mt-4 font-weight-bold'> Authority to Deduct </h4>
        </section>
        <section className='p-4' dangerouslySetInnerHTML={{__html: authorityToDeductHTML}}></section>
        <section className='p-4'>
          <h6 className='font-weight-bold'>Employee Acknowledgement:</h6>
          <p> Name: { userFullName } <br/> Date: { currentDate } </p>
          
          <form onSubmit={handleOnSubmit}>
            <div>
              <Link to="/" className='btn btn-white mr-2 border'> Cancel </Link>
              <input type="submit" className='btn btn-pink' value="I agree" />
            </div>
          </form>
        </section>
      </article>
    </Layout>
  )
}

export default SalaryDeductionForm
