import React from 'react';
import styled from '@emotion/styled';
import Track from './Track';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const StyledSubmitButton = styled(Button)`
  background: #1db4b3;
  font-weight: 600;
  font-size: 0.8rem;
  width: 100%;
  margin-bottom: 1rem;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px #1db4b3 solid;
  &:hover,
  &:focus {
    border: 1px #1db4b3 solid !important;
    background: #1db4b3 !important;
    color: white !important;
  }
`;

const BookASessionButton = ({ page, url = '/book_session' }) => {
  const history = useHistory();

  const bookSession = () => {
    Track.bookASession(page);
    if (
      url == '/individual/dashboard/packages' ||
      window.location.href.includes('/individual/')
    ) {
      window.location.href = url;
    } else {
      history.push(url);
    }
  };
  return (
    <StyledSubmitButton onClick={bookSession}>
      Book a session
    </StyledSubmitButton>
  );
};

export default BookASessionButton;
