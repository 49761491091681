import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Layout from '../Layout';
import useSWR from 'swr';
import { Row, Col, Image } from 'react-bootstrap';
import { useAppData } from '../contexts/AppDataProvider';
import styled from '@emotion/styled';
import ReactPaginate from 'react-paginate';
import User from './User';
import qs from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowAltUp,
  faLongArrowAltDown,
} from '@fortawesome/free-solid-svg-icons';
import UISelect, { CalendarUISelect } from '../shared/ui/Select';
import DragAndDrop from './DragAndDrop';
import * as moment from 'moment';
import { StyledButton } from '../shared/ui/Button';
import useDebounce from '../shared/hooks/useDebounce';
import ExportModal from './ExportModal';
import CustomDateModal from './CustomDateModal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { format, subQuarters, startOfQuarter, endOfQuarter } from 'date-fns';

const StyledHeading = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  color: #1db4b3;
  margin-bottom: 2rem;
`;

const SortButton = styled.button`
  background: transparent;
  border: transparent;
`;

const SelectContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  justify-content: flex-end;
  display: flex;
  gap: 10px;
`;

const SearchWrapper = styled.div`
  width: fit-content;
  padding: 5px;
  input {
    font-size: 13px;
  }
  input::placeholder,
  span.btn {
    color: #c1c1c1;
  }
  img {
    width: 100%;
    padding-left: 14px;
  }
  @media (max-width: 991.98px) {
    margin-top: 20px;
  }
`;

const StyledTable = styled.table`
  border: none;
  th {
    font-weight: normal;
    text-transform: uppercase;
    font-size: 12px;
    button {
      text-transform: uppercase;
    }
  }
  th,
  td,
  tr {
    border: none !important;
  }
  tbody tr:nth-child(odd) {
    background-color: #f8f9fa;
  }
  td {
    vertical-align: middle;
  }
`;

const StyledTabsRow = styled(Row)`
  .nav-item {
    color: #1db4b3;
  }
  .active {
    background-color: #1db4b3 !important;
    color: white !important;
  }
  .nav-tabs {
    width: 100%;
  }
`;

const CompanyUsers = () => {
  const defaultSiteId = qs.parse(location.search)['site_id'];
  const defaultCompanyId = qs.parse(location.search)['company_id'];
  const { companyID, companyName, companyPrefix, companyCsManager } =
    useAppData();
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showCustomDateModal, setShowCustomDateModal] = useState(false);
  const [currentUISelectAction, setCurrentUISelectAction] = useState(null);
  const [filteredCompanyId, setFilteredCompanyId] = useState(
    defaultCompanyId || companyID,
  );
  const [siteId, setSiteId] = useState(null);
  const [keyWord, setKeyWord] = useState();
  const debouncedSearchTerm = useDebounce(keyWord, 500);
  const [activeTab, setActiveTab] = useState('confirmed');

  const dateConfirmedSelections = [
    { text: 'Last Quarter', key: 'last_quarter' },
    { text: 'All', key: 'all' },
    { text: 'Custom', key: 'custom' },
  ];
  const [currentDateSelectAction, setCurrentDateSelectAction] = useState(
    dateConfirmedSelections[1],
  );

  useEffect(() => {
    setQuery({ ...query, query: keyWord });
  }, [debouncedSearchTerm]);

  const [customDate, setCustomDate] = useState({
    start: moment().startOf('month'),
    end: moment().endOf('month'),
  });

  const { data: site_data } = useSWR(
    companyCsManager
      ? `/api/v2/company_admin/cs_managers/sites?company_id=${filteredCompanyId}`
      : null,
    { revalidateOnFocus: false },
  );

  const { data: handledCompanies } = useSWR(
    companyCsManager ? '/api/v2/company_admin/cs_managers/companies' : null,
    { revalidateOnFocus: false },
  );

  useEffect(() => {
    if (handledCompanies?.length > 0) {
      setFilteredCompanyId(defaultCompanyId || handledCompanies[0].id);
    }
  }, [handledCompanies]);

  useEffect(() => {
    if (site_data?.length > 0) {
      setSiteId(defaultSiteId || site_data[0].id);
    }
  }, [site_data]);

  useEffect(() => {
    getData();
  }, [currentPage]);

  useEffect(() => {
    if (currentPage === 1) {
      getData();
    } else {
      setCurrentPage(1);
    }
  }, [query, filteredCompanyId, siteId, currentDateSelectAction, activeTab]);

  useEffect(() => {
    if (!showModal) {
      setData([]);
      getData();
    }
  }, [showModal]);

  useEffect(() => {
    setQuery({ ...query, query: keyWord });
  }, [debouncedSearchTerm]);

  const getData = async () => {
    let date_start = null;
    let date_end = null;

    if (currentDateSelectAction?.key === 'custom') {
      date_start = customDate.start.format('MMM D Y');
      date_end = customDate.end.format('MMM D Y');
    } else if (currentDateSelectAction?.key === 'last_quarter') {
      const currentDate = new Date();
      const lastQuarterDate = startOfQuarter(subQuarters(currentDate, 1));
      const lastQuarterEndDate = endOfQuarter(subQuarters(currentDate, 1));
      date_start = format(lastQuarterDate, 'MMM d yyyy');
      date_end = format(lastQuarterEndDate, 'MMM d yyyy');
    }
    const searchString = qs.stringify({
      ...query,
      page: currentPage,
      site_id: siteId,
      company_id: filteredCompanyId,
      date_start,
      date_end,
      status: activeTab,
    });
    const res = await axios.get(
      `/api/v2/company_admin/company_users?${searchString}`,
    );
    setData(res.data.data);
    setPageCount(res.data.pagy.last);
  };

  const sort = (field) => {
    if (field === query.sortedField) {
      const order = query.sortOrder === 'desc' ? 'asc' : 'desc';
      setQuery({ ...query, sortOrder: order });
    } else {
      setQuery({ ...query, sortOrder: 'asc', sortedField: field });
    }
  };

  const handleDownloadCSV = async () => {
    try {
      const response = await axios.get(
        `/api/v2/company_admin/company_users/download_template?template_type=${currentUISelectAction}`,
        {
          responseType: 'blob',
        },
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      let filename =
        currentUISelectAction === 'batch_resigned_download_template'
          ? 'batch_resignation_template.csv'
          : 'batch_upload_template.csv';
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error exporting CSV:', error);
    }
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setCurrentPage(selectedPage + 1);
  };

  const handleOnSelect = async (eventKey) => {
    if (eventKey === 'batch_upload' || eventKey === 'batch_resigned') {
      setShowModal(true);
    }
    setCurrentUISelectAction(eventKey);
  };

  const handleDateConfirmedSelect = async (eventKey) => {
    let selection = dateConfirmedSelections.filter(
      (item) => item.key === eventKey,
    )[0];
    if (eventKey === 'custom') {
      setShowCustomDateModal(true);
    } else {
      setCurrentDateSelectAction(selection);
    }
  };

  const onCompanyChange = (value) => {
    setFilteredCompanyId(value);
  };

  const handleTabSelect = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    if (
      currentUISelectAction === 'batch_resigned_download_template' ||
      currentUISelectAction === 'batch_upload_download_template'
    ) {
      handleDownloadCSV();
    }
  }, [currentUISelectAction]);

  return (
    <Layout>
      <Row className="d-flex justify-content-between">
        {handledCompanies?.length > 0 && (
          <Col md={4} sm={12} className="p-0">
            <StyledHeading>Company</StyledHeading>
            <select
              name="selectedCompany"
              defaultValue={filteredCompanyId}
              className="form-control mb-5"
              onChange={(event) => onCompanyChange(event.target.value)}>
              {handledCompanies.map((company, index) => (
                <option
                  key={`companyname-${index}-${company.id}`}
                  value={company.id}>
                  {company.name}
                </option>
              ))}
            </select>
            {site_data?.length > 0 && (
              <select
                name="siteId"
                defaultValue={siteId}
                className="form-control mb-4 d-block"
                onChange={(event) => setSiteId(event.target.value)}>
                <option disabled> -- Select a Company Site --</option>
                {site_data?.map((siteObject) => (
                  <option key={`site-${siteObject.id}`} value={siteObject.id}>
                    {siteObject.name}
                  </option>
                ))}
              </select>
            )}
          </Col>
        )}
        {handledCompanies?.length === 0 && (
          <StyledHeading>{companyName} Users</StyledHeading>
        )}
        <DragAndDrop
          setShowModal={setShowModal}
          refetchData={getData}
          show={showModal}
          companyId={filteredCompanyId}
          siteId={siteId}
          title={
            currentUISelectAction === 'batch_upload'
              ? 'Batch Upload'
              : 'Batch Resigned'
          }
        />
      </Row>
      <Row>
        <SelectContainer>
          <UISelect
            className="d-inline-block"
            selectedDropDown={currentUISelectAction}
            handleOnSelect={handleOnSelect}
            text={'Batch resignation'}
            key={'batch_resignation'}
            options={[
              { text: 'Tag as resigned', key: 'batch_resigned' },
              {
                text: 'Download template',
                key: 'batch_resigned_download_template',
              },
            ]}
            white={true}
          />
          <UISelect
            className="d-inline-block"
            selectedDropDown={currentUISelectAction}
            handleOnSelect={handleOnSelect}
            text={'Batch Uploads'}
            key={'batch_update'}
            options={[
              { text: 'Add new employees', key: 'batch_upload' },
              {
                text: 'Download template',
                key: 'batch_upload_download_template',
              },
            ]}
            white={true}
          />
          <StyledButton
            style={{
              width: 'fit-content',
              height: '43px',
              fontWeight: 'normal',
            }}
            onClick={() => setShowExportModal(true)}>
            Export{' '}
            <Image src="/file-upload-white.svg" style={{ width: '20px' }} />
          </StyledButton>
        </SelectContainer>
      </Row>
      <StyledTabsRow>
        <Tabs
          defaultActiveKey={activeTab}
          id="tab-filter"
          className="mb-3"
          onSelect={handleTabSelect}>
          <Tab eventKey="all" title="All"></Tab>
          <Tab eventKey="confirmed" title="Confirmed"></Tab>
        </Tabs>
      </StyledTabsRow>
      <Row
        style={{ gap: '10px', justifyContent: 'space-between' }}
        className="mb-4">
        <SearchWrapper className="bg-white input-group border rounded-lg">
          <div className="input-group-prepend">
            <img src="/search.svg" />
          </div>
          <input
            className="p-0 pl-2 form-control form-control-sm shadow-none border-0"
            style={{ width: '400px', lineHeight: '30px', height: '30px' }}
            type="search"
            placeholder="Search by email or first name or last name"
            onChange={(event) => {
              setKeyWord(event.target.value);
            }}
          />
        </SearchWrapper>
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <p className="m-0 pt-2">
            {activeTab === 'confirmed'
              ? 'Filter by Date Confirmed:'
              : 'Filter by Date Created:'}
            &nbsp;
          </p>
          <CalendarUISelect
            className="d-inline-block"
            selectedDropDown={currentDateSelectAction?.key}
            handleOnSelect={handleDateConfirmedSelect}
            with_active={true}
            text={currentDateSelectAction?.text}
            options={dateConfirmedSelections}
            white={true}
          />
        </div>
      </Row>
      <Row>
        <div className="table-responsive">
          <StyledTable className="table">
            <thead>
              <tr>
                {companyPrefix ? <th scope="col">User ID</th> : null}
                <th scope="col">Email</th>
                <th scope="col">
                  <SortButton
                    type="button"
                    onClick={() => sort('users.first_name')}>
                    {query.sortedField &&
                    query.sortedField === 'users.first_name' ? (
                      query.sortOrder === 'asc' ? (
                        <FontAwesomeIcon icon={faLongArrowAltUp} />
                      ) : (
                        <FontAwesomeIcon icon={faLongArrowAltDown} />
                      )
                    ) : null}
                    &nbsp;First Name
                  </SortButton>
                </th>
                <th scope="col">
                  <SortButton
                    type="button"
                    onClick={() => sort('users.last_name')}>
                    {query.sortedField &&
                    query.sortedField === 'users.last_name' ? (
                      query.sortOrder === 'asc' ? (
                        <FontAwesomeIcon icon={faLongArrowAltUp} />
                      ) : (
                        <FontAwesomeIcon icon={faLongArrowAltDown} />
                      )
                    ) : null}
                    &nbsp;Last Name
                  </SortButton>
                </th>
                <th scope="col">
                  <SortButton
                    type="button"
                    onClick={() => sort('users.confirmed_at')}>
                    {query.sortedField &&
                    query.sortedField === 'users.confirmed_at' ? (
                      query.sortOrder === 'asc' ? (
                        <FontAwesomeIcon icon={faLongArrowAltUp} />
                      ) : (
                        <FontAwesomeIcon icon={faLongArrowAltDown} />
                      )
                    ) : null}
                    &nbsp;Date Confirmed
                  </SortButton>
                </th>
                <th scope="col">Employee Status</th>
              </tr>
            </thead>
            {data.length > 0 && (
              <tbody>
                {data?.map((content) => (
                  <User
                    data={content}
                    key={content.id}
                    companyPrefix={companyPrefix}
                  />
                ))}
              </tbody>
            )}
          </StyledTable>
        </div>
        {pageCount !== 0 && data.length === 0 && (
          <div className="d-block text-center w-100 mt-4">
            <h6>No Results Found</h6>
            <p>We couldn't find any results matching your filter.</p>
          </div>
        )}
      </Row>
      {data.length > 0 && (
        <div className="custom-pagination">
          <ReactPaginate
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            containerClassName={'pagination'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            previousLabel={'prev'}
            nextLabel={'next'}
            breakLabel={'...'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            activeClassName={'active'}
          />
        </div>
      )}
      {showExportModal && (
        <ExportModal
          showExportModal={showExportModal}
          setShowExportModal={setShowExportModal}
          filteredCompanyId={filteredCompanyId}
          siteId={siteId}
        />
      )}
      {showCustomDateModal && (
        <CustomDateModal
          showCustomDateModal={showCustomDateModal}
          setShowCustomDateModal={setShowCustomDateModal}
          setCustomDate={setCustomDate}
          setCurrentDateSelectAction={setCurrentDateSelectAction}
          customDate={customDate}
        />
      )}
    </Layout>
  );
};

export default CompanyUsers;
