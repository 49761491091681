import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from '@emotion/styled';
import { UICard, UIBack } from '../../shared/ui';
import 'react-day-picker/dist/style.css';
import { StyledHeader, ImageContainer } from './styles';
import CoachModal from './CoachModal';
import { useBookSessionData } from '../../contexts/BookSessionDataProvider';
import { useAppData } from '../../contexts/AppDataProvider';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const StyledUICard = styled(UICard)`
  cursor: pointer;
  &.active {
    color: #1db4b3;
    border: #1db4b3 2px solid;
  }
  &.disabled{
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const StyledTitle = styled.h6`
  color: #00a2a1;
`;

const SelectSession = ({
  availableMeetingService
}) => {
  const {
    selectedSession,
    setCurrentStep,
    setSelectedSession,
    resetFields,
  } = useBookSessionData();
  const { companyID, consentSigned, hasPreference } = useAppData();
  const [sessionsLeft, setSessionsLeft] = useState(null);

  const fetchSessionLeft = async () => {
    // get updated sessions left from the api
    const res = await fetch('/api/v2/meetings');
    if (res.status == 200) {
      const { sessions_left } = await res.json();
      setSessionsLeft(sessions_left);
    }
  };

  useEffect(() => {
    fetchSessionLeft();
  }, []);

  const setSession = (sessionType) => {
    let nextStep = 'select_reasons';
    if (companyID && sessionsLeft <= 0){
      nextStep = 'out_of_session';
    }else if (sessionType == 'counseling') {
      if (hasPreference) {
        nextStep = 'consent_form'
      } else {
        nextStep = 'preference_form';
      }
    }
    if (!!selectedSession && selectedSession != sessionType) {
      resetFields();
    }
    setSelectedSession(sessionType);
    setCurrentStep(nextStep);
  };

  const tooltip = (
    <Tooltip id="tooltip">
      The coaching service is currently not available in your organization.
      Please contact HR for further assistance.
    </Tooltip>
  );

  const coachingServiceCard = (
    <StyledUICard
      className={`${
        availableMeetingService?.coaching_available ? '' : 'disabled'
      }`}
      onClick={() => {
        if (availableMeetingService?.coaching_available) {
          setSession('coaching');
        }
      }}>
      <StyledTitle>Coaching</StyledTitle>
      <p>Speak with a life coach to help you identify and achieve your goals</p>
    </StyledUICard>
  );

  return (
    <>
      <UIBack to="/" />
      <StyledHeader>
        <p className="book-session">BOOK A SESSION</p>
        <h3>What is your session for?</h3>
      </StyledHeader>
      <Row className="justify-content-center m-0">
        <Col lg={6}>
          <ImageContainer className="text-center">
            <img src="/coaching/working-with-laptop.png" />
          </ImageContainer>
        </Col>
        <Col lg={6}>
          <StyledUICard
            className={`mb-4 ${
              availableMeetingService?.counseling_available ? '' : 'disabled'
            }`}
            onClick={() => {
              if (availableMeetingService?.counseling_available) {
                setSession('counseling');
              }
            }}>
            <StyledTitle>Counseling</StyledTitle>
            <p>
              Speak with a counselor to help you resolve the challenges to your
              well-being
            </p>
          </StyledUICard>
          <CoachModal />
          {!availableMeetingService?.coaching_available && (
            <OverlayTrigger
              placement="top"
              overlay={tooltip}>
              <div>{coachingServiceCard}</div>
            </OverlayTrigger>
          )}
          {availableMeetingService?.coaching_available && coachingServiceCard}
        </Col>
      </Row>
    </>
  );
};
export default SelectSession;