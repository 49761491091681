import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Layout from '../Layout';
import moment from 'moment';
import qs from 'query-string';
import styled from '@emotion/styled';
import ReactPaginate from 'react-paginate';
import Spinner from '../../Spinner';
import UICard from '../shared/ui/Card';
import { Link } from 'react-router-dom';
import { Title } from '../CompanyDashboard/components/styles';
import { useLocation, useParams } from 'react-router-dom';


const StyledTh = styled.th`
  font-size: 12px;
  font-weight: normal;
  text-transform: uppercase;
  border: none !important;
`;

const StyledTd = styled.td`
  border: none !important;
  font-weight: normal;
  font-size: 14px;
  vertical-align: middle !important;
  &.status {
    text-transform: capitalize;
  }
`;

const StyledTr = styled.tr`
  :nth-child(odd) {
    background-color: #f2f2f2;
  }
`;

const StyledButton = styled.button`
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-size: 14px;
  padding: 8px 27px;
  &.approve {
    color: #1db4b3 !important;
    border: 1px solid #1db4b3;
  }
  &.approve:hover {
    background-color: white !important;
  }
  &.decline {
    border: 1px solid #ff8582;
    color: #ff8582 !important;
  }
`;

const StyledButtonTd = styled.td`
  width: 27%;
`;

function formattedDate(dateString) {
  return moment(dateString).format('MMM D, YYYY');
}

const DashboardTable = ({ data, siteId, companyId }) => {
  const [sessionRequests, setSessionRequests] = useState(data);
  /**
   * Sometimes, useState(data) doesn't update from new
   * `data` values, so we ensure that when `data` changes,
   * sessionRequests changes for correct rendering
   */
  useEffect(() => {
    setSessionRequests(data);
  }, [data]);

  async function handleStatusChange(status, sessionRequestId) {
    const payload = {
      meeting_sessions_request: {
        status: status,
      },
    };

    const res = await fetch(
      '/api/v2/company_admin/meeting_sessions_requests/' + sessionRequestId,
      {
        headers: { 'Content-Type': 'application/json' },
        method: 'PATCH',
        body: JSON.stringify(payload),
      },
    );

    if (res.status === 200) {
      fetchSessionRequests();
    }
  }

  async function fetchSessionRequests() {
    const res = await fetch(
      `/api/v2/company_admin/meeting_sessions_requests?limit=2&site_id=${siteId}&companyId=${companyId}`,
    );
    if (res.status == 200) {
      const data = await res.json();
      setSessionRequests(data.data);
    }
    return [];
  }

  // Don't display the table when there are no requests
  if (sessionRequests.length <= 0) return <></>;

  return (
    <>
      <Row>
        <Col md={12}>
          <div className="table-responsive">
            {sessionRequests.length <= 0 && (
              <p>There are no requests in your company yet...</p>
            )}
            {sessionRequests.length > 0 && (
              <>
                <table className="table mx-auto">
                  <thead>
                    <tr>
                      <StyledTh>Employee Name</StyledTh>
                      {sessionRequests[0].site != 'NA' && (
                        <StyledTh>Site</StyledTh>
                      )}
                      <StyledTh>Request Sent</StyledTh>
                      <StyledTh>Session Type</StyledTh>
                      <StyledTh>Status</StyledTh>
                      <StyledTh>Action</StyledTh>
                    </tr>
                  </thead>
                  <tbody>
                    {sessionRequests.map((data) => (
                      <StyledTr
                        id={'meeting_sessions_request_' + data.id}
                        key={data.id}>
                        <StyledTd>{data.employee_full_name}</StyledTd>
                        {sessionRequests[0].site != 'NA' && (
                          <StyledTd>{data.site}</StyledTd>
                        )}
                        <StyledTd>{formattedDate(data.created_at)}</StyledTd>
                        <StyledTd>{data.session_type}</StyledTd>
                        <StyledTd className="status">{data.status}</StyledTd>
                        <StyledButtonTd>
                          {data.status == 'pending' ? (
                            <>
                              <StyledButton
                                type="button"
                                className="btn btn-sm btn-green mx-1 approve"
                                data-confirm="Are you sure you want to approve this request?"
                                onClick={() =>
                                  handleStatusChange('accepted', data.id)
                                }>
                                Approve
                              </StyledButton>
                              <StyledButton
                                type="button"
                                className="btn btn-sm btn-pink mx-1 decline"
                                data-confirm="Are you sure you want to decline this request?"
                                onClick={() =>
                                  handleStatusChange('rejected', data.id)
                                }>
                                Decline
                              </StyledButton>
                            </>
                          ) : (
                            'N/A'
                          )}
                        </StyledButtonTd>
                      </StyledTr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

const MeetingSessionsRequests = () => {
  const [focusedRow, setFocusedRow] = useState('');
  const [sessionRequests, setSessionRequests] = useState([]);
  const [statistics, setStatistics] = useState();
  const [loading, setLoading] = useState(true);
  const [pagy, setPagy] = useState({ last: 0 });
  const [currentFilter, setCurrentFilter] = useState(null);
  const search = new URLSearchParams(useLocation().search);
  const company_id = search.get('ref')
  const site_id = search.get('site_id');

  function rowId(id) {
    return 'meeting_sessions_request_' + id;
  }

  async function fetchSessionRequests(params = {}) {
    params = { ...params, site_id: site_id, company_id: company_id };
    const res = await fetch(
      `/api/v2/company_admin/meeting_sessions_requests?${qs.stringify(params)}`,
    );
    if (res.status == 200) {
      const data = await res.json();
      setSessionRequests(data.data);
      setPagy(data.pagy);
      setLoading(false);
    }
    return [];
  }

  async function handleStatusChange(status, sessionRequestId) {
    const payload = {
      meeting_sessions_request: {
        status: status,
      },
      company_id: company_id,
    };

    const res = await fetch(
      '/api/v2/company_admin/meeting_sessions_requests/' + sessionRequestId,
      {
        headers: { 'Content-Type': 'application/json' },
        method: 'PATCH',
        body: JSON.stringify(payload),
      },
    );
    if (res.status === 200) {
      const updatedMeetingSessionsRequest = await res.json();
      const updatedSessionRequests = sessionRequests.map((data) => {
        if (data.id == sessionRequestId) {
          const row = rowId(data.id);
          setFocusedRow(row);
          return updatedMeetingSessionsRequest;
        }
        return data;
      });
      setSessionRequests(updatedSessionRequests);
      fetchStatistics();
    }
  }

  async function fetchStatistics() {
    const params = { site_id: site_id, company_id: company_id };
    const res = await fetch(
      `/api/v2/company_admin/meeting_sessions_requests/statistics?${qs.stringify(
        params,
      )}`,
    );
    debugger
    if (res.status == 200) {
      const data = await res.json();
      setStatistics(data);
    }
    return [];
  }

  useEffect(() => {
    fetchSessionRequests();
    fetchStatistics();
  }, []);

  const filterByStatus = (event) => {
    let status = event.target.value;
    status == 'all' ? setCurrentFilter(null) : setCurrentFilter(status);
    let filter = status == 'all' ? { page: 1 } : { status: status, page: 1 };
    fetchSessionRequests(filter);
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    fetchSessionRequests({ page: selectedPage + 1, status: currentFilter });
  };

  if (loading) {
    return (
      <Layout>
        <Spinner />
      </Layout>
    );
  }
  return (
    <Layout>
      <div>
        <div className="row">
          <div className="col-6">
            <UICard>
              <div className="d-flex align-items-center mb-2">
                <img src={'/session-requests-icon.svg'} />
                <span className="ml-2">Total Sessions Requests</span>
              </div>
              <h5 className="ml-2 pt-2">{statistics?.total}</h5>
            </UICard>
          </div>
          <div className="col-6">
            <UICard>
              <div className="d-flex align-items-center mb-2">
                <img src={'/session-requests-icon.svg'} />
                <span className="ml-2">Pending Requests</span>
              </div>
              <h5 className="ml-2 pt-2">{statistics?.pending_requests}</h5>
            </UICard>
          </div>
        </div>
        <div className="d-flex mt-5 justify-content-between">
          <div>
            <div className="input-group align-items-center">
              <label className="form-label p-0 m-0 mr-4" htmlFor="status">
                FILTER BY STATUS
              </label>
              <select
                className="form-control form-control-inline"
                name="status"
                id="status"
                onChange={filterByStatus}>
                <option value="all">All</option>
                <option value="pending">Pending</option>
                <option value="accepted">Accepted</option>
                <option value="rejected">Rejected</option>
              </select>
            </div>
          </div>

          <Link to="/company" className="text-green text-decoration-none">
            Go Back to Dashboard
          </Link>
        </div>

        <Title className="mt-5 mb-3">Requests for counseling sessions</Title>
        {sessionRequests.length > 0 && (
          <>
            <table className="table mx-auto table-striped table-borderless">
              <thead>
                <tr>
                  <StyledTh></StyledTh>
                  <StyledTh>Employee Name</StyledTh>
                  {sessionRequests[0].site != 'NA' && <th>Site</th>}
                  <StyledTh>Request Sent</StyledTh>
                  <StyledTh>Session Type</StyledTh>
                  <StyledTh>Reason</StyledTh>
                  <StyledTh>Status</StyledTh>
                  <StyledTh>Processed by</StyledTh>
                  <StyledTh>Processed at</StyledTh>
                  <StyledTh>Action</StyledTh>
                </tr>
              </thead>
              <tbody>
                {sessionRequests.map((data, index) => (
                  <StyledTr
                    id={rowId(data.id)}
                    key={data.id}
                    className={
                      rowId(data.id) == focusedRow ? 'table-active' : ''
                    }>
                    <StyledTd>{index + 1 + (pagy.page - 1) * 10}</StyledTd>
                    <StyledTd>{data.employee_full_name}</StyledTd>
                    {sessionRequests[0].site != 'NA' && <td>{data.site}</td>}
                    <StyledTd>{formattedDate(data.created_at)}</StyledTd>
                    <StyledTd>{data.session_type}</StyledTd>
                    <StyledTd>{data.reason}</StyledTd>
                    <StyledTd>{data.status}</StyledTd>
                    <StyledTd>{data.processed_by || 'N/A'}</StyledTd>
                    <StyledTd>
                      {data.processed_at
                        ? formattedDate(data.processed_at)
                        : 'N/A'}
                    </StyledTd>
                    <StyledTd>
                      {data.status == 'pending' ? (
                        <>
                          <StyledButton
                            type="button"
                            className="btn btn-sm btn-green mx-1 approve my-1"
                            data-confirm="Are you sure you want to approve this request?"
                            onClick={() =>
                              handleStatusChange('accepted', data.id)
                            }>
                            Approve
                          </StyledButton>
                          <StyledButton
                            type="button"
                            className="btn btn-sm btn-pink mx-1 decline"
                            data-confirm="Are you sure you want to decline this request?"
                            onClick={() =>
                              handleStatusChange('rejected', data.id)
                            }>
                            Decline
                          </StyledButton>
                        </>
                      ) : (
                        'N/A'
                      )}
                    </StyledTd>
                  </StyledTr>
                ))}
              </tbody>
            </table>
            <div className="custom-pagination float-right">
              <ReactPaginate
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                containerClassName={'pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                previousLabel={'prev'}
                nextLabel={'next'}
                breakLabel={'...'}
                pageCount={pagy.last}
                marginPagesDisplayed={2}
                pageRangeDisplayed={10}
                onPageChange={handlePageClick}
                activeClassName={'active'}
              />
            </div>
          </>
        )}
        {sessionRequests.length <= 0 && (
          <>
            <p className="text-center">
              There are no requests in your company yet...
            </p>
          </>
        )}
      </div>
    </Layout>
  );
};

export { DashboardTable };
export default MeetingSessionsRequests;
