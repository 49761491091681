import React from 'react';

const Cloaking = ({ user }) => {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content
  return (
    <section className='text-center mt-4'>
      <form action='/users/stop_impersonating' method='POST'>
        <input type='hidden' name='authenticity_token' value={csrfToken} />
        <input type='submit' value={`Uncloak ${user}`} className='btn btn-info' />
      </form>
    </section>
  )
};

export default Cloaking;
