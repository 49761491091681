import React from 'react';
import styled from '@emotion/styled';
import { Navbar, Nav, Container } from 'react-bootstrap';

const StyledNavbarCollapse = styled(Navbar.Collapse)`
  a {
    padding-right: 0;
  }
  a.nav-link {
    font-size: 0.8rem;
    &:hover {
      text-shadow: 0px 0px 1px white;
    }
  }
  a.nav-link.active {
    color: #1db4b3 !important;
  }
  a.nav-link:hover {
    color: #16181b;
    background-color: #f8f9fa;
  }
`;

const StyledNavbarBrand = styled(Navbar.Brand)`
  .company-logo {
    max-height: 50px;
    margin-left: 20px;
    margin-right: 0px;
  }
  .logo-divider {
    border-left: 2px solid #00a2a1;
    margin-left: 20px;
  }
`;

const PublicNavigation = () => {
  const pathname = window.location.pathname;

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <div>
          <StyledNavbarBrand href="/">
            <img src="/logo.svg" />
          </StyledNavbarBrand>
        </div>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <StyledNavbarCollapse id="navbarScroll">
          <Nav className="mr-auto w-100 justify-content-end">
            <Nav.Link
              target="_blank"
              rel="noopener noreferrer"
              href="http://infinitcare.co/#about-us">
              About
            </Nav.Link>
            <Nav.Link
              href="/faq"
              className={pathname == '/faq' ? 'active' : ''}>
              FAQ
            </Nav.Link>

            <Nav.Link
              href="/counselors"
              className={pathname == '/counselors' ? 'active' : ''}>
              Counselors
            </Nav.Link>
            <Nav.Link
              href="/pricing"
              className={pathname == '/pricing' ? 'active' : ''}>
              Pricing
            </Nav.Link>
            <Nav.Link
              href="/users/sign_in"
              className={pathname == '/users/sign_in' ? 'active' : ''}>
              Login
            </Nav.Link>
          </Nav>
        </StyledNavbarCollapse>
      </Container>
    </Navbar>
  );
};

export default PublicNavigation;
