import React from "react";
import * as moment from "moment";
import { css, cx } from "emotion";

import ProfileNavigation from "../shared/ProfileNavigation";
import usePayment from "../shared/usePayment";
import Spinner from "../Spinner";

const PaymentContent = ({ payments }) => {
  return (
    <>
      <h5 className="card-title">Payment Summary</h5>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Package</th>
              <th scope="col">Price</th>
              <th scope="col">Payment Date</th>
              <th scope="col">Payment Method</th>
              <th scope="col">Status</th>
              <th scope="col"># of Sessions</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment, index) => (
              <tr key={index}>
                <td>{payment.package_name}</td>
                <td>{payment.price}</td>
                <td>{moment(payment.order_date).format("ll")}</td>
                <td>{payment.payment_reference_type || "N/A"}</td>
                <td>{payment.order_status}</td>
                <td>{payment.number_of_sessions}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

const PaymentSummary = ({}) => {
  const [paymentState] = usePayment();

  return (
    <div className="container personal-information">
      <div className="row mt-5">
        <ProfileNavigation />
        <div className="col-lg-9 col-md-8 col-sm-12 mt-2">
          <div className="card">
            <div
              className={cx(
                'card-body',
                css`
                  min-height: 100px;
                `,
              )}>
              {paymentState.loading ? (
                <Spinner />
              ) : (
                paymentState.payments && (
                  <PaymentContent payments={paymentState.payments} />
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSummary;
