import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

const StyledContainer = styled.div`
  p {
    text-align: center;
    font-size: 16px;
    font-weight: medium;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
  }
`;
const ModalImageContainer = styled.div`
  text-align: center;
  margin: 35px;
`;
const sharedButtonStyle = styled.button`
  border-radius: 5px;
  opacity: 1;
  border: none;
  font-weight: bold;
  padding: 15px 30px 15px 30px;
  margin-right: 30px;
`
const CancelButton = styled(sharedButtonStyle)`
  background: #FFFFFF 0% 0% no-repeat padding-box;
  color: #1DB4B3;
`;
const RegisterButton = styled(sharedButtonStyle)`
  background:#1DB4B3 0% 0% no-repeat padding-box;
  color: #FFFFFF;
`;

const NotRegisteredModal = ({ show, handleClose }) => {
  const history = useHistory();

  return (
    <StyledContainer>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered>
        <Modal.Body>
          <ModalImageContainer>
            <img src={'question-vector.svg'} alt="" />
          </ModalImageContainer>

          <p className="text-center">
            The phone number is not registered. Do you want to register now?
          </p>
          <div className="text-center">
            <CancelButton
              onClick={() => {
                window.location.href = '/';
              }}>
              Cancel
            </CancelButton>
            <RegisterButton
              onClick={handleClose}>
              Register
            </RegisterButton>
          </div>
        </Modal.Body>
      </Modal>
    </StyledContainer>
  );
};

export default NotRegisteredModal;
