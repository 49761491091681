import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Col, Container, Row } from 'react-bootstrap';
import Navigation from '../Layout/Navigation';
import Footer from '../shared/Footer';
import { useAppData } from '../contexts/AppDataProvider';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import Cookies from 'js-cookie';
import axios from 'axios';

const VerifyEmailContainer = styled.div`
  width: 466px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  padding: 40px;
`;
const ContainerWrapper = styled(Container)`
  padding-top: 2.5rem;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #e3f2f6, #faf6f7);
    z-index: -1;
  }
  .background-image {
    width: 80%;
    height: auto;
    position: absolute;
    z-index: -1;
  }
`;

const FormContainer = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  .form-header {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    margin-bottom: 30px;
  }
  label {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
  }
  input {
    border-radius: 5px;
    opacity: 1;
    height: 50px;
    margin-right: 10px;
    border: 1px solid #dddddd;
  }
  button {
    background: #1db4b3 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    margin-top: 50px !important;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #ffffff;
    padding: 10px 40px 10px 40px;
    border: none;
    display: block;
    margin: 0 auto;
  }
  .verification-actions {
    margin-top: 50px;
    display: flex;
    flex-flow: row nowrap;
    .contact {
      flex-grow: 2;
    }
    .skip {
      text-align: right;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      color: #1db4b3;
      opacity: 1;
      cursor: pointer;
    }
  }
`;
const StyledLink = styled.a`
  text-decoration: none;
  color: #1db4b3;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: #1db4b3;
  }
`;

const VerifyEmail = () => {
  const location = useLocation();
  const query = qs.parse(location.search);
  const [emailSent, setEmailSent] = useState(false);
  const [verify, setVerify] = useState(query['email_sent'] === 'true');
  const [seconds, setSeconds] = useState(60);
  const { email } = useAppData();
  const background = '/otp/background.svg';

  useEffect(() => {
    if (verify) {
      resendEmail();
    }
  }, [verify]);

  const resendEmail = async () => {
    setEmailSent(true);
    await fetch('/api/v2/user_email/verify', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        const newSecond = prevSeconds - 1;
        if (newSecond === 0) {
          clearInterval(timer);
        }
        return newSecond;
      });
    }, 1000);
  };

  useEffect(() => {
    if (seconds === 0) {
      setEmailSent(false);
      setSeconds(60);
    }
  }, [seconds]);

  const handleSkip = async () => {
    const userType = Cookies.get('userType');
    const res = await axios.post('/api/v2/users/skip_verify', {
      user: { phone: Cookies.get('phone') },
    });

    if (res.status === 200) {
      if (userType === 'individual') {
        window.location = '/success?type=success_registration';
      } else {
        window.location =
          '/success?redirect=assessment&type=success_registration';
      }
    }
  };

  const handleVerify = () => {
    setVerify(true);
  };

  return (
    <>
      <Navigation />

      <ContainerWrapper>
        <img src={background} alt="" className="background-image" />
        <Row>
          <Col className="text-center d-flex flex-column align-items-center">
            <h6 className="font-weight-bold">
              Let&apos;s verify your email address
            </h6>
            <VerifyEmailContainer className="text-left mt-5">
              <FormContainer>
                {!verify && (
                  <>
                    <label htmlFor="email">Your email address</label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                      disabled
                      className="form-control"
                      value={email}
                    />

                    <button type="button" onClick={handleVerify}>
                      Verify
                    </button>

                    <div className="verification-actions">
                      <div className="contact">
                        Need help?&nbsp;
                        <a
                          href="mailto:support@infinitcare.co"
                          className="skip">
                          Contact us
                        </a>
                      </div>
                      <div className="skip" onClick={handleSkip}>
                        Skip for now
                      </div>
                    </div>
                  </>
                )}

                {verify && (
                  <>
                    <p>
                      An email has been sent to{' '}
                      <span className="font-weight-bold mt-3">{email}</span>.
                      Please click on the link to verify your email.
                    </p>
                    {emailSent && (
                      <span className="d-block mt-5">
                        Resend code in{' '}
                        <span className="font-weight-bold mt-3">
                          {seconds}s
                        </span>
                      </span>
                    )}
                    {!emailSent && (
                      <StyledLink
                        onClick={resendEmail}
                        className="font-weight-bold mt-5 d-block">
                        Resend
                      </StyledLink>
                    )}
                    <span className="d-block mt-2">
                      Need help?{' '}
                      <StyledLink
                        href="mailto:info@infinitcare.co"
                        className="font-weight-bold mr-4">
                        Contact Us
                      </StyledLink>
                    </span>
                  </>
                )}
              </FormContainer>
            </VerifyEmailContainer>
          </Col>
        </Row>
      </ContainerWrapper>
      <Footer />
    </>
  );
};

export default VerifyEmail;
