import React, { useEffect, useState } from 'react';
import Api from '../Api';

export default () => {
  const [emergencyContactState, setEmergencyContactState] = useState({
    loading: true,
    emergency_contact: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      Api.get('/api/v2/emergency_contacts', {}).then((res) => {
        setEmergencyContactState({
          ...emergencyContactState,
          loading: false,
          emergency_contact: res.data,
        });
      })
    };
    fetchData();
  }, []);

  return [emergencyContactState];
};
