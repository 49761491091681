import React from "react";
import { css, cx } from "emotion";

function Title({
  title = "Default Title",
  description = "Default description",
  className,
  children
}) {
  return (
    <div className="container pt-2">
      <div className={cx(className, "row text-center")}>
        <div
          className={cx(
            "col-xs-12 mx-auto px-3",
            css`
              padding-top: 1rem;
              padding-bottom: 1rem;
            `
          )}
        >
          <h1 className="h3 font-weight-bold">{title}</h1>
          <p className="text-muted">{description}</p>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Title;
