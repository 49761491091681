import React, { Component } from 'react';
import { css } from 'emotion';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

export default class DateRangeInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: this.props.startDate,
      endDate: moment().endOf('month'),
      minDate: moment('July, 1, 2022'),
      focusedInput: null,
    };

    this.renderCalendarInfo = this.renderCalendarInfo.bind(this);
    this.setDates = this.setDates.bind(this);
  }

  setDates(startDate, endDate) {
    this.setState({ startDate, endDate });
    this.props.onChange({
      startDate: startDate.format(),
      endDate: endDate.format(),
    });
  }

  renderCalendarInfo() {
    const rangeFilter = (label, startDate, endDate) => (
      <a
        href=""
        role="button"
        onClick={(e) => {
          e.preventDefault();
          this.setDates(startDate, endDate);
        }}>
        {label}
      </a>
    );

    return (
      <div
        className={css`
          border-top: 1px solid #eee;
          padding-top: 1rem;
          padding-bottom: 0.5rem;
          .list-inline {
            margin-left 1.5rem;
            margin-bottom: 0.5rem;
          }
          a {
            color: #1db4b3;
          }
        `}>
        <ul className="list-inline">
          <li className="list-inline-item">
            {rangeFilter(
              'This Month',
              moment().startOf('month'),
              moment().endOf('month'),
            )}
          </li>
          <li className="list-inline-item">
            {rangeFilter(
              'Last Month',
              moment().subtract(1, 'month').startOf('month'),
              moment().subtract(1, 'month').endOf('month'),
            )}
          </li>
        </ul>
      </div>
    );
  }

  render() {
    const { startDate, endDate, focusedInput, minDate } = this.state;
    const { orientation = 'horizontal', numberOfMonths = 2 } = this.props;

    return (
      <div
        className={css`
          .DateRangePicker {
            display: flex;
          }

          .DateRangePicker > div {
            border-radius: 4px;
            border: 1px solid hsl(0, 0%, 80%);
            width: 100%;
          }

          .DateRangePickerInput {
            display: flex;
            background: transparent;
          }

          .DateInput {
            background: transparent;
            width: auto;
          }

          .DateRangePickerInput_arrow {
            display: flex;
            align-items: center;
          }

          .DateInput_input {
            background: transparent;
            font-size: inherit;
            padding: 0.28rem 0.75rem;
            border-top: 2px solid transparent;
          }
        `}>
        <DateRangePicker
          onDatesChange={({ startDate, endDate }) => {
            this.setState({ startDate, endDate });
            if (startDate && endDate && this.props.onChange) {
              this.props.onChange({
                startDate: startDate.format(),
                endDate: endDate.format(),
              });
            }
          }}
          onFocusChange={(focusedInput) => {
            this.setState({
              focusedInput
            });
          }}
          startDateId="startDate"
          startDate={startDate}
          endDateId="endDate"
          endDate={endDate}
          minDate={minDate}
          focusedInput={focusedInput}
          isOutsideRange={() => false}
          orientation={orientation}
          numberOfMonths={numberOfMonths}
          displayFormat="D MMM Y"
          calendarInfoPosition="bottom"
          renderCalendarInfo={this.renderCalendarInfo}
          hideKeyboardShortcutsPanel
          noBorder
        />
      </div>
    );
  }
}
