import React, { useState } from 'react';
import {Container, Row } from 'react-bootstrap';
import Navigation from './Navigation';
import styled from '@emotion/styled';
import Footer from '../shared/Footer';
import InitialScreen from './InitialScreen';
import UpdateScreen from './UpdateScreen';

const ContainerWrapper = styled(Container)`
  margin-top: 2.5rem;
`;
const UpdateEmail = () => {
  const [currentStep, setCurrentStep] = useState('initial_screen');

  return (
    <>
      <Navigation />
      <ContainerWrapper>
        <Row>
          {currentStep == 'initial_screen' && (
            <InitialScreen setCurrentStep={setCurrentStep} />
          )}
          {currentStep == 'update_screen' && (
            <UpdateScreen/>
          )}
        </Row>
      </ContainerWrapper>
      <Footer />
    </>
  );
};

export default UpdateEmail;
