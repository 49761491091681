import axios from '../../Api';

export default {
  bookASession: (landingPage) => {
    axios.post('/api/v2/track/book_a_session', {
      landing_page: landingPage,
    });
  },
  watchWebinar: (slug) => {
    axios.post('/api/v2/track/watch_webinar', { slug });
  },
  pageView: (params) => {
    axios.post('/api/v2/track/track_client_page_view', params);
  },
  podcastView: (params) => {
    axios.post('/api/v2/track/track_content_page_view', params);
  },
};

const CheckIn = {
  url: '/api/v2/track/check_in',
  closedModal: function () {
    axios.post(this.url, { event_name: 'Check-in - Closed Modal' });
  },
};

export { CheckIn };
