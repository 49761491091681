import React from 'react';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import Track from '../../shared/Track';
import styled from '@emotion/styled';

const StyledContentCard = styled.div`
  display: flex;
  margin-bottom: 30px;
  padding: 1.5rem 1.25rem;
  background: #f3fbfb;
  border-radius: 8px;
  @media (max-width: 767.98px) {
    flex-direction: column;
  }
`;

const StyledImageWrapper = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  width: 20%;
  @media (max-width: 767.98px) {
    width: 100%;
    margin-bottom: 20px;
  }
  img {
    width: 100%;
  }
`;

const StyledInfoContainer = styled.div`
  padding: 0px 20px;
  @media (max-width: 575.98px) {
    padding: 0px;
  }
`;

const Date = styled.time`
  font-weight: 500;
  margin-bottom: 4px;
  display: block;
  font-size: 0.875rem;
`;
const Time = styled.time`
  color: #1db4b3;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  span {
    padding-top: 4px;
    display: inline-block;
    letter-spacing: 0.5px;
  }
`;

const Title = styled(Link)`
  padding-top: 7px;
  font-weight: 600;
  display: block;
  margin-bottom: 30px;
  color: #333333;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-font-smoothing: antialiased;
  line-height: 24px;
  max-width: 433px;
  &:hover {
    color: #333333;
  }
`;

const WatchNowContainer = styled.div`
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  @media (max-width: 767.98px) {
    display: none;
  }
`;

const WatchNow = styled.a`
  background-color: white;
  padding: 14px 28px;
  color: #1db4b3;
  border-radius: 5px;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: bold;
  @media (max-width: 575.98px) {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }

  &:hover {
    background-color: #1db4b4cb;
    text-decoration: none;
    color: white;
  }
`;
const WebinarCardFullWidth = ({ webinar }) => {
  const meeting_date = moment(webinar.event_date).format('MMM D ddd');
  return (
    <StyledContentCard>
      <StyledImageWrapper>
        <img
          src={webinar?.image_url ? webinar?.image_url : '/default_webinar.png'}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = '/default_webinar.png';
          }}></img>
      </StyledImageWrapper>
      <StyledInfoContainer className="pr-0">
        <div>
          <Title className="underlinable link" to={`/webinars/${webinar.slug}`}>
            {webinar?.title}
          </Title>

          <Date>
            {meeting_date}, via {webinar.tool_type}
          </Date>
          <Time>
            <img className="mr-2" src="/timer.svg" />
            <span>
              {webinar.start_time.replace('am', '').replace('pm', '')}-
              {webinar.end_time.toUpperCase()}
            </span>
          </Time>
          <WatchNow
            className="button--rectangle d-md-none float-right"
            onClick={() =>
              webinar.done ? Track.watchWebinar(webinar.slug) : null
            }
            href={webinar.done ? webinar.event_link : webinar.registration_url}
            target="_blank"
            rel="noopener noreferrer">
            {webinar.done ? 'Watch Now' : 'Register'}
          </WatchNow>
        </div>
      </StyledInfoContainer>
      <WatchNowContainer>
        <WatchNow
          className="button--rectangle"
          onClick={() =>
            webinar.done ? Track.watchWebinar(webinar.slug) : null
          }
          href={webinar.done ? webinar.event_link : webinar.registration_url}
          target="_blank"
          rel="noopener noreferrer">
          {webinar.done ? 'Watch Now' : 'Register'}
        </WatchNow>
      </WatchNowContainer>
    </StyledContentCard>
  );
};

export default WebinarCardFullWidth;
