import React from 'react';
import styles from './Counselors.module.scss';
import { Col } from 'react-bootstrap';

const ProfessionalCard = ({ professional, type }) => {
  const expertises = type == 'coach' ? (professional?.coach_expertise || []) : (professional?.counselor_expertise || [])
  return (
    <Col lg="4" sm="6" xs="12">
      <figure className={styles.professional_container}>
        {professional?.avatar_url && (
          <img className="my-5" src={professional?.avatar_url} />
        )}
        <figcaption>
          <span className={styles.profile_name}>{professional?.full_name}</span>
          <span className={styles.expert_in}>expert in</span>
          <div className={styles.expertise_container}>
            {expertises.map((expertise, index) => {
              return (
                <span key={index} className={styles.expertise}>
                  {expertise}
                </span>
              );
            })}
          </div>
        </figcaption>
      </figure>
    </Col>
  );
 }

export default ProfessionalCard;
