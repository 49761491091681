import qs from 'query-string';
import { Row } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import Layout from '../Layout';
import ContentCard from '../Content/Card';

export default () => {
  const locationState = useLocation().state;

  const { id } = useParams();
  const [currentContents, setCurrentContents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [canSeeMore, setCanSeeMore] = useState(true);

  async function fetchCategoryContents(categoryId, page) {
    const queryString = qs.stringify({
      category_id: categoryId,
      paginated: true,
      page: page,
    });
    return await fetch(`/api/v2/contents?${queryString}`);
  }
  function handleSeeMore() {
    setCurrentPage(currentPage + 1);
  }

  async function concatenateContentsFromCurrentPage() {
    const res = await fetchCategoryContents(id, currentPage);
    if (res.status === 200) {
      const data = await res.json();
      const { contents, last_page } = data;

      if (currentPage >= last_page) setCanSeeMore(false);

      setCurrentContents(currentContents.concat(contents));
    }
  }

  useEffect(() => {
    if (!canSeeMore) return;
    concatenateContentsFromCurrentPage();
  }, [currentPage]);

  return (
    <Layout>
      <h3 className="font-weight-bolder text-green">
        {locationState?.categoryData?.title}
      </h3>
      <p> {locationState?.categoryData?.description} </p>
      <Row>
        {currentContents?.map((content, index) => (
          <ContentCard key={index} withContent={true} data={content} />
        ))}
        {canSeeMore && (
          <button
            onClick={handleSeeMore}
            className="text-green mt-4 mx-auto p-2 w-100 border-0"
            style={{ background: '#1DB4B30D 0% 0% no-repeat padding-box' }}>
            See more
          </button>
        )}
      </Row>
    </Layout>
  );
};
