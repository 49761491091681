import React from "react"
import ContentLoader from "react-content-loader"

const Loader = () => (
  <ContentLoader width="100%" height="350">
    <rect x="20" y="157" rx="2" ry="2" width="140" height="10" /> 
    <rect x="20" y="172" rx="2" ry="2" width="140" height="10" /> 
    <rect x="19" y="43" rx="2" ry="2" width="272" height="103" /> 
    <rect x="202" y="156" rx="0" ry="0" width="86" height="24" /> 
    <rect x="312" y="157" rx="2" ry="2" width="140" height="10" /> 
    <rect x="312" y="172" rx="2" ry="2" width="140" height="10" /> 
    <rect x="311" y="43" rx="2" ry="2" width="272" height="103" /> 
    <rect x="494" y="156" rx="0" ry="0" width="86" height="24" /> 
    <rect x="601" y="158" rx="2" ry="2" width="140" height="10" /> 
    <rect x="601" y="173" rx="2" ry="2" width="140" height="10" /> 
    <rect x="600" y="44" rx="2" ry="2" width="272" height="103" /> 
    <rect x="22" y="318" rx="2" ry="2" width="140" height="10" /> 
    <rect x="22" y="333" rx="2" ry="2" width="140" height="10" /> 
    <rect x="21" y="204" rx="2" ry="2" width="272" height="103" /> 
    <rect x="204" y="317" rx="0" ry="0" width="86" height="24" /> 
    <rect x="314" y="318" rx="2" ry="2" width="140" height="10" /> 
    <rect x="314" y="333" rx="2" ry="2" width="140" height="10" /> 
    <rect x="313" y="204" rx="2" ry="2" width="272" height="103" /> 
    <rect x="496" y="317" rx="0" ry="0" width="86" height="24" /> 
    <rect x="603" y="319" rx="2" ry="2" width="140" height="10" /> 
    <rect x="603" y="334" rx="2" ry="2" width="140" height="10" /> 
    <rect x="602" y="205" rx="2" ry="2" width="272" height="103" />
  </ContentLoader>
)

export default Loader
