import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const UIBack = ({ to, text = 'Go Back' }) => {
  return (
    <Link className="btn btn-sm btn-light bg-white border-0" to={to}>
      <img className="pb-1 pr-2" src="/arrow-left.svg" />
      {text}
    </Link>
  );
};

const UIBackButton = ({ onClick }) => {
  return (
    <Button className="btn btn-sm btn-light bg-white border-0" onClick={onClick}>
      <img className="pb-1 pr-2" src="/arrow-left.svg" />
      Go Back
    </Button>
  )
}

export { UIBackButton };
export default UIBack;
