import React from 'react';
import styled from '@emotion/styled';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAppData } from '../contexts/AppDataProvider';

const StyledContainer = styled.div`
  position: relative;
  background-color: #e5ebfa;
  padding: 24px 30px;
  box-shadow: 0px 0px 5px #0000000d;
  border-radius: 5px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #eafcc9e6;
  a {
    color: #1db4b3 !important;
  }
  &.continue {
    background: #efefef 0% 0% no-repeat padding-box;
    a {
      color: #e28986 !important;
    }
  }
  &.done {
    background: #e5ebfa 0% 0% no-repeat padding-box;
    a {
      color: #1db4b3 !important;
    }
  }
  .points {
    color: #1db4b3;
  }
  p {
    font-weight: 600;
    font-size: 1rem;
    max-width: 500px;
    margin-bottom: 10px;
  }
  a {
    font-size: 0.875rem;
    color: #1db4b3 !important;
    font-weight: bold;
    cursor: pointer;
  }
  img {
    position: absolute;
    right: -25px;
    top: 10px;
    height: 90%;
  }
  @media (max-width: 991px) {
    img {
      width: 30%;
    }
  }
  @media (max-width: 790px) {
    p {
      font-size: 1rem;
    }
    a {
      font-size: 0.75rem;
    }
    img {
      width: 40%;
      margin-top: 35px;
    }
  }
`;

const AssessmentDashboard = ({ setShowAssessmentModal, is_retake }) => {
  const { currentAssessment } = useAppData();
  const isDone = currentAssessment?.done;

  if (!currentAssessment) {
    return (
      <StyledContainer>
        <p>Know your well-being score.</p>
        {is_retake != undefined && (
          <a
            id="assessment-take-the-assessment"
            onClick={() => {
              setShowAssessmentModal(true);
            }}>
            {is_retake ? 'Retake the Assessment' : 'Take the Assessment'}
          </a>
        )}
        <Image src="/assessment/pitcher_plant.svg" />
      </StyledContainer>
    );
  } else {
    return (
      <StyledContainer className={`${isDone ? 'done' : 'continue'}`}>
        {isDone && (
          <>
            <p>
              You scored a total of{' '}
              <span className="points">{currentAssessment.score} points</span>{' '}
              in our mental health assessment.
            </p>
            <Link to="/assessment/result">View Result</Link>
            <Image src="/assessment/pitcher_plant.svg" />
          </>
        )}
        {!isDone && (
          <>
            <p>Know your well-being score.</p>
            <a
              href={
                currentAssessment?.status == 'in_progress'
                  ? '/assessment'
                  : '/assessment/user_form'
              }>
              Continue the assessment
            </a>
            <Image src="/assessment/pitcher_plant.svg" />
          </>
        )}
      </StyledContainer>
    );
  }
};

export default AssessmentDashboard;
