import React, { useState, useEffect } from 'react';
import { useBookSessionData } from '../contexts/BookSessionDataProvider';
import { UIBackButton } from '../shared/ui/Back';
import { StyledHeader, ImageContainer, Selection, StyledUIButton } from './Coaching/styles';
import { Col, Row } from 'react-bootstrap';
import { openChat } from '../../../packs/freshchat';

const OutOfSession = () => {
  const {
    outOfSessionOption,
    setOutOfSessionOption,
    setCurrentStep,
    selectedSession
  } = useBookSessionData();
  const [ availableOptions, setAvailableOptions ] = useState({
    own_expense: false,
    request_session: false,
    salary_deduction: false,
  });
  const options = [
    `Request for a ${selectedSession} session`,
    'Book a session at your expense through salary deduction',
    'Book a session at your own expense',
  ];

  const [requestedSession, setRequestedSession] = useState(null);

  const fetchSessionRequests = async () => {
    const res = await fetch('/api/v2/meeting_sessions_requests');

    if (res.status == 200) {
      const response = await res.json();
      setRequestedSession(response);
    }
  }

  const fetchOutOfSessionOptions = async () => {
    const res = await fetch('/api/v2/out_of_session_options');
    if (res.status == 200) {
      const response = await res.json();
      setAvailableOptions(response)
    }
  };

  const handleSubmit = () => {
    switch(outOfSessionOption) {
      case options[0]:
        setCurrentStep('out_of_session_reason');
        break;
      case options[1]:
        setCurrentStep('salary_deduction_form');
        break;
      case options[2]:
        window.location = '/individual/dashboard/packages';
        break;
    }
  }

  useEffect(() => {
    fetchSessionRequests();
    fetchOutOfSessionOptions();
  }, []);

  return (
    <>
      <UIBackButton onClick={() => setCurrentStep('select_session')} />
      <StyledHeader>
        <p className="book-session">BOOK A SESSION</p>
        <h3>Out of Sessions</h3>
        <p className="pb-4">
          There are no sessions available to book. What would you like to do?
        </p>
      </StyledHeader>
      <Row className="justify-content-center m-0 pt-4 pb-4">
        <Col lg={4}>
          <ImageContainer className="text-center">
            <img src="/coaching/zero_sessions.svg" />
          </ImageContainer>
        </Col>
        <Col lg={4}>
          {options.map((option, index) => {
            if (!!requestedSession?.purpose && index == 0) {
              return (
                <Selection key={index} className="disabled h-auto">
                  You have a pending {requestedSession.purpose} session request.
                  You will receive an email notification regarding the status of
                  your request.
                </Selection>
              );
            } else if (
              (availableOptions.request_session && index == 0) ||
              (availableOptions.salary_deduction && index == 1) ||
              (availableOptions.own_expense && index == 2)
            ) {
              return (
                <Selection
                  key={index}
                  className={`${option == outOfSessionOption ? 'active' : ''}`}
                  onClick={() => setOutOfSessionOption(option)}>
                  {option}
                </Selection>
              );
            }
          })}
        </Col>
      </Row>
      <Row>
        <StyledUIButton
          text="Next"
          className={`mt-2 ${!outOfSessionOption ? 'disabled' : ''}`}
          disabled={!outOfSessionOption}
          action={() => handleSubmit()}></StyledUIButton>
      </Row>
      <Row>
        <p className="text-center m-3 mt-5 w-100">
          Infinit Care’s mental health support team is here to support you.{' '}
          <a style={{ color: '#1db4b3', cursor: 'pointer' }} onClick={openChat}>
            Reach out to us via CHAT.
          </a>
        </p>
      </Row>
    </>
  );
};
export default OutOfSession;
