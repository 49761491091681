import React from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

const StyledContainer = styled.div`
  width: 100%;
  .content-detail {
    cursor: pointer;
    margin-bottom: 37px;
    display: flex;
    flex-flow: row nowrap;

    .content-thumbnail {
      margin-right: 16px;
      max-width: 120px;
      height: auto;

      img {
        border-radius: 5px;
      }
    }

    .content-detail-description {
      text-align: left;
      font-size: 14px;
      letter-spacing: 0px;

      .title {
        font-weight: 600;
        color: #333333;
        opacity: 1;
      }

      .description {
        color: #191919;
        opacity: 0.7;
      }

      .estimated-time {
        margin-left: 8px;
        font-size: 12px;
        color: #333333;
        opacity: 1;
      }

      .played {
        margin-left: 8px;
        font-size: 12px;
        color: #1db4b3;
        opacity: 1;
      }

      .icon-times {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .icon {
          padding-bottom: 8px;
        }

        .play {
          cursor: pointer;
          width: 40px;
          height: 40px;
          margin-right: 10px;
          border-radius: 30px;
          background: #1db4b3 0% 0% no-repeat padding-box;
          opacity: 1;
          text-align: center;
          position: relative;

          img {
            width: 20px;
            height: 20px;
            position: absolute;
            top: 50%;
            right: 30%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
`;

const ExploreContentCategories = ({ content }) => {
  const history = useHistory();

  return (
    <StyledContainer>
      <div
        className="content-detail"
        onClick={() => {
          history.push(`/content/${content?.slug}`);
        }}>
        <div className="content-thumbnail">
          <img
            src={
              content?.image_url ? content?.image_url : '/default_article.png'
            }
            alt=""
          />
        </div>
        <div className="content-detail-description">
          <div className="title">{content?.title}</div>
          <div className="description">
            {content?.description.length > 180
              ? content?.description.slice(0, 180) + '...'
              : content?.description}
          </div>
          <div className="icon-times">
            {content?.content_type == 'video' && (
              <img className="icon" src="/video.svg" alt="" />
            )}
            {content?.content_type == 'audio' && (
              <img className="icon" src="/audio.svg" alt="" />
            )}
            {content?.content_type == 'article' && (
              <img className="icon" src="/book-black.svg" alt="" />
            )}
            <label className="estimated-time" htmlFor="">
              {content?.estimated_time}
              {content?.estimated_time}{' '}
              {parseInt(content?.estimated_time) > 1 ? ' mins' : ' min'}
            </label>
          </div>
        </div>
      </div>
    </StyledContainer>
  );
};

export default ExploreContentCategories;
