import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import PodcastCard from './PodcastCard';
import { Col } from 'react-bootstrap';

const PodcastHomeList = ({ podcasts }) => {
  const [podcastArray, setPodcastArray] = useState([]);

  useEffect(() => {
    const homePodcast = podcasts.slice(0, 3).filter(Boolean);
    setPodcastArray(homePodcast);
  }, [podcasts]);

  return (
    <Row className="mb-5">
      {podcastArray?.map((podcast) => (
        <Col
          key={podcast?.id}
          lg={{ span: 4, offset: 0 }}
          md={{ span: 10, offset: 1 }}
          style={{ marginTop: '2rem' }}>
          <PodcastCard podcast={podcast} />
        </Col>
      ))}
    </Row>
  );
};

export default PodcastHomeList;
