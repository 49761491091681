import React, { useEffect, useState } from 'react';
import DashboardCard from './DashboardCard';
import { Bar } from 'react-chartjs-2';
import { NoData } from './NoData';
import styled from '@emotion/styled';
import { Button } from 'react-bootstrap';
import { useAppData } from '../../contexts/AppDataProvider';

export const MonthlySessionsUtilization = ({ data, purpose }) => {
  let labels;
  const NavContainer = styled.div`
    width: fit-content;
    border-radius: 4px;
    display: flex;
    gap: 5px;
    padding: 5px;
    margin-left: auto;
    border: 1px solid #7e84a326;
    button {
      color: #7e84a3;
      background-color: white;
      &:hover,
      &:focus,
      &:active,
      &:focus-visible,
      &.active {
        color: #1db4b3 !important;
        background-color: #f8f9fa !important;
        box-shadow: none !important;
      }
    }
  `;
  const [currentPurpose, setCurrentPurpose] = useState(purpose);
  const [title, setTitle] = useState('');
  const [currentData, setCurrentData] = useState({ data: [], labels: [] });
  const { cancellation_period_time_in_words } = useAppData()
  const tooltipText = `${
    currentPurpose === 'counseling' ? 'Counseling' : 'Coaching'
  } sessions that were completed. Includes no-shows and cancellations made in less than ${cancellation_period_time_in_words}.`;

  useEffect(() => {
    if (currentPurpose) {
      if (data.hasOwnProperty('monthly_sessions')) {
        data = data.monthly_sessions[currentPurpose];
        setTitle('Monthly Sessions Utilization');
        labels = data.map((value) => `${value.month}, ${value.year}`);
      } else if (data.hasOwnProperty('daily_sessions')){
        data = data?.daily_sessions[currentPurpose];
        setTitle('Daily Sessions Utilization');
        labels = data.map((value) => value.day);
      }

      setCurrentData({ data: data, labels: labels });
    }
  }, [currentPurpose]);

  const options = {
    responsive: true,
    legend: {
      display: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
        stacked: true,
      },
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
    },
  };

  const chartData = {
    labels: currentData.labels,
    datasets: [
      {
        label: 'Done',
        data: currentData.data.map((value) => value.results['done']['count']),
        backgroundColor: '#97D7D2',
        borderRadius: 50,
        barThickness: 16,
        skipNull: true,
      },
      {
        label: 'Cancelled Paid',
        data: currentData.data.map(
          (value) => value.results['canceled_paid']['count'],
        ),
        backgroundColor: '#1DB4A8',
        borderRadius: 50,
        barThickness: 16,
        skipNull: true,
      },
      {
        label: 'No Show',
        data: currentData.data.map(
          (value) => value.results['no_show']['count'],
        ),
        backgroundColor: '#FED54D',
        borderRadius: 50,
        barThickness: 16,
        skipNull: true,
      },
      {
        label: 'Cancelled',
        data: currentData.data.map(
          (value) => value.results['canceled']['count'],
        ),
        backgroundColor: '#FEAF4D',
        borderRadius: 50,
        barThickness: 16,
        skipNull: true,
      },
      {
        label: 'Pending',
        data: currentData.data.map(
          (value) => value.results['pending']['count'],
        ),
        backgroundColor: '#F06680',
        borderRadius: 50,
        barThickness: 16,
        skipNull: true,
      },
    ],
  };

  return (
    <DashboardCard
      title={title}
      withQuestionMark={true}
      tooltipText={tooltipText}
      minHeight={'351px'}>
      <NavContainer>
        <Button
          variant="light"
          className={`${currentPurpose === 'counseling' ? 'active' : ''}`}
          onClick={() => setCurrentPurpose('counseling')}>
          Counseling
        </Button>
        <Button
          variant="light"
          className={`${currentPurpose === 'coaching' ? 'active' : ''}`}
          onClick={() => setCurrentPurpose('coaching')}>
          Coaching
        </Button>
      </NavContainer>
      {currentData?.data.length === 0 && (
        <NoData
          title={''}
          withQuestionMark={false}
          tooltipText={tooltipText}
        />
      )}
      {currentData?.data.length > 0 && (
        <Bar options={options} data={chartData} className="mt-4" />
      )}
    </DashboardCard>
  );
};
