import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { Row, Col, Image } from 'react-bootstrap';
import axios from 'axios';
import Layout from '../Layout';
import Track from '../shared/Track';

import { openChat } from '../../../packs/freshchat';
import { useAppData } from '../contexts/AppDataProvider';
import AssessmentHistory from './History';

const StyledContent = styled.div`
  height: 100%;
  .with-bg-image {
    background-image: url('/assessment/background.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    display: flex;
    > .row {
      width: 75%;
      margin: auto;
      display: flex;
      align-items: center;
    }

    @media (max-width: 991.98px) {
      background-size: contain;
      margin-bottom: 30px;
      > .row {
        width: 100%;
      }
      img {
        width: 40%;
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
    }
  }
  .with-history {
    width: 80%;
    margin: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1199.98px) {
      text-align: center;
      img {
        max-width: 200px !important;
        display: block;
        margin: auto;
        margin-bottom: 30px;
      }
    }
    @media (max-width: 991.98px) {
      width: 100%;
    }
  }
  .with-bg-image {
    @media (max-width: 1199.98px) {
      .col-md-8 {
        max-width: 100% !important;
        flex: 0 0 100%;
      }
    }
    @media (max-width: 1199.98px) and (min-width: 992px) {
      img {
        margin-bottom: 20px;
      }
    }
  }
`;
const StyledLeftContent = styled(Col)`
  > h1 {
    font-size: 2rem;
    margin-bottom: 25px;
    font-weight: bold;
    line-height: 45px;
  }
  > h6 {
    font-size: 1rem;
    margin-bottom: 30px;
    font-weight: bold;
    line-height: 30px;
  }
  > .p-container {
    font-size: 0.875rem;
    margin-bottom: 30px;
    p {
      line-height: 30px;
      font-size: 0.9375rem;
    }
    .last-p {
      margin-bottom: 30px !important;
    }
  }
  > .row {
    width: 80%;
    margin: auto;
  }
  .score {
    color: #1db4b3;
  }
  @media (max-width: 991.98px) {
    .score {
      display: inline-block;
    }
    > h1 {
      margin-top: 40px;
    }
  }
  a.clickable {
    color: #1db4b3;
    cursor: pointer;
  }
  a.clickable:hover {
    text-decoration: underline;
  }
`;
const StyledLink = styled(Link)`
  background: #1db4b3;
  font-size: 14px;
  font-weight: 500;
  padding: 15px 24px;
  text-decoration: none;
  color: white;
  border-radius: 5px;
  &:hover {
    color: white;
    text-decoration: none;
  }
  &.white {
    background-color: white;
    color: #1db4b3;
    border: 1px solid #1db4b3;
    margin-left: 16px;
  }
`;

const AssessmentResult = () => {
  const { currentAssessment, bookASessionLandingPage } = useAppData();
  const [assessments, setAssessments] = useState([]);
  const [showHistory, setShowHistory] = useState(null);

  const callAssessments = async () => {
    await axios.get('/api/v2/assessments?status=completed').then((res) => {
      setAssessments(res.data.reverse());
      setShowHistory(res.data.length > 1);
    });
  };

  useEffect(() => {
    if (currentAssessment?.is_retake) {
      callAssessments();
    }
  }, []);
  const ResultAssessmentScreen = () => (
    <StyledContent>
      <div className={`${showHistory ? 'with-history' : 'with-bg-image'}`}>
        <Row className="justify-content-center">
          {showHistory && (
            <Col xl={4} lg={12}>
              {currentAssessment.health_status == 'low' ? (
                <Image fluid={true} src="/assessment/low-mood.svg" />
              ) : (
                <Image fluid={true} src="/assessment/high-mood.svg" />
              )}
            </Col>
          )}
          {!showHistory && (
            <Col lg={4} className="d-xl-none">
              {currentAssessment.health_status == 'low' ? (
                <Image fluid={true} src="/assessment/low-mood.svg" />
              ) : (
                <Image fluid={true} src="/assessment/high-mood.svg" />
              )}
            </Col>
          )}
          <StyledLeftContent xl={8} lg={12} md={8}>
            <h1>
              You scored a total of{' '}
              <span className="score">{currentAssessment.score} points</span>
            </h1>
            <div className="p-container">
              {currentAssessment.health_status == 'low' ? (
                <>
                  <p>
                    Your score is likely indicative of reduced well-being. You
                    are recommended to seek mental health support via our{' '}
                    <a className="clickable" onClick={openChat}>
                      chat helpline
                    </a>{' '}
                    or{' '}
                    <Link
                      className="clickable"
                      to="/book_session"
                      onClick={() =>
                        Track.bookASession(bookASessionLandingPage())
                      }>
                      book a counseling session
                    </Link>{' '}
                    so a counselor can guide you on next steps.
                  </p>
                  <p className="last-p">
                    In the meantime, you are also advised to work on measures to
                    reduce stress in your personal or work life.
                  </p>
                  <StyledLink
                    to={{
                      pathname: '/',
                      state: { status: { should_take_assessment: false } },
                    }}>
                    Go Back Home
                  </StyledLink>
                </>
              ) : (
                <>
                  <p>
                    Your score is likely indicative of normal well-being.
                    Continue to look after your mental health by practicing good
                    mental hygiene: stay active, don't skimp on sleep and take
                    up a relaxation practice.
                  </p>
                  <p>
                    A coaching session will also help you sustain your growth.
                  </p>
                  <p className="last-p">
                    Book a coaching session and check out our resources to get
                    tips on how to look after your mental health.
                  </p>
                  <div>
                    <StyledLink to={'/explore'}>Check resources</StyledLink>
                    <StyledLink to={'/book_session'} className="white">
                      Book a session
                    </StyledLink>
                  </div>
                  <br />
                  <Link to={'/'} className="text-green font-weight-bold d-block mt-4">
                    Go Back Home
                  </Link>
                </>
              )}
            </div>
          </StyledLeftContent>
          {!showHistory && (
            <Col className="d-none d-xl-block" xl={4}>
              {currentAssessment.health_status == 'low' ? (
                <Image fluid={true} src="/assessment/low-mood.svg" />
              ) : (
                <Image fluid={true} src="/assessment/high-mood.svg" />
              )}
            </Col>
          )}
        </Row>
      </div>
    </StyledContent>
  );

  if (!currentAssessment) {
    return <Redirect to="/" />;
  }
  if (showHistory == null) {
    return <Layout />;
  }
  if (showHistory) {
    return (
      <Layout>
        <Row className="mt-5">
          <Col xl={9} lg={8} md={12}>
            <ResultAssessmentScreen />
          </Col>
          <Col xl={3} lg={4} md={12}>
            <AssessmentHistory assessments={assessments} />
          </Col>
        </Row>
      </Layout>
    );
  } else {
    return (
      <Layout>
        <ResultAssessmentScreen />
      </Layout>
    );
  }
};
export default AssessmentResult;
