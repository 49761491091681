import styled from '@emotion/styled';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const StyledCard = styled(Card)`
  box-shadow: 0px 1px 4px #15223214;
  border-radius: 6px;
  padding: 20px;
  border: none;
  margin-bottom: 24px;
  .chartjs-tooltip {
    -webkit-transform: translate(0%, 0);
    transform: translate(0%, 0);
    margin-left: 10px;
  }
  .chartjs-tooltip-header-item,
  .chartjs-tooltip-body-item {
    font-size: 12px !important;
  }
`;

export const CardContent = styled.div`
  min-height: 280px !important;
`;

export const Title = styled.h5`
  color: #7e84a3;
  font-size: 16px;
  font-weight: normal;
`;

export const QuestionMark = styled.img`
  padding-left: 8px;
`;

export const ViewAll = styled(Link)`
  color: #1db4b3;
  font-size: 12px;
  &:hover {
    color: #1db4b3;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const NoContentContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const DoughnutContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-top: 24px;

  .mood-chart {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .mood-results {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .dot {
      height: 12px;
      width: 12px;
      display: inline-block;
      margin-right: 10px;
    }

    .percent {
      font-weight: bold;
      font-size: 14px;
    }

    .value {
      font-size: 14px;
      display: block;
      margin-left: 26px;
      margin-top: 5px;
      margin-bottom: 15px;
    }

    @media (max-width: 575.98px) {
      .dot {
        height: 10px;
        width: 10px;
        margin-right: 10px;
      }

      .percent {
        font-size: 12px;
      }

      .value {
        font-size: 11px;
        margin-left: 20px;
      }
    }
  }
`;

export const HorizontalChartContainer = styled.div`
  min-width: '800px';
  margin: 'auto';
  @media (max-width: 991.98px) {
    min-width: auto;
  }
`;
