import React from 'react';
import Navbar from '../Layout/Navigation';
import QuestionForm from '../../Individual/QuestionsForm';

export default () => (
  <>
    <Navbar />
    <QuestionForm />
  </>
);
