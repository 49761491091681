import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Col, Card } from 'react-bootstrap';
import { truncate } from '../../../../packs/helpers';

const StyledContentCard = styled(Card)`
  line-height: 1.1rem;
  position: relative;
  padding-bottom: 4rem;
  &.related {
    height: 270px;
  }
  border: 0;
`;

const StyledContentCardTitle = styled(Card.Title)`
  font-size: 14px;
  font-weight: bold;
`;

const StyledContentCardText = styled(Card.Text)`
  font-size: 13px;
  line-height: 1.5;
  overflow: hidden;
`;

const StyledRightLink = styled.span`
  position: absolute;
  bottom: 15px;
  right: 15px;
  a {
    font-weight: bold;
    font-size: 0.8rem;
    color: #1db4b3;
    &:hover {
      text-decoration: none;
    }
  }
`;

const TimeText = styled.p`
  position: absolute;
  bottom: 0px;
  left: 15px;
  font-size: 13px;
  min-width: 100px;
  color: #191919;
  display: flex;
  span {
    justify-content: center;
    align-items: center;
    padding-top: 4px;
  }
`;

export const CardElement = ({ withContent = true, data }) => {
  return (
    <StyledContentCard
      className={`h-100 shadow-sm ${withContent ? '' : 'related'}`}>
      <Card.Img
        variant="top"
        src={data?.image_url ? data?.image_url : '/default_article.png'}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = '/default_article.png';
        }}
      />
      <Card.Body>
        <StyledContentCardTitle>{data?.title}</StyledContentCardTitle>
        <StyledContentCardText>
          {withContent && truncate(data?.description, 90)}
        </StyledContentCardText>
      </Card.Body>
      <TimeText className="time-text">
        {data?.content_type == 'video' && (
          <img className="mr-1" src="/play.svg" />
        )}
        {data?.content_type == 'audio' && (
          <img className="mr-1" src="/headset.svg" />
        )}
        {data?.content_type == 'article' && (
          <img className="mr-1" src="/book.svg" />
        )}
        <span>
          {data?.estimated_time}{' '}
          {parseInt(data?.estimated_time) > 1 ? ' mins' : ' min'}
        </span>
      </TimeText>
      <StyledRightLink className="right-link">
        <Link to={`/content/${data?.slug}`}>
          {data?.content_type == 'video'
            ? 'Start watching'
            : data?.content_type == 'audio'
            ? 'Start listening'
            : 'Start reading'}
        </Link>
      </StyledRightLink>
    </StyledContentCard>
  );
};
const ContentCard = ({ withContent = true, search = false, data }) => {
  return (
    <Col
      lg={search ? { span: 8, offset: 2 } : { span: 4, offset: 0 }}
      md={{ span: 10, offset: 1 }}
      style={{ marginTop: '2rem' }}>
      <CardElement withContent={withContent} data={data} />
    </Col>
  );
};

export default ContentCard;
export {
  StyledContentCard,
  StyledContentCardTitle,
  StyledContentCardText,
  StyledRightLink,
  TimeText,
};
