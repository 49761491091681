import React, { useState, useEffect } from 'react';
import styles from './Otp.module.scss';
import { Container } from 'react-bootstrap';
import OtpInput from './Otpinput';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import PublicLayout from '../PublicLayout';

const Otp = () => {
  const background = '/otp/background.svg';

  const [otp, setOtp] = useState('');
  const [resendTimer, setResendTimer] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const location = useLocation();
  const [phone, setPhone] = useState(null);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const registrationPhone = location.state?.registration_phone || null;
    const cookiePhone = Cookies.get('phone');

    if (registrationPhone) {
      setPhone(registrationPhone);
    } else if (cookiePhone) {
      setPhone(cookiePhone);
    }
  }, [location.state, Cookies.get('phone')]);

  useEffect(() => {
    if (phone) {
      sendCode();
    }
  }, [phone]);

  const sendCode = async () => {
    if (location.state?.registration_phone) {
      await axios.post('/api/v2/otp/send_code', { phone });
    }
  };

  const handleOtpChange = (value) => {
    setOtp(value);
  };

  const handleSubmit = async () => {
    try {
      const res = await axios.post('/api/v2/otp/login_code', { otp, phone });

      if (
        res.status === 200 &&
        res.data.user.email_verified === false &&
        !res.data.user.skipped_email_verify
      ) {
        Cookies.set('phone', phone, { expires: 5 / 1440 });
        window.location = '/verify_email';
      } else {
        window.location = '/';
      }
    } catch (_err) {
      if (_err['response']['data']['error']) {
        setErrors([_err['response']['data']['error']]);
        setOtp('');
      } else {
        console.log('errors', err);
      }
    }
  };

  const handleResend = async () => {
    setResendTimer(60);
    setIsResendDisabled(true);
    await axios.post('/api/v2/otp/send_code', { phone });
  };

  useEffect(() => {
    if (otp.length === 6) {
      handleSubmit();
    }
  }, [otp]);

  useEffect(() => {
    if (resendTimer > 0) {
      const timerInterval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
        if (resendTimer === 1) {
          setIsResendDisabled(false);
          clearInterval(timerInterval);
        }
      }, 1000);

      return () => clearInterval(timerInterval);
    }
  }, [resendTimer]);

  return (
    <>
      <PublicLayout>
        <Container className={styles.otp_background}>
          <img src={background} alt="" className={styles.background} />
          <div className={styles.header}>
            Enter your 6-digit verification code
          </div>
          <div className={styles.otp_container}>
            {phone && (
              <p className={styles.message}>
                A code was sent to your registered number **** ***{' '}
                {phone.substr(-4)}
              </p>
            )}

            <OtpInput numInputs={6} onOtpChange={handleOtpChange} />

            {errors.length > 0 && (
              <div>
                {errors.map((err, index) => {
                  return (
                    <small key={index} style={{ color: 'red' }}>
                      {err}
                    </small>
                  );
                })}
              </div>
            )}
            <div className={styles.resend_timer_container}>
              {isResendDisabled && (
                <label htmlFor="">
                  Resend code in
                  <a
                    className={styles.resend_timer}
                    disabled={isResendDisabled}>
                    {' '}
                    {resendTimer}s
                  </a>
                </label>
              )}

              {!isResendDisabled && (
                <label htmlFor="">
                  <a className={styles.resend_button} onClick={handleResend}>
                    Resend
                  </a>
                </label>
              )}
            </div>
            <div className={styles.contact_us}>
              Need help?
              <a href="mailto:support@infinitcare.co"> Contact us</a>
            </div>
          </div>
        </Container>
      </PublicLayout>
    </>
  );
};

export default Otp;
