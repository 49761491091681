import React from 'react';
import qs from 'query-string';
import DashboardCard from './DashboardCard';
import { DashboardTable } from '../../Company/MeetingSessionsRequests';

export const CounselingSessionRequest = ({ data, siteId, companyId }) => {
  const queryString = qs.stringify(
    {
      site_id: null,
      ref: companyId, //writing ref instead of company_id
    },
    { skipEmptyString: true, skipNull: true },
  );

  const linkUrl = `/company/meeting_sessions_requests?${queryString}`;

  return (
    <DashboardCard
      title={'Counseling Session Requests'}
      withViewAll={true}
      linkUrl={linkUrl}>
      <DashboardTable data={data} siteId={siteId} companyId={companyId} />
    </DashboardCard>
  );
};
