import React, { useEffect } from 'react';
import 'react-day-picker/dist/style.css';
import { UIBackButton } from '../../shared/ui/Back';
import { StyledHeader } from './styles';
import { CoachingQuestionsForm } from "../../../Individual/QuestionsForm"
import { useBookSessionData } from '../../contexts/BookSessionDataProvider';
import { useAppData } from '../../contexts/AppDataProvider';
import Track from '../../shared/Track';

const Preferences = () => {
  const { setCurrentStep } = useBookSessionData();
  const { setHasPreference, consentSigned } = useAppData();
  const onSubmit = () => {
    let nextStep = consentSigned ? 'select_date_time' : 'consent_form';
    setCurrentStep(nextStep);
    setHasPreference(true);
  };

  useEffect(() => {
    Track.pageView({
      page: 'Preference Page',
      purpose: 'counseling',
    });
  }, []);

  return (
    <>
      <UIBackButton onClick={() => setCurrentStep('select_session')} />
      <StyledHeader>
        <p className="book-session">BOOK A SESSION</p>
        <h3>Help us match you to the right counselor</h3>
        <p className="mb-0">
          Please answer a few questions on your preferences
        </p>
      </StyledHeader>
      <CoachingQuestionsForm onSubmit={onSubmit} />
    </>
  );
};
export default Preferences;
