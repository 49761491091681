import React from 'react';
import styled from '@emotion/styled';
import { Container } from 'react-bootstrap';

import { useAppData } from '../contexts/AppDataProvider';
import Cloaking from '../shared/Cloaking';
import Navigation from './Navigation';
import Footer from '../shared/Footer';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../globals.scss';
import '../../../../../public/stylesheets/quill.css';
import UIProgressBar from '../shared/ui/ProgressBar';

const ContainerWrapper = styled(Container)`
  margin-top: 2.5rem;
`;

const Layout = ({ children, progressBar = false, progressBarValue }) => {
  const { isCloaked, firstName, lastName } = useAppData();
  const user = firstName + ' ' + lastName;
  return (
    <>
      <Navigation />
      <UIProgressBar now={progressBarValue} />
      {isCloaked && <Cloaking user={user} />}
      <ContainerWrapper>{children}</ContainerWrapper>
      <Footer />
    </>
  );
};

export default Layout;
