import React, { useState, useEffect, useMemo } from 'react';
import { Row } from 'react-bootstrap';
import styled from '@emotion/styled';
import { useLocation, Link } from 'react-router-dom';
import ContentArray from '../Content/Array';
import PodcastDashboard from '../Dashboard/PodcastDashboard';
import PodcastHomeList from '../Podcast/PodcastHomeList';
import PodcastList from '../Podcast/PodcastList';
import useSWR from 'swr';
import axios from 'axios';
import qs from 'query-string';
import ExploreContentCategories from './ExploreContentCategories';

const StyledContainer = styled.div`
  text-align: center;

  .filters {
    margin-bottom: 50px;
    .pill {
      background: #f8f9fa 0% 0% no-repeat padding-box;
      border-radius: 23px;
      opacity: 1;

      text-align: left;
      font-size: 14px;
      letter-spacing: 0px;
      color: #191919;

      padding: 6px 32px 6px 32px;
      border: none;
      margin: 5px;
    }

    .active {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #1db4b3;
      font-weight: bold !important;
      border-radius: 23px;
      opacity: 1;
      color: #1db4b3;
    }
  }

  .podcast-header,
  .content-header {
    text-align: left;
    .podcast-text {
      text-align: left;
      font-size: 0.75rem;
      letter-spacing: 1.6px;
      opacity: 0.7;
      text-transform: uppercase;
      font-weight: bold;
    }

    .new {
      border: none;
      background: #eefcce 0% 0% no-repeat padding-box;
      border-radius: 2px;
      opacity: 1;

      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1.2px;
      color: #1db4b3;
      text-transform: uppercase;
      opacity: 0.7;
    }

    .see-all {
      background: #ffffff;
      border: none;
      float: right;
      text-align: right;
      font-size: 13px;
      font-weight: 700 !important;
      letter-spacing: 0px;
      color: #1db4b3;
    }
  }

  .podcast-description,
  .content-description {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    opacity: 1;
  }

  .podcast {
    text-align: left;
    color: #333333;
  }
`;

const ExploreFilter = ({ categories }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentView, setCurrentView] = useState('all');
  const [currentCategory, setCurrentCategory] = useState({});
  const [currentContents, setCurrentContents] = useState([]);
  const [canSeeMore, setCanSeeMore] = useState(true);
  const [podcasts, setPodcasts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [withPodcast, setWithPodcast] = useState(false);
  const [metadata, setMetadata] = useState({});
  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data: dashboard } = useSWR('/api/v2/dashboard', fetcher, {
    revalidateOnFocus: false,
  });

  const view = queryParams.get('view');

  useEffect(() => {
    setCurrentView(view || 'all');

    let found = categories?.filter(
      (category) => category.slug === view,
    );
    
    if (found != undefined && view) {
      handleCategoryChange(found[0]);
      concatenateContentsFromCurrentPage(found[0].id);
    }
    
    
    
    
    
  }, [view]);

  useEffect(() => {
    if (currentView != 'podcast' && currentView != 'all') {
      let found = categories?.filter(
        (category) => category.slug === currentView,
      );
      if (found != undefined) {
        handleCategoryChange(found[0]);
        concatenateContentsFromCurrentPage(found[0].id);
      }
    }
  }, [categories]);

  const handleViewChange = (view) => {
    setCurrentView(view);
  };

  const podcastCategory = useMemo(
    () => categories?.find((category) => category.slug === 'podcast'),
    [categories],
  );

  const handleCategoryChange = (category) => {
    setCurrentCategory(category);
  };

  useEffect(() => {
    if (categories){
      if (podcastCategory) {
        setWithPodcast(true);
        getPodcasts(currentPage);
      } else if (view == 'podcast') {
        window.location.href = '/explore';
      }
    }
  }, [categories]);

  const getPodcasts = async (page) => {
    const res = await fetch(`/api/v2/podcasts?page=${page}`);
    if (res.status === 200) {
      const data = await res.json();
      setPodcasts([...podcasts, ...data?.podcasts]);
      setMetadata(data);
    }
  };

  const fetchCategoryContents = async (categoryId, page) => {
    const queryString = qs.stringify({
      category_id: categoryId,
      paginated: true,
      page: page,
    });

    return await fetch(`/api/v2/contents?${queryString}`);
  };

  const concatenateContentsFromCurrentPage = async (id) => {
    const res = await fetchCategoryContents(id, 1);
    if (res.status === 200) {
      const data = await res.json();
      const { contents, last_page } = data;
      if (1 >= last_page) setCanSeeMore(false);
      setCurrentContents(contents);
    }
  };

  const seeMoreContent = async () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);

    const res = await fetchCategoryContents(currentCategory?.id, nextPage);

    if (res.status === 200) {
      const data = await res.json();
      const { contents, last_page } = data;

      if (nextPage >= last_page) setCanSeeMore(false);
      setCurrentContents([...currentContents, ...contents]);
    }
  };

  const loadMorePodcast = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    getPodcasts(nextPage);
  };

  const handleSelectPill = (category) => {
    window.location.href = `/explore?view=${category.slug}`;
  };

  return (
    <StyledContainer>
      <div className="filters">
        <button
          onClick={() => (window.location.href = `/explore?view=all`)}
          className={currentView == 'all' ? 'pill active' : 'pill'}>
          All
        </button>
        {categories?.map((category) => (
          <button
            key={category.id}
            onClick={() => handleSelectPill(category)}
            className={currentView == category.slug ? 'pill active' : 'pill'}>
            {category.title}
          </button>
        ))}
      </div>

      {currentView == 'all' &&
        withPodcast &&
        dashboard?.nudges?.show_podcast_banner && <PodcastDashboard />}

      {currentView == 'all' && (
        <>
          {withPodcast && (
            <>
              <div className="podcast-header">
                <label className="podcast-text">PODCASTS</label>{' '}
                <button className="new">NEW!</button>
                <Link
                  to={'/explore?view=podcast'}
                  className="text-green font-weight-bold see-all">
                  See All
                </Link>
              </div>
              <div className="podcast-description">
                {podcastCategory?.description}
              </div>
              {podcasts && <PodcastHomeList podcasts={podcasts} />}
            </>
          )}
          {categories?.map((categoryData, index) => (
            <div key={index} className="mb-5">
              <ContentArray categoryData={categoryData} />
            </div>
          ))}
        </>
      )}

      {currentView != 'podcast' && currentView != 'all' && (
        <>
          <div className="content-header">
            <label className="podcast-text">{currentCategory?.title}</label>
          </div>
          <div className="content-description mb-5">
            {currentCategory?.description}
          </div>
          <Row>
            {currentContents?.map((content, index) => (
              <ExploreContentCategories key={index} content={content} />
            ))}
            {canSeeMore && (
              <button
                onClick={seeMoreContent}
                className="text-green mt-4 mx-auto p-2 w-100 border-0"
                style={{ background: '#1DB4B30D 0% 0% no-repeat padding-box' }}>
                See more
              </button>
            )}
          </Row>
        </>
      )}

      {currentView == 'podcast' && (
        <div className="podcast">
          <div className="podcast-header">
            <label className="podcast-text">PODCASTS</label>{' '}
            <button className="new">NEW!</button>
          </div>
          <div className="podcast-description mb-5">
            {podcastCategory?.description}
          </div>

          {podcasts?.length > 0 &&
            podcasts?.map((podcast) => (
              <PodcastList key={podcast.id} podcast={podcast} />
            ))}
          {metadata?.last_page > currentPage && (
            <button
              onClick={() => loadMorePodcast()}
              className="text-green mt-4 mx-auto p-2 w-100 border-0"
              style={{ background: '#1DB4B30D 0% 0% no-repeat padding-box' }}>
              See more
            </button>
          )}
        </div>
      )}
    </StyledContainer>
  );
};

export default ExploreFilter;
