import React, { useContext } from "react";
import { css, cx } from "emotion";
import Title from "../Title";
import Spinner from "../Spinner";
import useProfessionals from "../shared/useProfessionals";

const FeaturedCounselors = () => {
  const [professionalState] = useProfessionals({ limit: 4 });
  return (
    <div
      id="featured-counselors"
      className={cx(
        "bg-light mb-5",
        css`
          font-size: 0.8rem;
        `
      )}
    >
      <Title
        className="text-dark"
        title="Meet some of our counselors"
        description=""
      >
        <div className="row justify-content-center">
          <div className="col-lg-8 col-xs-12 px-3">
            Our counselors are licensed and experienced mental health
            practitioners who have completed the necessary training, education
            and clinical practice to help you deal with the issues that you
            face.
          </div>
        </div>
      </Title>
      <div className="container">
        {professionalState.professionals ? (
          <div className="card-deck">
            {professionalState.professionals.map((professional, index) => {
              return (
                <div key={index} className="card shadow-sm">
                  <div
                    className={cx(
                      "card-body border-bottom bg-light",
                      css`
                        height: 200px;
                        max-height: 200px;
                      `
                    )}
                  >
                    <div className={cx("text-center mb-2")}>
                      <img
                        src={
                          professional.avatar_url || "https://picsum.photos/200"
                        }
                        className={cx(
                          "border-danger rounded-circle",
                          css`
                            width: 100px;
                            border: 3px solid lightgray !important;
                          `
                        )}
                      />
                    </div>
                    <h3 className="text-center h5 font-weight-bold">
                      {`${professional.full_name} ${professional.suffix || ""}`}
                    </h3>
                  </div>
                  <div className="card-body">
                    <p className="text-muted text-uppercase">Expertise</p>
                    <ul>
                      {professional.expertises.map((expertise, index) => (
                        <li key={index}>{expertise.name}</li>
                      ))}
                    </ul>
                    {professional.educations.map((education, index) => (
                      <div key={index}>
                        <p className="text-muted text-uppercase">
                          {education.school}
                        </p>
                        {education.notes !== "" && <p>{education.notes}</p>}
                        <p className="font-weight-bold">{education.degree}</p>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

export default FeaturedCounselors;
