import React, { useState } from 'react';
import 'react-day-picker/dist/style.css';
import { UIBackButton } from '../../shared/ui/Back';
import { StyledHeader } from './styles';
import { Col, Form, Row } from 'react-bootstrap';
import { useAppData } from '../../contexts/AppDataProvider';
import styled from '@emotion/styled';
import moment from 'moment';
import { UISubmitButton } from '../../shared/ui/Button';
import { useBookSessionData } from '../../contexts/BookSessionDataProvider';

export const StyledUISubmitButton = styled(UISubmitButton)`
  width: fit-content !important;
  margin: auto;
  padding: 12px 51px;
`;

export const StyledLi = styled.li`
  margin-top: 10px;
  font-size: 14px;
`;

const StyledLink = styled.a`
  color: #00a2a1;
  &:hover {
    color: #00a2a1;
  }
`;

const ConsentForm = () => {
  const { companyName, setConsentSigned, hasPreference, hasEmergencyContact } = useAppData();
  const { selectedSession, setCurrentStep } =
    useBookSessionData();
  const { cancellation_period_time_in_words } = useAppData()
  const [form, setForm] = useState({});
  const currentDate = moment().format('MMMM D, YYYY');
  const prevStep =
    selectedSession == 'coaching' ? 'select_reasons' : 'select_session';
  const handleOnChange = (event) => {
    let updatedAttribute = null;
    if (event.target.name == 'accepted') {
      updatedAttribute = { [event.target.name]: event.target.checked };
    } else {
      updatedAttribute = { [event.target.name]: event.target.value };
    }
    setForm({ ...form, ...updatedAttribute });
  };

  async function handleOnSubmit(event) {
    event.preventDefault();
    const payload = {
      consent: {
        date_signed: currentDate,
        name: form.signature,
        accepted: form.accepted,
      },
      emergency_contact: {
        relationship: form.relationship,
        phone: form.phone,
        name: form.name,
      },
    };
    const res = await fetch('/api/v2/consents', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    if (res.status === 200) {
      setCurrentStep('select_date_time');
      setConsentSigned(true);
    }
  }

  function handleNextStep() {
    setCurrentStep('select_date_time');
  }


  return (
    <>
      <UIBackButton onClick={() => setCurrentStep(prevStep)} />
      <StyledHeader>
        <p className="book-session">BOOK A SESSION</p>
        <h4 className="mb-3 font-weight-bold">
          Infinit Care Online Counseling and Coaching Services
        </h4>
        <h5 className="font-weight-bold">Confidentiality & Consent Form</h5>
      </StyledHeader>
      <Row>
        <Col>
          <ol>
            <StyledLi>
              I hereby authorize the mental health practitioners of Infinit Care to conduct coaching/counseling services via video calling technology (such as Zoom).
            </StyledLi>
            <StyledLi>
              I understand that I need to use a webcam, smartphone or any gadget with video capability during the session.
            </StyledLi>

            <StyledLi>
              I understand that all sessions may be audio/video recorded by life coaches/counselors and other authorized personnel. It is also my understanding that these recordings shall be for the following purposes: quality control, training, view or review sessions to further support you, conduct research and assessment, and build predictive models to improve Infinit Care’s services. The recordings shall be used solely as authorized by Infinit Care. I understand that the session/s shall remain strictly confidential. I fully understand that I may withdraw my permission to record future sessions at any point by submitting such withdrawal request to <StyledLink href="mailto:support@infinitcare.co">support@infinitcare.co</StyledLink>
            </StyledLi>  
            
            <StyledLi>
              I understand that technical difficulties or interruptions may occur before or during the coaching/counseling sessions and my appointment cannot be started or ended as intended. The Infinit Care counselor will wait for 15 minutes before ending the session and will reschedule the session as necessary.
            </StyledLi>

            <StyledLi>
              I agree to be in a quiet, private space that is free of distractions (including cell phone or other devices) during the session.
            </StyledLi>

            <StyledLi>
              I will strive to be on time. If I will cancel or change my appointment, I will notify <StyledLink href="mailto:support@infinitcare.co">support@infinitcare.co</StyledLink> at least {cancellation_period_time_in_words} before my appointment. Otherwise, the appointment or session is considered completed.
            </StyledLi>
            
            <StyledLi>
              I agree that my records can be kept for further evaluation, analysis, and documentation, and in all of these, my information will be kept private.
            </StyledLi>

            <StyledLi>
              The laws that protect confidentiality in a face-to-face coaching/counseling setting also apply to online counseling. Therefore, I am the only person who can avail of this service, unless stated otherwise. As such, I understand that the information disclosed by me during the course of my counseling session is generally confidential.
            </StyledLi>

            <StyledLi>
              I understand that the online coaching/counseling is different from in-person therapy and that if my counselor believes I would be better served by another form of intervention, I will be referred to another specialist.
            </StyledLi>

            <StyledLi>
              I confirm that I am voluntarily availing of the coaching/counseling services and I am not coerced into a coaching/counseling session.
            </StyledLi>
            
          </ol>
        </Col>
      </Row>
      <Row className="m-2">
        <Col>
          <h6 className="font-weight-bold">CONFIDENTIALITY PROCEDURES:</h6>
          <p>
            Infinit Care guarantees strict confidentiality of personal data and coaching/counseling information shared by myself. Without my written consent, no one can have access to the information that I shared during the sessions. Only in exceptional circumstances such as abuse, suicidal attempt, or harmful actions against self or others will this rule of confidentiality be overridden.
          </p>
        </Col>
      </Row>
      {
        !hasEmergencyContact && 
        <Row className="m-2">
          <Col xl={12}>
            <h6 className="font-weight-bold">Emergency Contact Information</h6>
            <Form onSubmit={handleOnSubmit}>
              <Row className="mb-3">
                <Col md={4}>
                  <label className="mb-0" htmlFor="name">
                    Name *
                  </label>
                  <input
                    id="name"
                    name="name"
                    className="form-control"
                    required
                    onChange={handleOnChange}
                  />
                </Col>
                <Col md={4}>
                  <label className="mb-0" htmlFor="relationship">
                    Relationship *
                  </label>
                  <select
                    id="relationship"
                    name="relationship"
                    onChange={handleOnChange}
                    className="form-control"
                    required
                    value={form?.relationship || ''}>
                    <option value="">select</option>
                    {[
                      'Spouse',
                      'Sibling',
                      'Parent',
                      'Guardian',
                      'Relative',
                      'Other',
                    ].map((option, index) => (
                      <option value={option} key={index}>
                        {option}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col md={4}>
                  <label className="mb-0" htmlFor="phone">
                    Contact Number *
                  </label>
                  <input
                    id="phone"
                    name="phone"
                    className="form-control"
                    required
                    onChange={handleOnChange}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={8}>
                  <label className="mb-0" htmlFor="signature">
                    Write your name as your electronic signature: *
                  </label>
                  <input
                    id="signature"
                    name="signature"
                    className="form-control"
                    required
                    onChange={handleOnChange}
                  />
                </Col>
                <Col md={4}>
                  <label className="mb-0" htmlFor="date_signed">
                    Date
                  </label>
                  <input
                    id="date"
                    name="date_signed"
                    className="form-control"
                    required
                    disabled
                    value={currentDate}
                    onChange={handleOnChange}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Check
                    id="accepted"
                    type="checkbox"
                    name="accepted"
                    required
                    onChange={handleOnChange}
                    label="I agree to these terms and conditions. *"
                  />
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                <StyledUISubmitButton text="Next: Date & Time" />
              </div>
            </Form>
          </Col>
        </Row>
      }

      {
        hasEmergencyContact &&
        <Row>
          <Col xl={12}>
            <Form onSubmit={handleNextStep}>
              <Row className="mb-3">
                <Col>
                  <Form.Check
                    id="accepted"
                    type="checkbox"
                    name="accepted"
                    required
                    onChange={handleOnChange}
                    label="I agree to these terms and conditions. *"
                  />
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                <StyledUISubmitButton text="Next: Date & Time" />
              </div>
            </Form>
          </Col>
        </Row>
      }
      
    </>
  );
};
export default ConsentForm;
